<template>
    <div>
        <div
            :style="$grid('5-1')"
            class="credit-application-lender-wrapper"
            ref="inputsend"
        >
            <InputRichDropdown
                label="Select a lender"
                lazyLoad
                :list="sortedLenderList"
                :valueMap="valuePredicate"
                :display="displayPredicate"
                :desc="descriptionPredicate"
                :iconCondition="balloonIconCondition"
                v-model:saturn="dropdownSelectedLender"
                :sortMode="DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                :invalid="!fimenu.dealType"
                :disabled="!fimenu.dealType || isEvaluatingExpresions || loadingCustomFields || onlyShowBalloonLenders"
                search
                class="ignore-deal-lock"
                @change="handleLenderChange"
            >
                <template #item-icon>
                    <img
                        src="@static/fa/svgs/custom/BalloonPayments.svg"
                        alt="balloon-payments"
                    />
                </template>
                <template #validation>
                    <span>You must select a deal type before selecting a lender.</span>
                </template>
            </InputRichDropdown>

            <div v-if="isEvaluatingExpresions || loadingCustomFields">
                <i class="fas fa-spinner fa-spin" />
            </div>
            <button
                v-else
                :disabled="!dropdownSelectedLender || balloonAmountIsNeeded"
                class="button-block ignore-deal-lock"
                @click="handleSendButtonAction"
            >
                Send
            </button>
        </div>
        <div ref="applicationLenderPanel">
            <PanelDealTerms
                v-if="displayBOPanelDealTerms"
                :fimenu="props.fimenu"
                :panelObject="dealTermsPanelObj"
                :validation
                :setGapPricing="() => fimenu.priceProducts([{ productType: PRODUCT_TYPES.GAP }], null, false)"
                :dealSave="() => fimenu.save()"
                :hideTerms="true"
                :dealTermsChangedHandler="dealTermsChangedHandler"
            />
        </div>

        <Panel
            v-bind="{ title: 'Lender Applications' }"
            class="panel-routeOneDealerTrack"
        >
            <template #header>
                <PanelCreditApplicationErrorMessage
                    :canSubmitCreditApplication
                    :creditApplications
                    :fimenu-lender-code="dealHasNoLender ? null : fimenu.lender?.lenderCode"
                    :selected-lender="dealHasNoLender ? null : (dropdownSelectedLender as VersionsLender)"
                />
            </template>
            <IsBusyScreenComponent v-if="isLoading" />
            <div :style="$grid('1')">
                <div class="applications-box-container">
                    <!-- LENDERS -->
                    <div
                        v-for="(lender, index) in preSelectedLenders"
                        :key="index"
                        :style="$grid('1-13-3')"
                        class="ApplicationsBox no-margin-grid"
                    >
                        <InputCheckbox
                            :useSquare="true"
                            :isControlled="true"
                            :modelValue="fimenu.lender?.isActiveOnDeal && fimenu.lender?.lenderCode == lender.lenderCode"
                            cssClass="button-nothing button-nothing-green"
                            @click="() => handleSelectLender(lender)"
                            :invalid="dealHasNoLender"
                            :disabled="lender.lenderCode == null || fimenu.dealStatus >= DEAL_STATUS.ACCOUNTING_IN_PROCESS"
                            class="ignore-deal-lock"
                        />

                        <div
                            :style="$grid('0.2-6-2')"
                            style="gap: 4px"
                        >
                            <i
                                class="fas fa-university"
                                style="color: green"
                            ></i>
                            <span style="font-weight: 600; margin: 0px">{{ lender.lenderName?.toUpperCase() }}</span>
                            <Pill
                                status="success"
                                label="Balloon"
                                slotName="balloonIcon"
                                v-show="showBalloonIcon(lender.lenderCode)"
                            >
                                <template #balloonIcon>
                                    <img
                                        src="@static/fa/svgs/custom/BalloonPayments.svg"
                                        alt="balloon-payments"
                                    />
                                </template>
                            </Pill>
                        </div>

                        <button
                            @click="() => openLenderInformationModal(lender)"
                            class="ignore-deal-lock"
                            :disabled="disableViewbuttonForCash(lender)"
                        >
                            <i class="fas fa-info-circle" />
                        </button>
                    </div>
                    <!-- CREDIT APPLICATIONS -->
                    <div
                        v-for="(application, index) in onlyShowBalloonLenders ? creditApplicationsWithBalloon : creditApplications"
                        :key="index"
                        :style="$grid('1-13-3')"
                        class="ApplicationsBox no-margin-grid"
                    >
                        <InputCheckbox
                            :useSquare="true"
                            :isControlled="true"
                            :modelValue="application.selected"
                            cssClass="button-nothing button-nothing-green"
                            @click="() => handleSelectApplication(application)"
                            :invalid="application.lender.lenderCode != null && dealHasNoLender"
                            :disabled="application.lender.lenderCode == null || fimenu.dealStatus >= DEAL_STATUS.ACCOUNTING_IN_PROCESS"
                            class="ignore-deal-lock"
                            style="padding-right: 0%"
                        />

                        <div
                            :style="$grid('0.2-6-2')"
                            style="gap: 4px"
                        >
                            <i
                                :class="getAction(application).icon"
                                style="height: fit-content"
                            />
                            <p style="font-weight: 600; margin: 0px">{{ lenderName(application) }}</p>
                            <Pill
                                status="success"
                                label="Balloon"
                                slotName="balloonIcon"
                                v-show="application.submissionInformation?.balloonAmount > 0 && showBalloonIcon(application.lender.lenderCode)"
                            >
                                <template #balloonIcon>
                                    <img
                                        src="@static/fa/svgs/custom/BalloonPayments.svg"
                                        alt="balloon-payments"
                                    />
                                </template>
                            </Pill>
                        </div>
                        <div>
                            <button
                                @click="() => openViewModal(application)"
                                class="ignore-deal-lock"
                            >
                                <i class="fas fa-info-circle"></i>
                            </button>
                        </div>

                        <!-- FOOTER -->
                        <div class="footer-container grid-3-column no-margin-grid">
                            <div style="display: flex; gap: 5px">
                                <span style="font-size: 13px">
                                    {{ application.decision.decisionDateTime ? util.toMoment(application.decision.decisionDateTime).format('MM/DD/YYYY - hh:mma') : '-' }}
                                </span>
                                <!-- <i class="SubmissionIcon fas fa-solid fa-paper-plane" /> -->
                            </div>

                            <div style="font-size: 13px; display: flex; gap: 3px">
                                <span>Rate: {{ getFormattedRateFromDecision(application) + `${isMFRateForApplication(application) ? "%": ""}` }}</span>
                                /
                                <span>Term: {{ application?.decision?.decisionNumbers?.term ?? '' }}</span>
                                /
                                <span class="tier-text"> Tier: {{ application?.decision?.tier ?? '-' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <LenderInformation
                    v-else-if="reserveProfitCalcs && reserveProfitCalcs.length && dealLenderInformation && !canSubmitCreditApplication"
                    :deal="fimenu"
                    :selectedLender="dealLenderInformation"
                    :validation="validation"
                    :lendersList="lendersList"
                    :reserveProfitCalcs="reserveProfitCalcs"
                /> -->
            </div>
        </Panel>
    </div>
</template>

<script setup lang="ts">
    import { computed, inject, nextTick, onMounted, Ref, ref, useTemplateRef, watch, watchEffect } from 'vue';
    import CreditApplication, { CreditApplicationDecision, CreditApplicationType } from '@core/classes/CreditApplication';
    import { CreditApplicationsEnabled, DisplayCreditApplication, RetrieveCustomFields, SubmitCreditApplication } from '@/helpers/finance-channels-helper';
    import { DEAL_STATUS, DROPDOWN_SORT_MODE, PARTNER_CODES, PRODUCT_TYPES } from '@core/classes/Enums';
    import { DealType, FinanceChannelsProvider } from '@core/classes/SharedEnums';
    import util, { EventBusCore } from '@core/services/util';
    import $modal from '@core/services/modal';
    import _ from 'underscore';
    import api from '@core/services/api';
    import { SetStatusToReverifyIfNeeded } from '@core/classes/FIMenuHelper';

    import InputCheckbox from '@core/components/InputCheckbox.vue';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue';
    import ModalLenderInformation from '@/modals/modalLenderInformation.vue';
    import modalRouteOneSend from '@/modals/modalRouteOneSend.vue';
    import modalRouteOneView from '@/modals/modalRouteOneView.vue';
    import Panel from '@core/components/Panel.vue';
    import PanelCreditApplicationErrorMessage from './PanelCreditApplicationErrorMessage.vue';
    import PanelDealTerms from './PanelDealTerms.vue';
    import Pill from '@core/components/Pill.vue';

    import { AlternativeLenderCode } from '@core/classes/AlternativeLenderCode';
    import FIDealJacketAdditionalInformation from '@core/classes/FIDealJacketAdditionalInformation';
    import { FIDynamicFields } from '@core/classes/FIDynamicFields';
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuCreditApplication from '@core/classes/FIMenuCreditApplication';
    import FIMenuLender from '@core/classes/FIMenuLender';
    import IReserveProfitCalc from '@core/classes/IReserveProfitCalc';
    import LenderDraft from '@core/classes/LenderDraft';
    import LenderLease from '@core/classes/LenderLease';
    import LenderRetail from '@core/classes/LenderRetail';
    import LenderVersionClass from '@core/classes/LenderVersionClass';
    import PartnerCode from '@core/classes/Store/PartnerCode';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';
    import VersionsLender from '@core/classes/LenderVersionClass';

    //#region Common Variables
    const $global = inject<SaturnGlobalPlugin>('$global');
    const balloonLenders = inject<string[]>('balloonLenders', []);
    const balloonIconCondition = inject<any>('balloonIconCondition', null);
    const bo = inject<any>('buyersOrder');
    const onlyShowBalloonLenders = inject<boolean>('onlyShowBalloonLenders', false);
    const activeCreditApplicationOrLender = inject<Ref<boolean>>('activeCreditApplicationOrLender', ref(false));

    const props = defineProps<{
        fimenu?: FIMenu | null;
        validation?: any;
    }>();
    //#endregion

    const r1DealerId = ref<string | null>(props.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === PARTNER_CODES.ROUTEONE_DEALER_ID)?.code);
    const r1PartnerId = ref<string | null>(props.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === PARTNER_CODES.ROUTEONE_PARTNER_ID)?.code);
    const dropdownSelectedLender = ref<VersionsLender>(null);
    const lendersList = computed<VersionsLender[]>(() => $global.Lenders ?? []);
    const header = [
        {
            name: 'selected',
            display: '',
            cssClass: 'action-column1',
            slot: 'checkbox',
        },
        {
            name: 'data',
            display: '',
            slot: 'creditAppData',
        },
        //    {name:'selected',display:"Selected"},
    ] as any[];
    const creditAppFieldsResponse = ref<any>(null);
    const isLoading = ref(false);
    const isEvaluatingExpresions = ref(false);
    const reserveProfitCalcs = ref<IReserveProfitCalc[]>([]);

    const loadingCustomFields = ref(false);
    const lenderCustomFields = ref<FIDynamicFields>(new FIDynamicFields());

    const canSubmitCreditApplication = computed(() => {
        if (props.fimenu.isCash()) return false;
        if (!props.fimenu.creditApplicationsEnabled) return false;
        const alternativeLenderCodes = dropdownSelectedLender?.value?.alternativeLenderCodes ?? ([] as AlternativeLenderCode[]);
        return alternativeLenderCodes.some((x: AlternativeLenderCode) => associatedFinanceSources.includes(x.code));
    });

    const balloonAmountIsNeeded = computed(() => props.fimenu.isBalloon() && !props.fimenu.loanTerms.atLeastOneBalloonAmount());
    const applicationIsSelected = computed(() => creditApplications.value?.some(app => app.selected));

    const sortedLenderList = computed(() => {
        if (!props.fimenu.creditApplicationsEnabled) return filteredLenderList.value;

        return [...filteredLenderList.value].sort((l1, l2) => {
            // Get R1Codes
            const r1Codes1 = l1.alternativeLenderCodes;
            const r1Codes2 = l2.alternativeLenderCodes;

            // Handle cases where both R1Codes lists are empty
            if (r1Codes1.length === 0 && r1Codes2.length === 0) {
                return l1.lenderName.localeCompare(l2.lenderName);
            }

            // Handle cases where only one R1Codes list is empty
            if (r1Codes1.length === 0) return 1; // Put l1 after l2
            if (r1Codes2.length === 0) return -1; // Put l1 before l2

            // Check if any code in each list matches associated finance sources
            const hasAssociated1 = r1Codes1.some(code => associatedFinanceSources.includes(code.code));
            const hasAssociated2 = r1Codes2.some(code => associatedFinanceSources.includes(code.code));

            if (hasAssociated1 && !hasAssociated2) return -1; // Place lender with associated codes first
            if (!hasAssociated1 && hasAssociated2) return 1; // Place lender with associated codes second

            // If both or neither are associated, fallback to lenderName sorting
            return l1.lenderName.localeCompare(l2.lenderName);
        });
    });

    const getLenderInformation = (lenderCode: string) => {
        var lenderInformation = lendersList.value.find(x => x.lenderCode == lenderCode);

        return lenderInformation;
    };

    const disableViewbuttonForCash = (lender: VersionsLender) => {
        return lender.lenderName.toLowerCase() == 'cash';
    };

    const isMFRateForApplication = (app: CreditApplication) => {
        switch (app.type) {
            case CreditApplicationType.Lease: {
                const applicationLender = lendersList.value.find(x => x.lenderCode == app?.lender?.lenderCode);

                if (applicationLender) {
                    var lenderForTheApplication = applicationLender.getLenderByDealType(DealType.Lease) as LenderLease;
                    if (lenderForTheApplication && lenderForTheApplication.isMoneyFactorAsRate) {
                        return true;
                    }
                }
                return false;
            }
            case CreditApplicationType.Retail:
                return false;
            default:
                return false;
        }
    };

    const getFormattedRateFromDecision = (app: CreditApplication) => {
        var rate = getRateFromDecision(app);

        if (rate == null) return '-';
        else return `${rate}`;
    };

    const getRateFromDecision = (app: CreditApplication) => {
        if (isMFRateForApplication(app)) return app.decision.decisionNumbers.annualPercentageRate;
        else return app.decision.decisionNumbers.leaseRateMoneyFactor;
    };

    const filteredLenderList = computed<VersionsLender[]>(() => {
        return lendersList.value.filter(x => {
            const lenderByDealType = x.getLenderByDealType(dealType.value);
            if (x.lenderCode == 'UNLISTED') return false;
            if (lenderByDealType.divisions.length <= 0) return false;

            if (props.fimenu.isCash()) return true;
            if (!props.fimenu.creditApplicationsEnabled) return true;

            if (onlyShowBalloonLenders) {
                return balloonLenders.includes(x.lenderCode);
            }

            // Add the lenders that have R1 code setup for this store, regardless of the divisions.
            if (x.alternativeLenderCodes.some((code: AlternativeLenderCode) => associatedFinanceSources.includes(code.code))) return true;

            // Filter lenders without division.
            // We should use getDefaultDivision when we use proper division types.
            if (!x.displayLender) return false;

            return true;
        });
    });

    const creditApplications = computed<FIMenuCreditApplication[]>(() => {
        const returnvalue = props.fimenu.dealJacket.applications.filter((ca: FIMenuCreditApplication) => ca.MatchesDealType(props.fimenu.dealType));
        return _.sortBy(returnvalue, (r: FIMenuCreditApplication) => r.dateCreated).reverse();
    });

    const creditApplicationsWithBalloon = computed(() => creditApplications.value.filter(app => app.submissionInformation.balloonAmount > 0));

    const dealType = computed<DealType>(() => {
        return props.fimenu.isFinance() ? DealType.Retail : props.fimenu.isLease() ? DealType.Lease : DealType.Draft;
    });

    const dealLenderInformation = computed<VersionsLender | null>(() => filteredLenderList.value.find(x => x.lenderCode == props.fimenu.lender.lenderCode));

    const buttonActions = [
        { name: 'error', status: null, icon: 'fas fa-exclamation-triangle icon-color-red', cssClass: 'button-nothing-red clickable' },
        { name: 'approved', status: CreditApplicationDecision.Approved, icon: 'fas fa-thumbs-up icon-color-green', cssClass: 'button-nothing-green clickable' },
        { name: 'denied', status: CreditApplicationDecision.Denied, icon: 'fas fa-thumbs-down icon-color-red', cssClass: 'button-nothing-red clickable' },
        { name: 'conditional', status: CreditApplicationDecision.Condition, icon: 'fas fa-exchange-alt icon-color-orange', cssClass: 'button-nothing-orange clickable' },
        { name: 'preliminary', status: CreditApplicationDecision.Preliminary, icon: 'fas fa-circle-notch fa-spin icon-color-orange', cssClass: 'button-nothing-orange clickable' },
        { name: 'withdrawn', status: CreditApplicationDecision.Withdrawn, icon: 'fas fa-trash icon-color-red', cssClass: 'button-nothing-red clickable' },
        { name: 'booked', status: CreditApplicationDecision.Booked, icon: 'fas fa-solid fa-coins icon-color-green', cssClass: 'button-nothing-green clickable' },
        { name: 'waiting', status: CreditApplicationDecision.Pending, icon: 'fas fa-spinner fa-spin icon-color-red', cssClass: 'button-nothing-red clickable' },
        { name: 'new', status: CreditApplicationDecision.New, icon: 'fas fa-spinner fa-spin icon-color-red', cssClass: 'button-nothing-red' },
        {
            name: 'contract_received',
            status: CreditApplicationDecision.ContractRecevied,
            icon: 'fas fa-solid fa-file-contract icon-color-green',
            cssClass: 'button-nothing-green clickable',
        },
    ];

    const getAction = (application: FIMenuCreditApplication): (typeof buttonActions)[number] => {
        let action = buttonActions.find(x => x.status == application.status);

        if (action == null) {
            action = buttonActions.find(x => x.status == CreditApplicationDecision.New);
        }

        return action;
    };

    const currentPreselectedLender = computed(() =>
        preSelectedLenders.value?.find(lender => lender.lenderCode == props.fimenu.lender?.lenderCode && props.fimenu.lender.isActiveOnDeal),
    );
    const dealHasNoLender = computed(() => !((applicationIsSelected.value || currentPreselectedLender.value) && !dropdownSelectedLender.value));
    const currentLenderAllowsBalloon = computed(() => balloonLenders.includes(props.fimenu.lender?.lenderCode) || balloonLenders.includes(dropdownSelectedLender.value?.lenderCode));

    onMounted(async () => {
        props.fimenu.loadLatestestCreditDecision();
        getReserveProfitCalcs();

        if (props.fimenu.isBalloon() && dealHasNoLender.value && !props.fimenu.isDealLocked()) {
            props.fimenu.loanTerms.isBalloon = false;
        }
    });

    const lenderName = (ca: FIMenuCreditApplication) => {
        return ca.lender.lenderName ?? 'Unknown lender';
    };

    const openSendModal = async () => {
        if (await props.fimenu.checkForFraud()) return;

        $modal.open(modalRouteOneSend, {
            name: 'modalRouteOneSend',
            passedData: {
                fimenu: props.fimenu,
                lender: dropdownSelectedLender,
                loading: loadingCustomFields.value,
                customFields: lenderCustomFields.value,
            },
            backdrop: false,
            postFunction: (additionalInformation: FIDealJacketAdditionalInformation) => send(dropdownSelectedLender.value as VersionsLender, additionalInformation),
        });
    };

    const openLenderInformationModal = (lender: VersionsLender) => {
        $modal.open(ModalLenderInformation, {
            name: 'modalLenderInformation',
            passedData: {
                deal: props.fimenu,
                lender: lender,
                reserveProfitCalcs: reserveProfitCalcs.value,
            },
            backdrop: false,
        });
    };
    const openViewModal = (ca: FIMenuCreditApplication) => {
        if (ca.status == null || ca.status == CreditApplicationDecision.Pending) return;

        const lender = lendersList.value.find(x => x.lenderCode == ca.lender.lenderCode);
        $modal.open(modalRouteOneView, {
            name: 'modalRouteOneView',
            passedData: { row: ca, fimenu: props.fimenu, lender, reserveProfitCalcs: reserveProfitCalcs.value },
            backdrop: false,
        });
    };

    const fetchDealFromQuote = async (dealId: string) => {
        try {
            isLoading.value = true;
            const response = await api.fimenu.getDeal(dealId);

            if (response.data) {
                props.fimenu.copyFromQuote(response.data.fimenu);
            }
        } finally {
            isLoading.value = false;
        }
    };

    const handleAddLender = (lenderCode: string) => {
        props.fimenu.preSelectedLenders = [lenderCode];

        if (props.fimenu.isCash()) {
            const selectedLender = lendersList.value?.find(x => x.lenderCode == lenderCode);
            handleSelectLender(selectedLender);
        } else {
            props.fimenu.lender = new FIMenuLender();
        }
    };

    /**
     * Assigns a lender to the deal that is not credit application based.
     */
    const handleSelectLender = async (lender: LenderVersionClass, isApplication = false, selectedProvider: FinanceChannelsProvider = FinanceChannelsProvider.None) => {
        const selectedLenderInformationForTheDeal = lender?.versions[0]?.getLenderByDealType(props.fimenu.dealType as "Lease" | "Finance" | "Cash");

        // Clear credit applications selection.
        clearSelectedLenderApplications(isApplication);
        const selectedLenderIsDifferent = lender.lenderCode !== props.fimenu.lender.lenderCode;
        var fimenuLender = new FIMenuLender({
            divisions: selectedLenderInformationForTheDeal?.divisions ?? [],
            lenderCode: lender.lenderCode,
            lenderContacts: selectedLenderInformationForTheDeal?.lenderContacts ?? [],
            lenderName: lender.lenderName,
            selectedProvider, // When we get new providers; this needs to update to the provider.
            isActiveOnDeal: true,
        });

        props.fimenu.lender = fimenuLender;
        props.fimenu.dealJacket.purchaseVehicleBookValue = null;

        updateLenderOnFIMenu(lender); // Update FIMenu as it goes without credit applications
        setAllPaperworkToMustRepopulate(); // Since the lender changed. IF we have paperwork; they must repopulate.
        setMaxDaysToFirstPayment(lender); // Set the days for max payment.
        saveDealIfNeeded(selectedLenderIsDifferent); // Will save the deal if required.
    };

    /**
     * Applies a credit application to the deal
     */
    const handleSelectApplication = async (selectedData: Partial<FIMenuCreditApplication>) => {
        props.fimenu.dealJacket.providerId = selectedData.dealJacketId;

        props.fimenu.dealJacket.applications.forEach(c => {
            c.selected = c.id === selectedData.id;
        });

        const selectedLender = lendersList.value?.find(x => x.lenderCode == selectedData.lender.lenderCode);

        handleSelectLender(selectedLender, true, selectedData.selectedProvider ?? FinanceChannelsProvider.RouteOne);

        props.fimenu.dealJacket.purchaseVehicleBookValue = selectedData.vehicleBookValue;
        if (!balloonLenders.includes(selectedData.lender.lenderCode)) props.fimenu.loanTerms.isBalloon = false;

        //// This will preload the current deal with the quote deal information.
        //await this.fetchDealFromQuote(selectedData.dealId);
    };

    const fetchDynamicFields = async (lenderCode: string) => {
        try {
            loadingCustomFields.value = true;
            const tokenId = 'get-getCustomFields';
            api.cancel(tokenId);

            const customFields = await RetrieveCustomFields(props.fimenu, lenderCode, 'get-getCustomFields');
            lenderCustomFields.value = customFields;
        } catch (err) {
            console.error(err);
        } finally {
            loadingCustomFields.value = false;
            // Reset cancel token
        }
    };

    /**
     * Modifies the lender on the deal object when using the lender drop down
     * This is the not apply a lender to the deal.
     */
    const handleLenderChange = async (lender: VersionsLender) => {
        const lenderInfo = lender.getLenderByDealType(props.fimenu.dealType); // either lenderRetail, lenderLease or lenderdraft.
        let providerLendercode = null;
        clearSelectedLenderApplications();
        setMaxDaysToFirstPayment(lender); // Set the days for max payment.
        updateLenderOnFIMenu(lender);

        const lenderIsAvailableToSubmit = props.fimenu.creditApplicationsEnabled && lender.alternativeLenderCodes.some((x: AlternativeLenderCode) => associatedFinanceSources.includes(x.code));
        // If we select a lender that is available for submission
        if (lenderIsAvailableToSubmit && props.fimenu.routeOneEnabled) {
            providerLendercode = await handleFetchPartnerId(lenderInfo, lender.lenderCode); // Get the lender code as response.
            fetchDynamicFields(providerLendercode);
        }

        SetStatusToReverifyIfNeeded(props.fimenu);
        EventBusCore.emit('recalculate');
    };

    const handleFetchPartnerId = async (lender: LenderRetail | LenderLease | LenderDraft, lenderCode: string): Promise<string | null> => {
        try {
            isEvaluatingExpresions.value = true;
            const response = await api.lenders.getLenderPartnerId(props.fimenu, lenderCode);

            return response.data.payload;
        } catch (ex) {
            console.error(ex);
        } finally {
            isEvaluatingExpresions.value = false;
        }
    };
    const updateLenderOnFIMenu = (lender: VersionsLender) => {
        // Copy from PanelLEnder.
        const lenderVersion = lender.getLenderByDealType(dealType.value); // Get the version of the lender. Either Retail, Lease or Cash.
        const selectedLenderIsDifferent = lender.lenderCode !== props.fimenu.lender.lenderCode; // wether the lender is the same.

        if (selectedLenderIsDifferent) {
            // Create FImenuLender and assign it to the deal.
            props.fimenu.lender = new FIMenuLender({
                divisions: lenderVersion.divisions,
                lenderContacts: lenderVersion.lenderContacts,
                lenderCode: lender.lenderCode,
                lenderName: lender.lenderName,
                selectedProvider: lender.alternativeLenderCodes.length > 0 ? FinanceChannelsProvider.RouteOne : FinanceChannelsProvider.None,
            });
        }
    };

    const handleSendButtonAction = () => {
        if (canSubmitCreditApplication.value && !loadingCustomFields.value) {
            openSendModal();
        } else {
            clearDropdownLender();
            handleAddLender(dropdownSelectedLender.value.lenderCode);
        }
    };
    const saveDealIfNeeded = (selectedLenderIsDifferent: boolean) => {
        const isLenderInvalid = util.isNull(props.validation) ? false : props.validation.lender.lenderCode.$invalid;
        if (!isLenderInvalid && props.fimenu.isDealLocked() && !props.fimenu.isSpectator && selectedLenderIsDifferent) {
            props.fimenu.save();
        }
    };

    const setMaxDaysToFirstPayment = (lender: VersionsLender) => {
        const lenderVersion = lender.getLenderByDealType(dealType.value);

        if(props.fimenu.loanTerms.maxDaysToFirstPayment != undefined && props.fimenu.loanTerms.maxDaysToFirstPayment  != null) return ;

        if (lenderVersion && 'maxDaysToFirstPayment' in lenderVersion && lenderVersion.maxDaysToFirstPayment) {
            props.fimenu.loanTerms.maxDaysToFirstPayment = lenderVersion.maxDaysToFirstPayment;
        } else {
            props.fimenu.loanTerms.maxDaysToFirstPayment = 45;
        }
    };

    const setAllPaperworkToMustRepopulate = () => {
        //set all current packet document to must repopulate
        if (props.fimenu.hasPaperworkPacket()) {
            props.fimenu.paperwork.currentPacket().documents.forEach((d: any) => {
                d.mustRepopulate = true;
            });
        }
    };

    const getLatestCreditAppFieldsResponse = () => {
        if (!$global.r1CreditAppField) return;
        creditAppFieldsResponse.value = util.clone($global.r1CreditAppField);
    };

    const send = async (lender: VersionsLender, additionalInformation: FIDealJacketAdditionalInformation) => {
        // You could still see this component if you have previous credit applications. But cannot send new one if we
        // dont have credentials or is disabled.
        if (!CreditApplicationsEnabled(props.fimenu)) return util.toastr('error', 'Error', 'Cannot send credit applications. Currently disabled.');

        if (props.fimenu.isSpectator) return false;

        isLoading.value = true;
        try {
            const response = await SubmitCreditApplication(props.fimenu, additionalInformation, lender);

            const concatMessage = response?.messages?.join(', ') ?? null;

            if (response?.statusCode < 400) {
                const creditApp = new FIMenuCreditApplication(response.payload);
                props.fimenu.dealJacket.applications.push(creditApp);
                props.fimenu.dealJacket.providerId = creditApp.dealJacketId;

                util.toastr('success', 'Credit Application', concatMessage?.trim() ? concatMessage : 'Submitted successfully.');
                dropdownSelectedLender.value = null;
            } else {
                util.toastr('error', 'Credit Application', concatMessage?.trim() ? concatMessage : 'There was an error submitting your credit application');
                console.error(JSON.stringify(response));
            }
        } catch (err) {
            console.error(err);
        } finally {
            isLoading.value = false;
        }
    };

    const valuePredicate = (l: VersionsLender) => {
        return l;
    };

    const comparerPredicate = (a: VersionsLender, b: VersionsLender) => {
        return a && b && a.lenderCode === b.lenderCode;
    };

    const displayPredicate = (l: VersionsLender) => {
        return l.lenderName;
    };

    const descriptionPredicate = (l: VersionsLender) => {
        if (l.alternativeLenderCodes.length > 0 && l.alternativeLenderCodes.some((x: AlternativeLenderCode) => associatedFinanceSources.includes(x.code)) && props.fimenu.creditApplicationsEnabled) {
            return 'R1';
        }

        return null;
    };

    async function getReserveProfitCalcs() {
        const { data } = await api.settings.getGetReserveProfitCalcs();
        if (data && Array.isArray(data)) reserveProfitCalcs.value = data;
        else console.error("Couldn't get reserve profit calcs. Wrong Data?");
    }

    getLatestCreditAppFieldsResponse();
    // Get finance sources that allow AutoSubmit
    const dealersThatAllowAutoSubmit =
        creditAppFieldsResponse.value?.financeSources ??
        [].filter((source: any) => source.services.some((service: any) => service.name === 'AutoSubmit')).map((source: any) => source.fsId);

    // Find the dealer that matches the current routeOneDealerId
    const dealer = creditAppFieldsResponse.value?.dealers.find((d: any) => d.routeOneDealerId === r1DealerId.value);

    // Get associated finance sources that are allowed for AutoSubmit
    const associatedFinanceSources: any[] = dealer
        ? dealer.associatedFinanceSources.filter((fsId: string) => {
              return dealersThatAllowAutoSubmit.some((obj: any) => obj.fsId == fsId);
          })
        : [];

    const preSelectedLenders = computed<VersionsLender[]>(() => {
        return props.fimenu.preSelectedLenders.map((lenderCode: string) => getLenderInformation(lenderCode));
    });

    function clearSelectedLenderApplications(isApplication = false) {
        if (!isApplication) creditApplications.value.forEach(x => (x.selected = false));
        props.fimenu.lender.isActiveOnDeal = false;
    }

    // #region DealTerm Logic
    //RECALCULATE BUYERS ORDER WHEN DEAL TERM CHANGE
    function dealTermsChangedHandler() {
        if (props.fimenu.buyersOrderEnabled) {
            bo.value.recalculate();
        }
    }

    function clearDropdownLender() {
        nextTick(() => {
            dropdownSelectedLender.value = null;
        });
    }

    const displayBOPanelDealTerms = computed(() => {
        const currentBO = props.fimenu.getCurrentBuyersOrder();

        return (
            props.fimenu.dealType != null &&
            props.fimenu.hasCoverageTerms &&
            (DisplayCreditApplication(props.fimenu) || props.fimenu.lender.lenderCode) &&
            props.fimenu.buyersOrderEnabled &&
            currentBO &&
            currentBO.isValid() &&
            currentBO.areAllBucketsValid()
        );
    });

    const dealTermsPanelObj = computed(() => {
        let title;
        if (props.fimenu.isDraft()) title = 'Draft Terms';
        else if (props.fimenu.isCash()) title = 'Cash Terms';
        else if (props.fimenu.isFinance()) title = 'Loan Terms';
        else title = 'Lease Terms';

        return { title, action: [] as any[] };
    });

    const applicationLenderPanel = useTemplateRef('applicationLenderPanel');
    const inputsend = useTemplateRef('inputsend');
    const lenderApplicationOffset = computed(() => `calc(100% - ${applicationLenderPanel.value.offsetHeight + inputsend.value.offsetHeight + 18}px)`);
    const showBalloonIcon = (lenderCode: string) => {
        if (!props.fimenu.isFinance()) return false;
        return props.fimenu.hasPaperworkPacket() ? balloonLenders.includes(lenderCode) && props.fimenu.loanTerms.isBalloon : balloonLenders.includes(lenderCode);
    };
    //#endregion

    // This provides feedback for the specctator when the editor changes lender.
    watch(
        () => props.fimenu.lender.lenderCode,
        (newVal, oldVal) => {
            if (props.fimenu.isSpectator) {
                dropdownSelectedLender.value = lendersList.value.find(x => x.lenderCode == props.fimenu.lender.lenderCode);
            }
        },
    );

    watch(dealHasNoLender,
        () => {
            activeCreditApplicationOrLender.value = !dealHasNoLender.value;
        },
        { immediate: true }
    );

    // Handle balloon mode for deal when switching lenders
    watchEffect(() => {
        if (!props.fimenu.isFinance() || props.fimenu.hasPaperworkPacket()) return;

        const preventBalloonReset = currentLenderAllowsBalloon.value && !props.fimenu.isSpectator;
        if (!preventBalloonReset) {
            // props.fimenu.loanTerms.resetBalloonAmounts();
            props.fimenu.loanTerms.isBalloon = false;
        }

        props.fimenu.loanTerms.isBalloon = currentLenderAllowsBalloon.value;
    });

    watch(
        () => [currentPreselectedLender.value, applicationIsSelected.value],
        ([newLender, newApp]) => {
            if (newLender || newApp) clearDropdownLender();
        },
    );

    watch(
        () => props.fimenu.dealType,
        () => {
            clearDropdownLender();
            preSelectedLenders.value.splice(0);
        },
    );
</script>
<style>
    .panel-routeOneDealerTrack {
        height: v-bind(lenderApplicationOffset);
    }

    .panel-routeOneDealerTrack .action-column1 {
        width: 28px;
    }

    .panel-routeOneDealerTrack .open-credit-app {
        width: 43px;
    }

    .credit-application-lender-wrapper {
        align-items: center;
    }

    .panel-routeOneDealerTrack .rich-table-rows .action-cell button {
        width: 40px;
        height: 40px;
        justify-content: space-between;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .panel-routeOneDealerTrack .terms-grid {
        grid-template-columns: 40px 140px 90px 80px;
    }

    .lender-selector {
        font-size: 25px;
    }

    .input-checkbox-container {
        display: block;
        max-width: 28px;
    }

    .applications-box-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .ApplicationsBox {
        width: 100%;
        border: 1px solid var(--richtable-border-color);
        min-height: 70px;
        box-sizing: border-box;
        padding: 7px;
        align-items: flex-start;
        justify-content: center;
        position: relative;
    }

    .ApplicationsBox .input-checkbox {
        padding: unset;
    }

    .SubmissionIcon {
        color: var(--main-color);
        font-size: 13px;
    }

    .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .status.button-nothing {
        border: 1px solid var(--richtable-border-color);
    }

    .tier-text {
        display: inline-block;
        max-width: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
