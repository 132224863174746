import api from '@core/services/api'
import ENUMS from "@core/classes/Enums"
import FIMenuPaymentAmount from '@core/classes/FIMenuPaymentAmount'
import settings from 'settings'

const LenderHelper = {
    async getReserveProfitResults(fimenu, globalLenders, lenderCode, paymentAmounts) {
        let lender = globalLenders.find(l => l.lenderCode == lenderCode);
        let lenderType = fimenu.isFinance() ? 'retail' : fimenu.isLease() ? 'lease' : 'draft';

        /// Get List of Reserve Profit Calculations
        let rpcs = settings.lookups.reserveProfitCalcs
        /// Get Formula that matches Deals Lenders Split ID
        let foundSplitRpc = rpcs.find(rpc => rpc.id === lender?.versions[0][lenderType].reserveProfitCalcGuids?.split);

        /// Get Formula that matches Deals Lenders Flat ID
        let foundFlatRpc = rpcs.find(rpc => rpc.id === lender?.versions[0][lenderType].reserveProfitCalcGuids?.flat);

        /// Build lender expressions Model like in Reserve Profit Settings - Should not need model anymore
        let model = {
            fimenu: fimenu.getSanitizedData(),
            store: fimenu.store,
            lender,
            paymentAmounts
        }
        /// Evaluate Expression and return list of results
        if (foundSplitRpc || foundFlatRpc) {
            let rpcResults = await api.utilities.getReserveProfits(model, foundSplitRpc?.expression, foundFlatRpc?.expression);

            return this.insertProfitsIntoPayments(rpcResults.data, paymentAmounts)
        }
        return paymentAmounts
    },
    getTermFIMenuPaymentAmount(dealTerm, coverageTermTerm, finalNumbers, sellRate, buyRate) {
        let paymentAmount = new FIMenuPaymentAmount({
            amountFinanced: finalNumbers.finalAmount,
            dealTerm: dealTerm,
            coverageTerm: coverageTermTerm,
            sellRate: sellRate,
            paymentAtSellRate: finalNumbers.finalPayment,
            paymentAtSellRateWithTax: finalNumbers.finalPayment,
            buyRate: buyRate,
            paymentAtBuyRate: finalNumbers.finalPaymentAtBuyRate,
            paymentAtBuyRateWithTax: finalNumbers.finalPaymentAtBuyRate
        });

        return paymentAmount;
    },
    insertProfitsIntoPayments(rpcResults, paymentAmounts) {
        paymentAmounts.forEach(pa => {
            pa.reserveProfitSplit = Number(rpcResults.splitResults?.find(r => r.dealTerm == pa.dealTerm && r.coverageTerm == pa.coverageTerm)?.result.toFixed(2));
            pa.reserveProfitFlat = Number(rpcResults.flatResults?.find(r => r.dealTerm == pa.dealTerm && r.coverageTerm == pa.coverageTerm)?.result.toFixed(2));
        })
        return paymentAmounts;
    }

}

export default LenderHelper;

