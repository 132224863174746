<template>
    <div>
        <p id="WizzardMessage">
            {{ displayMessage }}
        </p>
    </div>
</template>

<script lang="ts">
    import { PropType, defineComponent } from 'vue'
    import ENUMS from "@core/classes/Enums";
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuCustomer from '@core/classes/FIMenuCustomer';
    import {useVuelidate} from '@vuelidate/core';

    export default defineComponent({
        name: 'FIMenuWizardMessages',
        components: {},
        setup(){
            return { v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                customer: this.fimenu.customer,
                coCustomer: this.fimenu.coCustomer
            }
        },
        props: {
            fimenu: {
                type: Object as PropType<FIMenu>,
                required: true
            },
            actualStep: {
                type: String,
                default: null
            }
        },
        computed: {
            isRetrieveApplicationEnabled(): boolean {
                if (!this.fimenu.id) {
                    if (this.fimenu.store) {
                        return this.fimenu.store?.storeSettings?.customerApplication == ENUMS.CUSTOMER_RETRIEVAL_STATUS.RetrieveApplicationEnabled
                    }
                    return this.$global.selectedStore?.storeSettings?.customerApplication == ENUMS.CUSTOMER_RETRIEVAL_STATUS.RetrieveApplicationEnabled
                }
                return this.fimenu.customerApplication === ENUMS.CUSTOMER_RETRIEVAL_STATUS.RetrieveApplicationEnabled;
            },
            displayMessage(): string {
                switch(this.actualStep) {
                    case "Customer":
                        return this.customerMessages;
                    default:
                        return  ""
                }
            },
            customerMessages() : string {
                const validate = (validation: any, whichCustomer = "") => {
                    const idValidation = validation.customerApplication.id;
                    const statusValidation = validation.customerApplication.status ;

                    if (idValidation?.$invalid)     return `${validation.$model.fullName ?? whichCustomer} does not have a customer application. Please press "Create Customer Application" to start one for the customer.`
                    if (statusValidation?.$invalid) return `${validation.$model.fullName ?? whichCustomer} must complete their customer application before proceeding.`

                    return null;
                }

                if (this.isRetrieveApplicationEnabled) {
                    const customerValidation = validate(this.v$.customer, "Customer");
                    if (customerValidation != null && this.fimenu.customer.id != null) return customerValidation;

                    if(this.fimenu.hasCoSigner && this.fimenu.coCustomer.id != null) {
                        const coCustomerValidation = validate(this.v$.coCustomer, "Co-Customer");
                        if (coCustomerValidation != null) return coCustomerValidation;
                    }
                }

                return "";
            }
        },
        validations() {
            return {
                customer: this.fimenu.customer.validation(this.fimenu),
                coCustomer: this.fimenu.customer.validation(this.fimenu)
            }
        },
        methods: {
        },
    })
</script>
<style scoped>
    #WizzardMessage {
        color: var(--error-color);
        font-weight: 800;
        font-size: 14px;
    }
</style>