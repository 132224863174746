<template>
    <div id="video-chat">
        <VideoChatStream v-for="peer in allPeers"
                         :peer="peer.peer"
                         :id="peer.peerId"
                         :key="peer.peerId" />
        <div class="local-stream" v-if="localStream">
            <video v-if="localStream" ref="localStream" autoplay playsinline muted></video>
            <!-- showing mute button in overlay if fimanager (may be redesigned later) -->
            <div class="video-chat-stream-overlay" v-if="meetingHelper.myUserDetails && meetingHelper.inStore === false">
                <div id="user-name">{{ userName }}</div>
                <div v-if="meetingHelper.myUserDetails && meetingHelper.myUserDetails.role !== 'fimanager'" class="mute-status">
                    <i :class="['fas', meetingHelper.isMuted ? 'fa-microphone-slash' : 'fa-microphone' ]"></i>
                </div>
                <button v-else-if="meetingHelper.myUserDetails && meetingHelper.myUserDetails.role === 'fimanager'" @click="handleMuteButton" class="mute-button">
                    <i :class="['fas', meetingHelper.isMuted ? 'fa-microphone-slash' : 'fa-microphone' ]"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import $modal from '@core/services/modal'
    import api from '@core/services/api';
    import LogRocket from 'logrocket';
    import modalCameraMicPermission from '@core/modals/modalCameraMicPermission.vue'
    import Peer from 'simple-peer';
    import util from '@core/services/util'
    import VideoChatStream from '@core/components/VideoChatStream.vue'

    export default {
        props: ["peopleInLobby"],
        data() {
            return {
                localUserId: null,
                allPeers: [],
                localStream: null,
                iceServers: null,
                isUserInDev: false,
                stunServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    { urls: 'stun:global.stun.twilio.com:3478' }
                ],
                hasAlreadyJoinedChat: false,
            }
        },
        created() {
            this.setupListeners();
            this.setupWatches();
        },
        beforeUnmount() {
            console.log('%cVideoChatRoom - beforeDestroy', 'background: red');
            this.cleanupPeers()

            this.MeetingHubBus.off('userJoinedVideoChatRoom', this.userJoinedVideoChatRoom);
            this.MeetingHubBus.off('disconnectedFromSignalR', this.disconnectedFromSignalR);
            this.MeetingHubBus.off('reconnectedMeetingHub', this.reconnectedMeetingHubHandler);
            this.MeetingHubBus.off('getInitiatorSignal', this.getInitiatorSignal);
            this.MeetingHubBus.off('getNonInitiatorSignal', this.getNonInitiatorSignal);
            this.MeetingHubBus.off('reconnectingMeetingHub', this.reconnectingMeetingHubHandler);
        },
        mounted() {
            if (this.meetingHelper.cameraAccessAcknowledged !== true && this.meetingHelper.myRole !== 'fimanager') {
                $modal.open(modalCameraMicPermission, { name: 'modalCameraMicPermission', passedData: {}, backdrop: false });
            }

            // Check if user agreed to camera access before the video chat
            if (this.meetingHelper.cameraAccessAcknowledged === true || this.peopleInLobby) {
                console.log('initVideoChat called in MOUNTED');
                this.initVideoChat();
            }

        },
        computed: {
            userName() {
                if (this.meetingHelper.myUserDetails.firstName !== '' && this.meetingHelper.myUserDetails.lastName !== '') {
                    return this.meetingHelper.myUserDetails.firstName + ' ' + this.meetingHelper.myUserDetails.lastName;
                } else if (this.meetingHelper.fullName) {
                    return this.meetingHelper.fullName;
                } else {
                    return '';
                }
            },
        },
        methods: {
            async setupIceServers() {
                // Check if user is in development (CSS class set in App.vue), lets use free STUN servers if in development, otherwise use TURN servers
                this.isUserInDev = !!document.querySelector('.development');
                console.log(`---- setupIceServers ---- $route.name: ${this.$route.name}, meetingHelper.inStore: ${this.meetingHelper.inStore}`);
                if (this.isUserInDev) {
                    console.log('using STUN SERVERS');
                    this.iceServers = this.stunServers;
                } else {
                    console.log('using TURN SERVERS');
                    const response = await api.meeting.getTurnServers()
                    this.iceServers = response.data;
                }
            },
            setupListeners() {
                //HANDLE WHEN OTHER PEOPLE JOIN OR LEAVE
                this.MeetingHubBus.on('userJoinedVideoChatRoom', this.userJoinedVideoChatRoom);
                this.MeetingHubBus.on('disconnectedFromSignalR', this.disconnectedFromSignalR);
                this.MeetingHubBus.on('reconnectedMeetingHub', this.reconnectedMeetingHubHandler);
                this.MeetingHubBus.on('getInitiatorSignal', this.getInitiatorSignal);
                this.MeetingHubBus.on('getNonInitiatorSignal', this.getNonInitiatorSignal);
                this.MeetingHubBus.on('reconnectingMeetingHub', this.reconnectingMeetingHubHandler);
            },
            setupWatches() {
                this.$watch(() => this.$meetingHub.myConnectionId, function (newValue) {
                    this.localUserId = newValue;
                }, { deep: true, immediate: true });

                // Check if cameraAccessAcknowledged has been to true - if so initVideoChat so that the camera can be accessed
                this.$watch(() => this.meetingHelper.cameraAccessAcknowledged, function (newValue, oldValue) {
                    console.log(`\n\n In cameraAccessAcknowledged Watch - newValue: ${newValue}, oldValue: ${oldValue}`);
                    if (newValue === true) {
                        console.log('initVideoChat called within the cameraAccessAcknowledged Watch');
                        this.initVideoChat();
                    }
                }, { deep: true });

                // Check if user has allowed for camera access before joining a remote meeting
                this.$watch(() => this.meetingHelper.cameraAccessAllowed, async (newValue) => {
                    if (!newValue && this.meetingHelper.inStore === false && this.meetingHelper.myRole !== 'fimanager') {
                        $modal.open(modalCameraMicPermission, { name: 'modalCameraMicPermission', backdrop: false });
                    } else if (this.meetingHelper.myRole !== 'fimanager' && this.meetingHelper.inStore === false) {
                        // We only want to connect here if we are not fimanager (cameraAccessAllowed is set to true for fimanager by default)
                        await this.meetingHelper.customerConnectToSecureRoom(this.meetingHelper.myRole, this.meetingHelper.inStore);
                    }
                })

                this.$watch(() => this.meetingHelper.currentMeetingUsers, function (newValue, oldValue) {
                    console.log('currentMeetingUsers watch triggered inside VideoChatRoom: ', newValue, oldValue)

                    const hasCurrentMeetingUsersChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue);
                    console.log('HAS CURRENT MEETING USERS CHANGED???????', hasCurrentMeetingUsersChanged)
                    if (hasCurrentMeetingUsersChanged) {
                        this.disconnectUserCheck(this.meetingHelper.usersToDisconnect);
                    }

                }, { deep: true, immediate: true });

                this.$watch(() => this.meetingHelper.myUserDetails, function (updatedMyUserDetails, prevMyUserDetails) {
                    // If isUserWaiting gets set to false then we want to join the video chat
                    console.log("WATCH TRIGGERED myUserDetails!!!", updatedMyUserDetails, prevMyUserDetails);

                    // Deep comparison between the current and previous objects
                    const haveUserDetailsChanged = JSON.stringify(updatedMyUserDetails) !== JSON.stringify(prevMyUserDetails);

                    if (haveUserDetailsChanged) {
                        // If my updatedUserDetails are undefined and my prevMyUserDetails were defined, this means I have been disconnected
                        if (!updatedMyUserDetails && prevMyUserDetails) {
                            console.log('myUserDetails is going to trigger cleanupPeers');
                            this.cleanupPeers();
                            return;
                        }
                        if (updatedMyUserDetails.isUserWaiting === false &&
                            this.meetingHelper.cameraAccessAllowed &&
                            !this.hasAlreadyJoinedChat
                        ) {
                            this.$meetingHub.joinVideoChatRoom();
                            this.hasAlreadyJoinedChat = true;
                        }
                        //else {
                        //    //this.disconnectUserCheck([this.meetingHelper.myUserDetails.userId]);
                        //}

                    }
                }, { deep: true })

                this.$watch(() => this.meetingHelper.isMuted, function (newValue) {
                    if (newValue) {
                        this.localStream.getAudioTracks().forEach(track => track.enabled = false)
                    } else {
                        this.localStream.getAudioTracks().forEach(track => track.enabled = true)
                    }
                })

            },
            async chooseDevices() {
                console.log('Choose your device');
                try {
                    const devices = await navigator.mediaDevices.enumerateDevices()
                    devices.forEach(device => {
                        console.log(device.kind + ": " + device.label + " id = " + device.deviceId);
                    });

                } catch (err) {
                    console.error('Error enumerating devices: ', err);
                }
            },
            setupUserMediaConstraints() {
                const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

                // Setting audio constraints to help with noise and echo depending if they are supported by the browser
                const audioConstraints = ['echoCancellation', 'noiseSuppression', 'autoGainControl']
                    .filter(constraint => supportedConstraints[constraint])
                    .reduce((acc, constraint) => {
                        acc[constraint] = true;
                        return acc;
                    }, {});

                console.log('audioConstraints', audioConstraints)

                return {
                    audio: audioConstraints,
                    video: true
                };
            },
            async initVideoChat() {
                await this.setupIceServers();
                console.log('~~~~~~~~~~~~initVideoChat~~~~~~~~~~~~~');
                this.localUserId = this.$meetingHub.myConnectionId;
                console.log('is meetingHub connected', this.$meetingHub.connected, this.localUserId);

                const constraints = this.setupUserMediaConstraints();

                navigator.mediaDevices.getUserMedia(constraints)
                    .then(stream => {
                        this.localStream = stream;
                        this.meetingHelper.cameraAccessAllowed = true;

                        this.$nextTick(() => {
                            this.$refs.localStream.srcObject = this.localStream;
                        });

                        if (this.$meetingHub.connected && this.meetingHelper.currentMeetingUsers.length > 0 && !this.meetingHelper.myUserDetails?.isUserWaiting) {
                            //JOINS THE CHAT ROOM AND LETS EVERYONE ELSE KNOW THAT I HAVE JOINED THE CHAT ROOM
                            console.log('joinVideoChatRoom called within the initVideoChat method');
                            this.$meetingHub.joinVideoChatRoom();
                            LogRocket.track(`Joined Video Chat`, {
                                dealNumber: this.meetingHelper.dealNumber
                            });
                        }

                    })
                    .catch(error => {
                        //console error instead of alert
                        console.error('Error accessing camera:', error);
                        if (error.name === 'NotAllowedError') {
                            console.error('Permissions to access camera and microphone were denied. Please allow access and try again.');
                        } else if (error.name === 'NotFoundError') {
                            console.error('No camera or microphone found. Please connect them and try again.');
                        } else {
                            console.error('Error accessing media devices: ' + error.message);
                        }
                    });
            },
            userJoinedVideoChatRoom(remoteUserId) {
                // Return in case the peer already exists (may help in preventing some peer connection issues)
                if (this.allPeers.some(peer => peer.peerId === remoteUserId)) {
                    return;
                }
                // THIS RUNS FOR EVERYONE EXCEPT THE INITIATOR (LOCAL USER)
                const newPeer = this.startDirectConnectionToPeer(remoteUserId, this.localUserId, this.localStream);

                this.allPeers.push({
                    peerId: remoteUserId,
                    peer: newPeer,
                });

            },
            startDirectConnectionToPeer(userToSignal, callerId, stream) {
                // INITIATOR CALLS THIS FUNCTION
                const peer = new Peer({
                    initiator: true,
                    trickle: false,
                    stream: this.localStream,
                    config: { iceServers: this.iceServers }
                })

                peer.on("signal", signal => {
                    this.$meetingHub.requestDirectConnectionSignal({ userToSignal, callerId, signal });
                });

                peer.on("error", error => {
                    LogRocket.track('Peer Error');
                    console.error('PEER ERROR - startDirectConnectionToPeer', error);
                    // this.cleanupPeers();

                });

                return peer;
            },
            getInitiatorSignal(initiatorSignal) {
                const peer = new Peer({
                    initiator: false,
                    trickle: false,
                    stream: this.localStream,
                    config: { iceServers: this.iceServers }

                });

                peer.on("signal", signal => {
                    this.$meetingHub.acceptedInitiatorSignal({ userToSignal: initiatorSignal.callerId, callerId: this.localUserId, signal });
                });

                peer.on("error", error => {
                    console.error('PEER ERROR - getInitiatorSignal', error);
                    LogRocket.track('Peer Error');
                    // this.cleanupPeers();
                });


                //accept incoming signal
                peer.signal(initiatorSignal.signal);

                //add the initiator peer
                if (!this.allPeers.some(p => p.peerId === initiatorSignal.callerId)) {
                    this.allPeers.push({
                        peerId: initiatorSignal.callerId,
                        peer
                    });
                }
            },
            getNonInitiatorSignal(payload) {
                const remotePeer = this.allPeers.find(p => p.peerId === payload.callerId);
                remotePeer.peer.signal(payload.signal);
            },
            async disconnectedFromSignalR(remoteUserId) {
                const existingPeerIndex = this.allPeers.findIndex(p => p.peerId == remoteUserId);
                const existingUserIndex = this.meetingHelper.currentMeetingUsers.findIndex(user => user.userId == remoteUserId);

                if (existingPeerIndex > -1) {

                    //REMOVE CONNETION TO THAT PEER
                    const existingPeer = this.allPeers[existingPeerIndex];
                    existingPeer.peer.destroy();

                    //REMOVE FROM PEER INDEX
                    this.allPeers.splice(existingPeerIndex, 1);
                }
                if (existingUserIndex > -1) {
                    const existingUser = this.meetingHelper.currentMeetingUsers[existingUserIndex];

                    if(existingUser.role === 'fimanager' && this.meetingHelper.shouldMeetingEnd) {
                        // if the fimanager became disconnected then the other meetings participants should be removed as well
                        await this.meetingHelper.disconnectParticipant(this.meetingHelper.myUserDetails.userId);
                    }
                    //REMOVE FROM PEER INDEX
                    this.meetingHelper.currentMeetingUsers.splice(existingUserIndex, 1);
                }

            },
            disconnectUserCheck(usersToDisconnect = null) {
                console.log('disconnectUserCheck', usersToDisconnect);
                let shouldMyUserBeDisconnected;

                if (usersToDisconnect) {
                    console.log(`%cusersToDisconnect ${usersToDisconnect}`, `background: blue` )
                    shouldMyUserBeDisconnected = usersToDisconnect.some(user => user === this.meetingHelper.myUserDetails?.userId)

                    console.log("shouldMyUserBeDisconnected", shouldMyUserBeDisconnected);

                    usersToDisconnect.forEach(userToDisconnect => {

                        console.log('this.meetingHelper.shouldMeetingEnd ', this.meetingHelper.shouldMeetingEnd);
                        console.log('this.meetingHelper.myRole', this.meetingHelper.myRole);

                        if (!shouldMyUserBeDisconnected && !this.meetingHelper.shouldMeetingEnd) {
                            // If there is a userToDisconnect and it is not me remove that user from my peers
                            const peerToRemove = this.allPeers?.find(peer => peer?.peerId === userToDisconnect);

                            peerToRemove?.peer?.destroy();

                            //loop through the allPeers and remove the peer with the same id as userToDisconnect
                            this.allPeers = this.allPeers?.filter(peer => peer.peerId !== userToDisconnect);

                        } else if (shouldMyUserBeDisconnected && !this.meetingHelper.shouldMeetingEnd) {
                            // If we are the userToDisconnect - remove all of our peers
                            console.log('disconnectUserCheck AAAAAAAAAAAAA', userToDisconnect);
                            // Clean up all peers - destroy any peer objects
                            this.allPeers = this.allPeers?.forEach(peer => peer.peer.destroy());
                            this.allPeers = [];
                            this.hasAlreadyJoinedChat = false;

                        } else if (this.meetingHelper.shouldMeetingEnd) {
                            this.allPeers = this.allPeers?.forEach(peer => peer.peer.destroy());
                            this.allPeers = [];
                            this.hasAlreadyJoinedChat = false;

                        }

                    });
                }

                // If shouldMeetingEnd is true and we are fimanager, turn off our camera
                // (the check after the OR is to turn off fimanagers camera if they pressed disconnect on the user and then ended the meeting)
                if (this.meetingHelper.shouldMeetingEnd && this.meetingHelper.myRole === 'fimanager' ||
                    (this.meetingHelper.currentMeetingUsers?.length < 1 && this.meetingHelper.myRole === 'fimanager')
                ) {
                    console.log('MY CAMERA SHOULD BE TURNED OFF', this.localStream);
                    this.turnOffMyCamera();
                    this.cleanupPeers();

                }
            },
            reconnectedMeetingHubHandler() {
                console.log('----- RECONNECTED MEETING HUB HANDLER');
                //   this.$meetingHub.joinVideoChatRoom();
                this.cleanupPeers();
                setTimeout( () => {
                    this.initVideoChat();
                }, 2000);

                this.hasAlreadyJoinedChat = false;
            },
            turnOffMyCamera() {
                if (this.localStream) {
                    this.localStream.getTracks().forEach(function (track) {
                        track.stop(); // Stop each track to turn off the camera
                    });
                    this.localStream = null; // Reset the camera stream variable
                }
            },
            cleanupPeers() {
                console.log("CLEAN UP PEERS");
                // Clean up all peers - destroy any peer objects
                this.allPeers = this.allPeers?.forEach(peer => peer.peer.destroy());
                this.allPeers = [];
            },
            handleMuteButton() {
                this.meetingHelper.isMuted = !this.meetingHelper.isMuted;
                this.$meetingHub.updateMutedStatus(this.meetingHelper.myUserDetails.userId, this.meetingHelper.isMuted);
            },
        },
        components: {
            VideoChatStream,
        }

    }

</script>

<style>
    /* FI Manager side */
    .signalr-notes #video-chat {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        overflow: hidden;
        align-content: space-around;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        gap: 10px
    }

    .signalr-notes .video-chat-container #video-chat > * {
        background-color: #1e1e1e;
        width: 300px;
        height: 247.5px;
    }

        .signalr-notes .video-chat-container #video-chat > * video {
            background-color: #1e1e1e;
            /*width: 300px;*/
            height: 247.5px;
        }

    .video-chat-stream-container video {
        width: inherit;
        height: inherit;
    }

    #video-chat .remote-stream,
    #video-chat .local-stream {
        position: relative;
    }

    .video-chat-stream-overlay {
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        background-color: #1e1e1ea6;
        backdrop-filter: blur(10px);
        color: #f8f8f8;
        font-size: 16px;
    }

    .mute-status {
        height: 36px;
        width: 36px;
        /*border-radius: 50%;
            background: rgba(70, 70, 70, 0.65);*/
        color: #f8f8f8;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


        .mute-button .fas.fa-microphone-slash {
            background-color: var(--error-color);
        }

        .mute-status .fas.fa-microphone-slash {
            color: var(--error-color);
        }

    .mute-button {
        height: 36px;
        width: 36px;
        min-height: unset;
        padding: unset;
        box-sizing: border-box;
        /*border: 2px solid var(--main-color);*/
        border-radius: 50%;
        /*background: rgba(70, 70, 70, 0.65);*/
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .mute-button:hover {
            background: var(--main-color);
        }

        .mute-button:has(.fas.fa-microphone-slash) {
            background-color: var(--error-color);
        }

    .video-chat-stream-overlay #user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }
</style>