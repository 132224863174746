<template>
    <InputNumber
        :label
        :placeholder
        v-model:saturn="balloonAmount"
        @update:saturn="$e => emits('update:amount', $e)"
        :class="className"
        :showIcon
        :disabled
        :precision
        :height
        :invalid
    >
        <template #validation>
            <div
                v-if="validation?.$invalid && validation.$silentErrors.length > 0"
                style="width: 120px; height: fit-content"
            >
                <span
                    v-for="error in validation.$silentErrors"
                    :key="error.$property"
                    >{{ error.$message }}</span
                >
            </div>
        </template>
    </InputNumber>
</template>
<script lang="ts">
    export interface IBalloonAmountDisplayProps extends IInputNumberProps {
        amount: number | null;
        className?: string;
        validation?: any;
    }
</script>
<script setup lang="ts">
    import InputNumber, { IInputNumberProps } from '@core/components/InputNumber.vue';
    import { computed } from 'vue';
    const {
        amount = null,
        className,
        disabled = false,
        height = 40,
        invalid = false,
        label = 'Balloon',
        placeholder = 'Balloon',
        precision = 2,
        showIcon = false,
    } = defineProps<IBalloonAmountDisplayProps>();
    const emits = defineEmits(['update:amount']);

    const balloonAmount = computed(() => amount);
</script>
<style></style>
