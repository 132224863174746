<template>
    <div class="modal-container modal-x-large modal-document-preview" style="height: 100%;">
        <div class="modal-header">
            <span>{{ title }}</span>

            <div class="buttons-container">
                <template v-if="isImpactDoc">
                    <DropdownButton class="button button-print" label="Print Document" :disabled="isLoading" :actions="impactPrintActions" />

                    <DropdownButton class="button button-download" label="Download Document" :disabled="isLoading" :actions="impactDownloadActions" />
                </template>
                <template v-else>
                    <ButtonLoading class="button" :isLoading="isBusy" iconOnly @click="() => printPDF()">
                        Print
                    </ButtonLoading>
    
                    <ButtonLoading class="button" :isLoading="isBusy" iconOnly @click="() => downloadDocuments()">
                        Download
                    </ButtonLoading>
                </template>

                <button v-if="canUploadSignedCopy" :class="{'button': true, 'button-save': !isDocSigned}" :disabled="!mergedPDF" @click="openUploadAndComparePdfModal">
                    {{ (isDocSigned) ? 'Replace Signed Copy' : 'Upload Signed Copy' }}
                </button>

                <ButtonLoading v-if="fimenu.buyersOrderEnabled && displayCreditApplication" class="button" :isLoading="isBusy" iconOnly @click="sendToLender">
                    Send to RouteOne
                </ButtonLoading>
            </div>
        </div>

        <div class="modal-body">
            <div :style="$grid('1')">
                <IsBusySectionComponent v-if="isLoading" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" />

                <PDFViewer v-if="mergedPDF" ref="pdfViewer" :src="mergedPDF" @rendered="pageRendered" :class="{'hide-component': isLoading}" style="overflow-x: none" />
            </div>
        </div>

        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Close</button>
        </div>
    </div>
</template>


<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { PDFDocument } from 'pdf-lib';
    
    import $modal from '@core/services/modal';
    import api from '@core/services/api';
    import auth from "@core/services/auth";
    import util from '@core/services/util';
    
    import { DisplayCreditApplicationActions } from '@/helpers/finance-channels-helper';
    import USBHelper from '@core/helpers/usb-helper';

    import FIMenu from '@core/classes/FIMenu';
    
    import ButtonLoading from '@core/components/ButtonLoading.vue';
    import DropdownButton from '@/components/DropdownButton.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue';
    import modalUploadAndComparePDF from '@/modals/modalUploadAndComparePDF.vue';
    import PDFViewer from '@core/components/PDFViewer.vue';

    interface ModalPreviewPDFProps {
        passedData: {
            title?: string,
            pdf?: any,
            fimenu?: FIMenu,
            packetSelectedDocuments?: string[],
            uploadedSelectedDocuments?: string[],
            selectedImpactDocument?: string,
            selectedImpactPdfDocument?: string,
            documentId?: string,
            isDocSigned?: boolean,
            canUploadSignedCopy?: boolean,
            loadPDFs?: () => Promise<any>,
            uploadSignedDoc?: (documentId: string, fileData: File) => Promise<void>
        }
    }

    export default defineComponent({
        name: 'modalPreviewPDF',
        components: {
            PDFViewer,
            IsBusySectionComponent,
            ButtonLoading,
            DropdownButton
        },
        props: {
            modal: Object as PropType<ModalPreviewPDFProps>
        },
        data() {
            return {
                title: 'Document Preview',
                pdf: {} as { [key: string]: string }, // This is a dictionary mapping Azure filepaths to base-64 byte strings
                isLoading: true,
                fimenu: null as FIMenu,
                mergedPDF: null as string, // Base-64 byte string of all provided PDFs
                isBusy: true,
                renderedPDFCount: 0,
                packetSelectedDocuments: [] as string[],
                uploadedSelectedDocuments: [] as string[],
                selectedImpactDocument: null as string,
                selectedImpactPdfDocument: null as string,
                documentId: null as string,
                isDocSigned: false,
                canUploadSignedCopy: false,
            };
        },
        computed: {
            user() {
                return auth.getTokenPayload();
            },
            pdfEntries(): string[] {
                if (!this.pdf) return [];
                return Object.entries(this.pdf).map((x) => x[0]);
            },
            arrayOfPDFs(): string[] {
                if (!this.pdf) return [];
                return Object.values(this.pdf).map((pdfObj) => pdfObj);
            },
            displayCreditApplication(): boolean {
                return DisplayCreditApplicationActions(this.fimenu) && !!this.fimenu?.dealJacket?.providerId;
            },
            isImpactDoc(): boolean {
                return !!this.selectedImpactDocument;
            },
            impactPrintActions(): any[] {
                if (!this.isImpactDoc) return [];

                const actions: any[] = [
                    { 
                        label: 'Blank to Impact Printer', 
                        class: 'button', 
                        svg: '/static/img/fluxCapacitor.svg#fluxCapacitor',
                        click: async () => await USBHelper.printToImpactPrinter(this.selectedImpactDocument) 
                    },
                    { 
                        label: 'Blank to Laser Printer', 
                        class: 'button', 
                        icon: 'fas fa-print',
                        click: async () => await this.printOneDocument(this.selectedImpactPdfDocument, 'application/pdf')
                    }
                ];

                if (this.isDocSigned) {
                    actions.push({ 
                        label: 'Signed to Laser Printer', 
                        class: 'button', 
                        icon: 'fas fa-signature', 
                        click: () => this.printPDF() 
                    });
                }

                return actions;
            },
            impactDownloadActions(): any[] {
                if (!this.isImpactDoc) return [];

                const actions: any[] = [
                    { 
                        label: 'Blank PDF', 
                        class: 'button',
                        click: async () => await this.downloadOneDocument(this.selectedImpactPdfDocument, 'pdf') 
                    },
                    { 
                        label: 'Blank PRN', 
                        class: 'button',
                        click: async () => await this.downloadOneDocument(this.selectedImpactDocument, 'prn') 
                    }
                ];

                if (this.isDocSigned) {
                    actions.push({ label: 'Signed', class: 'button', click: () => this.downloadDocuments() });
                }

                return actions;
            }
        },
        created() {
            this.title = this.modal.passedData.title ?? 'Document Preview';
            this.pdf = this.modal.passedData.pdf;
            this.fimenu = this.modal.passedData.fimenu;
            this.packetSelectedDocuments = this.modal.passedData.packetSelectedDocuments;
            this.uploadedSelectedDocuments = this.modal.passedData.uploadedSelectedDocuments;
            this.selectedImpactDocument = this.modal.passedData.selectedImpactDocument;
            this.selectedImpactPdfDocument = this.modal.passedData.selectedImpactPdfDocument;
            this.documentId = this.modal.passedData.documentId;
            this.isDocSigned = this.modal.passedData.isDocSigned ?? false;
            this.canUploadSignedCopy = this.modal.passedData.canUploadSignedCopy;

            this.loadPDF();
        },
        methods: {
            cancel() {
                $modal.cancel();
            },
            async mergePDF(arrayOfPDF: string[]) { 
                // arrayOfPDF: Array of PDF in base64.

                try {
                    const mergedPdf = await PDFDocument.create();
                    
                    for (const base64Pdf of arrayOfPDF) {
                        const pdfBuffer = util.base64ToUint8Array(base64Pdf);
                        const pdf = await PDFDocument.load(pdfBuffer);
                        const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                        pages.forEach(page => mergedPdf.addPage(page));
                    }
                    
                    // Serialize the PDFDocument to bytes (a Uint8Array)
                    const mergedPdfBytes = await mergedPdf.save();
                    
                    // Set merge pdf
                    this.mergedPDF = util.uint8ArrayToBase64(mergedPdfBytes);
                }
                catch (err) {
                    console.error('Error Merging PDF: ', err);
                }
                finally {
                    this.isBusy = false;
                }
            },
            async loadPDF(): Promise<void> {
                this.pdf = await this.modal.passedData.loadPDFs();
                await this.mergePDF(this.arrayOfPDFs);
            },
            async downloadOneDocument(filepath: string, fileExtension: string = 'pdf'): Promise<void> {
                this.isBusy = true;
                try {
                    const docName = this.getDocumentName(filepath) ?? 'Packet';
                    await api.fimenu.downloadDocument(filepath, `${docName} - ${this.fimenu.dealNumber} - ${this.fimenu.storeCode}.${fileExtension}`);
                    const sessionInfo = await api.fimenu.getSessionInfo();
                    this.fimenu.addToLog(this.fimenu.paperwork.currentPacket().packetDateStamp,'Download', docName, this.user.EmployeeName, JSON.stringify(sessionInfo.data));
                }
                catch (err) {
                    console.error('Error when downloading document: ', err);
                    util.toastr('error', 'Error Download Document', 'An error occurred when trying to download the document.');
                }
                finally {
                    this.isBusy = false;
                }
            },
            async printOneDocument(filepath: string, mimeFileType: string) {
                this.isLoading = true;

                try {
                    const docBytes = await this.getDocumentBytes(filepath);
                    
                    const docBlob = util.base64ToBlob(docBytes, mimeFileType);
                    util.printBlob(docBlob);
                }
                catch (err) {
                    console.error('Error when printing document: ', err);
                    util.toastr('error', 'Error Printing Document', 'An error occurred when trying to print the document.');
                }
                finally {
                    this.isLoading = false;
                }
            },
            async getDocumentBytes(filepath: string): Promise<any> {
                this.isBusy = true;
                try {
                    const response = await api.fimenu.getDocumentBytes(filepath);
                    return response?.data;
                }
                finally {
                    this.isBusy = false;
                }
            },
            async downloadDocuments(): Promise<void> {
                this.isBusy = true;

                try {
                    const pdfBlobFile = await util.base64ToBlob(this.mergedPDF, 'application/pdf');
                    let docName = null;
                    if (this.pdfEntries.length === 1) 
                        docName = this.getDocumentName(this.pdfEntries[0]);

                    docName = docName ?? "Packet";

                    const fileName = `${docName} - ${this.fimenu.dealNumber} - ${this.fimenu.storeCode}`;
                    await util.downloadBlob(pdfBlobFile, `${fileName}.pdf`);
                    const sessionInfo = await api.fimenu.getSessionInfo();
                    this.fimenu.addToLog(this.fimenu.paperwork?.currentPacket().packetDateStamp,'Download', docName, this.user.EmployeeName, JSON.stringify(sessionInfo.data))
                } 
                catch (error) {
                    console.error('Error downloading PDF:', error);
                    util.toastr("error", "Error", "Could not download the file.");

                    // Propagate the error to the caller
                    throw error; 
                } 
                finally {
                    this.isBusy = false;
                }
            },
            getDocumentName(filepath: string): string {
                const currentDocuments = this.fimenu.paperwork?.currentPacket()?.documents;

                let docDescription = currentDocuments?.find((document: any) => {
                    return document.signedDoc === filepath || document.filledDoc === filepath || document.impactDoc === filepath;
                })?.description ?? null;

                if (!docDescription)
                    docDescription = this.fimenu.paperwork.uploadedDocuments.find((d: any) => d.id === filepath)?.docInfo?.docType ?? null;

                return docDescription;
            },
            async printPDF(): Promise<void> {
                this.isBusy = true;

                try {
                    const pdfBlobFile = await util.base64ToBlob(this.mergedPDF, 'application/pdf');
                    util.printBlob(pdfBlobFile);
                }
                catch(err) {
                    console.error('Error Printing PDF: ', err);
                    util.toastr("error", "Error", "Could not print the file.");
                }
                finally {
                    this.isBusy = false;
                }
            },
            async sendToLender(): Promise<void> {
                this.isBusy = true;

                try {
                    const response = await api.financeChannels.submitDocuments(this.fimenu.id, this.packetSelectedDocuments, this.uploadedSelectedDocuments);

                    if (response.data.statusCode == 200) { // Successfully sent
                        util.toastr("success", "Success", "Document(s) successfully submitted to RouteOne.")
                    } 
                    else { // Handled error
                        const messages = response.data.messages;

                        if (messages && messages.length > 0){
                            const joinedMessages = messages.join(" ");
                            util.toastr("error", "Error", joinedMessages);
                        } 
                        else {
                            util.toastr("error", "Error", `Unexpected sever error. Files could not be sent. Error #${response?.data?.status ?? 500}`);
                        }
                    }
                }
                catch(err: any) {
                    const errorMessage = err?.message ? err.message : "An unexpected error occurred.";
                    util.toastr("error", "Error", errorMessage);
                }
                finally {
                    this.isBusy = false;
                }
            },
            pageRendered() {
                if (this.isLoading) 
                    this.isLoading = false;
            },
            openUploadAndComparePdfModal() {
                $modal.open(modalUploadAndComparePDF, {
                    name: 'modalUploadAndComparePDF',
                    passedData: {
                        title: 'Upload Signed Document',
                        originalPDF: this.mergedPDF,
                        saveFunc: async (fileData: File) => {
                            await this.modal.passedData.uploadSignedDoc(this.documentId, fileData);
                        }
                    },
                    postFunction: (data: { base64: string }) => {
                        if (data?.base64) {
                            this.mergedPDF = data.base64;
                            this.isDocSigned = true;
                        }
                    }
                });
            }
        },
    });
</script>
<style>
    .modal-document-preview .pdf-section {
        height: 625px;
        margin-right: 30px;
        position: relative;
    }

    .modal-document-preview .pdf-section .pdf-container {
        overflow-y: scroll;
    }

    .modal-document-preview .pdf-section .pdf-container .page-container {
        margin: 0;
    }

    .modal-document-preview .pdf-section .loading-container {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-inline: 100px;
        font-weight: 500;
        font-size: 64px;
        color: var(--main-color);
    }

    .modal-document-preview .pdf-section .loading-container > * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-document-preview .pdf-section .loading-container button {
        width: 250px;
        height: 60px;
        font-size: 0.4em;
        margin-top: 20px;
    }

    .modal-document-preview .testmodel-section {
        margin-right: 30px;
    }

    .modal-document-preview .buttons-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .hide-component{
        opacity: 0
    }

    .modal-document-preview .buttons-container > button {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .modal-document-preview .button-dropdown-container.button-print > .button-dropdown > button {
        background-color: var(--main-color);
        border-color: var(--main-color);
    }
</style>
