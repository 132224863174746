<template>
    <div class="deal-audit-container ignore-all-locks">
        <TabMenu :tabs="tabs" :initialTab="tabs[0]" ref="tabMenu" @changeTab="handleTabChange">
                <template v-slot:[`tab-head-${tab}`] v-for="(tab, index) in tabs" :key="index">
                    <div>
                        <span>{{ tab }}</span>
                    </div>
                </template>

                <template v-slot:[`tab-panel-${tab}`] v-for="(tab, index) in tabs" :key="index">
                    <div class="tab-height">
                        <VideosRecordedList v-if="tab == 'Videos Recorded'"
                                            :fimenu="fimenu" />

                        <RecordedLogsList v-else-if="tab == 'Ratings Status'"
                                        :logs="fimenu.log"
                                        :ratings="true"
                                        :responses="fimenu.apiResponses" />

                        <!--<ApiResponseList v-else-if="tab == 'Ratings Status'"
                                        :responses="fimenu.apiResponses" />-->

                        <RecordedLogsList v-else-if="tab == 'Deal Logs'"
                                        :logs="fimenu.log" />

                        <RecordedLogsList v-else-if="tab == 'Viewed Logs'"
                                        :customerViewed="fimenu.customerViewed" />
                                        
                    </div>
                </template>
        </TabMenu>
    </div>
</template>

<script>
    import auth from '@core/services/auth';
    import TabMenu from '@/components/TabMenu.vue'
    import RecordedLogsList from '@/components/RecordedLogsList.vue'
    import VideosRecordedList from './VideosRecordedList.vue';

    export default {
        name: "PanelDealAudit",
        props: ['fimenu', 'handleStreams'],
        data() {
            return {
                tabs: [],
            }
        },

        computed: {
        },
        created() {
            this.setTabs();
        },
        methods: {
            setTabs() {
                this.tabs = ["Ratings Status"];
                if (this.user().EmployeeAccess.IsAdmin) {
                    this.tabs.push("Deal Logs", "Viewed Logs", "Videos Recorded");
                }
                return this.tabs;
            },
            user() {
                return auth.getTokenPayload();
            },
            handleTabChange() {
                if (this.handleStreams.list.length) {
                    this.handleStreams.emptyList();
                }
            }
        },
        components: {
            TabMenu,
            //ApiResponseList,
            RecordedLogsList,
            VideosRecordedList
        }
    };
</script>
<style>
    .deal-audit-container {
        margin-top: 10px;
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .tab-height{
        height: 100%;
        overflow: hidden;
    }
</style>