import api from "@core/services/api";
import { timeStamp } from "console";

export default class VideoRecordSystem {
    private fimenuId: string;
    private videoId: string;
    private dbReadPromise: Promise<IDBDatabase>;
    private dbWritePromise: Promise<IDBDatabase>;
    private waitingUploadToFinish: boolean = false;

    constructor(partial?: Partial<VideoRecordSystem>)
    {
        if (partial)
        {
            Object.assign(this, partial);
        }
        this.dbReadPromise = this.createDBPromise();
        this.dbWritePromise = this.createDBPromise();
    }

    public getFimenuId(): string {
        return this.fimenuId;
    }

    public setFimenuId(fimenuId: string) {
        this.fimenuId = fimenuId;
    }

    public getVideoId(): string {
        return this.videoId;
    }

    public setVideoId(videoId: string) {
        this.videoId = videoId;
    }

    private createDBPromise(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open("chunks", 1);

            request.onupgradeneeded = (event: any) => {
                const db = event.target.result;
                db.createObjectStore("chunks", { autoIncrement: true });
            };

            request.onsuccess = (event: any) => {
                const db = event.target.result;
                resolve(db);
            };

            request.onerror = (event: any) => {
                reject(event.target.error);
            };
        })
    };

    async queueVideoChunk(blob: Blob) {
        console.log("queueDB PUSH");
        try {
            const db = await this.dbWritePromise;
            const transaction = db.transaction("chunks", "readwrite");
            const queueStore = transaction.objectStore("chunks");
            queueStore.add({
                blob,
                fimenuId: this.fimenuId,
                videoId: this.videoId,
                timeStamp: Date.now()
            });
        } catch (error) {
            console.error("Error in addToQueue:", error);
        }
        if(!this.waitingUploadToFinish) {   
            this.getNextFromQueue()
        }
    }

    public async getNextFromQueue() {
        const db = await this.dbReadPromise;
        const transaction = db.transaction("chunks", "readwrite");
        const queueStore = transaction.objectStore("chunks");
        queueStore.openCursor().onsuccess = async (event: any) => {

            const cursor = <IDBCursorWithValue>event.target.result;
            console.log("CURSOR", cursor);
            if (cursor) {
                console.log("queueDB getNextFromQueue - CHUNK FOUND:", cursor);
                const chunkInfo = cursor.value;

                try {
                    /* Works without seeking */
                    cursor.delete();
                    await this.processQueueItem(chunkInfo);
                } catch (error) {
                    console.error("Error processing chunk:", error);
                }
            }            
        }
    }

    async processQueueItem(chunk: any) {
        this.waitingUploadToFinish = true 
        try {
            console.log("inside processQueueItem - chunk", chunk);
            const response = await chunk?.blob?.arrayBuffer();
            const bytes = new Uint8Array(response);
            let binary = "";
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            delete chunk["blob"];
            const processedChunk = {
                blobContent: window.btoa(binary),
                ...chunk,
            };
            console.log("----- \n\n  Processed chunk:", processedChunk, "\n\n ----- ");

            let uploadResponse = await api.azureMediaServices.uploadVideoChunk(processedChunk);
            this.waitingUploadToFinish = false

            if(uploadResponse) {
                console.log("retry after upload success");
                this.getNextFromQueue();
            }
        } catch (e) {
            console.log("Error processing chunk", e);
        }
    }
}