export default class VideoRecordingSourcesConfig
{
    stream?: MediaStream;
    width: number = 0;
    height: number = 0;
    top: number = 0;
    left: number = 0;
    video?: HTMLVideoElement;
    isStreamActive: boolean = false;
    deviceId: string = null
    
    constructor(partial?: Partial<VideoRecordingSourcesConfig>)
    {
        if (partial)
        {
            Object.assign(this, partial);
        }
    }
}