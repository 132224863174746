<template>
    <div class="modal-container modal-large">
        <div class="modal-header">Send Credit Application to {{ modal.passedData.lender.lenderName }}</div>
        <div class="modal-body">
            <div :style="$grid(modal.passedData.fimenu.isFinance() ? '1-1' : '3-4')">
                <Panel :style="$grid('1')" title="Vehicle Valuation" :isSection="true">
                    <!-- <BookValueCRUD v-if="modal.passedData.fimenu.inventoryType != 'New'" :BookValues="BookValues" ref="BookValueCrud"/> -->
                    <div :style="$grid('1-1')">
                        <InputRichDropdown
                            label="Select a Valuation Company"
                            class="grid-2-column"
                            v-model:saturn="submissionData.selectedBookValue"
                            :list="BookValuesDropdownValues"
                            :valueMap="valuePredicate"
                            :display="displayPredicate"
                            :invalid="v$.submissionData.selectedBookValue.$invalid"
                            search
                        >
                            <template #validation>
                                <span v-if="!submissionData.selectedBookValue">
                                    Please select which vehicle value to submit
                                </span>
                                <span v-else>
                                    <div v-if="v$.submissionData?.selectedBookValue.$invalid">{{v$.submissionData?.selectedBookValue.$silentErrors[0].$message}}</div>
                                </span>
                            </template>
                        </InputRichDropdown>
                        <div>
                            <InputCurrency
                                label="MSRP/Retail"
                                :saturn="BookValueSelected?.retail"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"
                            />
                            <span v-show="RetailLTVLine3" style="font-size:12px">Line 3 LTV: {{RetailLTVLine3}} </span><br/> <!-- - No preloads -->
                            <span v-show="RetailLTVLine5" style="font-size:12px">Line 5 LTV: {{RetailLTVLine5}} </span>
                        </div>

                        <div>

                            <InputCurrency
                                label="Invoice/Wholesale"
                                :precision="2"
                                :disabled="true"
                                :saturn="BookValueSelected?.wholesale"
                                :isCurrency="true"
                            />

                            <span v-show="WholesaleLTVLine3" style="font-size:12px">Line 3 LTV: {{WholesaleLTVLine3}} </span><br/> <!-- - No preloads -->
                            <span v-show="WholesaleLTVLine5" style="font-size:12px">Line 5 LTV: {{WholesaleLTVLine5}} </span>
                        </div>
                    </div>
                    <InputRichDropdown 
                        v-if="modal.passedData.fimenu.inventoryType == 'Used'"    
                        label="Submit Vehicle as Used/CPO"
                        v-model:saturn="submissionData.inventoryType"
                        :list="inventoryTypes"
                        :display="(i: InventoryType) => i.text"
                        :valueMap="(i:InventoryType) => i.value"
                        nothingSelected="-- Select Value --"
                        :sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                    />
                </Panel>
                <PanelRouteOneSendTerms
                    v-model:deal="fimenu"
                    v-model:submission-data="submissionData"
                    :lender="modal.passedData.lender"
                />
                <div :style="$grid(DisplayAdditionalFields ? '1-1' : '1')" class="grid-2-column" >
                    <FinanceInstitutionsCustomFields v-model:dynamicFields="submissionData.dynamicFields" v-if="DisplayAdditionalFields"/>
                    <div :style="$grid(DisplayAdditionalFields || !fimenu.hasCoSigner ? '1' : '1-1')">
                        <PanelRouteOneSendCustomerInformation
                            v-if="fimenu.hasCoSigner"
                            v-model:customer-info="fimenu.customersInfo"
                        />
                        <PanelRouteOneSendOtherPanel
                            v-model:submissionData="submissionData"
                        />
                    </div>
                </div>

                <Panel title="Lender Provided Information" :isSection="true" v-if="CustomFieldsDisplay.length">
                    <div v-for="fieldData in CustomFieldsDisplay" :key="fieldData.name" >
                        <Panel :title=" fieldData.name?.toUpperCase() ?? ''" :collapsed="true" :isSection="true">
                            <DisplayData
                                class="color-iteration"
                                v-for="kvp in fieldData.kvp"
                                :key="fieldData.name + '' + kvp.key"
                                :label="kvp.key"
                                :saturn="kvp.value.toLowerCase() == kvp.key.toLowerCase() ? null : kvp.value"
                            />
                        </Panel>
                    </div>
                </Panel>

            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button :disabled="v$.submissionData.$invalid" class="button-save" @click="close()">Send</button>
        </div>
    </div>
</template>

<script lang="ts">
    import FIDealJacketAdditionalInformation, { FIDealJacketAdditionalInformationValidation } from "@core/classes/FIDealJacketAdditionalInformation";
    import { FIField, FIFieldType } from "@core/classes/FIDynamicFields";
    import $modal from "@core/services/modal";
    import { CustomersRelationship } from "@core/classes/SharedEnums";
    import { defineComponent } from "vue";
    import DisplayData from "@/components/DisplayData.vue";
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import FinanceInstitutionsCustomFields from "@/components/FinanceInstitutionsCustomFields.vue";
    import { GetBookValueDropdownValues } from "@/helpers/fimenuvehicle-helper";
    import InputCurrency from "@core/components/InputCurrency.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import {InventoryTypeValues} from "@core/classes/FIDealJacketAdditionalInformation"
    import Panel from "@core/components/Panel.vue";
    import PanelRouteOneSendCustomerInformation from "@/components/PanelRouteOneSendCustomerInformation.vue";
    import PanelRouteOneSendOtherPanel from "@/components/PanelRouteOneSendOtherPanel.vue";
    import PanelRouteOneSendTerms from "@/components/PanelRouteOneSendTerms.vue";
    import {useVuelidate} from "@vuelidate/core";
    import util from "@core/services/util";
    import { VehicleBookValue } from "@core/classes/VehicleBookValue";
    import InputCheckbox from "@core/components/InputCheckbox.vue";
    import { GetOtherIncomeDescription } from "@/helpers/finance-channels-helper";

    interface BookValueDropDown {
        value: number;
        display: string;
    }

    type InventoryType = {
        text: string;
        value: InventoryTypeValues;
    };

    const inventoryTypes: InventoryType[] = [
        { text: 'USED', value: 'Used' },
        { text: 'CPO', value: 'Certified' }
    ] as const;

    export default defineComponent({
        name: "modalRouteOneSend",
        props: ["modal"],
        setup(){
            return {
                v$:useVuelidate()
            }
        },
        data() {
            return {
                BookValueCrudRef: null as any,
                submissionData: new FIDealJacketAdditionalInformation({
                    dynamicFields: this.modal.passedData.customFields,
                }),
                inventoryTypes,
            }
        },
        created() {
            const deal = this.modal.passedData.fimenu as FIMenu;
            if(deal.isFinance()) {
                // Checks for nullability. Better be safe than sorry.
                if (deal?.loanTerms?.term1 != null) {
                    this.submissionData.buyRate =  deal.loanTerms.term1.buyrate;
                    this.submissionData.sellRate = deal.loanTerms.term1.sellrate;
                    this.submissionData.term = deal.loanTerms.term1.term;
                    this.submissionData.balloonAmount = deal.loanTerms.term1.balloonAmount;
                }
            } else if(deal.isLease()) {
                // Checks for nullability. Better be safe than sorry.
                if (deal?.leaseTerms?.term1 != null) {
                    this.submissionData.buyRate =  deal.leaseTerms.term1.buyrate;
                    this.submissionData.sellRate = deal.leaseTerms.term1.sellrate;
                    this.submissionData.term = deal.leaseTerms.term1.term;
                    this.submissionData.residualPercentage = deal.leaseTerms.term1.residual;
                    this.submissionData.residualAmount = deal.leaseTerms.term1.residualAmount;
                }

            }

            this.preloadCommentWithOtherIncome()

        },
        computed: {
            util(): any {
                return util;
            },
            CustomFieldsDisplay(): FIField[] {
                return  this.submissionData.dynamicFields.fields?.filter(x => x.type === FIFieldType.DISPLAY) ?? [];
            },
            BookValuesDropdownValues(): BookValueDropDown[] {
                const deal = this.modal.passedData.fimenu as FIMenu;

                return GetBookValueDropdownValues().filter(dropdownValue =>
                    deal.vehicle.bookValues.some(
                        (vehBookVal: VehicleBookValue) =>
                            dropdownValue.value == vehBookVal.source && !!vehBookVal.retail && !!vehBookVal.wholesale,
                    ),
                );
            },
            BookValueSelected(): VehicleBookValue {
                var deal = this.modal.passedData.fimenu as FIMenu;
                const found = deal.vehicle.bookValues.find(
                    (x: VehicleBookValue) => x.source === this.submissionData.selectedBookValue,
                );
                return found;
            },
            Loading() : boolean{
                return this.modal?.passedData?.loading ?? false;
            },
            IsFinance(): boolean {
                return this.modal.passedData.fimenu.isFinance()
            },
            fimenu(): FIMenu{
                return this.modal.passedData.fimenu
            },
            CustomersRelationship(): typeof CustomersRelationship {
                return CustomersRelationship
            },
            DisplayAdditionalFields(): boolean {
                return !!this.submissionData.dynamicFields.fields.filter(x => x.type != FIFieldType.DISPLAY).length
            },
            RetailLTVLine5() : string{
                if (!this.BookValueSelected?.retail) return null;

                const bo =  this.fimenu.getCurrentBuyersOrder();
                const percentage = (bo.finalNumbers.total / this.BookValueSelected?.retail) * 100;
                const formattedPercentage = percentage.toFixed(2);
                return `${formattedPercentage}%`;
            },
            WholesaleLTVLine5() : string{
                if (!this.BookValueSelected?.wholesale) return null;

                const bo =  this.fimenu.getCurrentBuyersOrder();
                const percentage = ( bo.finalNumbers.total /this.BookValueSelected?.wholesale) * 100;
                const formattedPercentage = percentage.toFixed(2); 
                return `${formattedPercentage}%`;
            },
            WholesaleLTVLine3(): string {
                if (!this.BookValueSelected?.wholesale) return null;

                const bo =  this.fimenu.getCurrentBuyersOrder();
                const total = bo.finalNumbers.total;
                const productsTotal = bo.finalNumbers.productsSubtotal;
                
                const percentage =( (total - productsTotal) /this.BookValueSelected.wholesale)  * 100;
                const formattedPercentage = percentage.toFixed(2);
                return `${formattedPercentage}%`;
            },
            RetailLTVLine3(): string {
                if (!this.BookValueSelected?.retail) return null;

                const bo =  this.fimenu.getCurrentBuyersOrder();
                const total = bo.finalNumbers.total;
                const productsTotal = bo.finalNumbers.productsSubtotal;
                
                const percentage =((total - productsTotal) / this.BookValueSelected.retail ) * 100;
                const formattedPercentage = percentage.toFixed(2); 
                return `${formattedPercentage}%`;
            },
            ENUMS(){
                return ENUMS
            }
        },
        mounted() {
            if (this.$refs.BookValueCrud != null) {
                this.BookValueCrudRef = this.$refs.BookValueCrud;
            }
            const deal = this.modal.passedData.fimenu
            this.submissionData.inventoryType = deal.vehicleWarranty.isCertified == true ? "Certified" : deal.inventoryType;
        },
        validations(){
            return {
                submissionData: FIDealJacketAdditionalInformationValidation(this.modal.passedData.fimenu, this.submissionData)
            }
        },

        methods: {
            close() {
                // const bookValue = this.submissionData.selectedBookValue == BookValue.Default ? null : this.submissionData.selectedBookValue;
                // console.log("Asdasdsa", this.submissionData)
                $modal.close(this.submissionData);
            },
            cancel() {
                $modal.cancel();
            },
            displayPredicate(val: BookValueDropDown): any {
                return val.display;
            },
            valuePredicate(val: BookValueDropDown): any {
                return val.value;
            },
            async preloadCommentWithOtherIncome(): Promise<void> {
                try {
                    const deal = this.modal.passedData.fimenu as FIMenu;
                    const customerQuoteAppData = deal.customer?.customerApplication?.quoteAppData;
                    const coCustomerQuoteAppData = deal.coCustomer?.customerApplication?.quoteAppData;
                    
                    if (!customerQuoteAppData && !coCustomerQuoteAppData) {
                        console.warn('No quote application data found');
                        return;
                    }

                    const customerPersonalInfo = customerQuoteAppData?.applicationsDataAnswered?.personalInfo;
                    const coCustomerPersonalInfo = coCustomerQuoteAppData?.applicationsDataAnswered?.personalInfo;
                    
                    const descriptions: string[] = [];

                    if (customerPersonalInfo) {
                        const customerDescription = await GetOtherIncomeDescription(customerPersonalInfo);
                        if (customerDescription) {
                            descriptions.push(`Buyer Income: ${customerDescription}`);
                        }
                    }
                    
                    if (coCustomerPersonalInfo) {
                        const coCustomerDescription = await GetOtherIncomeDescription(coCustomerPersonalInfo);
                        if (coCustomerDescription) {
                            descriptions.push(`Co-Buyer Income: ${coCustomerDescription}`);
                        }
                    }

                    if (descriptions.length > 0) {
                        this.submissionData.comment = descriptions.join('\n').slice(0, 2000);
                    }
                } catch (error) {
                    console.error('Error in preloadCommentWithOtherIncome:', error);
                    throw error;
                }
            }
        },
        components: {
            PanelRouteOneSendTerms,
            InputRichDropdown,
            InputCurrency,
            FinanceInstitutionsCustomFields,
            Panel,
            PanelRouteOneSendOtherPanel,
            PanelRouteOneSendCustomerInformation,
            DisplayData
        },
    });
</script>
<style scoped>
.color-iteration:not(:first-child):nth-child(even) {
    background: var(--black-10percent);
}
.color-iteration:not(:first-child):nth-child(odd) {
    background: transparent;
}
</style>
