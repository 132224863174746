<template>
    <button
        v-if="canSalesManagerSendToSalesPerson"
        @click="moveDealToSalesPersonInProcess"
        class="button-delete"
    >
        Take Customer Back to Sales Person
    </button>

    <button
        v-else-if="canFISendToSalesManager"
        @click="moveDealToSalesManagerInProcess"
        class="button-delete"
    >
        Take Deal Jacket Back to Sales Manager
    </button>

    <button
        v-else-if="canFIAdminSendToFIManager"
        @click="moveDealToFIProcess"
        class="button-delete"
    >
        Take Deal Jacket Back to F&I Manager
    </button>

    <button
        v-else-if="canAccountingSendToFIAdmin"
        @click="moveDealToFIAdminProcess"
        class="button-delete"
    >
        Take Deal Jacket Back to F&I Admin
    </button>
</template>

<script lang="ts">
    export interface IButtonMoveDealProps {
        fimenu: FIMenu;
    }
</script>
<script setup lang="ts">
    import auth from '@core/services/auth';
    import { computed } from 'vue';
    import { DealStatuses } from '@core/classes/SharedEnums';
    import FIMenu from '@core/classes/FIMenu';

    const { fimenu } = defineProps<IButtonMoveDealProps>();
    const canFISendToSalesManager = computed(() => {
        return (
            auth.hasAccessToPermission('SalesManager') &&
            !fimenu.hasPaperworkPacket() &&
            !fimenu.hasCoverageSelected() &&
            fimenu.isDealInFinance() &&
            fimenu.dealStatus < DealStatuses.FiFinished
        );
    });
    const canSalesManagerSendToSalesPerson = computed(() => {
        return (
            (auth.hasAccessToPermission('SalesManager') && fimenu.dealStatus === DealStatuses.SalesManagerFinished) ||
            fimenu.dealStatus === DealStatuses.SalesManagerInProcess
        );
        //return true;
    });
    const canAccountingSendToFIAdmin = computed(() => {
        return fimenu.buyersOrderEnabled && auth.hasAccessToPermission('Accounting') && fimenu.dealStatusEnumsHelper.isWithAccounting(fimenu.dealStatus);
    });
    const canFIAdminSendToFIManager = computed(() => {
        return fimenu.buyersOrderEnabled && auth.hasAccessToPermission('FIAdmin') && fimenu.dealStatusEnumsHelper.isWithFiAdmin(fimenu.dealStatus);
    });
    function moveDealToSalesManagerInProcess() {
        fimenu.moveDealToSalesManagerInProcess();
    }
    function moveDealToSalesPersonInProcess() {
        fimenu.moveDealToSalesPersonInProcess();
    }
    function moveDealToFIProcess() {
        fimenu.moveDealToFIProcess();
    }
    function moveDealToFIAdminProcess() {
        fimenu.moveDealToFIAdminProcess();
    }
</script>

<style>
    .DealStatusIcon {
        position: relative;
        font-size: 1.8em;
        color: var(--print-color);
    }

    .DealStatusIcon .status-dot {
        position: relative;
    }

    .DealStatusIcon .status-dot svg {
        position: absolute;
        top: 40%;
        left: 60%;
        transform: translate(-50%, -50%);
        height: 1.25em;
        width: 1.25em;
    }

    .DealStatusIcon.final-step {
        color: var(--success-color);
    }

    .DealStatusIcon.dead-deal {
        color: var(--error-color);
    }

    .DealStatusIcon .spinner {
        transform-origin: center;
        animation: rotate 2.5s linear infinite;
    }

    .DealStatusIcon .spinner circle {
        stroke-linecap: round;
        animation: spinner-offset 2s ease-out infinite;
    }

    @keyframes spinner-offset {
        0% {
            stroke-dasharray: 0 150;
            stroke-dashoffset: 0;
        }

        47.5% {
            stroke-dasharray: 42 150;
            stroke-dashoffset: -16;
        }

        95%,
        100% {
            stroke-dasharray: 42 150;
            stroke-dashoffset: -59;
        }
    }
</style>
