<template>
    <Panel v-bind="{ ...panelObject, class: 'deal-terms' }">
        <template #HeaderComponent>
            <div class="panel-buttons">
                <InputRichDropdown v-if="!hideTerms"
                                   v-model:saturn="priceProfit"
                                   :list="profitPriceItems"
                                   :valueMap="(p: any) => p.value"
                                   :display="(p: any) => p.text"
                                   :height="30"
                                   class="ignore-all-locks"
                                   @change="changePriceProfit" />
            </div>
        </template>

        <div class="collapse-all-stack"
             @click="collapseAll">
            <span class="fa-stack fa-1x stack-container">
                <i class="far fa-square fa-stack-1x stack-border" />
                <i class="fas fa-square fa-stack-1x stack-background" />

                <i :class="['fas', 'fa-stack-1x', 'stack-sign', !allCollapse ? 'fa-plus-square' : 'fa-minus-square']" />
            </span>
        </div>

        <!--CASH DEAL-->
        <div v-if="fimenu.isCash() && !fimenu.isDraft() && fimenu.hasCoverageTerms">
            <!--HEADERS-->
            <div class="terms-grid">
                <span style="grid-column: span 3" />

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in fimenu.coverageTerms"
                          :key="coverageTerm.term"
                          class="spantitle ignore-deal-lock">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked"
                                class="button-span unselect-coverage ignore-deal-lock"
                                @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || coverageTerm.preferred"
                              :class="{ 'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': coverageTerm.preferred }"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ coverageTerm.term == 0 ? 'Custom Coverage' : coverageTerm.term + ' Month Coverage' }}

                        <span style="position: relative; display: block">
                            <button v-if="coverageTerm.term == 0 && !anyTermSelected && !fimenu.isSpectator"
                                    class="button-span clear-custom-coverage"
                                    @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>

                    <div v-if="fimenu.hasCoverageTerms && !hasCustomCoverage"
                         class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :height="30"
                                           :list="availableCoverageTerms"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy" />
                        <button :disabled="isAddCustomDisabled"
                                @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>
            </div>

            <!--PRICES-->
            <div class="terms-grid">
                <span style="grid-column: span 3" />

                <span :style="{ textAlign: 'right', marginTop: '3px', marginLeft: '-20px', justifySelf: 'flex-end' }">
                    <TooltipComponent class="disabled"
                                      :tooltip="tooltipTextCreation(null, allTerms[0])"
                                      :text="getBasePrice(allTerms[0])">
                    </TooltipComponent>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in fimenu.coverageTerms"
                         :key="coverageTerm.term">
                        <div class="termprice">
                            <TooltipComponent :class="{ 'disabled': true, 'displaypriceselected': coverageTerm.selected, 'displayprice': !coverageTerm.selected }"
                                              :tooltip="tooltipTextCreation(coverageTerm, allTerms[0])"
                                              :text="getFinalPrice(allTerms[0], coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!--FINANCE/DRAFT DEAL-->
        <div v-else-if="(fimenu.isFinance() || fimenu.isDraft()) && fimenu.hasCoverageTerms()"
             :style="{ 'display': 'flex', 'flex-direction': 'column', 'gap': '5px' }">
            <!--HEADERS-->
            <div class="terms-grid">
                <span>Mos.</span>

                <div :style="{ 'display': 'flex', 'justifyContent': 'space-around' }">
                    <span class="money-factor-rate"> Sell Rate </span>
                    <span class="money-factor-rate"> Buy Rate </span>
                </div>

                <span v-if="fimenu.loanTerms.isBalloon"
                      class="money-factor-rate"
                      title="Balloon Amount">
                    Balloon Amt.
                </span>
                <span v-else />

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in fimenu.coverageTerms"
                          :key="coverageTerm.term"
                          class="spantitle">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked"
                                class="ignore-deal-lock button-span unselect-coverage ignore-deal-lock"
                                @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || coverageTerm.preferred"
                              :class="{ 'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': coverageTerm.preferred }"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ coverageTerm.term == 0 ? 'Custom Coverage' : coverageTerm.term + ' Month Coverage' }}

                        <span v-if="coverageTerm.term == 0 && !anyTermSelected && !fimenu.isSpectator"
                              style="position: relative; display: block">
                            <button class="button-span clear-custom-coverage"
                                    @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>

                    <div v-if="fimenu.hasCoverageTerms && !hasCustomCoverage"
                         class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :list="availableCoverageTerms"
                                           :width="60"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy">
                        </InputRichDropdown>
                        <button :disabled="isAddCustomDisabled"
                                @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>
            </div>

            <!--PRICES-->
            <!--FIXME: Fix dynamic typing for cash term-->
            <div :class="{ 'terms-grid': true, 'hide-term': hideTerms }"
                 v-for="(term, index) in allTerms as any[]"
                 :key="index">
                <span v-if="index <= 1 && fimenu.isDraft()"
                      style="grid-column: 1 / -1">
                    {{ index === 0 ? 'Customer Terms' : 'Finance Terms' }}
                </span>

                <InputNumber v-model:saturn="term.term"
                             :disabled="isBusy || inputsDisabled"
                             placeholder="Months"
                             :showIcon="false"
                             max="999"
                             :height="40"
                             @blur="e => onDealTermBlur(term, e.target.value, index)"
                             @focus="onFocusSetRatingStatus"
                             @change="recalculate(term)"
                             :invalid="validation.loanTerms['term' + (index + 1)].term.$invalid">
                    <template #validation>
                        <div v-if="validation.loanTerms['term' + (index + 1)].term.minValue?.$invalid">Term must be at least 1.</div>
                        <div v-else-if="validation.loanTerms['term' + (index + 1)].term.required?.$invalid">Term is required</div>
                    </template>
                </InputNumber>

                <div style="display: flex">
                    <InputNumber v-model:saturn="term.sellrate"
                                 placeholder="Sell Rate"
                                 :class="{ 'flash': isReady }"
                                 label="%"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @change="recalculate(term)"
                                 :invalid="validation.loanTerms['term' + (index + 1)].sellrate.$invalid">
                        <template #validation>
                            <div v-if="validation.loanTerms['term' + (index + 1)].sellrate.minValue?.$invalid">Sell Rate must be equal or greater than {{ term.buyrate }}</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].sellrate.required?.$invalid">Sell Rate is required</div>
                        </template>
                    </InputNumber>
                    <InputNumber v-model:saturn="term.buyrate"
                                 placeholder="Buy Rate"
                                 :class="{ 'flash': isReady }"
                                 label="%"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @change="recalculate(term)"
                                 :invalid="validation.loanTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.loanTerms['term' + (index + 1)].buyrate.minValue?.$invalid">Buy Rate must be at least {{ term.sellrate - 3 }}%</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.sellrate }}%</div>
                            <div v-else-if="validation.loanTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate is required</div>
                        </template>
                    </InputNumber>
                </div>
                <div v-if="fimenu.loanTerms.isBalloon">
                    <BalloonAmount v-model:amount="term.balloonAmount"
                                   :class="{ 'flash': isReady }"
                                   :disabled="!term.term || inputsDisabled"
                                   @change="recalculate(term)"
                                   :invalid="validation.loanTerms['term' + (index + 1)].balloonAmount.$invalid"
                                   :validation="validation.loanTerms['term' + (index + 1)].balloonAmount" />
                </div>

                <span v-else />

                <span v-if="term.term && term.sellrate >= 0"
                      class="displaypricebold">
                    <TooltipComponent class="disabled"
                                      :tooltip="tooltipTextCreation(null, term)"
                                      :text="getBasePrice(term)">
                    </TooltipComponent>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in fimenu.coverageTerms"
                         :key="coverageTerm.term">
                        <div v-if="term.term && term.sellrate >= 0"
                             class="termprice">
                            <TooltipComponent v-if="term.term > 0 && getFinalPrice(term, coverageTerm, true) != 0"
                                              :class="{
                                    'disabled': true,
                                    'displaypriceselected': fimenu.loanTerms.acceptedTerm == index + 1 && coverageTerm.selected,
                                    'displayprice': fimenu.loanTerms.acceptedTerm != index + 1 || !coverageTerm.selected,
                                }"
                                              :tooltip="tooltipTextCreation(coverageTerm, term)"
                                              :text="getFinalPrice(term, coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>
                    <button v-if="$global.isAdminView && term.term && !fimenu.isDraft()"
                            title="Select term"
                            @click="fimenu.selectDealTerm(index + 1)"
                            class="admin-actions"
                            :disabled="selectedDealTerm === term">
                        <i class="fas fa-check-circle"></i>{{ selectedDealTerm !== term ? ' ?' : '' }}
                    </button>
                </template>
            </div>
        </div>

        <!--LEASE DEAL-->
        <div v-else-if="fimenu.isLease() && fimenu.hasCoverageTerms">
            <!--HEADERS-->
            <div class="terms-grid">
                <span>Mos.</span>

                <div style="display: flex; justify-content: space-around">
                    <span class="money-factor-rate"> Sell {{ isMoneyFactorAsRate ? 'Rate' : 'MF' }} </span>
                    <span class="money-factor-rate"> Buy {{ isMoneyFactorAsRate ? 'Rate' : 'MF' }} </span>
                </div>

                <span class="money-factor-rate">
                    Residual
                    <span class="fa-stack fa-1x"
                          @click="changeResidualPercentage">
                        <i class="fas fa-square fa-stack-2x stack-background" />
                        <i class="fas fa-exchange-alt fa-stack-1x"
                           style="color: white" />
                    </span>
                </span>

                <span class="spansubtitle">Base Payment</span>

                <template v-if="!hideTerms">
                    <span v-for="(coverageTerm, index) in fimenu.coverageTerms"
                          :key="coverageTerm.term"
                          class="spantitle">
                        <button v-if="coverageTerm.selected && !areDealTermsLocked"
                                class="button-span unselect-coverage ignore-deal-lock"
                                @click="unselectCoverage(index)">
                            <i class="fas fa-times-circle" />
                        </button>

                        <span v-if="coverageTerm.selected || showPreferredLease(coverageTerm)"
                              :class="{ 'selected-coverage-column': coverageTerm.selected, 'preferred-coverage-column': showPreferredLease(coverageTerm) }"
                              :style="moveSelectedCoverageColumn(index)" />

                        {{ coverageTerm.term == 0 ? 'Custom Coverage ' : coverageTerm.term + ' Month Coverage' }}

                        <span v-if="coverageTerm.term == 0 && !anyTermSelected && !fimenu.isSpectator"
                              style="position: relative; display: block">
                            <button class="button-span clear-custom-coverage"
                                    @click="deleteCustomCoverage(index)">
                                <i class="fas fa-trash-alt" />
                            </button>
                        </span>
                    </span>
                    <div v-if="fimenu.hasCoverageTerms && !hasCustomCoverage"
                         class="custom-selector">
                        <InputRichDropdown v-model:saturn="customCoveragePicker"
                                           :list="availableCoverageTerms"
                                           :width="60"
                                           :disabled="!(isPaymentSheetPrinted || hasCustomerSeenCarousel) || isBusy">
                        </InputRichDropdown>

                        <button :disabled="isAddCustomDisabled"
                                @click="addCustomTerm(customCoveragePicker)">
                            <i class="fas fa-copy" />
                        </button>
                    </div>
                </template>
            </div>

            <!--PRICES-->
            <!--FIXME: Fix dynamic typing for cash term-->
            <div class="terms-grid"
                 v-for="(term, index) in allTerms as any[]"
                 :key="index">
                <InputNumber v-model:saturn="term.term"
                             :disabled="isBusy || inputsDisabled"
                             placeholder="Months"
                             :showIcon="false"
                             max="999"
                             :height="40"
                             @blur="e => onDealTermBlur(term, e.target.value, index)"
                             @focus="onFocusSetRatingStatus"
                             :invalid="validation.leaseTerms['term' + (index + 1)].term?.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].term.required?.$invalid">Term is required</div>
                    </template>
                </InputNumber>

                <div style="display: flex">
                    <InputNumber v-show="isMoneyFactorAsRate"
                                 v-model:saturn="term.sellrate"
                                 label="%"
                                 placeholder="Sell Rate"
                                 :class="{ 'flash': isReady }"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @blur="sellRateOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].moneyfactor?.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.minValue?.$invalid">Sell Rate must be at least {{ term.buyrate }}</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.required?.$invalid">Sell Rate is required</div>
                        </template>
                    </InputNumber>

                    <InputNumber v-show="!isMoneyFactorAsRate"
                                 v-model:saturn="term.moneyfactor"
                                 label="#"
                                 placeholder="Sell MF"
                                 :class="{ 'flash': isReady }"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="5"
                                 :disabled="!term.term || inputsDisabled"
                                 :height="40"
                                 @blur="LMFOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].moneyfactor?.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].moneyfactor.required?.$invalid">Money Factor is required</div>
                        </template>
                    </InputNumber>

                    <!--Lease Buy Rate Inputs Term 1-->
                    <InputNumber v-show="isMoneyFactorAsRate"
                                 v-model:saturn="term.buyrate"
                                 label="%"
                                 placeholder="Buy Rate"
                                 :class="{ 'flash': isReady }"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="2"
                                 :disabled="!term.term || inputsDisabled"
                                 :isPercent="true"
                                 :height="40"
                                 @blur="buyRateOnBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.sellrate }}%</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.minValue?.$invalid">
                                Buy Rate must be equal or greater than {{ term.sellrate - 3 }}%
                            </div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate is required</div>
                        </template>
                    </InputNumber>

                    <InputNumber v-show="!isMoneyFactorAsRate"
                                 v-model:saturn="term.buyRateMoneyFactor"
                                 label="#"
                                 placeholder="Buy MF"
                                 :class="{ 'flash': isReady }"
                                 :clearable="false"
                                 :showIcon="false"
                                 :precision="5"
                                 :disabled="!term.term || inputsDisabled"
                                 :height="40"
                                 @blur="buyRateMoneyFactorBlurHandlerRewrite(term)"
                                 :invalid="validation.leaseTerms['term' + (index + 1)].buyrate.$invalid">
                        <template #validation>
                            <div v-if="validation.leaseTerms['term' + (index + 1)].buyrate.maxValue?.$invalid">Buy Rate must be equal or less than {{ term.moneyfactor }}</div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.minValue?.$invalid">
                                Buy Rate must be equal or greater than {{ term.moneyfactor - 0.00125 }}
                            </div>
                            <div v-else-if="validation.leaseTerms['term' + (index + 1)].buyrate.required?.$invalid">Buy Rate MF is required</div>
                        </template>
                    </InputNumber>
                </div>

                <InputNumber v-show="isResidualPercentage"
                             v-model:saturn="term.residual"
                             placeholder="Residual Pct"
                             :clearable="false"
                             :precision="2"
                             :isPercent="true"
                             :disabled="!term.term || inputsDisabled"
                             max="100"
                             :height="40"
                             @blur="residualPercentageOnBlurHandlerRewrite(term)"
                             :invalid="validation.leaseTerms['term' + (index + 1)].residual?.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].residual.required?.$invalid">Residual is required</div>
                        <div v-else-if="validation.leaseTerms['term' + (index + 1)].residual.minValue?.$invalid">
                            Residual must be greater than {{ validation.leaseTerms['term' + (index + 1)].residual.minValue?.$params.min }}%
                        </div>
                    </template>
                </InputNumber>

                <InputNumber v-show="!isResidualPercentage"
                             v-model:saturn="term.residualAmount"
                             placeholder="Residual Amount"
                             :clearable="false"
                             :precision="2"
                             customIcon="fas fa-dollar-sign"
                             :disabled="!term.term || inputsDisabled"
                             :max="fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? fimenu.getMSRP() ?? 0"
                             :height="40"
                             @blur="residualAmountOnBlurHandlerRewrite(term)"
                             :invalid="validation.leaseTerms['term' + (index + 1)].residual.$invalid">
                    <template #validation>
                        <div v-if="validation.leaseTerms['term' + (index + 1)].residual.required?.$invalid">Residual is required</div>
                        <div v-else-if="validation.leaseTerms['term' + (index + 1)].residual.minValue?.$invalid">
                            Residual must be greater than {{ validation.leaseTerms['term' + (index + 1)].residual.minValue?.$params.min }}%
                        </div>
                    </template>
                </InputNumber>

                <span>
                    <span v-if="term.term != null && term.moneyfactor != null && term.residual != null"
                          class="displaypricebold">
                        <TooltipComponent class="disabled"
                                          :tooltip="tooltipTextCreation(null, term)"
                                          :text="getBasePrice(term)">
                        </TooltipComponent>
                    </span>
                </span>

                <template v-if="!hideTerms">
                    <div v-for="coverageTerm in fimenu.coverageTerms"
                         :key="coverageTerm.term">
                        <div class="termprice"
                             v-if="term.term != null && term.moneyfactor != null && term.residual != null">
                            <!--if it's a multiple of 12, then return the same term, if not, return the one above and below-->
                            <TooltipComponent v-if="term.term > 0 && getFinalNumbers(term, coverageTerm) && getFinalNumbers(term, coverageTerm).finalPayment != 0"
                                              :class="{
                                    'disabled': true,
                                    'displaypriceselected': fimenu.leaseTerms.acceptedTerm == index + 1 && coverageTerm.selected,
                                    'displayprice': fimenu.leaseTerms.acceptedTerm != index + 1 || !coverageTerm.selected,
                                }"
                                              :tooltip="tooltipTextCreation(coverageTerm, term)"
                                              :text="getFinalPrice(term, coverageTerm)">
                            </TooltipComponent>
                        </div>
                    </div>

                    <button v-if="$global.isAdminView && term.term"
                            title="Select term"
                            @click="fimenu.selectDealTerm(index + 1)"
                            class="admin-actions"
                            :disabled="selectedDealTerm === term">
                        <i class="fas fa-check-circle"></i>{{ selectedDealTerm !== term ? ' ?' : '' }}
                    </button>
                </template>
            </div>
        </div>
    </Panel>
</template>
<script lang="ts">
    export interface IPanelDealTerms {
        fimenu: FIMenu;
        panelObject?: any; // Panel props: { title: '', action: [] }
        validation?: any; // Vuelidate object
        setGapPricing?: any; // function
        dealSave?: any; // function
        hideTerms?: boolean;
        dealTermsChangedHandler?: any; // function
    }
</script>
<script setup lang="ts">
    import { computed, inject, onBeforeUnmount, onUpdated, ref, watch, watchEffect } from 'vue';
    import util, { EventBusCore } from '@core/services/util';
    import { CreditApplicationsEnabled } from '@/helpers/finance-channels-helper';
    import { Emitter } from 'mitt';

    import { productTypes, RATING_STATUS } from '@core/classes/Enums';
    import CoverageTerm from '@core/classes/CoverageTerm';
    import CoverageTermInfo from '@core/classes/CoverageTermInfo';
    import CoverageTermPricing from '@core/classes/CoverageTermPricing';
    import { DealRoomHubPlugin } from '@core/plugins/dealRoomHub';
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuLeaseTerms from '@core/classes/FIMenuLeaseTerms';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';

    import BalloonAmount from '@core/components/_general/BalloonAmount.vue';
    import FIMenuLeaseTerm from '@core/classes/FIMenuLeaseTerm';
    import FIMenuLoanTerm from '@core/classes/FIMenuLoanTerm';
    import InputNumber from '@core/components/InputNumber.vue';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import LenderHelper from '@core/helpers/lender-helper';
    import Panel from '@core/components/Panel.vue';
    import TooltipComponent from '@core/components/TooltipComponent.vue';
    import { DealType } from '@core/classes/SharedEnums';

    const { dealSave, dealTermsChangedHandler, fimenu, hideTerms, panelObject, setGapPricing, validation } = defineProps<IPanelDealTerms>();
    const $global = inject<SaturnGlobalPlugin>('$global', null);
    const $dealRoomHub = inject<DealRoomHubPlugin>('$dealRoomHub', null);
    const EventBus = inject<Emitter<any>>('EventBus', null);

    const leaseMF = ref(true);
    const buyRate = ref(false);
    const previousDealTerms = ref<any[]>([null, null, null]);
    const allTerms = ref<FIMenuLoanTerm[] | FIMenuLeaseTerm[]>([])
    const priceProfit = ref(0);
    const customCoveragePicker = ref(null);
    const isBusy = ref(false);
    const allCollapse = ref(true);
    const cashTerm = ref(null);
    const profitPriceItems = ref([
        { text: 'Price', value: 0 },
        { text: 'Profit', value: 1 },
        { text: 'Cost', value: 2 },
    ]);
    const cachedFinalNumbers = ref<any>([]);
    const iconClass = ref({
        iconClass: 'fa-stack fa-1x ',
        icon: 'fas fa-exchange-alt iconColor',
    });

    const isReady = ref(false);
    const timeoutId = ref<NodeJS.Timeout>(null);
    const isResidualPercentage = ref<boolean>(null);

    const selectedDealTerm = computed(() => fimenu.getSelectedDealTerm());

    const hasPaperwork = computed(() => {
        const documents = fimenu?.paperwork?.currentPacket()?.documents;
        return documents && Array.isArray(documents) && documents.length > 0;
    });

    const areDealTermsLocked = computed(() => hasPaperwork.value || fimenu.isSpectator);

    const availableCoverageTerms = computed(() =>
        fimenu.coverageTerms.map((c: any) => {
            return c.term;
        }),
    );
    const hasCustomerSeenCarousel = computed(() => fimenu.customerViewed.some((c: any) => c.viewedType == 9));
    const isPaymentSheetPrinted = computed(() => fimenu.customerViewed.some((c: any) => c.viewedType == 11));
    const hasCustomCoverage = computed(() => fimenu.coverageTerms.some((t: any) => t.term === 0));
    const isAddCustomDisabled = computed(() => !(isPaymentSheetPrinted.value || hasCustomerSeenCarousel.value) || customCoveragePicker.value == null || isBusy.value);

    const inputsDisabled = computed(() => {
        if (CreditApplicationsEnabled(fimenu)) return false;

        if (fimenu.buyersOrderEnabled) {
            return !fimenu.lender.lenderCode;
        }
        return false;
    });

    const anyTermSelected = computed(() => fimenu.coverageTerms.some((c: any) => c.selected == true));

    const isBuyRatesInvalid = computed(() => {
        if (fimenu.isCash() && !fimenu.isDraft()) return false;

        if (fimenu.isLease()) {
            return (
                (fimenu.leaseTerms.term1.term && validation.leaseTerms.term1.buyrate.$invalid) ||
                (fimenu.leaseTerms.term2.term && validation.leaseTerms.term2.buyrate.$invalid) ||
                (fimenu.leaseTerms.term3.term && validation.leaseTerms.term3.buyrate.$invalid)
            );
        }

        return (
            (fimenu.loanTerms.term1.term && validation.loanTerms.term1.buyrate.$invalid) ||
            (fimenu.loanTerms.term2.term && validation.loanTerms.term2.buyrate.$invalid) ||
            (fimenu.loanTerms.term3.term && validation.loanTerms.term3.buyrate.$invalid)
        );
    });

    const isMoneyFactorsInvalid = computed(() => {
        if (fimenu.isCash() && !fimenu.isDraft()) return false;

        if (fimenu.isLease()) {
            return (
                (fimenu.leaseTerms.term1.term && validation.leaseTerms.term1.moneyfactor.$invalid) ||
                (fimenu.leaseTerms.term2.term && validation.leaseTerms.term2.moneyfactor.$invalid) ||
                (fimenu.leaseTerms.term3.term && validation.leaseTerms.term3.moneyfactor.$invalid)
            );
        }

        return (
            (fimenu.loanTerms.term1.term && validation.loanTerms.term1.sellrate.$invalid) ||
            (fimenu.loanTerms.term2.term && validation.loanTerms.term2.sellrate.$invalid) ||
            (fimenu.loanTerms.term3.term && validation.loanTerms.term3.sellrate.$invalid)
        );
    });

    const selectedLender = computed(() => {
        if (fimenu.lender.lenderCode) {
            return $global.Lenders.find(l => l.lenderCode == fimenu.lender.lenderCode);
        } else {
            return null;
        }
    });

    const isMoneyFactorAsRate = computed(() => selectedLender.value?.versions[0].lease.isMoneyFactorAsRate ?? false);
    const hasAcceptedCoverageTerm = computed(() => fimenu?.getSelectedCoverageTerm() !== null)
    function updateAllTerms(dealType?: DealType) {
        switch (dealType) {
            case DealType.Draft:
            case DealType.Retail: {


                break;
            }
            case DealType.Lease: {
                allTerms.value = [fimenu.loanTerms.term1, fimenu.loanTerms.term2, fimenu.loanTerms.term3];
                break;
            }
            default: {
                allTerms.value = [cashTerm.value];
                break;
            }
        }
    }
    watch(
        () => selectedLender.value,
        newValue => {
            if (newValue) {
                isResidualPercentage.value = newValue.versions?.[0].lease.isDefaultResidualAsRate;
                recalculate();
            }
        },
    );

    init();

    const dealTerms = fimenu.isFinance() || fimenu.isDraft() ? 'loanTerms' : fimenu.isLease() ? 'leaseTerms' : null;

    // if (dealTerms) {
    //     previousDealTerms.value = [fimenu[dealTerms].term1.term, fimenu[dealTerms].term2.term, fimenu[dealTerms].term3.term];
    // }
    iconClass.value = {
        iconClass: 'fa-stack fa-1x ',
        icon: 'fas fa-exchange-alt iconColor',
    };

    EventBus.on('recalculate', recalculate);
    (EventBusCore as Emitter<any>).on('recalculate', recalculate);

    onBeforeUnmount(() => {
        EventBus.off('recalculate', recalculate);
        (EventBusCore as Emitter<any>).off('recalculate', recalculate);
    });

    onUpdated(() => {
        isReady.value = true;
    });

    function init() {
        if (fimenu.isLease()) {
            (allTerms.value as FIMenuLeaseTerm[]).forEach(term => {
                term.residualAmount = util.isNull(term.residual)
                    ? null
                    : (term.residual / 100) * (fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? fimenu.getMSRP() ?? 0);
                term.sellrate = util.isNull(term.moneyfactor) ? null : term.moneyfactor * 2400;
                term.buyRateMoneyFactor = util.isNull(term.buyrate) ? null : term.buyrate / 2400;
            });
        }
        else if (fimenu.isFinance() || fimenu.isDraft()) {
            allTerms.value = [fimenu.loanTerms.term1, fimenu.loanTerms.term2, fimenu.loanTerms.term3];
        }

        recalculate();
    }

    function onFocusSetRatingStatus() {
        fimenu.ratingStatus = RATING_STATUS.REQUIRED;
    }

    async function onDealTermBlur(term: any, termValue: any, index: number) {
        //THIS WILL CHECK IF WE NEED TO REPRICE AND THEN REPRICE IF NECESSARY
        await checkReprice(termValue, index, setGapPricing, () => {
            term.term = null;
        });

        //IF THE DEAL TERM HAS CHANGED, RECALCULATE AFTER WE REPRICE
        //THIS PREVENTS UN-NECESSARY RECALCUATE CALLS
        if (previousDealTerms.value[index] != termValue) {
            recalculate(term);
        }
    }

    async function checkReprice(newValue: any, index: number, repriceFunc: any, resetValue: any) {
        if (dealTermsChangedHandler) dealTermsChangedHandler();

        if (hideTerms) return;

        if (!newValue || newValue == 0) {
            resetValue();
        }

        if (previousDealTerms.value[index] != newValue) {
            isBusy.value = true;
            previousDealTerms.value[index] = newValue;
            await repriceFunc();
            isBusy.value = false;
        }

        fimenu.ratingStatus = RATING_STATUS.UP_TO_DATE;
    }

    function changePriceProfit() {
        EventBus.emit('priceProfitUpdated', priceProfit.value);
    }

    function collapseAll() {
        allCollapse.value = !allCollapse.value;
        EventBus.emit('collapseAllProducts', allCollapse.value);
    }

    function showPreferredLease(coverage: any) {
        if (coverage.term == 0) {
            return false;
        }
        if (!util.isNull(fimenu.leaseTerms.term1.term)) {
            return coverage.term == fimenu.leaseTerms.term1.term;
        }
        if (!util.isNull(fimenu.leaseTerms.term2.term)) {
            return coverage.term == fimenu.leaseTerms.term2.term;
        }
        if (!util.isNull(fimenu.leaseTerms.term3.term)) {
            return coverage.term == fimenu.leaseTerms.term3.term;
        }
    }

    function getBasePrice(term: any, returnRawValue = false) {
        if (!term.finalNumbersPerCoverageTerm) return;
        const finalNumbers = getFinalNumbers(term, null);
        if (!finalNumbers) return;

        const isDealJustCash = fimenu.isCash() && !fimenu.isDraft();
        const basePrice = isDealJustCash ? finalNumbers.baseAmount : finalNumbers.basePayment;

        return returnRawValue ? basePrice : util.formatPrice(basePrice);
    }

    function getFinalPrice(term: any, coverage: any, returnRawValue = false) {
        if (!term.finalNumbersPerCoverageTerm) return;
        const finalNumbers = getFinalNumbers(term, coverage);
        if (!finalNumbers) return;

        const isDealJustCash = fimenu.isCash() && !fimenu.isDraft();
        const finalPrice = isDealJustCash ? finalNumbers.finalAmount : finalNumbers.finalPayment;

        return returnRawValue ? finalPrice : util.formatPrice(finalPrice);
    }

    //WHEN YOU CHANGE THE SELL MoneyFactor, UPDATE THE SELL Rate
    function LMFOnBlurHandlerRewrite(term: any) {
        term.sellrate = util.isNull(term.moneyfactor) ? null : term.moneyfactor * 2400;
        recalculate(term);
        if (dealTermsChangedHandler) dealTermsChangedHandler();
    }

    //WHEN YOU CHANGE THE BUY MoneyFactor, UPDATE THE BUY Rate
    function buyRateMoneyFactorBlurHandlerRewrite(term: any) {
        term.buyrate = util.isNull(term.buyRateMoneyFactor) ? null : term.buyRateMoneyFactor * 2400;
        recalculate(term);
    }

    //WHEN YOU CHANGE THE SELL Rate, UPDATE THE SELL MoneyFactor
    function sellRateOnBlurHandlerRewrite(term: any) {
        term.moneyfactor = util.isNull(term.sellrate) ? null : term.sellrate / 2400;
        recalculate(term);
        if (dealTermsChangedHandler) dealTermsChangedHandler();
    }

    //WHEN YOU CHANGE THE BUY rate, UPDATE THE BUY MoneyFactor
    function buyRateOnBlurHandlerRewrite(term: any) {
        term.buyRateMoneyFactor = util.isNull(term.buyrate) ? null : term.buyrate / 2400;
        recalculate(term);
        if (dealTermsChangedHandler) dealTermsChangedHandler();
    }

    //WHEN YOU CHANGE THE RESIDUAL percent, UPDATE THE RESIDUAL amount
    function residualPercentageOnBlurHandlerRewrite(term: any) {
        term.residualAmount = (term.residual / 100) * (fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? fimenu.getMSRP() ?? 0);
        recalculate(term);
        if (dealTermsChangedHandler) dealTermsChangedHandler();
    }

    //WHEN YOU CHANGE THE RESIDUAL amount, UPDATE THE RESIDUAL percent
    function residualAmountOnBlurHandlerRewrite(term: any) {
        if (term.residualAmount == null) term.residual = null;
        else term.residual = (term.residualAmount / (fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? fimenu.getMSRP() ?? 1)) * 100;

        recalculate(term);
        if (dealTermsChangedHandler) dealTermsChangedHandler();
    }

    // function rateOnBlurHandler(termNum: number, rateVar: any, rateType: any) {
    //     const currentTerm = ('term' + termNum) as keyof FIMenuLeaseTerms;
    //     const currentRateVar = (this as any)[rateVar];
    //     if (currentRateVar == null) {
    //         fimenu.leaseTerms[currentTerm][rateType] = null;
    //     } else {
    //         fimenu.leaseTerms[currentTerm][rateType] = currentRateVar / 2400;
    //     }
    // }

    // function residualOnBlurHandler(residualVar: any) {
    //     fimenu.leaseTerms[('term' + residualVar.replace('residual', '')) as keyof FIMenuLeaseTerms].residual =
    //         ((this as any)[residualVar] / (fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? fimenu.getMSRP() ?? 1)) * 100;
    // }

    function changeLeaseMF() {
        leaseMF.value = !leaseMF.value;
    }

    function changeResidualPercentage() {
        isResidualPercentage.value = !isResidualPercentage.value;
    }

    function changeBuyRate() {
        buyRate.value = !buyRate.value;
    }

    function addCustomTerm(copyFromTerm: any) {
        if (hasCustomCoverage.value || !copyFromTerm) return;

        let coverageTermCustom = new CoverageTerm();

        coverageTermCustom.wear = true;
        coverageTermCustom.wearPricing = new CoverageTermPricing();
        coverageTermCustom.wearInfo = new CoverageTermInfo();
        coverageTermCustom.gapPricing = new CoverageTermPricing();
        coverageTermCustom.gapInfo = new CoverageTermInfo();

        const copyFrom = fimenu.coverageTerms.find((t: any) => t.term === copyFromTerm);
        if (copyFrom) {
            coverageTermCustom = new CoverageTerm(copyFrom);
        }

        coverageTermCustom.preferred = false;
        coverageTermCustom.selected = false;
        coverageTermCustom.term = 0;
        coverageTermCustom.originalTerm = copyFromTerm;

        //If a custom coverage is missing a provider, add it
        productTypes
            .filter(pt => pt.fimenuDesc)
            .forEach(product => {
                const productInfo = (product.fimenuDesc + 'Info') as keyof CoverageTerm;
                if (util.isNull(coverageTermCustom[productInfo].provider)) {
                    const coverageTerm = fimenu.coverageTerms.find((ct: CoverageTerm) => ct[productInfo].provider);
                    if (coverageTerm) {
                        coverageTermCustom[productInfo].provider = coverageTerm[productInfo].provider;
                    }
                }
            });

        fimenu.coverageTerms.push(coverageTermCustom);
        recalculate();
        dealSave();
    }

    function deleteCustomCoverage(index: number) {
        if ($dealRoomHub.connected && fimenu.isSpectator) return;

        fimenu.coverageTerms.splice(index, 1);
        customCoveragePicker.value = null;
        recalculate();
        dealSave();
    }

    function recalculate(changedLeaseOrLoanTerm?: FIMenuLoanTerm | FIMenuLeaseTerm) {
        //SET THE FINAL NUMBERS FOR EACH TERM AND COVERAGE TERM COMBO
        if (fimenu.isLease()) {
            allTerms.value = [fimenu.leaseTerms.term1, fimenu.leaseTerms.term2, fimenu.leaseTerms.term3];
            allTerms.value
                .filter(term => (changedLeaseOrLoanTerm ? term == changedLeaseOrLoanTerm : true))
                .forEach(leaseOrLoanTerm => {
                    //PER COVERAGE TERM
                    leaseOrLoanTerm.finalNumbersPerCoverageTerm = fimenu.coverageTerms.map((coverageTerm: CoverageTerm) => ({
                        term: coverageTerm.term,
                        finalNumbers: leaseOrLoanTerm.term ? calculateAndSetFinalNumbers(leaseOrLoanTerm, coverageTerm) : null,
                    }));

                    //BASE TERM
                    leaseOrLoanTerm.finalNumbersPerCoverageTerm.push({
                        term: null,
                        finalNumbers: leaseOrLoanTerm.term ? calculateAndSetFinalNumbers(leaseOrLoanTerm, null) : null,
                    });
                });
        } else if (fimenu.isFinance() || fimenu.isDraft()) {
            allTerms.value = [fimenu.loanTerms.term1, fimenu.loanTerms.term2, fimenu.loanTerms.term3];
            allTerms.value
                .filter(term => (changedLeaseOrLoanTerm ? term == changedLeaseOrLoanTerm : true))
                .forEach(leaseOrLoanTerm => {
                    //PER COVERAGE TERM
                    leaseOrLoanTerm.finalNumbersPerCoverageTerm = fimenu.coverageTerms.map((coverageTerm: CoverageTerm) => ({
                        term: coverageTerm.term,
                        finalNumbers: calculateAndSetFinalNumbers(leaseOrLoanTerm, coverageTerm),
                    }));

                    //BASE TERM
                    leaseOrLoanTerm.finalNumbersPerCoverageTerm.push({
                        term: null,
                        finalNumbers: calculateAndSetFinalNumbers(leaseOrLoanTerm, null),
                    });
                });
            allTerms.value = [fimenu.loanTerms.term1, fimenu.loanTerms.term2, fimenu.loanTerms.term3];
        } else if (fimenu.isCash()) {
            cashTerm.value = {
                finalNumbersPerCoverageTerm: fimenu.coverageTerms.map((ct: CoverageTerm) => ({
                    term: ct.term,
                    finalNumbers: calculateAndSetFinalNumbers(null, ct),
                })),
            };
            cashTerm.value.finalNumbersPerCoverageTerm.push({
                term: null,
                finalNumbers: calculateAndSetFinalNumbers(null, null),
            });
            allTerms.value = [cashTerm.value];
        }

        //RESERVE PROFIT RECALCULATE
        if (!fimenu.hasPaperworkPacket()) getReserveProfitResult();
    }

    function getPaymentAmounts() {
        const paymentAmounts: any[] = [];
        allTerms.value
            .filter(term => term.term)
            .forEach(term => {
                term.finalNumbersPerCoverageTerm?.forEach((fnpct: any) => {
                    let paymentAmount = null;
                    if (fnpct.finalNumbers !== null) {
                        paymentAmount = LenderHelper.getTermFIMenuPaymentAmount(term.term, fnpct.term, fnpct.finalNumbers, term.sellrate, term.buyrate);
                        paymentAmounts.push(paymentAmount);
                    }
                });
            });
        return paymentAmounts;
    }

    function getFinalNumbers(leaseOrLoanTerm: any, coverageTerm: CoverageTerm) {
        //if (leaseOrLoanTerm && leaseOrLoanTerm.finalNumbersPerCoverageTerm) {
        const finalNumbersForTerm = leaseOrLoanTerm.finalNumbersPerCoverageTerm.find((ct: any) => ct.term == coverageTerm?.term);
        return finalNumbersForTerm?.finalNumbers;
        //}
        //else {
        //    let finalNumbersForTerm = allTerms.value[0].finalNumbersPerCoverageTerm.find(ct => ct.term == coverageTerm?.term);
        //    return finalNumbersForTerm?.finalNumbers;
        //}
    }

    function calculateAndSetFinalNumbers(leaseOrLoanTerm: any, coverageTerm: CoverageTerm) {
        const isCDK = false;
        const localFimenu = fimenu;
        const state = $global.selectedStore.storeState;

        //IF WE ARE HIDING TERMS, ONLY CONTINUE FOR BASE TERM
        if (hideTerms && coverageTerm != null) return null;

        //IF WE ARE HIDING TERMS, ONLY CONTINUE IF WE HAVE A DEAL TERM
        if (hideTerms && !localFimenu.isCash() && leaseOrLoanTerm.term == null) return null;

        //IF WE ARE HIDING TERMS, FOR DRAFT DEALS, ONLY CONTINUE IF WE HAVE A DEAL TERM
        if ((localFimenu.isDraft() || localFimenu.isLease() || localFimenu.isFinance()) && leaseOrLoanTerm.term == null) return null;

        const finalNumbers = localFimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isCDK);

        return finalNumbers;
    }

    function moveSelectedCoverageColumn(index: number) {
        return {
            left: 366 + index * 100 + 'px',
        };
    }

    function unselectCoverage(index: number) {
        if ($dealRoomHub.connected && fimenu.isSpectator) return;

        fimenu.coverageTerms[index].selected = false;
        fimenu.leaseTerms.acceptedTerm = null;
        fimenu.loanTerms.acceptedTerm = null;
    }

    function getReserveProfitResult() {
        if (timeoutId.value !== null) {
            clearTimeout(timeoutId.value);
        }

        timeoutId.value = setTimeout(async () => {
            if (!fimenu.lender.lenderCode) return;
            const paymentAmounts = getPaymentAmounts();
            const filteredPaymentAmounts = paymentAmounts.filter(pa => pa.buyRate != null && pa.sellRate != null);
            if (filteredPaymentAmounts.length > 0) {
                const paymentAmountsWithProfits = await LenderHelper.getReserveProfitResults(fimenu, $global.Lenders, fimenu.lender.lenderCode, filteredPaymentAmounts);
                savePaymentAmounts(paymentAmountsWithProfits);
            } else {
                savePaymentAmounts(paymentAmounts);
            }
            timeoutId.value = null;
        }, 1500);
    }

    function savePaymentAmounts(paymentAmounts: any[]) {
        if (paymentAmounts) {
            allTerms.value
                .filter(term => term.term)
                .forEach(dealTerm => {
                    dealTerm.paymentAmounts = paymentAmounts.filter(pa => pa.dealTerm == dealTerm.term && pa.sellRate == dealTerm.sellrate && pa.buyRate == dealTerm.buyrate);
                });
        }
    }

    function tooltipTextCreation(coverageTerm: CoverageTerm, dealTerm: any) {
        const toolTipLines = [];

        let line3 = null;
        let totalCoverageWithTaxes = null;
        let line5 = null;
        let totalCoverage = null; //TOTAL COVERAGE BASED ON WHAT WE WANT TO DISPLAY

        if (fimenu.buyersOrderEnabled) {
            //GET BUYERS ORDER SO WE CAN GET ALL THE TOTALS
            let finalNumbers = getFinalNumbers(dealTerm, coverageTerm);
            const buyersOrder = finalNumbers?.bo;

            if (buyersOrder && buyersOrder.finalNumbers) {
                totalCoverageWithTaxes = buyersOrder.finalNumbers.productsSubtotal + buyersOrder.finalNumbers.productsTaxTotal;
                line3 = buyersOrder.finalNumbers.total - totalCoverageWithTaxes;
                totalCoverage = buyersOrder.finalNumbers.productsSubtotal;
                line5 = buyersOrder.finalNumbers.total;
            }
        } else {
            const theFinalNumbers = fimenu.finalNumbers(fimenu.store.storeState, dealTerm, coverageTerm, false);

            line3 = fimenu.otdBase;
            totalCoverage = (theFinalNumbers as any).productsSubtotal;
            totalCoverageWithTaxes = (theFinalNumbers as any).totalPrice;
            line5 = theFinalNumbers.finalAmount;
        }
        // parse through result to get payment amounts
        const currentPaymentAmount = fimenu.isCash()
            ? null
            : allTerms.value.find(term => term.term == dealTerm?.term).paymentAmounts.find((pa: any) => pa.coverageTerm == coverageTerm?.term);
        const reserveProfitSplit = currentPaymentAmount?.reserveProfitSplit;
        const reserveProfitFlat = currentPaymentAmount?.reserveProfitFlat;

        toolTipLines.push('Line 3: ' + util.formatPrice(line3)); //OTD without Products
        toolTipLines.push('');
        toolTipLines.push('Total Coverage: ' + util.formatPrice(totalCoverage));
        toolTipLines.push('(excluding tax)');
        //toolTipLines.push("Coverage Tax: " + util.formatPrice(totalCoverageWithTaxes - totalCoverage)) // //Total Coverage Tax
        //toolTipLines.push("Coverage with Tax: " + util.formatPrice(totalCoverageWithTaxes))
        toolTipLines.push('');

        //IF NOT CASH, SHOW FINAL OTD AS WELL
        if (fimenu.dealType != 'Cash') {
            toolTipLines.push('Line 5: ' + util.formatPrice(line5));
        }

        if (reserveProfitSplit) {
            toolTipLines.push('');
            toolTipLines.push('Reserve Profit Split: ' + util.formatPrice(reserveProfitSplit));
        }
        if (reserveProfitFlat) {
            toolTipLines.push('');
            toolTipLines.push('Reserve Profit Flat: ' + util.formatPrice(reserveProfitFlat));
        }

        return toolTipLines;
    }
</script>
<style>
    .panel.deal-terms .panel-header-title {
        padding-left: 25px;
    }

    .panel.deal-terms .hide-term {
        grid-template-columns: 40px 140px 70px 100px !important;
        grid-row-gap: 10px;
    }

    .panel.deal-terms .admin-actions {
        margin-left: 5px;
        width: calc(50% - 5px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .panel.deal-terms .panel-header-button-array {
        position: absolute;
        right: 140px;
    }

    .iconColor {
        color: var(--background-color);
    }
</style>
