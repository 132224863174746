<template>
    <div class="deal-locked">
        <div >
            <span style="color:var(--text-color);">Deal is {{lockType == "sentCreditApplication"? "partially " : ""}}locked. &nbsp;
                <a @click="openModal" style="color: var(--text-color)" class="clickable">Click here for details.</a>
            </span>
            <!-- <span>
                {{lockText[lockType]}}
                <a v-if="lockType == 'sentCreditApplication'" class="clickable"> &nbsp; Copy Quote</a>
            </span> -->
            <IsBusyScreenComponent v-if="copyingQuote"/>
        </div>


    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import  {copyDeal} from '@core/classes/FIMenuHelper';
    import FIMenu from '@core/classes/FIMenu';
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue';
    import util from '@core/services/util';
    import { DealKind } from '@core/classes/SharedEnums';

    export default defineComponent({
        name: "DealIsLocked",
        components:{IsBusyScreenComponent},
        props: {
            lockType: {
                type: String,
            },
            deal: {
                type: Object as PropType<FIMenu>
            }
        },
        data() {
            return {
                lockText: {
                    paperwork: "This Deal is locked. To make any change, please Void Deal Paperwork.",
                    sales: "This Deal is locked. Sales is now in charge of this deal.",
                    finance: "This Deal is locked. Finance is now in charge of this deal.",
                    accounting: "This Deal is locked. Accounting is now in charge of this deal.",
                    coverageSelected: "This Deal is locked. To make any change, unselect current selected Coverage Term.",
                    spectator: "This Deal is locked. To make any changes, please review your options in the co-browsing menu.",
                    sentCreditApplication: ["This Quote is partially locked. To make changes, please copy the quote.", "The deal is partially locked because at least one credit application has been submitted to the lender."]
                },
                copyingQuote: false
            }
        },
        methods: {
            openModal() {

                let okLabel = this.copyingQuote ? "Ok" : "Loading ...";
                let message = this.lockText[this.lockType as keyof typeof this.lockText];

                if (Array.isArray(message)) {
                    if(this.deal.dealKind == DealKind.Quote) {
                        message = message[0]
                    } else {
                        message = message[1]
                    }
                }
                let postFunction = (storeCode: string | null) => {}
                let cancelLabel = null;
                let cancelFunction = () => {}

                switch(this.lockType) {
                    case "sentCreditApplication" :
                        okLabel = "Copy Deal"
                        cancelLabel = "Cancel";
                        postFunction = async (storeCode: string | null) => {
                            try {
                                this.copyingQuote = true
                                await copyDeal(this.deal.id, this.deal.dealKind, storeCode)

                            } catch(err) {
                                console.error(err)
                            } finally {
                                this.copyingQuote = false;
                            }
                        }
                        break;
                    default :
                        okLabel = "Ok";

                        break;
                }

                util.modalLockedMessage(message, okLabel, postFunction, cancelLabel, cancelFunction, "Deal Locked", true, this.lockType)
            },
        }
    });
</script>

<style scoped>
    .clickable {
       text-decoration: underline;
       font-weight: 500;
    }
</style>