<template>
    <div class="RecordedLogsList">
        <div v-if="logs" class="richtable-container">
            <div v-if="logs && logs.length > 0 && ratings" class="ratings">
                <AccordionList :list="getFilteredList()" :closeOtherOnOpen="true">
                    <template #listHeader="{headerItem}">
                        <span v-if="headerItem && getCount(headerItem).success > 0" class="successes">{{ getCount(headerItem).success }}</span>
                        <span v-if="headerItem && getCount(headerItem).error > 0" class="errors">{{ getCount(headerItem).error }}</span>
                    </template>
                    <template #listBody="{bodyItem}">
                        <RichTable v-if="bodyItem" :tableData="latestRatings(bodyItem)" :headers="ratingsListHeaders" />
                    </template>
                </AccordionList>
            </div>

            <div v-if="logs && logs.length > 0 && !ratings">
                <RichTable :tableData="logs" :headers="logListHeaders" :search="true" />
            </div>
            <div v-if="!logs.find(l => l.where.includes('Rate')) || !logs.length" class="empty-list">
                <p v-if="ratings && !logs.find(l => l.where.includes('Rate'))">There are currently no recent ratings recorded for this deal.</p>
                <p v-if="!ratings && logs && !logs.length">There are currently no logs recorded for this deal.</p>
            </div>
        </div>
        <div v-if="customerViewed" class="richtable-container">
            <div v-if="customerViewed && customerViewed.length > 0">
                <RichTable :tableData="customerViewed" :headers="customerViewedListHeaders" :search="true" />
            </div>
            <div v-else class="empty-list">
                <p>There is currently no activity on the customer's side of the deal.</p>
            </div>
        </div>
    </div>
</template>
<script>
    import { _ } from 'vue-underscore';
    import Warnings from '@core/services/warningMessages'
    import ENUMS, { customerViews, VIDEOS_SEEN_TITLES, productTypes, PRODUCT_TYPES } from "@core/classes/Enums"

    import RichTable from '@core/components/RichTable.vue'
    import AccordionList from '@core/components/AccordionList.vue'
    import settings from 'settings'
    import $modal from '@core/services/modal'
    import auth from '@core/services/auth';
    import util from '@core/services/util'
    import modalRateResponse from '@/modals/modalRateResponse.vue';

    export default {
        name: "RecordedLogsList",
        props: ['logs', 'customerViewed', 'ratings', 'dealLogs', 'responses'],
        data() {
            return {
                generatedWarnings: new Warnings("RecordedLogsList"),
                ratingsListHeaders: [
                    {
                        name: 'provider',
                        display: 'Provider',
                        value: v => {
                            return v.provider ? this.getProviderName(v.provider) : "N/A"
                        },
                    },
                    {
                        name: 'status',
                        display: 'Status',
                        value: v => {
                            return v.status ? v.status : "N/A"
                        },
                        cellCssClass: (row) => row.status !== "STATUS: Success" ? 'status-error' : ''
                    },
                    {
                        name: 'ratingDate',
                        display: 'Rating Date',
                        value: v => {
                            const rateResponse = this.responses.find(r=> r.provider == v.provider && r.productType == v.productType)?.responseDateTime;

                            if(rateResponse){
                                const timestamp = Date.parse(rateResponse);
                                return new Date(timestamp).toLocaleString();
                            }
                            return 'N/A';
                        },
                    },
                    {
                        name: 'action',
                        display: "",
                        sortable: false,
                        actions: [
                            {
                                name: 'apiResponses', type: 'button', text: 'View', cssClass: 'button button-edit', disabled: (row) => {return !this.isAdmin() || row.status != "STATUS: Success"},
                                action: (row) => { $modal.open(modalRateResponse, { name: 'Response', passedData: { data: this.getResponseData(row), title: 'Response', headers: this.RichTableHeaders} }); }
                            },
                        ],
                    },
                ],
                sessionTableHeaders: [
                    {
                        display: "Date",
                        name: "sessionTimestamp",
                        value: v => v.sessionTimestamp ?? "N/A",
                    },
                    {
                        display: "IP",
                        name: "ipv4Address",
                        value: v => v.ipv4Address ?? "N/A",
                    },
                    {
                        display: "OS",
                        name: "os",
                        value: v => v.os ?? "N/A",
                    },
                    {
                        display: "Device",
                        name: "device",
                        value: v => v.device ?? "N/A",
                    },
                    {
                        display: "Brand",
                        name: "brand",
                        value: v => v.brand ?? "N/A",
                    },
                    {
                        display: "Model",
                        name: "model",
                        value: v => v.model ?? "N/A",
                    },
                ],
                RichTableHeaders:[
                    {
                        autoFilter: true,
                        display: "Program",
                        name: "program",
                        sortable: true,
                    },
                    {
                        autoFilter: true,
                        display: "Program Description",
                        name: "programDescription",
                        sortable: true,
                    },
                    {
                        autoFilter: true,
                        display: "Subprogram",
                        name: "subprogram",
                        value: r => r.subprogram ?? "N/A",
                        sortable: true,
                    },
                    {
                        autoFilter: true,
                        display: "Miles Limit",
                        name: "milesLimit",
                        value: r => r.milesLimit ?? "N/A",
                        sortable: true,
                    },
                    {
                        autoFilter: true,
                        display: "Net Miles",
                        name: "netMiles",
                        value: r => r.netMiles ?? "N/A",
                        sortable: true,
                    },
                    {
                        autoFilter: true,
                        display: "Term Limit",
                        name: "termLimit",
                        sortable: true,
                    },
                    {
                        display: "Price",
                        name: "msrp",
                        sortable: true,
                    },
                    {
                        display: "Cost",
                        name: "cost",
                        sortable: true,
                    },
                    {
                        display: "Surcharges",
                        name: "surcharges",
                        sortable: false,
                        slot:"tooltipTwo"
                    },
                    {
                        display: "Meta",
                        name: "meta",
                        sortable: false,
                        slot:"tooltip"
                    },
                ],
                logListHeaders: [
                    {
                        name: 'where',
                        display: "Section Name",
                        value: v => {
                            return v.where ? v.where : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                    {
                        name: 'when',
                        display: "Time",
                        value: v => {
                            return v.when ? util.toMoment(v.when).format('MM/DD/YYYY hh:mma') : "N/A";
                        },
                        sortable: true
                    },
                    {
                        name: 'how',
                        display: "Action",
                        value: v => {
                            return v.how ? v.how : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                    {
                        name: 'what',
                        display: "Status",
                        value: v => {
                            return v.what ? v.what : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                    {
                        name: 'who',
                        display: "Done By",
                        value: v => {
                            return v.who ? v.who : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                    {
                        name: 'action',
                        display: "",
                        sortable: false,
                        actions: [
                            {
                                name: 'meta', type: 'button', text: 'View', cssClass: 'button button-edit', disabled: (row) => { return util.isNullOrEmpty(row.meta) || !this.isAdmin() },
                                action: (row) => { $modal.open(modalRateResponse, { name: 'Meta Data', passedData: { data: [this.getSessionMeta(row)], title: 'Meta Data', headers: this.sessionTableHeaders} }); }
                            },
                        ],
                    },
                ],
                customerViewedListHeaders: [
                    {
                        name: 'videosSeen',
                        display: "Seen Videos",
                        value: v => {
                            return v.videosSeen ? VIDEOS_SEEN_TITLES[v.videosSeen] : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                    {
                        name: 'viewedDateTime',
                        display: "Time",
                        value: v => {
                            return v.viewedDateTime ? util.toMoment(v.viewedDateTime).format('MM/DD/YYYY hh:mma') : "N/A";
                        },
                        sortable: true
                    },
                    {
                        name: 'viewedType',
                        display: "Action",
                        value: v => {
                            return v.viewedType ? customerViews[v.viewedType] : "N/A";
                        },
                        searchable: true, sortable: true, autoFilter: true
                    },
                ],
            };
        },
        computed: {
        },

        methods: {
            getCount(item) {
                const data = this.latestRatings(item)
                const success = data.filter(r => r.status === "STATUS: Success").length
                const error = data.filter(r => r.status !== "STATUS: Success").length
                return { success, error }
            },
            isAdmin() {
                return auth.getTokenPayload().EmployeeAccess.IsAdmin
            },
            getSessionMeta(row) {
                if(row.meta) {
                    return JSON.parse(row.meta)
                }
                return ""
            },
            getResponseData(row) {
                const foundIndex = this.responses.findIndex(key => (key.provider == row.provider && key.productType == row.productType))
                if (foundIndex >= 0) {
                    return this.responses[foundIndex].rateResponse?.rates ?? JSON.parse(this.responses[foundIndex].response)
                }
                else return row.status
            },
            getProviderName(provider) {
                const foundIndex = settings.lookups.providers.findIndex(key => key.providerCode === provider)
                if (foundIndex >= 0) {
                    return settings.lookups.providers[foundIndex].providerName
                }
                else return provider
            },
            latestRatingsProvider() {
                if (this.logs.some(l => l.where.includes('Rate'))) {
                    const endIndex = this.logs.findLastIndex(l => l.what == ENUMS.LOG_WHAT.STEP_FINISHED && l.where == ENUMS.LOG_WHERE.RATE);
                    const startIndex = this.logs.findLastIndex(l => l.what == ENUMS.LOG_WHAT.STEP_STARTED && l.where == ENUMS.LOG_WHERE.RATE) + 1;

                    const providersList = this.logs.slice(startIndex, endIndex)
                    ///When Product Type is not found and defaults to 0 reassign to OTHER
                    const findErrorList = providersList.map(item => item.how.split('/')[2]).filter((prv, index, self) => prv && self.indexOf(prv) == index)
                    if (findErrorList.includes('0')) {
                        providersList[findErrorList.indexOf('0')].how = providersList[findErrorList.indexOf('0')].how.replace('0',`${PRODUCT_TYPES.OTHER}`)
                    }
                    return providersList
                }
                else {
                    return []
                }
            },
            getFilteredList() {
                //mapping product types in deal logs and reducing to only unique values
                const filteredProductList = this.latestRatingsProvider().map(item => item.how.split('/')[2]).filter((prv, index, self) => prv && self.indexOf(prv) == index)
                const foundList = filteredProductList.map(product => productTypes.find(pt => pt.code == product).description)
                return foundList
            },
            listArrOjb() {
                const listArrObj = this.latestRatingsProvider().map(item => ({
                    provider: item.how.split('/')[1],
                    productType: item.how.split('/')[2],
                    status: item.what
                }))
                return listArrObj.filter(item => item.provider)
            },
            latestRatings(item) {
                const filteredArrObj = this.listArrOjb()
                const productTypeObj = productTypes.find(pt => pt.description == item)
                return filteredArrObj.filter(obj => obj.productType === productTypeObj.code.toString())
            },
        },
        components: {
            RichTable,
            AccordionList,
        }
    };
</script>
<style>
    .status-error {
        color: var(--error-color);
    }
</style>
<style scoped>
    .error {
        background-color: var(--error-color);
    }

    .RecordedLogsList {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .successes,
    .errors {
        padding: 5px 10px;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;
    }

    .successes {
        background-color: var(--success-color);
    }

    .errors {
        background-color: var(--error-color);
    }

    .RecordedLogsList .rich-table-paging-container {
        justify-content: flex-end
    }

    .RecordedLogsList .richtable-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .RecordedLogsList .ratings .rich-table {
        table-layout: fixed;
    }

    .RecordedLogsList .empty-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

        .RecordedLogsList .empty-list p {
            color: var(--text-color);
            font-size: 1.4rem;
            margin: 0;
        }

    .RecordedLogsList .Accordion {
        max-width: 1055px;
    }

    .RecordedLogsList .action-cell {
        justify-content: flex-end !important;
    }

    .RecordedLogsList .Accordion .rich-table-table {
        border-top: 1px solid var(--border-color);
    }

    .RecordedLogsList .Accordion .children-container {
        width: 95%;
        padding: 10px 0;
        margin-top: 0;
    }

    /*            .RecordedLogsList .empty-list p {
                color: var(--text-color);
                font-size: 1.4rem;
            }*/
</style>
