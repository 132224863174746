import { reactive } from 'vue';

import { HubConnection, HubConnectionBuilder, LogLevel, Subject } from '@microsoft/signalr';
import util, { EventBusCore } from '@core/services/util';
import settings from 'settings';

import { ICB_HTMLCoordinates, ICB_JoinRoomRequest } from '@core/classes/CoBrowsingModels';
import FIMenu from '@core/classes/FIMenu';

export class DealRoomHubPlugin {
    manuallyClosed: boolean;
    connected: boolean;
    reconnecting: boolean;

    /** Functions as the SignalR Group ID */
    code: string;

    subject: Subject<unknown>;
    connection: Partial<HubConnection>;
    startedPromise: Promise<void>;

    constructor(init?: Partial<DealRoomHubPlugin>) {
        this.manuallyClosed = init?.manuallyClosed ?? false;
        this.connected = init?.connected ?? false;
        this.reconnecting = init?.reconnecting ?? false;
        this.code = init?.code;
        this.subject = new Subject();
    }

    startConnection(code: string): Promise<void> {
        if (this.connected) return Promise.resolve();

        this.code = code;

        this.startedPromise = this.connection
            .start()
            .then(() => {
                this.updateConnectedStatus(true);
                this.manuallyClosed = false;
            })
            .catch(err => {
                console.error('CO-BROWSING: FAILED TO CONNECT - ', err);
            });
    }

    async stopConnection(): Promise<void> {
        if (!this.connected && !this.reconnecting) return Promise.resolve();

        this.updateConnectedStatus(false);
        this.code = '';
        this.manuallyClosed = true;

        try {
            await this.connection.stop();
            console.warn('CO-BROWSING: DISCONNECTED');
        } catch (err) {
            console.error('CO-BROWSING: ERROR DISCONNECTING - ', err);
        }
    }

    onConnectionClosed() {
        this.connection.stop();

        this.connected = false;
        this.reconnecting = false;

        // eslint-disable-next-line no-console
        console.info('%cCO-BROWSING: CONNECTION CLOSED.', 'color: red; font-weight: bold;');
    }

    onConnectionReconnecting(error: Error) {
        this.updateConnectedStatus(false);
        this.updateReconnectingStatus(true);

        console.warn(
            `CO-BROWSING: CONNECTION LOST DUE TO ERROR: ${error?.name ?? '[UNKNOWN ERROR]'}; RECONNECTING - `,
            error,
        );
    }

    onConnectionReconnected(connectionId: string) {
        this.updateConnectedStatus(true);
        this.updateReconnectingStatus(false);

        // eslint-disable-next-line no-console
        console.info('%cCO-BROWSING: SUCCESSFULLY RECONNECTED.', 'color: green; font-weight: bold;');
    }

    updateConnectedStatus(newValue: boolean) {
        this.connected = newValue;
        EventBusCore.emit('dealRoomHubConnected', newValue);
    }

    updateReconnectingStatus(newValue: boolean) {
        this.reconnecting = newValue;
        EventBusCore.emit('dealRoomHubReconnecting', newValue);
    }

    // ============================
    // EVENT SUBSCRIPTION FUNCTIONS
    // ============================
    // #region Event Subscription Functions

    /** A list of all possible events can be found in SignalRDealRoom.cs */
    subscribe(eventName: string, callback: (...args: any[]) => any) {
        this.connection.on(eventName, callback);
    }

    /** Expects an object in the format: { EventNameA: () => {}, EventNameB: () => {}, ... } */
    bulkSubscribe(eventObj: { [eventKey: string]: (...args: any[]) => any }) {
        if (typeof eventObj !== 'object') {
            console.error(
                'Could not bulk subscribe to events. Expected to receive an object. Instead received: ',
                typeof eventObj,
            );
            return;
        }

        Object.keys(eventObj).forEach(key => {
            if (util.isFunction(eventObj[key]))
                this.subscribe(key, eventObj[key]);
            else
                console.error(`Could not subscribe to event ${key}. Provided callback was not a function.`);
        });
    }

    unsubscribe(eventName: string, callback: (...args: any[]) => any) {
        this.connection.off(eventName, callback);
    }

    /** Expects an object in the format: { EventNameA: () => {}, EventNameB: () => {}, ... } */
    bulkUnsubscribe(eventObj: { [eventKey: string]: (...args: any[]) => any }) {
        if (typeof eventObj !== 'object') {
            console.error(
                'Could not bulk subscribe to events. Expected to receive an object. Instead received: ',
                typeof eventObj,
            );
            return;
        }

        Object.keys(eventObj).forEach(key => {
            if (util.isFunction(eventObj[key]))
                this.unsubscribe(key, eventObj[key]);
            else
                console.error(`Could not subscribe to event ${key}. Provided callback was not a function.`);
        });
    }
    // #endregion

    // ===========================
    // GENERAL DEAL ROOM FUNCTIONS
    // ===========================
    // #region General Deal Room Functions

    async ping(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('Ping');
        }
        catch (err) {
            console.error('COBROWSING - PING: ', err);
        }
    }

    getDealRoomId(storeCode: string, dealNumber: string, dealId: string) {
        // If you change this format, update the backend logic as well (DealRoom.cs)
        return `${storeCode}_${dealNumber}_${dealId}`;
    }

    /**
     * Connect to a deal room, or creates a new deal room to join if the requested room doesn't exist yet.
     * If user is an Editor && a match for the given login is discovered in the deal room, disconnects the OLD connection.
     * When connecting as an Admin Spectator, name + mouse are automatically hidden from other NON-ADMIN participants.
     * Upon joining, all spectators are given the Editor's version of FIMenu + their HTML.
     */
    async joinDealRoom(joinRequest: ICB_JoinRoomRequest): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('JoinDealRoom', this.code, joinRequest);
        }
        catch (err) {
            console.error('COBROWSING - JOIN DEAL ROOM: ', err);
        }
    }

    /**
     * Alert the DealRoomHub singleton of an impending disconnection. Removes the connection from the singleton, and does
     * any clean-up necessary to safely do that (see function ProcessDisconnect in SignalRDealRoom.cs).
     *
     * $dealRoomHub.stopConnection() needs to be called to actually close the Signal R connection.
     */
    async alertDisconnect(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('AlertDisconnect', this.code);
        }
        catch (err) {
            console.error('COBROWSING - ALERT DISCONNECT: ', err);
        }
    }

    /**
     * When an Admin wishes to reveal their presence to other participants in the deal room (name uncensored, mouse can be shared).
     * All Admins enter a deal room "invisible" by default.
     */
    async requestBecomeVisible(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('RequestBecomeVisible', this.code);
        }
        catch (err) {
            console.error('COBROWSING - REQUEST BECOME VISIBLE: ', err);
        }
    }

    /**
     * Send a chat message to all participants in the deal room (including the message's sender)
     * If user is an invisible admin, name will be set to "Administrator"
     */
    async sendChatMessage(message: string): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('SendChatMessage', this.code, message);
        }
        catch (err) {
            console.error('COBROWSING - SEND CHAT MESSAGE: ', err);
        }
    }
    // #endregion

    // ======================
    // EDIT REQUEST FUNCTIONS
    // ======================
    // #region Edit Request Functions

    /**
     * When a Spectator wants to take control of the deal and gain edit access. If the Editor already has an active edit
     * request, this user will receive the "Editor Busy" event. Invisible Admins get their name censored to "Administrator"
     * When the edit request has been sent, this user will receive the "Edit Request Pending" event.
     */
    async requestEditAccess(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('RequestEditAccess', this.code);
        }
        catch (err) {
            console.error('COBROWSING - REQUEST EDIT ACCESS: ', err);
        }
    }

    /**
     * An Editor's response to the edit request, sent back to the original requester.
     *
     * If rejected, the requester will be sent the event "Response to Edit Request - FALSE".
     *
     * If accepted, the requester will be promoted to Editor, some clean up will be performed, and then all other
     * participants will be notified of this change.
     */
    async respondToEditRequest(requesterId: string, requestAccepted: boolean): Promise<void> {
        await this.startedPromise;
        try {
            await this.connection.invoke('RespondToEditRequest', this.code, requesterId, requestAccepted);
        }
        catch (err) {
            console.error('COBROWSING - RESPOND TO EDIT REQUEST: ', err);
        }
    }

    /**
     * When a Spectator wants to inform all of THEIR other connections that they've made an edit request. Spectators are allowed
     * to be connected to a deal in several tabs at once. Keeps all these Spectator tabs in sync and prevents duplicate edit
     * requests from being made.
     */
    async sendEditRequestPending(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('SendEditRequestPending', this.code, null);
        }
        catch (err) {
            console.error('COBROWSING - SEND EDIT REQUEST PENDING: ', err);
        }
    }

    /** When an Editor wants to tell a Spectator that they cannot process their edit request (usually due to already having another active edit request) */
    async sendEditorBusy(requesterId: string): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('SendEditorBusy', this.code, requesterId);
        }
        catch (err) {
            console.error('COBROWSING - SEND EDITOR BUSY: ', err);
        }
    }

    /**
     * When a deal room is in an "Editor Vacancy" state, a Spectator sends this to tell other participants that they're assuming control of the deal.
     *
     * If the deal room already has an Editor, the requester is sent the "Editor Role Already Claimed" event.
     *
     * If the deal room doesn't have an Editor, the Spectator will be promoted to Editor.
     */
    async claimEditorRole(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('ClaimEditorRole', this.code);
        }
        catch (err) {
            console.error('COBROWSING - CLAIM EDITOR ROLE: ', err);
        }
    }

    /**
     * When an Editor wants to reliquish control of the deal to the other Spectators in the deal room. The room enters an "Editor Vacancy"
     * state, where the first Spectator to claim the Editor position becomes the new Editor.
     */
    async cedeEditingPower(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('CedeEditingPower', this.code);
        }
        catch (err) {
            console.error('COBROWSING - CEDE EDITING POWER: ', err);
        }
    }
    // #endregion

    // ========================
    // EDITOR SYNCING FUNCTIONS
    // ========================
    // #region Editor Syncing Functions

    /**
     * When an Editor wants to get all Spectators to follow their screen (subscribe to HTML updates). Only sent to viewers not already
     * subscribed to HTML updates
     */
    async askSpectatorsToFollow(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('AskSpectatorsToFollow', this.code);
        }
        catch (err) {
            console.error('COBROWSING - ASK SPECTATORS TO FOLLOW: ', err);
        }
    }

    /**
     * A Spectator's response to the "Editor Wants You to Follow" event, sent to the Editor. Does not subscribe/unsubscribe to HTML updates,
     * only informs Editor whether or not they accepted the follow request.
     */
    async respondToFollowRequest(requestAccepted: boolean): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('RespondToFollowRequest', this.code, requestAccepted);
        }
        catch (err) {
            console.error('COBROWSING - RESPOND TO FOLLOW REQUEST: ', err);
        }
    }

    /**
     * When the Editor wants to send out their current version of FIMenu to all other participants. Deal updates are not sent to HTML
     * subscribers (and won't be sent to an empty room).
     */
    async alertDealUpdated(fimenu: FIMenu | any): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('AlertDealUpdated', this.code, fimenu);
        }
        catch (err) {
            console.error('COBROWSING - ALERT DEAL UPDATED: ', err);
        }
    }

    /**
     * An Editor's response to the "Request for Deal" event. The Editor's current version of FIMenu is sent to all Spectators that have
     * made a Deal Request.
     */
    async updateDealForConnection(fimenu: FIMenu | any): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateDealForConnection', this.code, fimenu);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE DEAL FOR CONNECTION: ', err);
        }
    }

    /**
     * When a Spectator wants to receive the Editor's current version of FIMenu. The Spectator is added to a list of "Deal Requesters".
     */
    async requestForDeal(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('RequestForDeal', this.code);
        }
        catch (err) {
            console.error('COBROWSING - REQUEST FOR DEAL: ', err);
        }
    }

    /**
     * When an Editor wants to indicate that their currently connected with a customer through a Socket connection.
     *
     * isConnected: true => active connection, false => no active connection
     */
    async updateCustomerConnectionStatus(isConnected: boolean): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateCustomerConnectionStatus', this.code, isConnected);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE CUSTOMER CONNECTION STATUS: ', err);
        }
    }

    /**
     * When the Editor wants to send out their current HTML to all HTML subscribers
     * @param {string} innerHTML the inner HTML of the shared element, as a string
     * @param {ICB_HTMLCoordinates} screenXY the length and width (pixels) of the Editor's entire screen
     * @param {ICB_HTMLCoordinates} elementXY the length and width (pixels) of the section being sent over
     */
    async updateEditorHTML(
        innerHTML: string,
        screenXY: ICB_HTMLCoordinates,
        elementXY: ICB_HTMLCoordinates,
    ): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateEditorHTML', this.code, innerHTML, screenXY, elementXY);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE EDITOR HTML: ', err);
        }
    }

    /**
     * When the Editor wants to send out their current HTML, as a response to the Request for HTML event
     * HTML is forwarded to all participants on the HTML Requesters list
     * See dealRoomHub.updateEditorHTML for details on parameters
     */
    async updateEditorHTMLForViewer(
        innerHTML: string,
        screenXY: ICB_HTMLCoordinates,
        elementXY: ICB_HTMLCoordinates,
    ): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateEditorHTMLForViewer', this.code, innerHTML, screenXY, elementXY);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE EDITOR HTML FOR VIEWER: ', err);
        }
    }

    /**
     * When a Spectator wants the Editor's current HTML.
     * The requesting user is added to a list of "HTML Requesters"
     */
    async getEditorHTML(): Promise<void> {
        await this.startedPromise;

        try {
            await this.connection.invoke('GetEditorHTML', this.code);
        }
        catch (err) {
            console.error('COBROWSING - GET EDITOR HTML: ', err);
        }
    }

    /**
     * When a Spectator wants to subscribe to HTML updates from the Editor
     * This function acts as a toggle, so the current subscription state is set to !currentState
     * When toggled on, requester will receive current editor HTML
     * When toggled off, will receive current FIMenu + current Editor section
     */
    async toggleFollowEditor() {
        await this.startedPromise;

        try {
            await this.connection.invoke('ToggleFollowEditor', this.code);
        }
        catch (err) {
            console.error('COBROWSING - TOGGLE FOLLOW EDITOR: ', err);
        }
    }

    /**
     * When an Editor changes their current FIMenu section
     * Does not send a message to other participants. Only updates room data in the singleton.
     */
    async changeEditorSection(currentSection: string) {
        await this.startedPromise;

        try {
            await this.connection.invoke('ChangeEditorSection', this.code, currentSection);
        }
        catch (err) {
            console.error('COBROWSING - CHANGE EDITOR SECTION: ', err);
        }
    }

    /**
     * When an Editor wants to send out their current scroll position to all HTML subscribers
     * @param {string} targetClass a CSS selector that represents the scrolled element
     * @param {number} scrollPercent the % amount the element has been scrolled
     */
    async updateScrollPosition(targetClass: string, scrollPercent: number) {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateScrollPosition', this.code, targetClass, scrollPercent);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE SCROLL POSITION: ', err);
        }
    }
    // #endregion

    // ======================
    // MOUSE UPDATE FUNCTIONS
    // ======================
    // #region Mouse Update Functions

    /**
     * When a deal participant wants to send out the current coordinates of their mouse to other deal room participants
     * Sent to all HTML subscribers (+Editor, if applicable)
     * Coordinates are represented as a percentage across the shared HTML element, with (0%, 0%) representing the top left corner
     */
    async updateMousePosition(xPercent: number, yPercent: number) {
        await this.startedPromise;

        try {
            await this.connection.invoke('UpdateMousePosition', this.code, xPercent, yPercent);
        }
        catch (err) {
            console.error('COBROWSING - UPDATE MOUSE POSITION: ', err);
        }
    }

    /**
     * When a deal participant wants to send out a mouse click events to other deal room participants
     * Sent to all HTML subscribers (+Editor, if applicable)
     * Since it's handled separately from the Mouse Move event, the click may appear to be slightly out of sync with the mouse's current position
     */
    async sendClickEvent() {
        await this.startedPromise;

        try {
            await this.connection.invoke('SendClickEvent', this.code);
        }
        catch (err) {
            console.error('COBROWSING - SEND CLICK EVENT: ', err);
        }
    }

    /**
     * When a deal participant wants to hide their "mouse ghost" on other participants' screens
     * Sends a "Mouse Hidden" event to all HTML subscribers (+Editor, if applicable)
     */
    async hideMouseUpdates() {
        await this.startedPromise;

        try {
            await this.connection.invoke('HideMouseUpdates', this.code);
        }
        catch (err) {
            console.error('COBROWSING - HIDE MOUSE UPDATES: ', err);
        }
    }
    // #endregion

    // =====================
    // ADMIN PANEL FUNCTIONS
    // =====================
    // #region Admin Panel Functions

    /**
     * When the "Active Deals" page is requesting to connect to the DealRoomHub singleton
     * After joining, will receive a list of all active deals in the store they subscribed to
     */
    async adminPanelJoin(storeCode: string) {
        await this.startedPromise;

        try {
            await this.connection.invoke('AdminPanelJoin', this.code, storeCode);
        }
        catch (err) {
            console.error('COBROWSING - ADMIN PANEL JOIN: ', err);
        }
    }

    /** When the "Active Deals" page is requesting to disconnect to the DealRoomHub singleton */
    async adminPanelLeave() {
        await this.startedPromise;

        try {
            await this.connection.invoke('AdminPanelLeave', this.code);
        }
        catch (err) {
            console.error('COBROWSING: ADMIN PANEL LEAVE: ', err);
        }
    }

    /**
     * When the "Active Deals" page wants to update which store they want to receive updates from
     * After the store change has been processed, will receive a list of all active deals in the new store
     * To subscribe to all stores, input "ALL" as the store code
     */
    async changeAdminPanelStore(storeCode: string) {
        await this.startedPromise;

        try {
            await this.connection.invoke('ChangeAdminPanelStore', storeCode);
        }
        catch (err) {
            console.error('COBROWSING - CHANGE ADMIN PANEL STORE: ', err);
        }
    }

    /**
     * A request for a list of all active deals belonging to the given store code
     * Does not check if the requester is actively connected to the given store code
     */
    async getAllDealsInStore(storeCode: string) {
        await this.startedPromise;

        try {
            await this.connection.invoke('GetAllDealsInStore', storeCode);
        }
        catch (err) {
            console.error('COBROWSING: GET ALL DEALS IN STORE: ', err);
        }
    }
    // #endregion
}

const dealRoomHub = reactive(new DealRoomHubPlugin());
const setupDealRoomHubConnection = () => {
    dealRoomHub.connection = new HubConnectionBuilder()
        .withUrl(`${settings.mediaServiceApiUrl}/SignalRHub`, { withCredentials: false })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

    dealRoomHub.connection.onclose(() => dealRoomHub.onConnectionClosed());
    dealRoomHub.connection.onreconnecting((error: Error) => dealRoomHub.onConnectionReconnecting(error));
    dealRoomHub.connection.onreconnected((connectionId: string) => dealRoomHub.onConnectionReconnected(connectionId));
}

export function install(app: any) {
    setupDealRoomHubConnection();

    // Every component will use this.$dealRoomHub to access the event bus
    app.config.globalProperties.$dealRoomHub = dealRoomHub;
    app.provide('$dealRoomHub', dealRoomHub);
}
