<template>
    <div v-if="!isBusySetup && fimenu && fimenu.customer && actualStep" :class="{'fimenubasic': true, 'deal-is-locked': fimenu.isDealLocked(), 'spectator-lock': fimenu.isSpectator }">
        <!--<PanelWebcam></PanelWebcam>-->
        <PanelMenu v-model:sections="sections" class="wizard-container" ref="panelMenu" @nextStep="(section) => { nextStep = section; }">

            <!--DONE-->
            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.CUSTOMER)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isCustomerLocked() || isDisabledCustomerInformation()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidCustomerInformation()"
                                     @continue="customerContinue()">
                    <PanelCustomerSection :fimenu="fimenu" ref="customerSection" />
                </FIMenuWizardContent>
            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.INSURANCE)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isDisabledInsuranceInformation()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="fimenu.isDealLocked() || !isValidInsurance()"
                                     @continue="insuranceContinue()"
                                     :continueText="insuranceContinueText()">
                    <InsurancePage :isAutoCompleteEnabled="isAutoCompleteEnabled"
                                   :storeCode="fimenu.storeCode"
                                   :dealId="fimenu.id"
                                   :dealKind="fimenu.dealKind"
                                   :customer="fimenu.customer"
                                   :data="fimenu.insurance"
                                   :validation="v$.fimenu.insurance" />

                </FIMenuWizardContent>
            </template>

            <!--DONE-->
            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.VEHICLE)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isVehicleLocked() || isDisabledVehicleInformation()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidVehicleInformation()"
                                     @continue="vehicleContinue()">
                    <div :style="$grid('1')" class="fade-in">
                        <PanelVehicle :fimenu
                                      :panelObject="{title: 'Vehicle'}"
                                      :notFirstPencil="true"
                                      :mscanAccount="mscanAccount"
                                      :spireonEnabled="spireonEnabled"
                                      :validation="v$.fimenu"
                                      :skipDBCheck="fimenu.store.storeSettings.useCustomVinDecoder"
                                      :allowVehicleRefresh="allowVehicleRefresh"
                                      :disabled="isDealLocked" />
                        <!-- <fieldset :disabled="!allowEditVehicleSection"> -->
                        <PanelVehicleExtra :data="fimenu"
                                           :panelObject="{title: 'Vehicle Details'}"
                                           :showExtra="true"
                                           :oilTypeChangeAfterPricing="oilTypeChangeAfterPricing"
                                           :validation="v$.fimenu"
                                           :oilTypeNotAvailable="oilTypeNotAvailable"
                                           :skipDBCheck="fimenu.store.storeSettings.useCustomVinDecoder"
                                           :disabled="lockVehicleVin"
                                           :refreshCache="refreshBrandsAndModels"
                                           :vinFailed="vinFailed"
                                           :overrideVinDetails="overrideVinDetails"
                                           :swapDropdown="isDealLocked"
                                           :setWearPricing="rePriceWearIfNeeded" />
                        <PanelCarFax :data="fimenu.carFax" :response="carFaxResponse" ref="carFax" />
                        <PanelSurchargesInQuestion :vehicle="fimenu.vehicle"
                                                   :panelObject="{title: 'Surcharges'}"
                                                   :validation="v$.fimenu.vehicle"
                                                   :panelView="true"
                                                   :isEntity="fimenu.customer.isEntity"
                                                   ref="surchargesPanel" />
                        <PanelManufacturerWarranty :data="fimenu"
                                                   :panelObject="{title: 'Original Manufacturer Warranty Details'}"
                                                   :validation="v$.fimenu"
                                                   ref="manufacturerWarranty" />


                        <PanelVehicleRecallInfo v-if="fimenu.vin"
                                                :noOpenRecalls="isCarFaxAvailable && !fimenu.carFax.recallExists"
                                                :vin="fimenu.vin"
                                                :make="fimenu.vehicle.make"
                                                :model="fimenu.vehicle.model"
                                                :modelYear="fimenu.vehicle.modelYear"
                                                :nhtsaCampaigns="nhtsaCampaignNumbers" />
                    </div>
                </FIMenuWizardContent>
            </template>

            <!--DONE-->
            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.TRADE_IN)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isTradeInLocked() || isDisabledTradeInInformation()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidTradeInformation()"
                                     @continue="tradeInContinue()">
                    <PanelTradeIn :fimenu="fimenu"
                                  :isDisabled="isContinueBusy()"
                                  ref="tradeIn" />
                </FIMenuWizardContent>
            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.PURCHASE)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isDisabledPurchaseFigures()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidPurchaseFigures()"
                                     @continue="purchaseContinue()">
                    <div :style="$grid('1')" :class="fimenu.buyersOrderEnabled ? 'purchase-figures-container' : 'purchase-figures-container-nonbo'">
                        <PurchaseFiguresPage :fimenu :validation="v$.fimenu" ref="purchaseFigures" />
                    </div>
                </FIMenuWizardContent>
            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.REGISTRATION)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isRegistrationLocked() || isDisabledRegistrationInformation()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidRegistration()"
                                     @continue="RegistrationContinue()">

                    <PanelVehicleRegistration v-if="fimenu.buyersOrderEnabled" :fimenu="fimenu" :validation="v$.fimenu" />

                </FIMenuWizardContent>
            </template>

            <!--DONE-->
            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_DETAILS)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isDisabledDealDetails()"
                                     :actualStep="actualStep"
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="!!fimenu.isDealLocked() || !isValidDealDetails()"
                                     @continue="dealDetailsContinue()">
                    <div :style="$grid('1')">
                        <PanelDealDetails :fimenu="fimenu" :validation="v$.fimenu" :mscanAccount="mscanAccount" />
                    </div>
                </FIMenuWizardContent>
            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.FI_MENU)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :actualStep="actualStep"
                                     disableScrollBarMargins
                                     :continueBusy="isContinueBusy()"
                                     :continueDisabled="disableFinalButton()"
                                     @continue="goToFinal()">
                    <div class="fade-in fimenu-section-wrapper">
                        <IsBusySectionComponent v-if="isBusy" text="Please wait while we rate the products..." delayToShowText="3000"></IsBusySectionComponent>
                        <PanelFIMenu v-else
                                     :fimenu="fimenu"
                                     :validation="v$.fimenu"
                                     :dealSave="($parent as any).dealSave"
                                     :viewingRole="viewingRole"
                                     :handleRerate="rateProductsAllForceRepriceAll" />
                    </div>
                </FIMenuWizardContent>

            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_RECAP)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="isDisabledRecap()"
                                     :actualStep="actualStep"
                                     :continueClass="{'pulsing': !disableGetPaperwork()}"
                                     :continueDisabled="disableGetPaperwork()"
                                     :continueBusy="isContinueBusy()"
                                     :continueText="recapContinueText()"
                                     @continue="finalizeFI()">
                    <PanelFIFinalStep :fimenu="fimenu"
                                      :validation="v$.fimenu"
                                      :checkChecklistFunc="checkChecklist"
                                      :products="fimenu.storeProducts" />
                </FIMenuWizardContent>

            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_JACKET)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :isLocked="!anyPaperwork || isContinueBusy()"
                                     :actualStep="actualStep"
                                     slotName="wizardButton"
                                     hideContinue>
                    <PanelDealJacket :fimenu="fimenu" :rootName="'Deal #' + fimenu.dealNumber + ' - ' + fimenu.storeCode" />

                    <template #wizardButton>
                        <div class="wizard-buttons">
                            <button v-if="isAllPaperworkActivated && isDealWithFiManager && fimenu.buyersOrderEnabled && !fimenu.isSpectator"
                                    :class="{
                                        'button-edit' : true,
                                        'final-btn' : true,
                                        'hint-click': canFinalizeForFIAdmin && statusButtonMessageIndex === null,
                                        'button-unwind' : !canFinalizeForFIAdmin && (statusButtonMessageIndex === null || statusButtonMessageIndex === 1),
                                    }"
                                    @click="sendToNextDepartment"
                                    :disabled="isBusyChangingStatus">
                                {{ nextDepartmentButtonMessage }}
                            </button>
                        </div>
                    </template>

                </FIMenuWizardContent>


            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.FI_ADMIN)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :actualStep="actualStep"
                                     slotName="wizardButton"
                                     hideContinue>

                                     {{canFinalizeForAccounting ? "Review the deal items and submit the deal to accounting when completed." : "This deal has been submitted to the costing clerk."}}

                    <template #wizardButton>
                    <div class="wizard-buttons">
                        <button v-if="isDealWithFIAdmin && fimenu.buyersOrderEnabled && !fimenu.isSpectator"
                                :class="{
                                    'button-edit' : true,
                                    'final-btn' : true,
                                    'hint-click': canFinalizeForAccounting && statusButtonMessageIndex === null,
                                    'button-unwind' : !canFinalizeForAccounting && (statusButtonMessageIndex === null || statusButtonMessageIndex === 1),
                                }"
                                @click="sendToNextDepartment"
                                :disabled="isBusyChangingStatus">
                            {{ nextDepartmentButtonMessage }}
                        </button>
                    </div>
                </template>

                </FIMenuWizardContent>

            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.POSTING)]>
                <div class="wizard-content scroll-bar-margins">
                    <PanelAccounting :fimenu="fimenu" :dealSave="($parent as any).dealSave" />
                </div>
            </template>

            <template v-slot:[ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_AUDIT)]>
                <FIMenuWizardContent :fimenu="fimenu"
                                     :actualStep="actualStep"
                                     continueClass="button-accept ignore-all-locks"
                                     continueText="Review"
                                     :continueDisabled="!selectedForStreaming.length"
                                     @continue="openStreamingModal()">
                    <PanelDealAudit :fimenu="fimenu" :handleStreams="{onSelect: handleStreamSelect, list: selectedForStreaming, emptyList: resetSelectedForStreaming}" />
                </FIMenuWizardContent>
            </template>

            <template v-slot:deal-is-locked v-if="!inPostingSection">
                <DealIsLocked :lockType="lockType" :deal="fimenu" v-if="fimenu.isDealLocked() || fimenu.isDealPartiallyLockedBecauseWeSentACreditApp()" />
            </template>

            <template v-slot:always-on>
                <div class="floating-side-menu">
                    <button v-if="isDealChecklistEnabled" class="button button-accept" @click="openDealChecklistModal" style="margin-bottom: 10px;">
                        Deal Checklist
                    </button>
                </div>
            </template>
        </PanelMenu>
    </div>
    <IsBusySectionComponent v-else />
</template>

<script lang="ts">
    import _ from 'underscore';
    import settings from 'settings'

    import { defineComponent, PropType, provide, ref } from 'vue';
    import { required, maxLength, minLength, minValue, requiredIf, decimal } from '@vuelidate/validators'
    import { validDate, validVIN, validZIPUS, validZIPInternational, validSurcharge, validInServiceDate, validVehicleYear, validVehicleMake, validLicense } from '@core/services/custom-validators'
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import auth from '@core/services/auth';
    import financials from '@core/services/financials'
    import { useVuelidate } from '@vuelidate/core';
    import util from '@core/services/util'

    import Application from "@core/classes/Applications/Application"
    import { buyersOrderHelper } from '@core/helpers/buyers-order-helper';
    import CoverageTerm from '@core/classes/CoverageTerm'
    import CoverageTermInfo from '@core/classes/CoverageTermInfo'
    import CoverageTermPricing from '@core/classes/CoverageTermPricing'
    import DealChecklistItem from '@core/classes/DealChecklistItem'
    import ENUMS from "@core/classes/Enums"
    import FIMenuCreditReport from '@core/classes/FIMenuCreditReport';
    import FIMenuExportInfo from '@core/classes/FIMenuExportInfo';
    import FIMenuPaperwork from '@core/classes/FIMenuPaperwork'
    import PlaidHelper from '@core/helpers/Providers/plaid-helper';

    import modalDealChecklist from "@/modals/modalDealChecklist.vue"
    import modalDealChecklistSubset from '@/modals/modalDealChecklistSubset.vue'
    import modalFIFinalStep from '@/modals/modalFIFinalStep.vue'
    import modalForVideoStreams from "@/modals/modalForVideoStreams.vue"
    import modalInfo from '@core/modals/modalInfo.vue'
    import modalLender from '@/modals/modalLender.vue'
    import modalOil from '@/modals/modalOil.vue'
    import modalPaperworkErrors from '@/modals/modalPaperworkErrors.vue'
    import modalWarranty from '@/modals/modalWarranty.vue'

    import { BookValue, DealStatuses, PaperworkStatus, PlateTransactionType, VehiclePrimaryUsage } from '@core/classes/SharedEnums';
    import { CreditApplicationsEnabled, DisplayCreditApplication } from '@/helpers/finance-channels-helper'
    import CreditApplicationsStatus from '@/components/CreditApplicationsStatus.vue'
    import DealIsLocked from '@/components/DealIsLocked.vue'
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuDealEmployee from '@core/classes/FIMenuDealEmployee';
    import FIMenuLoanTerms from '@core/classes/FIMenuLoanTerms';
    import FIMenuLogItem from '@core/classes/FIMenuLogItem';
    import FIMenuVehicleSurcharge from '@core/classes/FIMenuVehicleSurcharge';
    import FIMenuWizardContent from '@/components/fimenu/FIMenuWizardContent.vue'
    import { getValidationForBookValues } from '@/helpers/fimenuvehicle-helper';
    import { handlePlateTransaction } from '@/helpers/plate-transactions-helper'
    import InsurancePage from '@/components/fimenu/InsurancePage.vue'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import { IsPlateTransactionsEnabled } from '@/helpers/plate-transactions-helper';
    import PanelAccounting from "@/components/PanelAccounting.vue"
    import PanelCarFax from '@core/components/PanelCarFax.vue'
    import PanelCustomerSection from '@/components/PanelCustomerSection.vue'
    import PanelDealAudit from "@/components/PanelDealAudit.vue"
    import PanelDealDetails from '@/components/PanelDealDetails.vue'
    import PanelDealJacket from "@/components/PanelDealJacket.vue"
    import PanelFIFinalStep from '@/components/PanelFIFinalStep.vue'
    import PanelFIMenu from '@/components/PanelFIMenu.vue'
    import PanelManufacturerWarranty from '@/components/PanelManufacturerWarranty.vue'
    import PanelMenu from '@/components/PanelMenu/PanelMenu.vue'
    import PanelSurchargesInQuestion from '@/components/PanelSurchargesInQuestion.vue'
    import PanelTradeIn from '@/components/PanelTradeIn.vue'
    import PanelVehicle from '@/components/PanelVehicle.vue'
    import PanelVehicleExtra from '@/components/PanelVehicleExtra.vue'
    import PanelVehicleRecallInfo from '@core/components/PanelVehicleRecallInfo.vue';
    import PanelVehicleRegistration from '@/components/PanelVehicleRegistration.vue'
    import PurchaseFiguresPage from '@/components/fimenu/PurchaseFiguresPage.vue'
    import { SubmitDealJacket } from "@/helpers/finance-channels-helper";
    import { VehicleBookValue } from "@core/classes/VehicleBookValue"

    export default defineComponent({
        name: "FIMenuBasic",
        props: {
            fimenu: Object as PropType<FIMenu>,
            viewingRole: String,
        },
        setup() {
            const activeCreditApplicationOrLender = ref(false);
            const populatingVehicleDetails = ref(false);

            provide('activeCreditApplicationOrLender', activeCreditApplicationOrLender);
            provide('populatingVehicleDetails', populatingVehicleDetails);

            return { v$: useVuelidate({ $scope: false }), activeCreditApplicationOrLender, populatingVehicleDetails }
        },
        data() {
            return {
                carFaxResponse: {
                    status: null as any,
                    description: null as string,
                },
                isBusySetup: false,
                isMuted: false,
                isBusyChangingStatus: false,
                editorSection: '',
                sectionsToFollow: {},
                selectedForStreaming: [] as any[],
                selectedVideos: [] as any[],
                meetingcodeTest: null as any,
                busyDownloading: false,
                statusButtonMessageIndex: null as number,
                statusButtonMessages: ["Finalizing...", "Reverting..."],
                cdkPushResponses: [] as any[],
                oilTypeNotAvailable: [] as any[],
                actualStep: null as any,
                nextStep: null as any,
                allowToPrintPaymentSheet: null as any,
                paperworkBusy: false,
                insurance: {
                    insuranceCompany: null,
                    policyNumber: null,
                    agencyName: null,
                    spokeWith: null,
                    phoneNumber: null,
                    effectiveDay: null,
                    verifyBy: null,
                    expirationDay: null,
                    collision: null,
                    compensation: null,
                    fireTheft: null
                },
                tradeInVehicles: [],
                glowTriggers: {
                    copiedWarrantyInfo: null,
                    copiedVehicleExtraInfo: null,
                },
                sections: [
                    //{ isActive: false, disabled: () => false, valid: this.isValidPresentation, name: 'Presentation', displayName: 'Presentation', indicator: {}, extraInfo: { info: [{ title: 'Deal Type:', value: () => this.fimenu.dealType, upperCase: true }, { title: 'Vehicle Status:', value: () => this.fimenu.inventoryType, upperCase: true }] }, onOpen: this.onChangeSection, notChangeAllowed: () => this.tradeInBusy() },
                    {
                        isActive: false,
                        disabled: this.isDisabledCustomerInformation,
                        valid: this.isValidCustomerInformation,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.CUSTOMER),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.CUSTOMER),
                        enumValue: ENUMS.FIMENU_SECTION.CUSTOMER,
                        indicator: {},
                        extraInfo: {
                            info:
                                [
                                    {
                                        title: '',
                                        display: () => true,
                                        value: () => this.fimenu.customer.fullName,
                                        upperCase: true
                                    },
                                    {
                                        title: '',
                                        display: () => !!this.fimenu.coCustomer?.fullName,
                                        value: () => this.fimenu.coCustomer?.fullName,
                                        upperCase: true
                                    }
                                ]
                        },
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledVehicleInformation,
                        valid: this.isValidVehicleInformation,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.VEHICLE),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.VEHICLE),
                        enumValue: ENUMS.FIMENU_SECTION.VEHICLE,
                        indicator: {},
                        extraInfo: this.fimenu.vin ? { info: [{ title: 'VIN #:', value: () => this.fimenu.vin, upperCase: true }] } : null,
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledTradeInInformation,
                        valid: this.isValidTradeInformation,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.TRADE_IN),
                        displayName: () => this.tradeInMenuDisplayName,
                        enumValue: ENUMS.FIMENU_SECTION.TRADE_IN,
                        indicator: {},
                        //extraInfo: { info: [{ title: '', value: () => this.fimenu.tradeIns && this.fimenu.tradeIns.length > 0 ? this.fimenu.tradeIns[0].modelYear + " " + this.fimenu.tradeIns[0].make + " " + this.fimenu.tradeIns[0].model + " " + this.fimenu.tradeIns[0].style : "No Trade In", upperCase: true }, { title: '', value: () => this.fimenu.tradeIns.length > 1 ? this.fimenu.tradeIns[1].modelYear + " " + this.fimenu.tradeIns[1].make + " " + this.fimenu.tradeIns[1].model + " " + this.fimenu.tradeIns[1].style : null, upperCase: true }] },
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy(),
                        visible: () => this.fimenu.buyersOrderEnabled
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledRegistrationInformation,
                        valid: this.isValidRegistration,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.REGISTRATION),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.REGISTRATION),
                        enumValue: ENUMS.FIMENU_SECTION.REGISTRATION,
                        indicator: {},
                        //extraInfo: { info: [{ title: 'Company:', value: () => this.fimenu.insurance.companyName, upperCase: true }, { title: 'Policy #:', value: () => this.fimenu.insurance.policyNumber, upperCase: true }] },
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy(),
                        visible: () => this.fimenu.buyersOrderEnabled
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledPurchaseFigures,
                        valid: this.isValidPurchaseFiguresAndVerified,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.PURCHASE),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.PURCHASE),
                        enumValue: ENUMS.FIMENU_SECTION.PURCHASE,
                        indicator: {},
                        extraInfo: {
                            info: [
                                /*{ title: 'Current:', value: () => this.fimenu.lender.lenderName, upperCase: true },*/
                                { title: 'Credit Apps:', display: () => DisplayCreditApplication(this.fimenu), component: { import: () => CreditApplicationsStatus, props: { creditApps: this.fimenu.dealJacket.applications, displayDecisions: () => this.displayCreditApplications } } }
                            ]
                        },
                        //extraInfo: { info: [{ title: this.fimenu.inventoryType == 'New' ? 'MSRP:' : 'NADA Retail:', value: () => this.formatPrice(this.fimenu.getMSRP(), 0) }, { title: 'OTD w/o Prod:', value: () => this.formatPrice(this.fimenu.latestFinalNumbers().baseAmount, 0) }] },
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledInsuranceInformation,
                        valid: this.isValidInsurance,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.INSURANCE),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.INSURANCE),
                        enumValue: ENUMS.FIMENU_SECTION.INSURANCE,
                        indicator: {},
                        //extraInfo: { info: [{ title: 'Company:', value: () => this.fimenu.insurance.companyName, upperCase: true }, { title: 'Policy #:', value: () => this.fimenu.insurance.policyNumber, upperCase: true }] },
                        onOpen: this.onChangeSection,
                        notChangeAllowed: () => this.tradeInBusy(),
                        // visible: () => this.fimenu.buyersOrderEnabled
                        visible: true
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledFIMenu,
                        valid: this.isValidfiMenu,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.FI_MENU),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.FI_MENU),
                        enumValue: ENUMS.FIMENU_SECTION.FI_MENU,
                        indicator: {},
                        onOpen: this.onChangeSection,
                        //visible: () => !this.fimenu.buyersOrderEnabled || (this.fimenu.buyersOrderEnabled && this.hasFImanagerLevelAccess),
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: this.isDisabledRecap,
                        valid: this.isValidRecap,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_RECAP),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.DEAL_RECAP),
                        enumValue: ENUMS.FIMENU_SECTION.FINALIZE_FI,
                        indicator: {},
                        onOpen: this.onChangeSection,
                        //visible: () => !this.fimenu.buyersOrderEnabled || (this.fimenu.buyersOrderEnabled && this.hasFImanagerLevelAccess),
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: () => {
                            if (this.isContinueBusy()) {
                                return true
                            }

                            return !this.anyPaperwork
                        },
                        valid: () => this.fimenu.paperworkStatus === ENUMS.PAPERWORK_STATUS.ACTIVATED,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_JACKET),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.DEAL_JACKET),
                        enumValue: ENUMS.FIMENU_SECTION.DEAL_JACKET,
                        indicator: {},
                        onOpen: this.onChangeSection,
                        //visible: () => !this.fimenu.buyersOrderEnabled || (this.fimenu.buyersOrderEnabled && this.hasFImanagerLevelAccess),
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: () => {
                            if (this.isContinueBusy() || !this.hasFIAdminLevelAccess || !this.fimenu.buyersOrderEnabled) {
                                return true
                            }

                            return this.fimenu.dealStatus < DealStatuses.FiFinished
                        },
                        valid: () => this.fimenu.dealStatus >= DealStatuses.FiAdminFinished,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.FI_ADMIN),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.FI_ADMIN),
                        enumValue: ENUMS.FIMENU_SECTION.FI_ADMIN,
                        onOpen: this.openFiAdminSection,
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                    {
                        isActive: false,
                        disabled: () => {
                            if (this.isContinueBusy() || !this.hasAccountingLevelAccess || !this.fimenu.buyersOrderEnabled) {
                                return true
                            }

                            return this.fimenu.dealStatus < DealStatuses.FiAdminFinished
                        },
                        onOpen: this.openAccountingSection,
                        valid: () => this.fimenu.dealStatus >= DealStatuses.AccountingFinished,
                        name: this.getPostingSectionName(),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.POSTING),
                        notChangeAllowed: () => this.tradeInBusy(),
                    },
                    {
                        isActive: false,
                        disabled: () => {
                            if (this.isContinueBusy()) {
                                return true
                            }

                            return false
                        }, //if list of recording == 0 or 1 (because it's the first recording), then return true!
                        valid: () => true,
                        name: ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.DEAL_AUDIT),
                        displayName: ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.DEAL_AUDIT),
                        enumValue: ENUMS.FIMENU_SECTION.UNKNOWN,
                        /*                        visible: this.fimenu.store.storeSettings.isVideoRecordingEnabled,*/
                        //visible: () => !this.fimenu.buyersOrderEnabled || (this.fimenu.buyersOrderEnabled && this.hasFImanagerLevelAccess),
                        notChangeAllowed: () => this.tradeInBusy()
                    },
                ] as any[],
                isBusy: false,
                isBusyFinalButton: false,
                isBusySaving: false,
                nsdLookupBusy: false,
                usWarrantyLookupBusy: false,
                GWCLookupBusy: false,
                commodoreLookupBusy: false,
                calTexLookupBusy: false,
                customCoveragePicker: null as any,
                priceProfit: 0,
                personTypes: [{ code: false, description: 'Person' }, { code: true, description: 'Company' }],
                titaniumPrograms: [{ planCodes: ["CU4"], description: "With Chrome Wheels" }, { planCodes: ["U4", "U46"], description: "Without Chrome Wheels" }],
                theftPrograms: [{ planCodes: ["B"], description: "$2,500 Benefit" }, { planCodes: ["L"], description: "$7,500 Benefit" }],
                wearPrograms: [{ planCodes: ["P", "Q", "R"], description: "Standard (90 Days)" }, { planCodes: ["V", "W", "X"], description: "Open (365 Days)" }],
                //customerNameValidation: null,
                loanTermValidation: null as any,
                leaseTermValidation: null as any,
                creditApplicationsEnabled: false,
                mscanEnabled: false,
                mscanAccount: null as any,
                spireonEnabled: false,
                loJackResponse: null as any,
                dealLojackStatus: this.fimenu.dealLojackStatus,
                dealMakeId: null as string,
                vinFailed: false,
                refreshBrandsAndModels: false,
                development: null as any
            };
        },
        validations() {

            if (this.fimenu == null || this.fimenu.customer == null) return {};

            const fimenuValidation = this.fimenu.validation()
            //Set Vehicle Min Miles to Last CarFax Odo or 1 if All Pre Reqs Do Not Exist
            const vehichleMinMiles = !this.$global.isAdminView && this.fimenu.carFax.lastOdometerReading && this.settings.lookups.carFaxOdometerMaxOffset && (this.fimenu.carFax.lastOdometerReading - this.fimenu.vehicleMiles > this.settings.lookups.carFaxOdometerMaxOffset) ? this.fimenu.carFax.lastOdometerReading : 1;
            const validations = {
                fimenu: {
                    dealNumber: { required },
                    dealDate: fimenuValidation.dealDate,
                    dealType: { required },
                    language: fimenuValidation.language,
                    inventoryType: { required },
                    customer: fimenuValidation.customer,
                    hasCoSigner: fimenuValidation.hasCoSigner,
                    coCustomer: fimenuValidation.coCustomer,
                    preloads: fimenuValidation.preloads,
                    otdBase: { required } as any,
                    dealAddressesQuestions: this.fimenu.dealAddressesQuestions.map(() => ({ answer: { required: requiredIf(() => this.fimenu.buyersOrderEnabled) } })),
                    dealAddresses: { ...fimenuValidation.dealAddresses },
                    downPayments: {},
                    vin: {
                        required,
                        validVIN
                    },
                    stockNumber: { required },
                    vehicleMiles: {
                        required,
                        maxValue: false,
                        minValue: minValue(vehichleMinMiles)
                    },
                    vehicleWarranty: {
                        warrantyStartDate: { isValid: (value?: any) => validInServiceDate(value, this.fimenu.dealDate, this.fimenu.vehicle.modelYear) } as any,
                        warranties: {
                            basic: {
                                months: { required },
                                miles: { required }
                            },
                            drivetrain: {
                                months: { required },
                                miles: { required }
                            }
                        },
                        isLoanerIncluded: { required }
                    },
                    vehicleOil: { required },
                    vehicle: {
                        modelYear: {
                            required,
                            minLength: minLength(4),
                            validVehicleYear: () => validVehicleYear(this.fimenu.vehicle.modelYear),
                        },
                        make: {
                            required,
                            isValid: () => validVehicleMake(this.fimenu, this.fimenu.store.storeSettings, this.dealMakeId),
                        },
                        model: { required },
                        otherVehicleInfo: {
                            MPGCity: { required, decimal, minValue: minValue(0) },
                            MPGCombined: { required, decimal, minValue: minValue(0) },
                            MPGHighway: { required, decimal, minValue: minValue(0) },
                        },
                        hasFiveDigitOdometer: { required },
                        mileageStatus: { required },
                        milesPerYear: { required },
                        fuelType: { required },
                        exteriorColor: { required },
                        primaryBodyType: { required },
                        vehicleSurcharges: this.fimenu.vehicle.vehicleSurcharges.map(() => ({ validSurcharge })),
                        bookValues: {
                            ...(this.fimenu.vehicle.bookValues.map(() => VehicleBookValue.validation(this.fimenu))),
                            isValid: () => {
                                // Non BO Deal.
                                if (!this.fimenu.buyersOrderEnabled) return true;

                                // additionals validations add them to "GetValidationForBookValues" as they are used to display the message in the BO
                                // To not have to recreate the same logic in both places.
                                const premadeValidation = getValidationForBookValues(this.fimenu)

                                for (const [_, validation] of Object.entries(premadeValidation)) {
                                    if (!validation.valid()) return false;
                                }

                                return true;
                            },
                            valid: {} as (arg: any) => boolean,
                        },
                        registrationInfo: {} as any
                    },
                    isVehicleDelivered: { required },
                    isEContracting: { required },
                    sellingPrice: { required } as any,
                    externalDMSDealNumber: fimenuValidation.externalDMSDealNumber,
                    otd: { required } as any,
                    otdFinal: { required },
                    nonBuyersOrderFinalNumbers: {
                        frontEndProfit: {
                            required: requiredIf(() => !this.fimenu.buyersOrderEnabled),
                        },
                    },
                    msrp: {
                        //required: requiredIf(() => !this.fimenu.buyersOrderEnabled),
                        maxValue: false,
                        minValue: minValue(1)
                    },
                    invoice: {},
                    lender: {
                        lenderCode: { required },
                        //lenderName: { required },
                        //lenderAddress: { required },
                        //lenderAddressExtra: {},
                        //lenderCity: { required },
                        //lenderZip: { required, validZIPUS },
                        //lenderState: { required }
                    },
                    leaseTerms: {
                        ...this.fimenu.leaseTerms.validation(this.fimenu, this.fimenu.leaseTerms.validationTypes.TERMS)
                    },
                    loanTerms: {
                        ...this.fimenu.loanTerms.validation(this.fimenu, FIMenuLoanTerms.validationTypes.TERMS)
                    },
                    insurance: fimenuValidation.insurance,
                    tradeIns: fimenuValidation.tradeIns,
                    hasOtherTradeIns: { required: requiredIf(() => this.fimenu.buyersOrderEnabled) }
                },
            };

            if (this.fimenu.buyersOrderEnabled) {
                validations.fimenu.invoice = {
                    //required,
                    maxValue: false,
                    minValue: minValue(1)
                }

                validations.fimenu.vehicle.bookValues.valid = (values?: any) => !this.fimenu.isNew() || values.some((value: any) => value.source === BookValue.Manufacturer)
                validations.fimenu.downPayments = fimenuValidation.downPayments

                validations.fimenu.vehicle.registrationInfo = {
                    primaryUsage: { required },
                    deliveryMethod: { required },
                    isPlateTransfer: { required },
                    plateNumber: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.isPlateTransfer) },
                    plateState: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.isPlateTransfer) },
                    registrationLengthInMonths: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.isPlateTransfer) },
                    currentPlateExpirationDate: {
                        validDate,
                        required: requiredIf(() => this.fimenu.vehicle.registrationInfo.isPlateTransfer)
                    },
                    titledUnder: { required },
                    //controlNumber: {required: requiredIf((registrationInfo) => registrationInfo.transactionType == ENUMS.PLATE_TRANSACTIONS_TYPES.NewTempPlate )},
                    //transactionType: { required },
                }

                if (this.fimenu.vehicle.registrationInfo.primaryUsage == VehiclePrimaryUsage.EXPORT) {
                    validations.fimenu.vehicle.registrationInfo.freightForwarderInfo = {
                        name: { required },
                        address: {
                            address: { required },
                            addressExtra: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.usingAddressExtra) },
                            city: { required },
                            state: { required },
                            zip: {
                                required,
                                validZIPUS: () => {
                                    if (this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.usingUSAddress != false) {
                                        return validZIPUS(this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.zip);
                                    }
                                    return true;
                                },
                                validZIPInternational: () => {
                                    if (this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.usingUSAddress == false) {
                                        return validZIPInternational(this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.zip);
                                    }
                                    return true;
                                }
                            },
                            county: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.freightForwarderInfo.address.usingUSAddress) },
                            country: { required },
                        },
                        phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(10) },
                        licenseNumber: { required, validLicense },
                    };
                    validations.fimenu.vehicle.registrationInfo.shippingCompanyInfo = {
                        name: { required },
                        address: {
                            address: { required },
                            addressExtra: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.usingAddressExtra) },
                            city: { required },
                            state: { required },
                            zip: {
                                required,
                                validZIPUS: () => {
                                    if (this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.usingUSAddress != false) {
                                        return validZIPUS(this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.zip);
                                    }
                                    return true;
                                },
                                validZIPInternational: () => {
                                    if (this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.usingUSAddress == false) {
                                        return validZIPInternational(this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.zip);
                                    }
                                    return true;
                                }
                            },
                            county: { required: requiredIf(() => this.fimenu.vehicle.registrationInfo.shippingCompanyInfo.address.usingUSAddress) },
                            country: { required },
                        },
                        phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(10) },
                        licenseNumber: { required, validLicense },
                    };
                }

                validations.fimenu.dealAddressesQuestions = this.fimenu.dealAddressesQuestions.map(() => ({
                    answer: { required }
                }));

                validations.fimenu.sellingPrice = {}
                validations.fimenu.otdBase = {}
                validations.fimenu.otd = {}


            }
            else {
                validations.fimenu.vehicle.registrationInfo = {
                    plateNumber: {},
                    plateState: {},
                    isPlateTransfer: {},
                    registrationLengthInMonths: {},
                    currentPlateExpirationDate: {},
                }
            }

            if (this.fimenu.inventoryType != "New" || !util.isNull(this.fimenu.vehicleWarranty.warrantyStartDate)) {
                validations.fimenu.vehicleWarranty.warrantyStartDate = {
                    isValid: (value: any) => validInServiceDate(value, this.fimenu.dealDate, this.fimenu.vehicle.modelYear),
                    required
                };

            }

            if (this.anyTermSelected) {
                validations.fimenu.customer.address = { required };
                validations.fimenu.customer.city = { required };
                validations.fimenu.customer.state = { required };
                validations.fimenu.customer.zip = { required };

            }

            if (this.fimenu.customer.usingAddressExtra) {
                validations.fimenu.customer.addressExtra = { required }
            }

            if (this.fimenu.coCustomer.usingAddressExtra) {
                validations.fimenu.coCustomer.addressExtra = { required }
            }

            if (this.fimenu.isCash() && !this.fimenu.isFinance() && !this.fimenu.isLease()) {
                validations.fimenu.lender = {
                    lenderCode: { required },
                }
            }

            return validations;
        },
        computed: {
            payload(): any {
                return auth.getTokenPayload();
            },
            nextDepartmentButtonMessage(): string {
                if (this.statusButtonMessageIndex !== null) return this.statusButtonMessages[this.statusButtonMessageIndex];
                else if (!this.canFinalizeForFIAdmin && !this.canFinalizeForAccounting) return "Take Back Deal Jacket";
                else if(this.isDealWithFiManager) return "Give Deal Jacket to F&I Admin";
                else return "Give Deal Jacket to Costing Clerk";
            },
            canFinalizeForFIAdmin() {
                return this.fimenu.dealStatus === DealStatuses.FiInProcess;
            },
            isAllPaperworkActivated()
            {
                return this.fimenu.paperworkStatus === PaperworkStatus.Activated;
            },
            canFinalizeForAccounting(): boolean {
                return this.fimenu.dealStatus === DealStatuses.FiAdminInProcess;
            },
            isDealWithFiManager() {
                return this.fimenu.dealStatusEnumsHelper.isWithFiManager(this.fimenu.dealStatus);
            },
            isDealWithFIAdmin() {
                return this.fimenu.dealStatusEnumsHelper.isWithFiAdmin(this.fimenu.dealStatus);
            },
            canRevertFromAccounting(): boolean {
                return !this.fimenu.isDealInAccounting() && this.dealHasFiFinalizedStatus
            },
            inPostingSection(): boolean {
                const activeSection = this.sections.find(section => section.isActive);
                return activeSection.name === this.getPostingSectionName();
            },
            hasSalesLevelAccess(): boolean {
                const { IsFIManager, IsAdmin, IsAccounting, IsGeneralManager, IsSalesManager, IsSalesPerson, IsDirector } = this.payload.EmployeeAccess;

                const AllAccessAccountTypes = IsAdmin || IsDirector || IsGeneralManager
                const usersWithAccessInSales = IsSalesManager || IsSalesPerson || IsFIManager || IsAccounting || AllAccessAccountTypes

                return usersWithAccessInSales;
            },
            hasFImanagerLevelAccess(): boolean {
                const { IsFIManager, IsAdmin, IsAccounting, IsGeneralManager, IsDirector } = this.payload.EmployeeAccess;

                // Combine access levels that provide full access
                const hasTopLevelAccess = IsAdmin || IsDirector || IsGeneralManager;
                // Determine if user has any finance-related access
                const hasFinanceAccess = IsFIManager || IsAccounting || hasTopLevelAccess;

                return hasFinanceAccess;
            },
            hasAccountingLevelAccess(): boolean {
                return auth.hasAccessToPermission("Accounting");
            },
            hasFIAdminLevelAccess(): boolean{
                return auth.hasAccessToPermission("FIAdmin");
            },
            hasCostingClerkLevelAccess()
            {
                console.log("this.payload.EmployeeAccess.isCostingClerk present?", this.payload.EmployeeAccess)
                return this.payload.EmployeeAccess.IsCostingClerk || this.payload.EmployeeAccess.IsAdmin
            },
            isDealInSales(): boolean {
                return this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.SALES_IN_PROCESS && this.fimenu.dealStatus <= ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED;
            },
            isDealInFinance(): boolean {
                return this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.FI_IN_PROCESS && this.fimenu.dealStatus <= ENUMS.DEAL_STATUS.FI_FINISHED;
            },
            isDealInAccounting(): boolean {
                return this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS && this.fimenu.dealStatus <= ENUMS.DEAL_STATUS.ACCOUNTING_FINISHED;
            },
            displayCreditApplications(): any {
                return DisplayCreditApplication(this.fimenu)
            },
            downloadStatus(): any {
                return this.busyDownloading;
            },
            visibleSections(): any {
                return this.sections.filter((section: any) => (util.isFunction(section.visible) ? section.visible() : (section.visible ?? true)));
            },
            hasValidLeaseOrLoanTerms(): boolean {
                return !this.v$.fimenu.loanTerms.$invalid && !this.v$.fimenu.leaseTerms.$invalid;
            },
            anyPaperwork(): boolean {
                return this.fimenu.paperwork && this.fimenu.paperwork.packets && this.fimenu.paperwork.packets.length > 0;
            },
            tradeInMenuDisplayName(): string {
                return ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.TRADE_IN) + (this.fimenu.tradeIns.length > 1 ? ' - ' + (this.fimenu.tradeIns.length) : '');
            },
            settings(): any {
                return settings;
            },
            util(): typeof util {
                return util;
            },
            financials(): typeof financials { return financials; },
            anyTermSelected(): boolean {
                return this.fimenu.coverageTerms.some((c: CoverageTerm) => c.selected == true);
            },
            hasPaperwork(): boolean {
                return (this.fimenu.paperwork != null && this.fimenu.paperwork.currentPacket() && this.fimenu.paperwork.currentPacket().documents != null && Array.isArray(this.fimenu.paperwork.currentPacket().documents) && this.fimenu.paperwork.currentPacket().documents.length > 0);
            },
            hasPaperworkErrors(): boolean {
                return !(this.fimenu.paperwork && this.fimenu.paperwork.currentPacket());
            },
            isDealLocked(): boolean {
                return this.hasPaperwork || this.anyTermSelected || this.fimenu.isDealInAccounting();
            },
            lockType(): any {
                if (this.hasPaperwork) return 'paperwork';
                if (this.fimenu.isSpectator) return 'spectator';
                if (this.fimenu.getSelectedCoverageTerm() != null) return 'coverageSelected';

                if (this.fimenu.lockByDealStatus()) {
                    if (this.fimenu.isDealInAccounting()) return "accounting";
                    if (this.fimenu.isDealInFinance()) return "finance";
                    if (this.fimenu.isDealInSales()) return "sales";
                }

                if (this.fimenu.isDealPartiallyLockedBecauseWeSentACreditApp()) return "sentCreditApplication";

                return null;
            },
            isNewDeal(): boolean {
                return util.isNull(this.fimenu.vin) || this.fimenu.vin == ''
            },
            ENUMS(): typeof ENUMS {
                return ENUMS;
            },
            fimenuPropsForBuyersOrder(): any {
                return buyersOrderHelper.getPropsToCompare(this.fimenu);
            },
            salesperson(): any {
                const emp = this.fimenu.employees?.filter((e: any) => e.dealRole == ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1);
                if (emp && emp.length > 0)
                    return emp[0].name;
                else
                    return '';
            },
            salesmanager(): any {
                const emp = this.fimenu.employees?.filter((e: FIMenuDealEmployee) => e.dealRole == ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER);
                if (emp && emp.length > 0)
                    return emp[0].name;
                else
                    return '';
            },
            financemanager(): any {
                const emp = this.fimenu.employees?.filter((e: FIMenuDealEmployee) => e.dealRole == ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER);
                if (emp && emp.length > 0)
                    return emp[0].name;
                else
                    return '';
            },
            ratingObjToWatch(): any {
                return this.fimenu.getRatingObjToWatch();
            },
            isDealChecklistEnabled(): boolean {
                return this.fimenu.store.storeSettings.isDealChecklistEnabled;
            },
            dealHasFiInProcessStatus(): boolean {
                return this.fimenu.dealStatus === DealStatuses.FiInProcess;
            },
            dealHasFiFinalizedStatus(): boolean {
                return this.fimenu.dealStatus === DealStatuses.FiFinished;
            },
            dealHasFiAdminFinalizedStatus() {
                return this.fimenu.dealStatus === DealStatuses.FiAdminFinished;
            },
            lockVehicleVin(): boolean {
                return this.fimenu.store.storeSettings.lockVehicleVin && !this.payload.EmployeeAccess.IsAdmin;
            },
            overrideVinDetails(): boolean {
                return this.fimenu.store.storeSettings.overrideVinDetails || this.payload.EmployeeAccess.IsAdmin;
            },
            isAutoCompleteEnabled(): any {
                return this.fimenu.store?.storeSettings?.isAutoCompleteEnabled;
            },
            isCarFaxAvailable(): any {
                return this.carFaxResponse?.status;
            },
            nhtsaCampaignNumbers(): any {
                if (!this.fimenu?.carFax?.recallExists) return [];
                return this.fimenu.carFax.recallInfoList.map((r: any) => r.nhtsaNum);
            },
            allowVehicleRefresh(): boolean {
                return this.$global.isAdminView && !this.fimenu.isDealLocked();
            }
        },
        async created() {
            try {
                // console.time("FiMenuBasic");
                this.isBusySetup = true
                const apiCallsToMake = [];
                await this.initializeCustomerApplicationObjects();

                await this.loadCustomers()

                apiCallsToMake.push(Promise.resolve(this.fimenu.customer.getAndSetCustomerApplication()))

                if (this.fimenu.coCustomer.id) {
                    apiCallsToMake.push(Promise.resolve(this.fimenu.coCustomer.getAndSetCustomerApplication()))
                }

                if (this.fimenu.vehicle.saturnStyleId) {
                    this.loadStyleId()
                }

                this.development = this.$route.query.debug == 'true';

                //SIMCHA - CLEANUP. LOADS PRICES AND PROVIDERS
                apiCallsToMake.push(Promise.resolve())

                this.allowToPrintPaymentSheet = this.fimenu.store.storeSettings.allowToPrintPaymentSheet;

                this.selectInitialSection();

                this.$watch(() => this.fimenu.customer.isEntity, (newVal) => {
                    const commercial = this.fimenu.vehicle.vehicleSurcharges.find((s: FIMenuVehicleSurcharge) => s.code == ENUMS.VEHICLE_SURCHARGES.COMMERCIAL);
                    if (newVal && commercial) {
                        commercial.value = true;
                    }
                    else if (commercial) {
                        commercial.value = null;
                    }
                });

                this.setOilTypesNotAvailable()
                if (!this.isSelectedOilTypeAvailable() && !this.fimenu.isDealLocked()) {
                    this.openModalOil()
                }
                this.$watch(() => this.ratingObjToWatch, (newVal, oldVal) => {
                    if (newVal.vehicleSurcharges.some((ns: any) => oldVal.vehicleSurcharges.some((os: any) => os.code === ns.code && os.value !== ns.value))) {
                        this.fimenu.ratingStatus = ENUMS.RATING_STATUS.REQUIRED_WITH_SURCHARGE_CHANGE
                    } else {
                        this.fimenu.ratingStatus = ENUMS.RATING_STATUS.REQUIRED
                    }
                }, { deep: true });


                this.creditApplicationsEnabled = CreditApplicationsEnabled(this.fimenu);
                //console.log('partnerCodes', this.fimenu.store.partnerCodes);
                this.mscanEnabled = this.fimenu.store.partnerCodes.some((p: any) => p.type === ENUMS.PARTNER_CODES.MSCAN_ACCOUNT);
                this.spireonEnabled = this.fimenu.store.partnerCodes.some((p: any) => p.type === ENUMS.PARTNER_CODES.SPIREON_ACCOUNT_ID);
                //console.log('mscanEnabled', this.mscanEnabled)
                if (this.mscanEnabled) {
                    const account = this.fimenu.store.partnerCodes.filter((p: any) => p.type === ENUMS.PARTNER_CODES.MSCAN_ACCOUNT);
                    //console.log('account', account);
                    if (account && account.length > 0) {
                        this.mscanAccount = account[0].code;
                        //console.log('this.mscanAccount', this.mscanAccount)
                    }
                }

                // Subscribe to DealRoomHub Events
                if (this.fimenu.store.storeSettings.isDealCoBrowsingEnabled) {
                    this.$watch(() => this.isBusy, () => { this.EventBus.emit('fimenuBasicBusy', this.isBusy) });

                    this.$dealRoomHub.bulkSubscribe({
                        EditorSectionChanged: this.editorSectionChangedHandler,
                        UpdatedRoomInfo: this.updatedRoomInfoHandler,
                    });
                }

                this.EventBus.on("copiedWarrantyInfo", this.handleCopiedWarrantyInfo)
                this.EventBus.on("copiedVehicleExtraInfo", this.handleCopiedVehicleExtraInfo)
                this.EventBus.on('populatingVehicleDetails', this.handlePopulatingVehicleDetails);
                this.EventBus.on('clearVehicleInformation', this.clearVehicleInformation)
                this.EventBus.on("vinFailed", this.handleFailedVin);
                this.EventBus.on('refreshBrandsAndModels', this.handleMakeAndModelRefresh);
                this.EventBus.on('updateStyleId', this.loadStyleId);
                this.EventBus.on('saveCarFaxResponse', this.handleCarFaxResponse);

                this.fimenu.loJackSearchByVin();
                apiCallsToMake.push(Promise.resolve(this.getCreditReportWhenInitialized()))

                await Promise.all(apiCallsToMake)

                //CarFax Response
                if (this.fimenu.carFax.lastOdometerReading !== null) {
                    this.carFaxResponse.status = true;
                }
            } catch (error) {
                console.warn(error);

            } finally {
                this.isBusySetup = false
            }


            // KEEP THIS WAS AT LAST. BECAUSE LOADING FIMENU WOULD MAKE BO NEED TO VERIFY ON INITIAL LOAD.
            //BUYERS ORDER ENABLED
            if (this.fimenu.buyersOrderEnabled) {

                //BuyersOrder GLOBAL WATCH
                this.$watch(() => this.fimenuPropsForBuyersOrder, async (newVal, oldVal) => {
                    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                        await buyersOrderHelper.onFIMenuChanged(this.fimenu, newVal, oldVal)
                    }
                }, { deep: true });
            }

            // Auto-add employee to deal if role is empty (if they aren't an admin)
            if (!this.payload.EmployeeAccess.IsAdmin) {
                if (!this.fimenu.store.storeSettings.isDealCoBrowsingEnabled) {
                    this.fimenu.autoAddUserToVacantRole(this.payload.EmployeeCode, this.payload.EmployeeName, this.payload.Position);
                }
                else {
                    this.$watch(() => this.fimenu.isSpectator, (currValue) => {
                        // If spectator, don't auto-add user to employee list
                        if (currValue) return;
                        this.fimenu.autoAddUserToVacantRole(this.payload.EmployeeCode, this.payload.EmployeeName, this.payload.Position);
                    }, { immediate: true });
                }
            }
        },

        beforeUnmount() {

            this.$dealRoomHub.bulkUnsubscribe({
                EditorSectionChanged: this.editorSectionChangedHandler,
                UpdatedRoomInfo: this.updatedRoomInfoHandler,
            });

            this.EventBus.off('copiedWarrantyInfo', this.handleCopiedWarrantyInfo)
            this.EventBus.off('copiedVehicleExtraInfo', this.handleCopiedVehicleExtraInfo)
            this.EventBus.off('populatingVehicleDetails', this.handlePopulatingVehicleDetails);
            this.EventBus.off('clearVehicleInformation', this.clearVehicleInformation)
            this.EventBus.off('vinFailed', this.handleFailedVin);
            this.EventBus.off('refreshBrandsAndModels', this.handleMakeAndModelRefresh);
            this.EventBus.off('updateStyleId', this.loadStyleId);
            this.EventBus.off('saveCarFaxResponse', this.handleCarFaxResponse);
        },
        methods: {
            async loadCustomers() {
                if (this.fimenu.paperwork?.packets?.length ?? 0 > 0) return;
                if (this.fimenu.isDealLocked()) return;

                if (!util.isNullOrEmpty(this.fimenu.customer?.id)) await this.fimenu.customer?.reload(false)
                if (!util.isNullOrEmpty(this.fimenu.coCustomer?.id)) await this.fimenu.coCustomer?.reload(false)
            },
            loadStyleId() {
                if (this.fimenu.vehicle.saturnStyleId) {
                    api.settings.getMakeByStyle(this.fimenu.vehicle.saturnStyleId)
                        .then(res => this.dealMakeId = res?.data?.id)

                        .catch(err => console.error(err));
                }
            },
            handleFailedVin(newValue?: boolean) {
                this.vinFailed = newValue;
                // this.fimenu.vin = null;
                this.clearVehicleInformation();
            },
            handleMakeAndModelRefresh(newValue?: boolean) {
                this.refreshBrandsAndModels = newValue;
            },
            handlePopulatingVehicleDetails(newValue?: boolean) {
                this.populatingVehicleDetails = newValue
                if (newValue) { this.vinFailed = false; }
            },
            handleCopiedWarrantyInfo(newValue?: any) {
                const glowInputs = this.$refs.manufacturerWarranty ? this.$el.querySelectorAll(".glow") : null
                if (glowInputs)
                    util.setInputGlow(glowInputs, newValue ? 'success' : 'error')
            },
            handleCopiedVehicleExtraInfo(newValue?: any) {
                const glowInputs = this.$refs.extraInfo ? this.$el.querySelectorAll(".glow") : null
                if (glowInputs)
                    util.setInputGlow(glowInputs, newValue ? 'success' : 'error')
            },
            handleCarFaxResponse(status?: any, description?: any) {
                this.carFaxResponse.status = status
                this.carFaxResponse.description = description
            },
            openFiAdminSection()
            {
                if (this.dealHasFiFinalizedStatus) {
                    this.fimenu.setDealStatus(DealStatuses.FiAdminInProcess);
                }
            },
            openAccountingSection() {
                if (this.dealHasFiAdminFinalizedStatus) {
                    this.fimenu.setDealStatus(DealStatuses.AccountingInProcess);
                }
            },
            sendToNextDepartment() {
                if (this.canFinalizeForFIAdmin || this.canFinalizeForAccounting) {
                    this.statusButtonMessageIndex = 0;
                }
                else {
                    this.statusButtonMessageIndex = 1;
                }

                this.toggleStatus();
                this.statusButtonMessageIndex = null;

            },
            toggleStatus() {
                const originalStatus = this.fimenu.dealStatus;
                try {
                    this.isBusyChangingStatus = true;
                    if (this.fimenu.dealStatusEnumsHelper.isInAProcessingStep(this.fimenu.dealStatus)) {
                        this.fimenu.setDealStatus(this.fimenu.dealStatusEnumsHelper.getNextDealStatus(this.fimenu.dealStatus));
                    }
                    else {
                        this.fimenu.setDealStatus(this.fimenu.dealStatusEnumsHelper.getPreviousDealStatus(this.fimenu.dealStatus));
                    }

                }
                catch (error) {
                    this.fimenu.setDealStatus(originalStatus);

                    console.warn(error);
                }
                finally {
                    this.isBusyChangingStatus = false;
                }
            },
            // toggleSave(){
            //     this.allowEditVehicleSection = !this.allowEditVehicleSection;
            // },
            changeCustomerVideoVolume(e?: any) {
                const volumeLevel = e.target.value;
                this.$meetingHub.adjustCustomerVideoVolume(parseInt(volumeLevel));
            },
            getPostingSectionName() {
                return ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.POSTING);
            },
            async initializeCustomerApplicationObjects() {
                this.fimenu.customer.customerApplication = new Application()

                //if co signer
                if (this.fimenu.hasCoSigner && this.fimenu.coCustomer.customerApplication == null) {
                    this.fimenu.coCustomer.customerApplication = new Application()
                }

            },
            openStreamingModal() {
                const setOfVideos = [...(new Set(this.selectedForStreaming.map((sfs) => sfs.who + "|" + sfs.pov)))];
                const videosData = setOfVideos.map((pi) => {
                    const split = pi.split("|");
                    const vd: {who?: string, pov?: string, videos?: any } = { who: split[0], pov: split[1] };
                    vd.videos = this.selectedForStreaming.filter((sfs) => sfs.who === vd.who && sfs.pov === vd.pov).map((sfs) => ({
                        url: sfs.streamUrl,
                        start: sfs.startTime,
                        end: sfs.finishTime,
                    }))
                    return vd;
                })
                $modal.open(modalForVideoStreams, { name: "modalForVideoStreams", passedData: { videosData, events: null, postFunction: null } })
            },
            handleStreamSelect(videoDetails?: any) {
                //if vidDetails is already in the array, remove it and return;
                const index = this.selectedForStreaming.findIndex((selected) => selected.blobName === videoDetails.blobName);

                if (index === -1) {
                    this.selectedForStreaming.push(videoDetails);
                }
                else {
                    this.selectedForStreaming.splice(index, 1);
                }
            },
            isCustomerLocked() { // This only takes care of disabling inputs.
                return false
            },
            isVehicleLocked() { // This only takes care of disabling inputs.
                return false
            },
            isTradeInLocked() { // This only takes care of disabling inputs.
                return false
            },
            isRegistrationLocked() { // This only takes care of disabling inputs.
                return false
            },
            paymentSheetDisabled() {
                return this.isBusy || !this.fimenu.hasCoverageTerms() || this.fimenu.isDealLocked() || !this.hasValidLeaseOrLoanTerms || this.fimenu.isRatingRequired();
            },
            openModalOil() {
                $modal.open(modalOil, { name: 'modalOil', passedData: { oilTypeNotAvailable: this.oilTypeNotAvailable, fimenu: this.fimenu, info: ENUMS.oilTypes.find(o => o.value == this.fimenu.vehicleOil).display + ' is NOT available. Please select another oil type.', acceptText: 'OK' }, backdrop: false, postFunction: this.oilTypeChangeAfterPricing });
            },
            isContinueBusy() {
                return this.fimenu.buyersOrderIsBusy || this.isBusySaving;
            },
            developmentMode() {
                return settings.environmentName != 'PRODUCTION' || this.$route.query.debug == 'true';
            },
            tradeInBusy() {
                return (this.$refs.tradeIn && (((this.$refs.tradeIn as any).editVehicle == 0 || (this.$refs.tradeIn as any).editVehicle > 0) || (this.$refs.tradeIn as any).addNewVehicle))
            },
            oilTypeChangeAfterPricing() {
                if (this.fimenu.hasCoverageTerms()) {
                    this.fimenu.priceProducts([{ productType: ENUMS.PRODUCT_TYPES.MAINTENANCE }]).then(() => {
                        this.setOilTypesNotAvailable()
                        this.dealSave()
                    })
                }
            },
            isDisabledCustomerInformation() {
                if (this.isContinueBusy()) {
                    return true
                }

                return false;
            },
            isValidCustomerInformation() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                if (this.v$.fimenu) {

                    return !(this.v$.fimenu.customer.$invalid ||
                        this.v$.fimenu.hasCoSigner.$invalid ||
                        (this.v$.fimenu.coCustomer && this.v$.fimenu.coCustomer.$invalid) ||
                        this.v$.fimenu.language.$invalid
                    )
                }
                else {
                    return null;
                }
            },
            isDisabledRegistrationInformation() {
                if (!this.v$.fimenu) {
                    return false
                }

                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.buyersOrderEnabled) {

                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.TRADE_IN))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidTradeInformation()
                    );
                }
                else {
                    return false
                }
            },
            isDisabledInsuranceInformation() {
                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }

                if (this.fimenu.buyersOrderEnabled) {

                    const isDealReady = this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED
                    const hasSalesManagerAccessLevel = auth.hasAccessToPermission("FIManager");

                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.PURCHASE))
                        && isDealReady
                        && hasSalesManagerAccessLevel
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidRegistration()
                        && this.isValidTradeInformation()
                        && this.isValidPurchaseFiguresAndVerified()
                    );
                }
                else {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.PURCHASE))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidPurchaseFigures()
                    );
                }
            },
            isValidInsurance() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                if (this.v$.fimenu) return !this.v$.fimenu.insurance.$invalid;
                else return null;
            },

            isValidRegistration() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }
                if (this.v$.fimenu) {
                    if (this.fimenu.buyersOrderEnabled) {
                        return !(this.v$.fimenu.dealAddresses.$invalid || this.v$.fimenu.dealAddressesQuestions.$invalid || this.v$.fimenu.vehicle.registrationInfo.$invalid)
                    }
                    else {
                        return false
                    }
                }
                else {
                    return null;
                }
            },
            isDisabledVehicleInformation() {

                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }

                return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.CUSTOMER))
                    && this.isValidCustomerInformation()
                )
            },
            isValidVehicleInformation() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                if (this.v$.fimenu) {
                    const isAnyInputInvalid = this.v$.fimenu.vehicle.modelYear.$invalid ||
                        this.v$.fimenu.vehicle.make.$invalid ||
                        this.v$.fimenu.vehicle.model.$invalid ||
                        this.v$.fimenu.vehicle.hasFiveDigitOdometer.$invalid ||
                        this.v$.fimenu.vehicle.mileageStatus.$invalid ||
                        this.v$.fimenu.vehicle.milesPerYear.$invalid ||
                        this.v$.fimenu.vehicle.fuelType.$invalid ||
                        this.v$.fimenu.vehicle.exteriorColor.$invalid ||
                        this.v$.fimenu.vehicle.primaryBodyType.$invalid ||
                        this.v$.fimenu.vehicle.otherVehicleInfo.$invalid ||
                        this.v$.fimenu.vin.$invalid ||
                        this.v$.fimenu.stockNumber.$invalid ||
                        this.v$.fimenu.vehicleOil.$invalid ||
                        this.v$.fimenu.vehicleMiles.$invalid ||
                        this.v$.fimenu.vehicle.vehicleSurcharges.$invalid ||
                        (!this.fimenu.buyersOrderEnabled && this.v$.fimenu.vehicle.bookValues.$invalid) ||
                        this.v$.fimenu.inventoryType.$invalid ||
                        //this.v$.fimenu.msrp.$invalid ||
                        this.v$.fimenu.vehicleWarranty.warrantyStartDate.$invalid ||
                        this.v$.fimenu.vehicleWarranty.warranties.basic.miles.$invalid ||
                        this.v$.fimenu.vehicleWarranty.warranties.basic.months.$invalid ||
                        this.v$.fimenu.vehicleWarranty.warranties.drivetrain.miles.$invalid ||
                        this.v$.fimenu.vehicleWarranty.warranties.drivetrain.months.$invalid ||
                        this.v$.fimenu.vehicleWarranty.isLoanerIncluded.$invalid;
                    return !isAnyInputInvalid
                }
                else {
                    return null;
                }
            },
            isDisabledTradeInInformation() {
                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }


                if (this.fimenu.buyersOrderEnabled) {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.VEHICLE))
                        && this.isValidCustomerInformation()
                        //&& this.isValidInsuranceAndRegistration()
                        && this.isValidVehicleInformation()
                    );
                }
                else {
                    return false
                }
            },
            isValidTradeInformation() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                if (!this.v$.fimenu.tradeIns.$invalid && !this.v$.fimenu.hasOtherTradeIns.$invalid) {
                    return true;
                }
                return false;
            },
            getRef(refName?: string) {
                return this.$refs[refName];
            },
            isDisabledPurchaseFigures() {
                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }

                if (this.fimenu.buyersOrderEnabled) {

                    const isDealReady = this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.SALES_FINISHED
                    const hasSalesManagerAccessLevel = auth.hasAccessToPermission("SalesManager");

                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.REGISTRATION))
                        && isDealReady
                        && hasSalesManagerAccessLevel
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidTradeInformation()
                        && this.isValidRegistration()
                    );
                }
                else {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.VEHICLE))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                    )
                }
            },
            isValidPurchaseFigures() {
                if (this.v$.fimenu) {
                    if (this.fimenu.buyersOrderEnabled) {
                        const currentBuyersOrder = this.fimenu.getCurrentBuyersOrder()

                        const lenderIsInvalid = this.v$.fimenu.loanTerms.maxDaysToFirstPayment.$invalid || this.v$.fimenu.lender.$invalid || !this.activeCreditApplicationOrLender;
                        const isLeaseTermsInvalid = this.fimenu.isLease() ? this.v$.fimenu.leaseTerms.$invalid : false;
                        const isLoanTermsInvalid = this.fimenu.isFinance() ? this.v$.fimenu.loanTerms.$invalid : false;
                        const bookValuesInvalid = this.v$.fimenu.vehicle.bookValues.$invalid;
                        return currentBuyersOrder && currentBuyersOrder.isValid() && currentBuyersOrder.areAllBucketsValid() && !lenderIsInvalid && !isLeaseTermsInvalid && !isLoanTermsInvalid && !bookValuesInvalid;
                    }
                    else {
                        return !(this.v$.fimenu.loanTerms.maxDaysToFirstPayment.$invalid || this.v$.fimenu.lender.$invalid || this.v$.fimenu.preloads.$invalid || this.v$.fimenu.otdBase.$invalid || this.v$.fimenu.sellingPrice.$invalid);
                    }
                }
                else {
                    return null;
                }
            },
            isValidPurchaseFiguresAndVerified() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                if (!this.isValidPurchaseFigures()) {
                    return false;
                }

                if (this.fimenu.buyersOrderEnabled) {
                    return this.fimenu.isBuyersOrderVerified;
                }
                else {
                    return true;
                }
            },
            isDisabledLender() {
                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }


                if (this.fimenu.buyersOrderEnabled) {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.PURCHASE))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidTradeInformation()
                        && this.isValidRegistration()
                        && this.isValidPurchaseFigures()
                    ) || (this.fimenu.buyersOrderUpdateRequired && !this.fimenu.isBuyersOrderVerified)
                }
                else {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.PURCHASE))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidPurchaseFigures()
                    );
                }
            },
            isDisabledDealDetails() {
                if (this.isContinueBusy()) {
                    return true
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }


                if (this.fimenu.buyersOrderEnabled) {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.TRADE_IN))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidTradeInformation()
                    ) || (this.fimenu.buyersOrderUpdateRequired && !this.fimenu.isBuyersOrderVerified)
                }
                else {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.LENDER))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidPurchaseFigures()
                    );
                }
            },
            isDisabledFIMenu() {
                if (this.isContinueBusy()) {
                    return true
                }
                const isDealReady = this.fimenu.dealStatus >= ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED

                if (this.fimenu.hasCoverageTermOrPaperwork() || !this.v$.fimenu) {
                    return false
                }
                if (this.fimenu.buyersOrderEnabled) {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.INSURANCE))
                        && isDealReady
                        && auth.hasAccessToPermission("FIManager")
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidTradeInformation()
                        && this.isValidInsurance()
                        && this.isValidRegistration()
                        && this.isValidPurchaseFiguresAndVerified()
                    ) || (this.fimenu.buyersOrderUpdateRequired && !this.fimenu.isBuyersOrderVerified)
                }
                else {
                    return !(this.stepPreviouslyCompleted(ENUMS.FIMENU_SECTION.getDisplay(ENUMS.FIMENU_SECTION.INSURANCE))
                        && this.isValidCustomerInformation()
                        && this.isValidVehicleInformation()
                        && this.isValidInsurance()
                        && this.isValidPurchaseFigures());
                }
            },
            isDisabledRecap() {
                if (this.isContinueBusy()) {
                    return true;
                }

                if (this.fimenu.hasCoverageTermOrPaperwork()) {
                    return false
                }

                if (this.fimenu.buyersOrderEnabled &&
                    !this.hasFImanagerLevelAccess &&
                    this.fimenu.dealStatus < ENUMS.DEAL_STATUS.FI_FINISHED) {
                    return true;
                }

                return true;
            },
            isValidDealDetails() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                return !this.v$.fimenu.preloads.$invalid;
            },
            isValidfiMenu() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }

                return this.anyTermSelected;
            },
            isValidRecap() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return true
                }
                return (!this.v$.fimenu.$invalid) && this.anyTermSelected && this.fimenu.hasPaperworkPacket();
            },
            recapContinueText() {
                if (this.fimenu.hasPaperworkPacket()) {
                    return 'Continue';
                }
                else {
                    return 'Get Paperwork';
                }
            },
            insuranceContinueText() {
                if (this.fimenu.buyersOrderEnabled && !this.hasFImanagerLevelAccess && this.fimenu.dealStatus < ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED) {
                    return "Send Deal Jacket to F&I";
                } else {
                    return "Continue";
                }
            },
            stepPreviouslyCompleted(step?: any) {

                return this.fimenu.log.some((log: FIMenuLogItem) => log.what == `${step} ${ENUMS.LOG_WHAT.STEP_FINISHED}`)
            },
            onChangeSection() {
                this.updateRatesIfNeeded()

                if (!this.fimenu.isSpectator) {
                    this.$nextTick(() => {
                        const currentSection = this.sections[this.sections.findIndex(s => s.isActive === true)].name;
                        if (this.$dealRoomHub.connected) this.$dealRoomHub.changeEditorSection(currentSection);
                    });
                }

                if (this.tradeInBusy()) {
                    $modal.open(modalInfo, { name: 'modalInfo', passedData: { info: 'Leaving this page will make your last changes to be discard. Do you want to leave this Page?', acceptText: 'OK', cancelText: 'Cancel' }, backdrop: false, postFunction: this.changeSection });
                } else {
                    this.changeSection()
                }

                // this.allowEditVehicleSection = false;
            },
            changeSection() {
                if (this.fimenu.buyersOrderEnabled && this.fimenu.buyersOrderUpdateRequired)
                    buyersOrderHelper.resetBuyersOrderInTheBackground(this.fimenu);

                if (this.actualStep) {
                    const previousStep = this.actualStep
                    if (this.nextStep) {
                        this.sections.forEach(s => s.isActive = s.name == this.nextStep.name)
                        this.actualStep = this.nextStep.name
                    } else {
                        this.actualStep = this.sections[this.sections.findIndex(s => s.isActive == true)].name
                    }
                    this.nextStep = null
                    if (this.actualStep != previousStep) {
                        this.fimenu.addToLog(ENUMS.FIMENU_SECTION.getDisplay(this.actualStep), ENUMS.LOG_HOW.SECTION.CHANGED, `${ENUMS.LOG_WHAT.STEP_CHANGED} ${ENUMS.FIMENU_SECTION.getDisplay(previousStep)}`)
                    }
                }
            },
            updateActiveSection(currentSection?: any) {
                this.sections.forEach(s => s.isActive = (s.name === currentSection.name));
            },
            editorSectionChangedHandler(currentSection?: any) {
                if (this.actualStep.name === currentSection) return;

                this.editorSection = currentSection;
                const sectionObj = this.sections[this.sections.findIndex(s => s.name === currentSection)];

                if (!this.actualStep) {
                    this.actualStep = sectionObj;
                }
                else {
                    this.nextStep = sectionObj;
                    this.changeSection();
                }
            },
            updatedRoomInfoHandler(roomInfo?: any, viewerInfo?: any) {
                if (viewerInfo.followEditor)
                    this.editorSectionChangedHandler(roomInfo.editorSection);
            },
            selectInitialSection() {
                //SET ALL SECTIONS AS INACTIVE
                this.sections.forEach(s => s.isActive = false);
                this.sections = this.sections.filter(s => (typeof s.visible == 'function' ? s.visible() : true))

                // Show always the first section, Customer Section,   we need this to avoid any video recording lost time
                // check more info in MultiStreamRecorder.vue comments
                this.sections[0].isActive = true;
                this.actualStep = this.sections[0].name;

                this.updateRatesIfNeeded()
            },
            async customerContinue() {
                const idvEnabled = this.fimenu.store.storeSettings.isCustomerPlaidIDVEnabled;
                const noCustomerApp = this.fimenu.customerApplication === ENUMS.CUSTOMER_RETRIEVAL_STATUS.NoApplication;
                const isCBCEnabled = this.fimenu.store.storeSettings.isCBCEnabled;

                this.isBusySaving = true;

                try {
                    // If a Customer record was created during an IDV check, make sure the index is up-to-date
                    if ((idvEnabled && noCustomerApp) /*|| isCBCEnabled*/) {
                        if (this.fimenu.customer.id) {
                            await api.customers.saveCustomerFromFIMenu(this.fimenu.customer);
                            if (this.fimenu.customer.creditReport?.id != null) {
                                await this.fimenu.customer?.creditReport?.saveFlags(this.fimenu.customer.id)
                            }
                        }
                        if (this.fimenu.coCustomer.id) {
                            await api.customers.saveCustomerFromFIMenu(this.fimenu.coCustomer);
                            if (this.fimenu.coCustomer.creditReport?.id != null) {
                                await this.fimenu.coCustomer?.creditReport?.saveFlags(this.fimenu.coCustomer.id)
                            }
                        }
                    }

                    // Check if we need to open the IDV skip modal
                    if (idvEnabled) {
                        const customers = [this.fimenu.customer];
                        if (this.fimenu.hasCoSigner) customers.push(this.fimenu.coCustomer);

                        const isIdvValid = await PlaidHelper.checkForCustomerIdv(this.fimenu.store, customers, this.payload.EmployeeCode, this.payload.EmployeeName, null, true);
                        if (!isIdvValid)
                            throw new Error('IDV Invalid.', { cause: 'Customers do not have a valid IDV session. Sessions need to be skipped in order to proceed.' });
                    }

                    const activeSection = this.visibleSections.find((s: any) => s.isActive);
                    if (!this.isValidCustomerInformation)
                        throw new Error('Customer Section Invalid.', { cause: 'Customer Section did not pass validation rules. Cannot proceed.' });

                    await this.handleContinue();
                }
                catch (err) {
                    console.error('Error when Continuing from Customer Section.', err);
                    util.toastr('error', 'Customer Error', 'An error occurred when trying to validate customer data. Please ensure all customer info is valid before proceeding.');
                }
                finally {
                    this.isBusySaving = false;
                }
            },
            async insuranceContinue() {
                await this.handleContinue();
            },
            async RegistrationContinue() {
                if (this.fimenu.vehicle.registrationInfo.primaryUsage != VehiclePrimaryUsage.EXPORT) {
                    this.fimenu.vehicle.registrationInfo.freightForwarderInfo = new FIMenuExportInfo();
                    this.fimenu.vehicle.registrationInfo.shippingCompanyInfo = new FIMenuExportInfo();
                }
                const handleChangeStatus = async () => {
                    if (auth.hasAccessToPermission("SalesPerson")) {
                        this.fimenu.setDealStatus(DealStatuses.SalesManagerInProcess);
                        await this.handleContinue(false, false);
                    }
                }

                const dealStatusIsReadyForSalesMgr = this.fimenu.dealStatus <= ENUMS.DEAL_STATUS.SALES_FINISHED;
                if (this.fimenu.buyersOrderEnabled) {
                    if (dealStatusIsReadyForSalesMgr) {
                        const submitMessage = "Would you like to submit this quote to the Sales Manager?";
                        util.modalConfirmation(submitMessage, "Submit", handleChangeStatus, "Cancel", null, "Submit to Sales Manager")
                    } else {
                        await this.handleContinue();
                    }

                } else {
                    await this.handleContinue();
                }
            },
            async vehicleContinue() {
                // If Trade-In section is not available (currently tied to Buyer's Order), start AutoComplete case here
                await this.handleContinue();

                if (this.isAutoCompleteEnabled && !this.fimenu.buyersOrderEnabled) {
                    this.isBusySaving = true;
                    await this.startAutoCompleteScan(true);
                    this.isBusySaving = false;
                }
            },
            async tradeInContinue() {

                // Currently, Trade-In section is tied to Buyer's Order
                if (this.isAutoCompleteEnabled && this.fimenu.buyersOrderEnabled) {
                    this.isBusySaving = true;
                    await this.startAutoCompleteScan(true);
                    this.isBusySaving = false;
                }

                await this.handleContinue();
            },
            async purchaseContinue() {
                if (this.fimenu.buyersOrderEnabled) {
                    if (await this.fimenu.checkForFraud()) return

                    const dealStatusIsSalesManagerFinished = this.fimenu.dealStatus <= ENUMS.DEAL_STATUS.SALES_MANAGER_FINISHED;

                    const handleChangeStatus = async () => {
                        if (auth.hasAccessToPermission("SalesManager")) {
                            this.fimenu.moveDealToFIProcess();
                            return await this.handleContinue(false, false);
                        }
                    }

                    if (dealStatusIsSalesManagerFinished) {
                        const submitMessage = "Would you like to submit this quote to the Finance Department?";
                        util.modalConfirmation(submitMessage, "Submit", handleChangeStatus, "Cancel", null, "Submit to F&I Dept.")
                    }
                    else {
                        await this.handleContinue();
                    }

                    this.fimenu.isBuyersOrderVerified = true;
                }
                else {
                    await this.handleContinue();
                }
            },
            async dealDetailsContinue() {
                await this.handleContinue();
            },
            async lenderContinue() {
                await this.handleContinue();
            },
            async handleContinue(skipChecklist = false, goToNextSection = true, skipSave = false) {
                const sections = this.visibleSections;
                const currentStepIndex = sections.findIndex((section: any) => section.isActive);
                const currentSection = sections[currentStepIndex];
                const nextSection = sections[currentStepIndex + 1];
                this.fimenu.addToLog(ENUMS.FIMENU_SECTION.getDisplay(currentSection.name), ENUMS.LOG_HOW.SECTION.CLOSED, `${ENUMS.FIMENU_SECTION.getDisplay(currentSection.name)} ${ENUMS.LOG_WHAT.STEP_FINISHED}`)

                if (goToNextSection) {
                    this.fimenu.addToLog(ENUMS.FIMENU_SECTION.getDisplay(nextSection.name), ENUMS.LOG_HOW.SECTION.OPENED, `${ENUMS.FIMENU_SECTION.getDisplay(nextSection.name)} ${ENUMS.LOG_WHAT.STEP_STARTED}`)

                    //SAVE THE DEAL
                    if (!skipSave) await this.dealSave();

                    //CHECK FOR PENDING CHECKLIST ITEMS
                    this.isBusySaving = true;
                    const canProceed = (skipChecklist) ? true : await this.checkChecklist(currentSection.enumValue)
                    this.isBusySaving = false;

                    if (canProceed) {
                        currentSection.isActive = false;
                        nextSection.isActive = true;

                        this.updateRatesIfNeeded()

                        this.actualStep = nextSection.name;
                        if (this.$dealRoomHub.connected) this.$dealRoomHub.changeEditorSection(this.actualStep);
                    }
                } else {
                    //SAVE THE DEAL
                    await this.dealSave();
                }
            },
            async dealSave() {
                if (!this.fimenu.canSave()) return;

                this.isBusySaving = true;
                try {
                    await this.fimenu.save();
                } catch (e) {

                    console.error('error', e);
                } finally {
                    this.isBusySaving = false;
                }
            },
            async checkChecklist(section: any) {
                if (!this.isDealChecklistEnabled) return true;

                const response = await api.checklist.getChecklistForFiMenu(this.fimenu.id, section);

                if (!response?.data) {
                    util.toastr('error', 'Error', 'Could not retrieve checklist. Cannot proceed to next step.');
                    return;
                }

                // No items for this section
                if (response.data.length < 1) return true;

                const sectionChecklist = response.data.map((c: any) => new DealChecklistItem(c));
                if (sectionChecklist.every((c: any) => c.isItemVerified())) return true;

                $modal.open(modalDealChecklistSubset, {
                    name: 'modalDealChecklistSubset',
                    passedData: {
                        fimenu: this.fimenu,
                        checklist: sectionChecklist.filter((c: any) => !c.isItemVerified()),
                        lockAllActions: this.fimenu.isSpectator,
                        additionalMessage: 'The following items need to be addressed before proceeding with the deal:',
                        enableItemActions: true,
                    }
                });

                return false;
            },
            getFinalNumbers(leaseOrLoanTerm?: any, coverageTerm?: CoverageTerm) {
                const isCDK = false
                const fimenu = this.fimenu;
                const state = this.$global.selectedStore.storeState;
                return fimenu.finalNumbers(state, leaseOrLoanTerm, coverageTerm, isCDK);
            },
            formatPrice(value?: any, toFixed = 2) {
                if (value != null && typeof value !== 'undefined' && !Number.isNaN(parseFloat(value))) {
                    return '$' + parseFloat(value).toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,").toString()
                } else {
                    return '$' + parseFloat('0').toFixed(toFixed);
                }
            },

            rePriceWearIfNeeded() {
                //If not in threshold, reprice.
                if (this.fimenu.coverageTerms?.length > 0 && !this.checkNSDMinMaxMSRP(this.fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.fimenu.getMSRP(), this.fimenu.coverageTerms[0].wearInfo.program.code)) {
                    this.fimenu.priceProducts([{ productType: ENUMS.PRODUCT_TYPES.EXCESS_WEAR_AND_TEAR }])
                }
            },
            checkNSDMinMaxMSRP(msrp: number, plancode?: string) {
                let returnValue = false;
                if (msrp < 40000 && (plancode === 'P' || plancode === 'V')) returnValue = true;
                if (msrp >= 40000 && msrp < 75000 && (plancode === 'Q' || plancode === 'W')) returnValue = true;
                if (msrp >= 75000 && msrp < 150000 && (plancode === 'R' || plancode === 'X')) returnValue = true;
                return returnValue;
            },
            addCustomTerm(copyFromTerm: any) {
                //console.log('in addCustomTerm', copyFromTerm);
                if (!this.fimenu.hasCustomCoverage && copyFromTerm) {
                    let coverageTermCustom = new CoverageTerm();
                    //if (this.isLease()) {
                    //    coverageTermCustom.wear = true;
                    //    coverageTermCustom.wearPricing = new CoverageTermPricing();
                    //    coverageTermCustom.wearInfo = new CoverageTermInfo();
                    //    coverageTermCustom.gap = false;
                    //    coverageTermCustom.gapPricing = new CoverageTermPricing();
                    //    coverageTermCustom.gapInfo = new CoverageTermInfo();
                    //}
                    //else if (this.isFinance()) {
                    //    coverageTermCustom.wear = false;
                    //    coverageTermCustom.wearPricing = new CoverageTermPricing();
                    //    coverageTermCustom.wearInfo = new CoverageTermInfo();
                    //    coverageTermCustom.gap = true;
                    //    coverageTermCustom.gapPricing = new CoverageTermPricing();
                    //    coverageTermCustom.gapInfo = new CoverageTermInfo();
                    //}
                    //else {
                    coverageTermCustom.wear = true;
                    coverageTermCustom.wearPricing = new CoverageTermPricing();
                    coverageTermCustom.wearInfo = new CoverageTermInfo();
                    coverageTermCustom.gap = true;
                    coverageTermCustom.gapPricing = new CoverageTermPricing();
                    coverageTermCustom.gapInfo = new CoverageTermInfo();
                    //}
                    //if (copyFromTerm && copyFromTerm > 0) {
                    const copyFrom = this.fimenu.coverageTerms.find((t: any) => t.term === copyFromTerm)
                    if (copyFrom) coverageTermCustom = new CoverageTerm(copyFrom);
                    coverageTermCustom.preferred = false;
                    coverageTermCustom.selected = false;
                    //}
                    coverageTermCustom.term = 0;
                    this.fimenu.coverageTerms.push(coverageTermCustom);
                }
            },
            setOilTypesNotAvailable() {
                const responseRates = this.fimenu.getApiResponseDetails(ENUMS.PRODUCT_TYPES.MAINTENANCE, 'USW')?.flattenedRates
                if (responseRates && responseRates.length > 0) {
                    const selectedTermPlan = responseRates[0];
                    const surcharge = JSON.parse(selectedTermPlan.meta['Surcharge']);

                    if (surcharge.syntheticOilBlend == null && surcharge.syntheticOil != null) {
                        this.oilTypeNotAvailable = [this.ENUMS.OIL_TYPES.REGULAR];
                    }
                    if (surcharge.syntheticOilBlend == null && surcharge.syntheticOil == null) {
                        this.oilTypeNotAvailable = [this.ENUMS.OIL_TYPES.REGULAR, this.ENUMS.OIL_TYPES.BLEND];
                    }
                }
            },
            isSelectedOilTypeAvailable() {
                return !this.oilTypeNotAvailable.includes(this.fimenu.vehicleOil)
            },
            showWarrantyInfo() {
                $modal.open(modalWarranty, { name: 'modalWarranty', passedData: { fimenu: this.fimenu }, backdrop: true });
            },
            openLenderModal() {
                $modal.open(modalLender, { name: 'modalLender', passedData: { fimenu: this.fimenu, validation: this.v$.fimenu }, backdrop: true });
            },
            disableFinalButton() {

                return !this.anyTermSelected ||
                    this.hasPaperwork ||
                    (this.fimenu.isLease() && this.fimenu.leaseTerms && this.fimenu.leaseTerms.acceptedTerm == null) ||
                    (this.fimenu.isFinance() && this.fimenu.loanTerms && this.fimenu.loanTerms.acceptedTerm == null) ||
                    this.fimenu.isSpectator ||
                    !this.hasFImanagerLevelAccess
            },
            disableGetPaperwork() {
                const requiredEmployees = [
                    ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1,
                    ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER,
                    ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER
                ];

                // Assumes there's no role duplicates.
                const matchingEmployees = this.fimenu.employees.reduce((sum?: any, currValue?: any) => {
                    if (requiredEmployees.includes(currValue.dealRole)) sum++;
                    return sum;
                }, 0);

                return !!(this.fimenu.hasPaperworkPacket()
                    || this.isBusySaving
                    || !this.anyTermSelected
                    || this.v$.fimenu.$invalid
                    || this.fimenu.isSpectator
                    || matchingEmployees < requiredEmployees.length
                    || (this.fimenu.buyersOrderEnabled ? !this.fimenu.getCurrentBuyersOrder()?.areAllBucketsValid() : false)
                );
            },
            async goToFinal() {
                //CHECK FOR PENDING CHECKLIST ITEMS
                //let currentSection = this.visibleSections.find(s => s.isActive);
                //this.isBusyFinalButton = true;
                //let canProceed = await this.checkChecklist(currentSection.enumValue);
                //this.isBusyFinalButton = false;

                //if (!canProceed) return;

                if (!this.fimenu.isDealLocked() && this.fimenu.buyersOrderEnabled && this.fimenu.buyersOrderUpdateRequired) {
                    await buyersOrderHelper.resetBuyersOrderAsync(this.fimenu);
                }

                if (this.fimenu.hasCoverageTermOrPaperwork() && !this.fimenu.hasPaperworkPacket()) {
                    await this.applyProductItemizationRules()
                }

                //CHECK IF WE ARE DOING A CDK SYNC
                if (this.fimenu.store.cdkSettings.sync != null && this.fimenu.store.cdkSettings.sync.push) {
                    this.pushToCDK();
                }
                else {
                    await this.handleContinue();
                }
            },
            async applyProductItemizationRules() {

                const response = await api.settings.getStateProducts(
                    this.fimenu.store.storeState,
                    JSON.stringify({
                        fimenu: this.fimenu.getSanitizedData(), store: this.fimenu.store
                    })
                );

                this.fimenu.getCurrentBuyersOrder()?.getAllItemsForBucketTypeAndProfitFlag("Products").forEach((i: any) => {
                    i.setProductItemization(response.data)
                })
            },
            pushToCDK() {

                const cdkDealNumber = this.fimenu.getCDKDealNumber();

                this.isBusyFinalButton = true;
                this.EventBus.emit('WaitingCDK');

                return api.dart.checkIfDealExistsOrLockedInCDK(cdkDealNumber, this.$global.selectedStore.storeCode)
                    .then(response => {

                        if (response && response.data && !response.data.syncPush) {
                            //WE DONT DO CDK PUSH IN THIS STORE
                            this.EventBus.emit('CDKPush', { pushing: false, postFunction: this.openFinalModal });
                        }
                        else if (response && response.data && !response.data.isExists) {
                            //DEAL DOESNT EXIST IN CDK
                            this.EventBus.emit("CDKPushFinish")
                            $modal.open(modalInfo, { name: 'modalInfo', passedData: { title: `Deal  doesn't exist in CDK`, info: `Deal ${cdkDealNumber} is not a deal in CDK for store ${this.$global.selectedStore.storeName}. Make sure you have the correct deal number and store selected.`, acceptText: 'OK' }, backdrop: false });
                        }
                        else if (response && response.data && response.data.isLocked) {
                            //DEAL IS LOCKED
                            this.EventBus.emit("CDKPushFinish")
                            $modal.open(modalInfo, { name: 'modalInfo', passedData: { title: 'Deal Is Locked in CDK', info: `Deal ${cdkDealNumber} is currently open in CDK. Please close the deal and try again.`, acceptText: 'OK' }, backdrop: false });
                        }
                        else {
                            //START SYNC
                            this.EventBus.emit('CDKPush', { pushing: true, postFunction: this.openFinalModal });
                        }
                    }).finally(() => {
                        this.isBusyFinalButton = false;
                    });
            },
            openFinalModal(syncResponse?: any) {
                $modal.open(modalFIFinalStep, {
                    name: 'modalFIFinalStep',
                    passedData: {
                        fimenu: this.fimenu,
                        checkChecklistFunc: this.checkChecklist,
                        license: this.v$,
                        syncResponse: syncResponse,
                        products: this.fimenu.storeProducts
                    },
                    backdrop: false,
                    postFunction: () => this.finalizeFI()
                });
            },
            openPaperworkErrorsModal() {
                $modal.open(modalPaperworkErrors, { name: 'modalPaperworkErrors', passedData: { packetId: this.fimenu.id, paperwork: this.fimenu.paperwork, fimenu: this.fimenu, rootName: 'Deal #' + this.fimenu.dealNumber + ' - ' + this.fimenu.storeCode }, backdrop: true });
            },
            updateRatesIfNeeded() {
                if (!this.$global.isAdminView && !this.fimenu.isDealLocked() && this.fimenu.isRatingRequired() && this.sections.some(s => s.name === ENUMS.FIMENU_SECTION.getName(ENUMS.FIMENU_SECTION.FI_MENU) && s.isActive)) {
                    this.rateProducts()
                }
            },
            async rateProducts() {

                this.isBusy = true;
                this.fimenu.addToLog(ENUMS.LOG_WHERE.RATE, ENUMS.LOG_HOW.ASYNC.STARTED, ENUMS.LOG_WHAT.STEP_STARTED);


                try {
                    await this.fimenu.getProductsRates()

                    //update oil type Availability if we have maintenance
                    this.setOilTypesNotAvailable()

                    await (this.$parent as any).updatePENProducts();

                    if (this.fimenu.selectedProviders.find((sp: any) => sp.productType === ENUMS.PRODUCT_TYPES.MAINTENANCE)?.provider != null && !this.isSelectedOilTypeAvailable()) {
                        this.openModalOil()
                    }
                } catch (err) {

                    console.error("error", err);
                } finally {
                    this.fimenu.addToLog(ENUMS.LOG_WHERE.RATE, ENUMS.LOG_HOW.ASYNC.DONE, ENUMS.LOG_WHAT.STEP_FINISHED);
                    this.isBusy = false;
                }
            },
            async rateProductsAllForceRepriceAll() {
                //force reprice all
                this.fimenu.selectedProviders = []

                //reset coverage terms
                this.fimenu.resetDefaultCoverageTerms()

                //re-rate
                this.rateProducts()
            },
            getPaymentStartDate() {
                if (this.fimenu.dealType.toLowerCase() === 'finance' && this.fimenu.loanTerms && (this.fimenu.loanTerms.selectedDaysToFirstPayment ?? 30))
                    return this.$filters.prettyDate(util.toMoment(this.fimenu.dealDate).add(this.fimenu.loanTerms.selectedDaysToFirstPayment ?? 30, 'days'))
                else
                    return this.$filters.prettyDate(util.toMoment(this.fimenu.dealDate).add(30, 'days'));
            },
            async handleSubmitDealJacketToRouteOne() {
                return new Promise<any>((resolve, reject) => {
                    try {
                        if (this.fimenu.isCash()) return resolve(null);

                        const canSubmit = this.fimenu.store?.storeSettings?.submitCustomersToFinanaceInstitutions == true || this.fimenu.creditApplicationsEnabled == true
                        if (!canSubmit) return resolve(null);
                        if (!this.fimenu.isEContracting) return resolve(null);

                        // Call SubmitDealJacket and wait for its Promise to resolve
                        SubmitDealJacket(this.fimenu)
                            .then((result) => resolve(result))
                            .catch((error) => console.error(error));

                    } catch (err) {
                        console.error(err);
                        resolve(err);
                    }
                });
            },
            async finalizeFI(controlNumber?: any) {
                if (await this.fimenu.checkForFraud()) return

                let message = "";

                if (!this.payload.EmployeeAccess.IsAdmin) {
                    //change the ownership of the documents just if is not admin, in case we need to play with production deal
                    this.fimenu.fiManagerName = this.payload.EmployeeName;
                    this.fimenu.fiManagerCode = this.payload.EmployeeCode;
                }

                const { customer, coCustomer, hasCoSigner } = this.fimenu;

                if (!customer.isEntity && !customer.ssnLast4) {
                    message = `${customer.fullName} is missing their SSN.`;
                }

                if (hasCoSigner && !coCustomer.isEntity && !coCustomer.ssnLast4) {
                    if (message) {
                        message = `${customer.fullName} and ${coCustomer.fullName} are missing their SSN.`;
                    } else {
                        message = `${coCustomer.fullName} is missing their SSN.`;
                    }
                }

                if (this.fimenu.buyersOrderEnabled && !!message && this.fimenu.dealType != 'Cash') {
                    return util.modalInformation(message, () => { return; }, "Missing Customer Information", "Ok")
                }

                //WE ALREADY HAVE PAPERWORK, SO GO TO NEXT SECTION
                if (this.fimenu.hasPaperworkPacket()) {
                    this.handleContinue();
                }
                else {

                    const executionPromises = [
                        this.handleSubmitDealJacketToRouteOne(),
                        this.getPaperwork(),
                        this.copyAndAssociateCreditReport(),
                        this.transferPlate()
                    ]

                    this.includeUpdateDealjacketPromise(executionPromises)

                    //GENERATE PAPERWORK
                    Promise.all(executionPromises)
                        .catch(error => {

                            console.error(error)
                            util.toastr('error', 'Paperwork', error);
                        })
                        .finally(() => {
                            this.handleContinue().then(() => { this.EventBus.emit('paperworkReady') });
                        })
                }

            },
            includeUpdateDealjacketPromise(executionPromises?: any) {
                const boEnabled = this.fimenu.buyersOrderEnabled;
                const R1Enabled = CreditApplicationsEnabled(this.fimenu);
                const hasSelectedApplication = !!this.fimenu.dealJacket.getSelectedApplication()?.id;

                if (boEnabled && R1Enabled && hasSelectedApplication) {
                    executionPromises.push(api.routeOne.updateDealJacket(this.fimenu))
                }
            },
            copyAndAssociateCreditReport() {

                if (this.fimenu.customer?.creditReport?.id) {

                    if (this.fimenu.coCustomer?.creditReport?.id) {
                        return [
                            api.creditreport.copyAndAssociateCreditReport(this.fimenu.id, this.fimenu.customer.creditReport.id),
                            api.creditreport.copyAndAssociateCreditReport(this.fimenu.id, this.fimenu.coCustomer.creditReport.id)
                        ]
                    } else {
                        return api.creditreport.copyAndAssociateCreditReport(this.fimenu.id, this.fimenu.customer.creditReport.id)
                    }
                }

                return Promise.resolve();
            },
            transferPlate() {
                return new Promise(async (resolve, reject) => {
                    try {
                        const isPlateTransfer = this.fimenu.vehicle.registrationInfo.isPlateTransfer;

                        // Check if we can actually do a transfer.
                        if (!this.fimenu.buyersOrderEnabled || !IsPlateTransactionsEnabled(this.fimenu)) return resolve(false);
                        // IF we're not doing anything, kill it here.
                        if (!isPlateTransfer) return resolve(false)
                        // Lets check if the user has credentials.
                        const response = await api.plateTransactions.hasCredentials()
                        if (!response.data) return resolve(false)
                        // We should only automatically generate if it's the first one.
                        await handlePlateTransaction(this.fimenu, PlateTransactionType.Transfer, "DMV Transaction Completed.", false);
                        return resolve(true);

                    }
                    catch (err) {

                        console.error(err)
                        util.toastr('error', 'Paperwork', err)
                        return resolve(err)
                    }
                });
            },
            getPaperwork() {
                return new Promise((resolve, reject) => {
                    this.fimenu.cleanUpCustomers()

                    this.EventBus.emit('paperworkStarted');

                    //PREP fimenu OBJECT FOR PAPERWORK

                    api.fimenu.getPaperwork(this.fimenu)
                        .then((response) => {
                            if (response && response.data) {
                                this.fimenu.paperwork = new FIMenuPaperwork(response.data);
                                //SET DOCUMENT ISOPEN
                                this.fimenu.paperwork.currentPacket().documents.forEach((doc: any) => {
                                    doc.isOpen = false;
                                });
                                util.toastr('success', 'Paperwork Success!', 'Paperwork is ready');
                            }
                            else {
                                util.toastr('error', 'Paperwork', 'Unknown Error');
                            }
                        })
                        .catch(function (error) {
                            util.toastr('error', 'Paperwork', error);
                            resolve(error)
                        })
                        .finally(() => {
                            resolve(true)
                        });
                })

            },
            isVideoSelected() {
                return this.selectedVideos.length === 0 ? true : false
            },
            resetSelectedForStreaming() {
                this.selectedForStreaming = [];
            },
            openDealChecklistModal() {
                $modal.open(modalDealChecklist, {
                    name: 'modalDealChecklist',
                    passedData: {
                        fimenu: this.fimenu,
                        settings: this.settings,
                    }
                });
            },
            async getCreditReportWhenInitialized() {

                const customerId = this.fimenu.customer?.id;
                if (customerId) {
                    try {

                        const { data: customerReport } = await api.creditreport.getByCustomerId(customerId, this.fimenu.isDealLocked() ? this.fimenu.id : null)
                        this.fimenu.customer.creditReport = new FIMenuCreditReport(customerReport)

                    } catch (error) {

                        console.error(error);
                    }
                }

                const coCustomerId = this.fimenu.coCustomer?.id
                if (coCustomerId) {
                    try {

                        const { data: coCustomerReport } = await api.creditreport.getByCustomerId(coCustomerId, this.fimenu.isDealLocked() ? this.fimenu.id : null)
                        this.fimenu.coCustomer.creditReport = new FIMenuCreditReport(coCustomerReport)

                    } catch (error) {

                        console.error(error);
                    }
                }
            },
            async startAutoCompleteScan(isQuoteRequested?: boolean) {
                // Starts an insurance scan on the customer with AutoComplete
                const promiseList = [Promise.resolve(this.startAutoCompleteInsuranceScan())];

                if (isQuoteRequested)
                    promiseList.push(Promise.resolve(this.startAutoCompleteQuoteRequest()));

                await Promise.allSettled(promiseList);
            },
            async startAutoCompleteInsuranceScan() {
                try {
                    // AutoComplete will reject anyone with an address outside the US
                    if (this.fimenu.customer.country !== 'US') return;

                    const response = await api.autoComplete.createVerificationFromDeal(
                        this.fimenu.id,
                        this.fimenu.dealKind,
                        this.fimenu.storeCode,
                        this.fimenu.coCustomer.country === 'US'
                    );

                    if (!response?.data || response.data.message)
                        throw new Error(response?.data?.message ?? 'No response from server.', { cause: response });

                    response.data.results.forEach((d: any) => {
                        if (d.message) throw new Error(d.message, { cause: d });
                    });
                }
                catch (err) {
                    console.warn('COULD NOT START AUTOCOMPLETE INSURANCE SCAN: ', err);
                }
            },
            async startAutoCompleteQuoteRequest() {
                try {
                    // AutoComplete will reject anyone with an address outside the US
                    if (this.fimenu.customer.country !== 'US') return;

                    const quoteResponse = await api.autoComplete.createLeadFromDeal(this.fimenu.id, this.fimenu.dealKind, this.fimenu.storeCode);
                    if (!quoteResponse?.data || quoteResponse.data.message)
                        throw new Error(quoteResponse?.data?.message ?? 'No response from server.', { cause: quoteResponse });

                    quoteResponse.data.results.forEach((d: any) => {
                        if (d.message) throw new Error(d.message, { cause: d });
                    });
                }
                catch (err) {
                    console.warn('COULD NOT FETCH AUTOCOMPLETE INSURANCE QUOTE: ', err);
                }
            },
            clearVehicleInformation() {
                if (this.fimenu.isDealLocked()) return;
                this.fimenu.vehicle.make = null;
                this.fimenu.vehicle.model = null;
                this.fimenu.vehicle.modelYear = null;
                this.fimenu.vehicle.style = null;
            },
        },
        components: {
            PanelMenu,
            PanelVehicle,
            PanelVehicleExtra,
            PanelManufacturerWarranty,
            InsurancePage,
            PanelTradeIn,
            PurchaseFiguresPage,
            DealIsLocked,
            PanelSurchargesInQuestion,
            PanelVehicleRegistration,
            PanelDealDetails,
            PanelCarFax,
            PanelVehicleRecallInfo,
            PanelFIMenu,
            PanelFIFinalStep,
            PanelCustomerSection,
            IsBusySectionComponent,
            PanelDealAudit,
            PanelDealJacket,
            PanelAccounting,
            FIMenuWizardContent,
            /*            VideosRecordedList,*/
        }
    });
</script>
<style>


    .cocustomer-container {
        margin-bottom: 300px;
    }

    .sidemenu-inside-container fieldset:disabled .trade-ins-container i.close-button,
    .sidemenu-inside-container fieldset:disabled .customer-section-container i.close-button {
        display: none;
    }

    .sidemenu-inside-container fieldset:disabled .customer-radio-button-inline {
        pointer-events: none;
    }

    .fimenupage .fimenu-section-wrapper .loading-saturn-anim.fade-in.IsBusySectionComponent-container {
        height: calc(100% - 75px);
    }

    .fimenubasic .sidemenu-main .sidemenu-contents {
        border-top: none !important;
    }

    .fimenubasic .sidemenu-menu {
        border-top: none !important;
    }

    .fimenubasic .purchase-figures-container {
        height: 100%;
        margin-bottom: unset !important;
    }

    .purchase-figures-container > * {
        height: 99% !important;
        overflow: hidden;
    }

    .purchase-figures-container-nonbo > * {
        height: 99% !important;
        overflow: visible;
    }

    .fimenubasic .purchase-figures-container .panel .panel-body {
        overflow-y: auto;
    }
</style>