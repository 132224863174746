<template>
    <div class="video-list-container">
        <div v-if="videoList" class="video-list">
            <Card v-for="video in videoList" :key="video" onHoverReact class="card-video">
                <div>
                    <div class="fimanager-name">
                        {{ video.fiManagerName }}
                        <span>
                            ({{ video.id != fimenu.currentRecordingVideo ? getVideoDuration(video) : 'Unknown' }})
                        </span>
                    </div>
                    <div class="video-title">
                        {{ getVideoDate(video) }}
                    </div>
                </div>
                <div>
                    <button @click="openModalVideoPlayer(video)" v-if="video.id != fimenu.currentRecordingVideo">
                        Open Video
                    </button>
                    <button v-else class="button-delete" disabled>
                        Recording
                    </button>
                </div>
            </Card>
        </div>
    </div>
</template>
<script>
    import { _ } from 'vue-underscore';
    import Warnings from '@core/services/warningMessages'
    import api from '@core/services/api';
    import Card from '@core/components/Card.vue';
    import moment from 'moment';
    import modalDealVideoPlayer from '@/modals/modalDealVideoPlayer.vue';
    import $modal from '@core/services/modal'
    /*    import RichTable from '@core/components/RichTable.vue'*/

    export default {
        name: "VideosRecordedList",
        props: ["fimenu"],
        data() {
            return {
                generatedWarnings: new Warnings("VideosRecordedList"),
                videoList: []
            };
        },
        computed: {
        },
        async created() {
            this.init();
        },

        methods: {
            async init(){
                try{
                    const response = await api.videos.getDealVideos(this.fimenu.id);

                    this.videoList =  response.data
                }
                catch(err) {
                    console.log(err);
                }
            },
            getVideoDate(video) {
                return moment(video.startTimestamp)
            },
            getVideoDuration(video){
                if(!video.endTimestamp) {
                    return "Unknown"
                } else {
                    
                    return `${moment.utc(moment(video.endTimestamp).diff(video.startTimestamp, 'seconds') * 1000).format("mm:ss")}`
                }
            },
            openModalVideoPlayer(video) {
                $modal.open(modalDealVideoPlayer, { name: 'modalDealVideoPlayer', passedData: {logs: this.fimenu.log, video}, postFunction: () => { return;}, backdrop: false });
            }
        },
        components: {
            /*            RichTable,*/
            Card
        }
    };
</script>
<style>
.video-list-container {
    height: 100%;
}
    .video-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        height: 100%;
        padding: 0 10px 0 0;
    }
    .card-video {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
    }
    .fimanager-name {
        font-weight: 600;
        font-size: 20px;
    }
    .fimanager-name span {
        font-size: 16px;
        font-weight: 500;
        color: var(--third-color)
    }
    .video-title {
        font-size: 14px;
    }

</style>
