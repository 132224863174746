import CoverageTerm from './CoverageTerm';
import FIMenuCustomer from './FIMenuCustomer';
import FIMenuCustomerViewed from './FIMenuCustomerViewed';
import FIMenuLeaseTerms from './FIMenuLeaseTerms';
import FIMenuLender from './FIMenuLender';
import FIMenuLoanTerms from './FIMenuLoanTerms';
import FIMenuVehicleWarranty from './FIMenuVehicleWarranty';

export default class FIMenuForCustomerMeeting {
    language: string = null;
    storeInfo: any = {}; // storeInfo is used in customer application
    storeCode: string = null;
    storeState: string = null;
    coverageTerms: CoverageTerm[] = [];
    customerViewed: FIMenuCustomerViewed[] = [];
    customer:CustomerLite = null;
    coCustomer:CustomerLite = null;
    hasCoSigner: boolean = null;
    googleReviewsURL: string = '';

    dealType: string = null;
    dealDate: string = null;
    otd: number = null;

    lender: LenderLite = null;
    leaseTerms: FIMenuLeaseTerms = new FIMenuLeaseTerms();
    loanTerms: FIMenuLoanTerms = new FIMenuLoanTerms();
    isDraft: boolean = null;
    pricesReady: boolean = null;
    calculatedFinalNumbers: ICalculatedFinalNumbers = null;
    disclaimerPayment: IDisclaimerPayment = null;

    vin: string = null;
    inventoryType: string = null;
    vehicle: any = null;
    vehicleMiles: number = null;
    vehicleWarranty = new FIMenuVehicleWarranty();
    currentPacket: any = null;

    constructor(init?: any) {
        if (init) {
            this.language = init.language ?? null;
            this.storeInfo = init.storeInfo ?? {};
            this.storeCode = init.storeCode ?? null;
            this.storeState = init.storeState ?? null;
            this.coverageTerms = init.coverageTerms ? [...init.coverageTerms] : [];
            this.customerViewed = init.customerViewed ? [...init.customerViewed] : [];
            this.customer = init.customer ? this.getFilteredCustomerData(init.customer) : null;
            this.coCustomer = init.coCustomer ? this.getFilteredCustomerData(init.coCustomer) : null;
            this.hasCoSigner = init.hasCoSigner ?? null;
            this.googleReviewsURL = init.googleReviewsURL ?? '';

            this.dealType = init.dealType ?? null;
            this.dealDate = init.dealDate ?? null;
            this.otd = init.otd ?? null;

            this.lender = init.lender ? this.getFilteredLenderData(init.lender) : null;
            this.leaseTerms = init.leaseTerms ? new FIMenuLeaseTerms(init.leaseTerms) : new FIMenuLeaseTerms();
            this.loanTerms = init.loanTerms ? new FIMenuLoanTerms(init.loanTerms) : new FIMenuLoanTerms();
            this.isDraft = init.isDraft ?? null;
            this.pricesReady = init.pricesReady ?? null;
            this.calculatedFinalNumbers = init.calculatedFinalNumbers ?? ({} as ICalculatedFinalNumbers);
            this.disclaimerPayment = init.disclaimerPayment ?? ({} as IDisclaimerPayment);

            this.vin = init.vin ?? null;
            this.inventoryType = init.inventoryType ?? null;
            this.vehicle = init.vehicle ? { ...init.vehicle } : null;
            this.vehicleMiles = init.vehicleMiles ?? null;
            this.vehicleWarranty = init.vehicleWarranty ? new FIMenuVehicleWarranty(init.vehicleWarranty) : new FIMenuVehicleWarranty();
            
            this.currentPacket = init.currentPacket ? init.currentPacket : null;
        }

    }

    getFilteredCustomerData(fimenuCustomer: FIMenuCustomer): CustomerLite {
        return {
            fullName: fimenuCustomer.fullName,
            currentSignatureId: fimenuCustomer.currentSignatureId,
            currentInitialsId: fimenuCustomer.currentInitialsId,
            // TODO: REMOVE SIGNATURE AND INTIALS AT A LATER POINT (SEE ISSUE FMD-7807)
            signature: fimenuCustomer.signature,
            initials: fimenuCustomer.initials,
        }
    }

    getFilteredLenderData(fimenuLender: FIMenuLender): LenderLite {
        return {
            lenderName: fimenuLender.lenderName,
            lenderCode: fimenuLender.lenderCode,
        }
    }
}
interface CustomerLite {
    fullName: string;
    currentSignatureId: string;
    currentInitialsId: string;
    // TODO: REMOVE SIGNATURE AND INITIALS AT A LATER POINT (SEE ISSUE FMD-7807)
    signature: string;
    initials: string;

}

interface LenderLite {
    lenderName: string;
    lenderCode: string;
}

interface IDisclaimerPayment {
    basePayment: number;
}

interface ICalculatedFinalNumbers {
    termNumbers: any;
    [key: number]: any;
}


// interface IDealTerm {
//     term: number,
//     numbers: IDealTermDetails
// }

// interface IDealTermDetails {
//     baseAmount: number,
//     basePayment: number,
//     dealType: string,
//     finalAmount: number,
//     finalPayment: number,
//     finalPaymentAtBuyRate: number,
// }


