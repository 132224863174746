class Warnings {
    messageHeader;
    SaturnStyle = "background-color: purple; font-weight: bold; color: white";
    WRONG_DEVICE = "The selected device is not available.";
    NO_DEVICES = "Devices are not yet available.";

    constructor(componentName) {
        this.messageHeader = `%c${componentName} Component:`
    }

    errorMessage(err) {
        const message = {
            text: `${this.messageHeader} ${err}`,
            style: this.SaturnStyle,
        }
        const { text } = message
        const { style } = message
        return { text, style }
    }

    wrongPropValue(propName, message, expectedType) {
        return {
            text: `${this.messageHeader} ${message ? message + " " : ""
                }'${propName}' prop was expecting a value of type '${expectedType}'.`,
            style: this.SaturnStyle,
        };
    }

    contradictingProps(prop1, prop2) {
        return {
            text: `${this.messageHeader} Can't use '${prop1}' and '${prop2}' props simultaneously. Disabling '${prop2}'.`,
            style: this.SaturnStyle,
        };
    }
}


export default Warnings