<template>
    <Panel
        v-bind="panelObject"
        :class="{ 'errorBlink': loJackMsgClass() }"
    >
        <!-- <IsBusyScreenComponent v-if="(!panelView) && vinLookupBusy || stockNumberLookupBusy"></IsBusyScreenComponent> -->
        <div
            class="no-margin-grid"
            :style="$grid('1-1')"
        >
            <div
                class="no-margin-grid"
                :style="$grid('1-1.6-0.7')"
            >
                <InputTextbox
                    label="Stock Number"
                    v-model:saturn="fimenu.stockNumber"
                    :invalid="util.isNull(validation) ? false : validation.stockNumber.$invalid"
                    placeholder="Stock Number"
                    :disabled="disabled || vinLookupBusy || stockNumberLookupBusy || isBusyEval"
                    @blur="handleStockLookupBlur"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.stockNumber.required.$invalid">Stock Number is required</div>
                    </template>
                </InputTextbox>
                <InputTextbox
                    label="VIN"
                    :maxlength="17"
                    v-model:saturn="fimenu.vin"
                    :invalid="util.isNull(validation) ? false : validation.vin.$invalid"
                    placeholder="VIN"
                    @blur="() => handleVinDecode(false)"
                    icon="fas fa-search"
                    :disabled="disabled || vinLookupBusy || stockNumberLookupBusy || isBusyEval"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vin.required.$invalid">VIN is required</div>
                        <div v-if="!util.isNull(validation) && validation.vin.validVIN.$invalid">Invalid VIN</div>
                    </template>
                </InputTextbox>
                <InputRichDropdown
                    label="New/Used"
                    v-model:saturn="inventoryType"
                    :list="inventoryTypes"
                    :display="(i: any) => i.text"
                    :valueMap="(i: any) => i.value"
                    :invalid="util.isNull(validation) ? false : validation.inventoryType.$invalid"
                    nothingSelected="-- Select Value --"
                    @change="changeInventoryHandle"
                    :disabled="disabled"
                    :sortMode="DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.inventoryType.required.$invalid">New/Used is required</div>
                    </template>
                </InputRichDropdown>
            </div>
            <div
                class="no-margin-grid"
                :style="$grid('1-1-1')"
            >
                <InputNumber
                    label="Odometer"
                    v-model:saturn="fimenu.vehicleMiles"
                    :invalid="util.isNull(validation) ? false : validation.vehicleMiles.$invalid"
                    placeholder="Miles"
                    :precision="0"
                    :disabled="disabled"
                    @blur="carFaxOdometerWarning"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.required.$invalid">Odometer is required</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.maxValue.$invalid">A New Car can't have this amount of Miles</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.minValue.$invalid">Odometer value must be at least 1 or last CarFax Odo</div>
                    </template>
                </InputNumber>
                <!--<InputDate label="In Service Date"
                           v-model:saturn="fimenu.vehicleWarranty.warrantyStartDate"
                           :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warrantyStartDate.$invalid"
                           placeholder="In Service Date"
                           class="presentation-serviceDate-shift-left"
                           :disabled="disabled">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.required.$invalid">In Service date is required on used vehicles</div>
                    </template>
                </InputDate>-->
                <InputNumber
                    label="Miles Per Year"
                    v-model:saturn="fimenu.vehicle.milesPerYear"
                    placeholder="Miles Per Year"
                    :precision="0"
                    :invalid="util.isNull(validation) ? false : validation.vehicle.milesPerYear.$invalid"
                    :disabled="disabled"
                    :min="0"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicle.milesPerYear.required.$invalid">Miles Per Year is required</div>
                    </template>
                </InputNumber>
                <InputDate
                    label="In Service Date"
                    v-model:saturn="fimenu.vehicleWarranty.warrantyStartDate"
                    :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warrantyStartDate.$invalid"
                    :disabled="disabled"
                    icon="fas fa-calendar"
                >
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.required?.$invalid">{{ dateErrorMsg }}</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.isValid.$invalid">
                            {{ invalidOrFutureDateErrorMsg + fimenu.vehicle.modelYear }}
                        </div>
                    </template>
                </InputDate>
            </div>
        </div>
        <template #header>
            <div
                style="display: inline-flex"
                class="loadingContainer"
                v-if="vinLookupBusy || stockNumberLookupBusy"
            >
                <strong
                    class="vin-lookup-flash"
                    style="align-self: center"
                    >Loading...</strong
                >
                <img
                    class="loading-anim"
                    src="@static/img/SaturnLogoAnim.svg"
                    style="width: 40px; margin-right: 2%"
                />
            </div>
            <div
                v-if="spireonEnabled"
                class="loJack-installed ignore-deal-lock"
            >
                <div
                    class="iconClass"
                    @mouseover="
                        () => {
                            $refs.loJackIcon.classList.remove('statusPulse');
                        }
                    "
                >
                    <InfoIcon :infoIconObj="handleLoJackResponse()" />
                    <div
                        :style="{ '--status': 'var(--lojacknuetral)' }"
                        ref="loJackIcon"
                        class="statusPulse"
                    ></div>
                </div>
                <button
                    v-if="allowVehicleRefresh"
                    :disabled="vinLookupBusy || stockNumberLookupBusy || populatingVehicleDetails"
                    class="button-save"
                    title="Redo this vehicle's vin lookup"
                    @click="() => handleVinDecode(false, true)"
                >
                    Reload Vehicle
                </button>
                <button
                    class="button"
                    :disabled="enableLoJackRefresh"
                    @click="loJackSearchByVin(true)"
                >
                    <i class="fas fa-sync-alt" />LoJack Refresh
                </button>
                <button
                    v-if="vehicleLocation && !canPingVehicle"
                    @click="showLocation()"
                    class="ignore-all-locks"
                >
                    <i class="fas fa-map-marker-alt"></i>
                </button>
                <button
                    v-if="canPingVehicle"
                    @click="showLocation()"
                    class="ping ignore-all-locks"
                >
                    <i class="fas fa-map-marker-alt"></i>
                </button>
            </div>

            <!-- <div>
                <button @click="() => vinDecode(false, forceRefresh = true)">
                    <i class="fas fa-sync-alt"></i>
                    Vehicle Refresh
                </button>
            </div> -->
        </template>
    </Panel>
</template>
<script lang="ts">
    export interface IPanelVehicleProps {
        fimenu: FIMenu; // Set to FIMenu in TS.
        panelObject?: any;
        validation?: any;
        disabled?: boolean;
        panelView?: boolean;
        notFirstPencil?: boolean;
        mscanAccount?: string;
        spireonEnabled?: boolean;
        customVin?: boolean;
        allowVehicleRefresh?: boolean;
    }

    type IconObject = {
        icon: string;
        iconClass: string;
        tooltipText: string[];
        clear?: boolean;
    };

    type CarFaxResponse = {
        responseStatus: any;
        responseDescription: any;
    };
</script>

<script setup lang="ts">
    import { computed, inject, nextTick, onMounted, ref, watch } from 'vue';
    import { DEAL_LOJACK_STATUS, FUEL_TYPES, HTTP_STATUS_CODE, OIL_TYPES } from '@core/classes/Enums';
    import $modal from '@core/services/modal';
    import _ from 'underscore';
    import api from '@core/services/api';
    import { CreateBookValues } from '@/helpers/fimenuvehicle-helper';
    import { FiltersPlugin } from '@core/services/filters';
    import settings from 'settings';
    import util from '@core/services/util';

    import { DROPDOWN_SORT_MODE } from '@core/classes/Enums';
    import { Emitter } from 'mitt';
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuCarFax from '@core/classes/FIMenuCarFax';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';
    import { StockNumberLookupResponse } from '@core/classes/Responses/StockNumberLookupResponse';
    import VAutoLookupResponse from '@core/classes/Responses/VAutoLookupResponse';

    import InfoIcon from '@core/components/InfoIcon.vue';
    import InputDate from '@core/components/InputDate.vue';
    import InputNumber from '@core/components/InputNumber.vue';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import InputTextbox from '@core/components/InputTextbox.vue';
    import modalInfo from '@core/modals/modalInfo.vue';
    import modalMap from '@/modals/modalMap.vue';
    import modalRichTable from '@/modals/modalRichTable.vue';
    import modalStockVehicles from '@/modals/modalStockVehicles.vue';
    import Panel from '@core/components/Panel.vue';
    import SaturnResponse from '@core/classes/Responses/SaturnResponse';
    import { VINInfo } from '@core/classes/VINInfo';

    const { fimenu, spireonEnabled, validation, customVin: skipDBCheck, mscanAccount, disabled } = defineProps<IPanelVehicleProps>();
    const EventBus = inject<Emitter<any>>('EventBus', null);
    const $global = inject<SaturnGlobalPlugin>('$global', null);
    const $filters = inject<FiltersPlugin>('$filters', null);
    const populatingVehicleDetails = inject<boolean>('populatingVehicleDetails', false);

    const inventoryType = ref<string>(null);
    const inventoryTypes = ref([
        { text: 'New', value: 'New' },
        { text: 'Used', value: 'Used' },
        { text: 'CPO', value: 'Certified' },
    ]);

    const previousVin = ref<string>(null);
    const isBusyEval = ref(false);
    const vinLookupBusy = ref(false);
    const previousStockNumber = ref<string>(null);
    const stockNumberLookupBusy = ref(false);
    const dateErrorMsg = ref('In Service date is required on used vehicles');
    const invalidOrFutureDateErrorMsg = ref("In service date can't be in the future and must be within 1 year of ");
    const dealLojackStatus = ref(fimenu.dealLojackStatus);
    const loJackResponse = ref(null);
    const vehicleLocation = ref(false);
    const canPingVehicle = ref(false);
    const runVINLookupDetails = ref(false);

    const vinLookupEnabled = computed(() => validation && !validation.vin.$invalid);
    const enableLoJackRefresh = computed(() => !fimenu.vin || handleLoJackResponse().clear || !spireonEnabled);
    const isVehicleDelivered = computed(() => fimenu.isVehicleDelivered);
    const noLoJackResponse = computed(() => loJackResponse.value == null);
    const stockNumberIsDifferent = computed(() => previousStockNumber.value !== fimenu.stockNumber);
    const vinIsDifferent = computed(() => previousVin.value !== fimenu.vin);

    const loJackIcon = ref();

    watch(
        () => loJackResponse.value,
        () => {
            showLoJackAlertIfNeeded();
        },
        { deep: true },
    );

    watch(
        () => fimenu.inventoryType,
        async () => await loadData(),
    );

    watch(
        () => fimenu.vehicleWarranty.isCertified,
        async () => await loadData(),
    );

    // watch(
    //     () => fimenu.vin,
    //     newValue => {
    //         vehicleLocation.value = false;
    //         if (!newValue) {
    //             previousVin.value = newValue;
    //         }
    //     },
    // );
    // watch(
    //     () => fimenu.stockNumber,
    //     (newValue: string, oldValue: string) => {
    //         console.log("new and old", newValue, oldValue)
    //         if (!oldValue) previousStockNumber.value = oldValue;
    //     },
    // );
    watch(
        () => runVINLookupDetails.value,
        newValue => {
            if (newValue) {
                vinLookup();
                runVINLookupDetails.value = false;
            }
        },
    );

    onMounted(() => {
        loadData();
    });

    function pulseCheck() {
        if (spireonEnabled) {
            nextTick(() => {
                loJackIcon.value.classList?.add('statusPulse');
                switch (fimenu.dealLojackStatus) {
                    case DEAL_LOJACK_STATUS.NOT_INSTALLED:
                        return loJackIcon.value.style.setProperty('--status', 'var(--lojackbad)');
                    case DEAL_LOJACK_STATUS.INSTALLED:
                        return loJackIcon.value.style.setProperty('--status', 'var(--lojackgood)');
                    default:
                        return loJackIcon.value.style.setProperty('--status', 'var(--lojacknuetral)');
                }
            });
        }
    }

    function loJackMsgClass() {
        if (!spireonEnabled || isVehicleDelivered) return;
        return loJackResponse.value?.attributes.deviceStatus === 'Not Installed';
    }

    function showLoJackAlertIfNeeded() {
        pulseCheck();
        if (isVehicleDelivered.value) return;
        //Possibly add activated to message as a lojack can be installed but not active.
        let infoText = 'SEND THIS VEHICLE TO THE SERVICE DEPARTMENT AS SOON AS POSSIBLE.\n\nDO NOT DELIVER THE VEHICLE WITHOUT A LOJACK INSTALLED!';
        if (noLoJackResponse.value) {
            infoText =
                'LOJACK DID NOT RESPOND AND WAS MOST LIKELY NOT INSTALLED ON THIS VEHICLE.\n\nSEND THIS VEHICLE TO THE SERVICE DEPARTMENT AS SOON AS POSSIBLE.\n\nDO NOT DELIVER THE VEHICLE WITHOUT A LOJACK INSTALLED!';
        }
        //Commented out per Avidan
        //if ((spireonEnabled && noLoJackResponse) || loJackResponse?.active === false || loJackResponse?.length == 0) {
        //    $modal.open(modalInfo, {
        //        name: "modalInfo",
        //        passedData: {
        //            title: `LoJack Alert`,
        //            info: infoText,
        //            cancelText: "Close",
        //            cancelFunction: null
        //        },
        //        backdrop: true,
        //        postFunction: null
        //    });
        //}
        // eslint-disable-next-line no-console
        console.log(`LoJack Alert: ${infoText}`);
    }
    function handleLoJackResponse(): IconObject {
        let iconObj: IconObject = { icon: 'fas fa-exclamation-circle', iconClass: 'bad', tooltipText: ['Enter VIN for LoJack Info'], clear: null };
        if (vinLookupBusy.value && !loJackResponse.value) {
            iconObj.iconClass = 'info-lojack';
            iconObj.tooltipText = ['Lojack is processing...'];
            return iconObj;
        }
        if (isVehicleDelivered.value) {
            iconObj.iconClass = 'info-lojack';
            iconObj.tooltipText = dealLojackStatus.value == 0 ? ['LoJack Status Good'] : dealLojackStatus.value == 1 ? ['Lojack Needs Activation'] : ['LoJack Status Bad'];
            return iconObj;
        }

        if (!spireonEnabled) {
            iconObj = { icon: 'fas fa-info', iconClass: 'normal', tooltipText: ['LoJack Search Disabled'] };
        } else if (loJackResponse.value) {
            const deviceStatus = loJackResponse.value?.attributes.deviceStatus;
            const location = loJackResponse.value?.lastLocation;
            const active = loJackResponse.value?.active;
            //let store = loJackResponse?.assetGroupName
            vehicleLocation.value = loJackResponse.value?.lastLocation ? true : false;
            //active: 4 possibilities
            if (active) {
                if (location && deviceStatus === 'Not Installed') {
                    iconObj.tooltipText = ['LoJack Uninstalled', 'LoJack Account Activated'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
                } else if (location && deviceStatus === 'Installed') {
                    iconObj.tooltipText = ['LoJack Installed', 'LoJack Account Activated'];
                    iconObj.iconClass = 'good';
                    iconObj.icon = 'fas fa-check';
                    //clear disables lojack refresh button
                    iconObj.clear = true;
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.INSTALLED;
                } else if (!location && deviceStatus === 'Not Installed') {
                    iconObj.tooltipText = ['LoJack Device Not Installed', 'LoJack Account Activated'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
                } else {
                    iconObj.tooltipText = ['LoJack Installed - Ping for Location', 'LoJack Account Activated'];
                    iconObj.iconClass = 'ping';
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.INSTALLED;
                    canPingVehicle.value = true;
                }
            } else if (!active) {
                // not active: 5 possibilities
                if (location && deviceStatus === 'Installed') {
                    iconObj.tooltipText = ['LoJack Account found, Needs Activation'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NEEDS_ACTIVATION;
                    //add some flag for allowing activation of loJack
                } else if (location && deviceStatus === 'Not Installed') {
                    iconObj.tooltipText = ['LoJack Device Uninstalled, Account Not Active', 'Last Location Found'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
                } else if (location === null || (!location && deviceStatus === 'Installed')) {
                    iconObj.tooltipText = ['LoJack Account Needs Activation', 'Last Location Not Found'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NEEDS_ACTIVATION;
                } else if (location === null || (!location && deviceStatus === 'Not Installed')) {
                    iconObj.tooltipText = ['LoJack Device Not Installed', 'LoJack Account Not Activated!'];
                    fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
                }
            } else {
                iconObj.tooltipText = ['LoJack Not Installed!'];
                fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
            }
            const currentStore = util.getSpireonAccount(fimenu.storeCode);
            if (loJackResponse.value?.storeAccount !== null && loJackResponse.value?.storeAccount !== currentStore.code)
                iconObj.tooltipText.push(`Found in ${loJackResponse.value?.assetGroupName}`);
        }

        if (noLoJackResponse.value) {
            iconObj.tooltipText = ['LOJACK DID NOT RESPOND AND WAS MOST LIKELY NOT INSTALLED ON THIS VEHICLE'];
            fimenu.dealLojackStatus = DEAL_LOJACK_STATUS.NOT_INSTALLED;
        }
        return iconObj;
    }
    async function showLocation() {
        const title = loJackResponse.value.instrumentationRef?.deviceId ?? `${loJackResponse.value.attributes.deviceStatus}, Showing Last Location`;
        const store = loJackResponse.value?.assetGroupName;
        const spireonAcct = loJackResponse.value.storeAccount ?? util.getSpireonAccount(fimenu.storeCode).code;
        if (vehicleLocation.value) {
            const eventResults = await api.spireon.getLoJackEvents(loJackResponse.value.id, spireonAcct);
            const history = Object.values(eventResults.data.content.reduce((acc: any, obj: any) => ({ ...acc, [obj.id]: obj }), {}));
            $modal.open(modalMap, {
                name: 'modalMap',
                passedData: {
                    title: `Device ID: ${title}`,
                    subTitle: `Found at: ${store}`,
                    center: { lat: loJackResponse.value.lastLocation.lat, lng: loJackResponse.value.lastLocation.lng },
                    history: history,
                },
                backdrop: true,
                postFunction: null,
            });
        } else {
            try {
                const pingResponse = await api.spireon.pingAssetById(loJackResponse.value.id, spireonAcct);
                // eslint-disable-next-line no-console
                console.log('pingResponse', pingResponse);
                if (pingResponse.data.success) {
                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            info: 'Vehicle was Pinged for Location, Check back within 24 hours for location.',
                            acceptText: 'Close',
                        },
                        backdrop: true,
                    });
                }
            } catch (err) {
                console.log('Error on Asset Ping', err);
            }
        }
    }

    function changeInventoryHandle() {
        if (inventoryType.value == 'New') {
            fimenu.vehicleWarranty.warrantyStartDate = null;
        }

        if (inventoryType.value == 'Certified') {
            fimenu.inventoryType = 'Used';
            fimenu.vehicleWarranty.isCertified = true;
        } else {
            fimenu.inventoryType = inventoryType.value;
            fimenu.vehicleWarranty.isCertified = false;
        }
    }

    async function loadData() {
        previousVin.value = fimenu.vin;
        previousStockNumber.value = fimenu.stockNumber;
        if (fimenu.inventoryType == 'Used' && fimenu.vehicleWarranty.isCertified == true) {
            inventoryType.value = 'Certified';
        } else {
            inventoryType.value = fimenu.inventoryType;
        }
        if (!loJackResponse.value) loJackResponse.value = await fimenu.loJackSearchByVin(false);
    }

    function handleStockLookupBlur() {
        stockNumberLookup();
    }

    async function handleVinDecode(clearStockNumber: boolean, refreshVehicle = false, clearBookValues = true) {
        const hasVinChangedOrNeedsVehicleRefresh = vinIsDifferent.value || refreshVehicle;

        if (!validation.vin.$invalid && hasVinChangedOrNeedsVehicleRefresh) {
            // If the vin actually changed
            if (clearBookValues) fimenu.vehicle.clearBookValues(); // Since vin changed; lets clear book values.

            await vinDecode(clearStockNumber, refreshVehicle);
            await vAutoLookup();
        } else if (!fimenu.vin) {
            // Else clear location/response
            loJackResponse.value = null;
            vehicleLocation.value = false;
        }
    }

    async function vAutoLookup() {
        try {
            if (!fimenu.buyersOrderEnabled) return;

            const response = await api.vAutoLookupByVin(fimenu.vin);
            if (response.data) {
                const bookValues = CreateBookValues(new VAutoLookupResponse(response.data));

                if (bookValues?.length) {
                    fimenu.vehicle.upsertBookValues(bookValues ?? []);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function stockNumberLookup() {
        // Clear any book value.
        fimenu.vehicle.clearBookValues();

        if (!validation.stockNumber.$invalid && stockNumberIsDifferent.value) {
            stockNumberLookupBusy.value = true;

            fimenu.vehicleWarranty.warrantyStartDate = null;
            EventBus.emit('stockNumberLookupBusy', true);
            try {
                let response = (await api.stockNumberLookup(fimenu.stockNumber))?.data;
                const stockInfo: StockNumberLookupResponse[] = response.table?.map((res: any) => new StockNumberLookupResponse(res));
                if (stockInfo && stockInfo?.length > 0) {
                    if (stockInfo?.length > 1) {
                        $modal.open(modalStockVehicles, {
                            name: 'modalStockVehicles',
                            passedData: { stock: fimenu.stockNumber, stockMatches: stockInfo },
                            backdrop: false,
                            postFunction: (data: StockNumberLookupResponse | VAutoLookupResponse) => {
                                populateMyResult(data);
                            },
                        });
                    } else {
                        populateMyResult(stockInfo?.[0] ?? response);
                    }
                } else {
                    response = (await api.vAutoLookupByStockNo(fimenu.stockNumber))?.data;
                    if (response) {
                        response = new VAutoLookupResponse(response);
                        populateMyResult(response);
                    }
                }
            } catch (error: any) {
                console.error(error);
            } finally {
                stockNumberLookupBusy.value = false;
                previousStockNumber.value = fimenu.stockNumber;
                EventBus.emit('stockNumberLookupBusy', false);
            }
        }
    }

    async function populateMyResult(result: StockNumberLookupResponse | VAutoLookupResponse) {
        if (result) {
            fimenu.vin = result.vin;
            if (result instanceof StockNumberLookupResponse) {
                if (result.stocktype == 'NEW') {
                    inventoryType.value = 'New';
                    if (result.msrp && result.msrp > 0) {
                        fimenu.vehicle.getOrCreateManufacturer().retail = result.msrp;
                    }

                    if (result.invoice && result.invoice > 0) {
                        fimenu.vehicle.getOrCreateManufacturer().wholesale = result.invoice;
                    }
                    fimenu.actualCost = result.actualCost;
                    fimenu.commissionCost = result.commissionCost;
                } else {
                    if (result.certified == 'Y') inventoryType.value = 'Certified';
                    else inventoryType.value = 'Used';
                }
            } else if (result instanceof VAutoLookupResponse) {
                console.log('Do we use vauto');
                if (result.newUsed == 'N') {
                    inventoryType.value = 'New';
                } else {
                    if (result.certified == 'Y') inventoryType.value = 'Certified';
                    else inventoryType.value = 'Used';
                }
            }
            changeInventoryHandle();
        }
        await handleVinDecode(false, false, false);
    }

    async function loJackSearchByVin(ignoreLock: boolean) {
        canPingVehicle.value = false;
        vinLookupBusy.value = true;
        loJackResponse.value = await fimenu.loJackSearchByVin(ignoreLock);
        vinLookupBusy.value = false;
        EventBus.emit('vinLookupBusy', false);
    }

    async function vinDecode(clearStockNumber: boolean, refreshVehicle = false) {
        EventBus.emit('clearVehicleInformation');
        vinLookupBusy.value = true;
        EventBus.emit('populatingVehicleDetails', true);

        const response = (await api.vinLookup(fimenu.vin, skipDBCheck, !refreshVehicle))?.data;
        try {
            let vinInfo = null;
            if (response) {
                vinInfo = new SaturnResponse<VINInfo>(response).payload;
                const isContentNew = response?.statusCode == HTTP_STATUS_CODE.REFRESH_CONTENT;

                //Processed VIN != Submitted VIN
                if (vinInfo && vinInfo.processedNotMatchSubmitted) {
                    if (isContentNew) {
                        EventBus.emit('refreshBrandsAndModels', true);
                    }
                    const onContinue = () => {
                        populateVINFields(response, clearStockNumber);
                        runVINLookupDetails.value = true;
                    };
                    const onCancel = () => {
                        fimenu.vin = null;
                        populateVINFields(null, clearStockNumber);
                    };

                    processedNotSubmittedWarning(onContinue, onCancel);
                } else {
                    if (isContentNew) {
                        EventBus.emit('refreshBrandsAndModels', true);
                    }
                    populateVINFields(vinInfo, clearStockNumber);
                    if (vinInfo == null) {
                        EventBus.emit('vinFailed', true);
                    }
                    runVINLookupDetails.value = true;
                }
            }
            if (mscanAccount != null) {
                const mscanresponse = await api.marketScan.getVehiclesByVIN(mscanAccount, fimenu.vin, fimenu.inventoryType == 'New');
                if (mscanresponse && mscanresponse.data && mscanresponse.data.length == 1) {
                    fimenu.marketScan.vehicleId = mscanresponse.data[0].id;
                } else {
                    fimenu.marketScan.vehicleId = null;
                }
            }

            fimenu.vehicleWarranty.updateUsingVINCarFaxLookup(vinInfo, fimenu.carFax);
            EventBus.emit('updateStyleId');
        } catch (err) {
            console.error(err);
            EventBus.emit('vinFailed', true);
        } finally {
            EventBus.emit('populatingVehicleDetails', false);
            isBusyEval.value = true;
            fimenu.resetSurcharges().finally(() => {
                isBusyEval.value = false;
            });
            vinLookupBusy.value = false;
            previousVin.value = fimenu.vin;
        }
    }

    async function vinLookup() {
        try {
            vinLookupBusy.value = true;
            EventBus.emit('vinLookupBusy', true);
            const stateForLoJack = fimenu.store?.storeState ?? settings.userStores.find((s: any) => s.storeCode == fimenu.storeCode).storeState;
            const zip = fimenu.store?.storeZip ?? settings.userStores.find((s: any) => s.storeCode == fimenu.storeCode).storeZip;
            const response = await util.vinLookup(fimenu.vin, fimenu.storeCode, false, true, true, stateForLoJack, zip);
            if (response) {
                loJackResponse.value = response[1]?.data.content[0];
                populateCarFaxDetails(response);
            }

            vinLookupBusy.value = false;
            EventBus.emit('vinLookupBusy', false);
        } catch (e) {
            console.error(e);
            util.toastr('error', 'VIN Lookup', e);
            vinLookupBusy.value = false;
            EventBus.emit('vinLookupBusy', false);
        }
    }

    function populateVINFields(vinInfo: VINInfo, clearStockNumber: boolean) {
        if (vinInfo) {
            if (previousVin.value) {
                //Clear Vehicle Info Entered
                if (clearStockNumber) {
                    fimenu.stockNumber = null;
                    fimenu.inventoryType = null;
                }
            }
            fimenu.vehicleMiles = null;
            fimenu.vehicle.updateUsingVINLookupResponse(vinInfo);
            fimenu.vehicleWarranty.updateUsingVINLookupResponse(vinInfo);
            checkForOtherDealsForVin();
            EventBus.emit('copiedWarrantyInfo', vinInfo.warrantyInfo ? true : false);
            EventBus.emit('copiedVehicleExtraInfo', true);
            previousVin.value = fimenu.vin;
        } else {
            fimenu.vehicle.updateUsingVINLookupResponse({});
            fimenu.vehicleWarranty.updateUsingVINLookupResponse({});
            EventBus.emit('copiedWarrantyInfo', false);
            EventBus.emit('copiedVehicleExtraInfo', false);
            previousVin.value = fimenu.vin;
        }

        //UPDATE VEHICLE OIL IF FUEL TYPE IS ELECTRIC
        if (fimenu.vehicle.fuelType == FUEL_TYPES.ELECTRIC) {
            fimenu.vehicleOil = OIL_TYPES.NA;
        }
    }

    function populateCarFaxDetails(response: any) {
        // eslint-disable-next-line no-console
        console.log('Carfax Details', response);
        const carFaxResponse: CarFaxResponse = {
            responseStatus: null,
            responseDescription: null,
        };

        if (response && response?.[0]?.data && response[0].data.responseStatus != null) {
            // console.log("response[0].data", response[0].data)
            fimenu.carFax = new FIMenuCarFax(response[0].data.fiMenuCarFax);
            //data.vehicleWarranty.updateUsingVINCarFaxLookup(vinInfo,fimenu.carFax);
            carFaxResponse.responseStatus = response[0].data.responseStatus;
            carFaxResponse.responseDescription = response[0].data.responseDescription;

            EventBus.emit('copiedCarFaxInfo', true);
            EventBus.emit('saveCarFaxResponse', carFaxResponse);
        } else {
            console.warn('response[0].data is empty');
            fimenu.carFax = new FIMenuCarFax({});
            EventBus.emit('copiedCarFaxInfo', false);
            EventBus.emit('saveCarFaxResponse', carFaxResponse);
        }
    }

    function carFaxOdometerWarning() {
        util.carFaxOdometerWarning(fimenu.carFax.lastOdometerReading, fimenu.vehicleMiles, settings.lookups.carFaxOdometerMaxOffset, $global.isAdminView, fimenu);
    }

    function checkForOtherDealsForVin() {
        if (!validation.vin.$invalid) {
            api.fimenu
                .getDealsByVIN(fimenu.vin)
                .then(response => {
                    if (response && response.data && response.data.length > 0) {
                        //exclude current deal from list
                        const currentdeal = fimenu.dealNumber;
                        const currentstore = fimenu.storeCode;
                        //load modal with deal grid to go to other deals.
                        let data = _.reject(response.data, function (d: any) {
                            return d.dealNumber === currentdeal && d.storeCode === currentstore;
                        });
                        const userStores = $global.stores.map((store: any) => store.storeCode);
                        const filteredData = data.filter((d: any) => {
                            return userStores.includes(d.storeCode);
                        });
                        data = filteredData;
                        if (data.length > 0) {
                            const title = 'Other Deals exist for this VIN';
                            const headers = [
                                {
                                    name: 'dealNumber',
                                    display: 'Deal #',
                                    sortable: true,
                                    autoFilter: true,
                                    actions: [
                                        {
                                            name: 'open',
                                            type: 'route',
                                            text: (row: any) => row.dealNumber,
                                            action: (row: any) => {
                                                ////go to that deal
                                                return { name: 'customer', query: { t: row.id, dk: row.dealKind } };
                                            },
                                            click: () => {
                                                // Close modal.
                                                $modal.closeAll();
                                            },
                                        },
                                    ],
                                },
                                { name: 'storeCode', display: 'Store', sortable: true, autoFilter: true },
                                { name: 'dealDate', display: 'Deal Date', sortable: true, autoFilter: true, filter: $filters.prettyDate },
                                { name: 'fullName', display: 'Customer', value: (a: any) => a.customer.fullName, sortable: true, autoFilter: true },
                            ];
                            $modal.open(modalRichTable, { name: 'modalRichTable', passedData: { title, data, headers }, backdrop: true });
                        }
                    }
                })
                .finally(() => {
                    //anything?
                });
        }
    }

    function processedNotSubmittedWarning(onContinue: any, onCancel: any) {
        // When processed VIN# != submitted VIN#, create a warning modal
        $modal.open(modalInfo, {
            name: 'modalInfo',
            passedData: {
                info: 'The VIN number you entered seems to be incorrect and is most likely a typo. Please confirm the VIN number from the actual vehicle dashboard/door jam and re-enter the VIN number.',
                acceptText: 'Proceed Anyways',
                cancelText: 'Re-Enter VIN',
                cancelFunction: onCancel,
            },
            backdrop: true,
            postFunction: onContinue,
        });
    }
</script>
<style>
    .loadingContainer {
        display: inline-flex;
        color: var(--main-color);
        font-size: larger;
    }

    .loJack-installed {
        margin-right: 10px;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 10px;
    }

    .loJack-installed .info-lojack {
        color: var(--print-color);
    }

    .loJack-installed button {
        height: 30px;
        border-radius: 4px;
        width: auto;
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .loJack-installed .ping {
        color: var(--print-color);
    }

    .loJack-installed.loJackError {
        font-weight: 900;
        color: var(--error-color);
    }

    .loJack-installed.success {
        color: var(--success-color);
    }

    @-webkit-keyframes errorBlink {
        from,
        to {
            border-color: transparent;
        }

        50% {
            border-color: var(--error-color);
        }
    }

    @keyframes errorBlink {
        from,
        to {
            border-color: transparent;
        }

        50% {
            border-color: var(--error-color);
        }
    }

    .errorBlink {
        border: 1px solid black;
    }

    .errorBlink {
        -webkit-animation: errorBlink 1s step-end infinite;
        animation: errorBlink 1s step-end infinite;
    }
    /*.vehicle-info-container{
        margin-bottom: unset !important;
    }*/
    .iconClass {
        position: relative;
    }

    .statusPulse {
        animation: loJackPulse 1.5s infinite;
        position: absolute;
        top: 50%;
        height: 22px;
        left: 50%;
        width: 22px;
        translate: -50% -50%;
        pointer-events: none;
    }

    :root {
        --lojackgood: #38ac2080;
        --lojackbad: #c11b1b80;
        --lojacknuetral: var(--black-50percent);
    }

    @keyframes loJackPulse {
        0% {
            border-radius: 50%;
            transform: scale(0.99);
            background: 0 0 0 0 var(--status);
        }

        70% {
            border-radius: 50%;
            transform: scale(1);
            box-shadow: 0 0 0 3px var(--status);
        }

        100% {
            border-radius: 50%;
            transform: scale(0.99);
            box-shadow: 0 0 0 0 var(--status);
        }
    }
</style>
