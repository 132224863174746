enum EnumWebSocketReadyStates {
    Connecting = 0,
    Open = 1,
    Closing = 2,
    Closed = 3,
}
interface WebSocketResponse {
    message: string;
    appVersion?: string;
    payload?: any;
    isError?: boolean,
}

type Listener = (message: WebSocketResponse) => void;

export default class SaturnDesktopController {
    private _url: string = "ws://localhost:9000/"
    private _appController: WebSocket;
    private _isReconnecting: boolean = false;
    private _reconnectAttempts: number = 0;
    private _maxReconnectAttempts: number = 5;
    private _listeners: Map<string, Listener[]> = new Map();
    private _onConnectedCallback: () => void


    constructor(onConnectedCallback: () => void) {
        this._appController = new WebSocket(this._url);
        this.setEventListeners();
        this._onConnectedCallback = onConnectedCallback
    }

    public setEventListeners(): void {
        this._appController.onopen = () => {
            console.log("[SaturnDesktopController] Connection established.");
            // this._isReconnecting = false;
            // this._reconnectAttempts = 0;
            this.notifyAll({ message: "connected" });
            this._onConnectedCallback()
        };

        this._appController.onmessage = (event: MessageEvent) => {
            const response: WebSocketResponse = JSON.parse(event.data);
            
            if (!(window as any).desktopAppVersion) {
                (window as any).desktopAppVersion = response.appVersion;
                console.log("desktopAppVersion", (window as any).desktopAppVersion);
            }

            if (response.isError) {
                console.error("[SaturnDesktopController] Error occurred:", response.message);
            }
            else {
                console.log("[SaturnDesktopController] Message received:", response.message);
            }
            this.notifyAll(response);
        };

        this._appController.onerror = (error) => {
            console.error("[SaturnDesktopController] Error occurred:", error);
            this.notifyAll({ message: "error", payload: error, isError: true });
        };

        this._appController.onclose = () => {
            console.warn("[SaturnDesktopController] Connection closed.");
            this.notifyAll({ message: "disconnected" });
            // this.attemptReconnect();
        };
    }

    public get isConnected(): boolean {
        return this._appController.readyState === EnumWebSocketReadyStates.Open;
    }

    public update(): void {
        this.send("update");
    }

    private send(message: string): void {
        if (!this.isConnected) return;

        this._appController.send(message);
    }

    // Attempt to reconnect with an exponential backoff strategy
    private attemptReconnect(): void {
        if (this._isReconnecting || this._reconnectAttempts >= this._maxReconnectAttempts) {
            console.warn("[SaturnDesktopController] Maximum reconnect attempts reached. Giving up.");
            return;
        }
        this._isReconnecting = true;
        this._reconnectAttempts += 1;
        const delay = Math.min(1000 * this._reconnectAttempts, 10000); // Exponential backoff up to 10 seconds
        console.log(`[SaturnDesktopController] Attempting to reconnect in ${delay}ms...`);

        setTimeout(() => {
            console.log("[SaturnDesktopController] Reconnecting...");
            this._appController = new WebSocket(this._url);
            this.setEventListeners();
        }, delay);
    }


    // Subscribe to specific WebSocket events
    public subscribe(eventType: string, listener: Listener): void {
        if (!this._listeners.has(eventType)) {
            this._listeners.set(eventType, []);
        }
        this._listeners.get(eventType)?.push(listener);
    }

    // Unsubscribe from a specific WebSocket event
    public unsubscribe(eventType: string, listener: Listener): void {
        if (this._listeners.has(eventType)) {
            const listeners = this._listeners.get(eventType);
            if (listeners) {
                this._listeners.set(eventType, listeners.filter(l => l !== listener));
            }
        }
    }

    // Notify all subscribed listeners for a specific event type
    private notifyAll(response: WebSocketResponse): void {
        const listeners = this._listeners.get(response.message) || [];
        for (const listener of listeners) {
            try {
                listener(response);
            } catch (error) {
                console.error("[SaturnDesktopController] Error notifying listener:", error);
            }
        }
    }


    // Gracefully close the WebSocket connection
    public closeConnection(): void {
        console.log("[SaturnDesktopController] Closing WebSocket connection...");
        this._appController.close();
        this.notifyAll({ message: "disconnected" });
    }

}