export default class CanvasRecordingConfig
{
    public stream?: MediaStream;
    public width: number;
    public height: number;
    public ctx: CanvasRenderingContext2D;

    constructor(partial?: Partial<CanvasRecordingConfig>)
    {
        if (partial)
        {
            Object.assign(this, partial);
        }
    }
}