<template>
    <div class="remote-stream">
        <video ref="remoteStream" autoplay playsinline :muted="meetingHelper.inStore" />
        <i v-show="isBusy || !streamReady" class="fas fa-spinner fa-pulse" />
        <div v-if="meetingHelper.inStore === false" class="video-chat-stream-overlay">
            <div id="user-name">{{ userName }}</div>
            <div class="mute-status">
                <i :class="['fas', peerDetails.isMuted ? 'fa-microphone-slash' : 'fa-microphone' ]"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import util, { EventBusCore } from '@core/services/util';
    export default {
        props: ["peer", "id", "userLabel"],
        data() {
            return {
                isBusy: true,
                streamReady: false,
            }
        },
        created() {
            this.peer.on("stream", stream => {
                this.isBusy = false;

                this.$nextTick(() => {
                    this.$refs.remoteStream.srcObject = stream;
                    this.$refs.remoteStream.addEventListener('loadedmetadata', () => {
                        this.streamReady = true;
                    });
                    EventBusCore.emit('setCustomersCameraStream', {stream, details: this.peerDetails})
                });

            })

            this.peer.on("connectionStateChange", (state) => {
                console.log('Receiver Connection State:', state);
                //peer.destroy();
            });
        },
        computed: {
            peerDetails() {
                return this.meetingHelper.currentMeetingUsers?.find(user => user.userId === this.id);
            },
            userName() {
                if(this.peerDetails) {
                    if(this.peerDetails.firstName !== '' || this.peerDetails.lastName !== '' ) {
                        return this.peerDetails.firstName + ' ' + this.peerDetails.lastName;
                    } else {
                        // Use fullName for companies
                        return this.peerDetails.fullName;
                    }
                } else {
                    return '';
                }
            },
        },

    }

</script>
<style>
    .remote-stream {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 2.5rem;
    }

    .remote-stream i {
        position: absolute;
        color: #cccccc;
    }

</style>