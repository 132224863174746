<template>
    <Panel v-bind="panelObject" class="PanelVehicleExtra">
        <template #header>
            <strong v-if="vinFailed" style="color: var(--error-color)">The VIN could not be processed</strong>
            <div style="display: inline-flex; " v-if="populatingVehicleDetails">
                <strong class="vin-lookup-flash" style="">Loading...</strong>
                <img class="loading-anim"
                     src="@static/img/SaturnLogoAnim.svg"
                     style="width: 40px; margin-right: 2%;" />
            </div>
        </template>
        <div ref="extraInfo">
            <div class="inputs-grid" :style="$grid('1-2-2')">
                <InputNumber label="Year"
                             class="glow"
                             placeholder="Year"
                             :thousandSeparator="''"
                             v-model:saturn="data.vehicle.modelYear"
                             :invalid="util.isNull(validation) ? false : usedValidation.modelYear.$invalid"
                             :maxlength="4"
                             :disabled="(disabled && !overrideVinDetails) || populatingVehicleDetails"
                             @change="yearChanged"
                             @blur="saveYearAsInt">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.modelYear.required.$invalid">
                            Year is required
                        </div>
                        <div v-else-if="!util.isNull(validation) && usedValidation.modelYear.validVehicleYear.$invalid">
                            Year is invalid
                        </div>
                    </template>
                </InputNumber>
                <InputTextbox label="Style"
                              v-model:saturn="data.vehicle.style"
                              :disabled="disabled"
                              placeholder="Style"
                              class="glow grid-3-column"
                              v-show="!showExtra">
                </InputTextbox>
                <InputRichDropdown v-if="showTextBox"
                                   v-model:saturn="data.vehicle.make"
                                   label="Make"
                                   :class="{ 'grid-2-column': !showExtra }"
                                   :invalid="util.isNull(validation) ? false : usedValidation.make.$invalid"
                                   :list="allMakes"
                                   :valueMap="(make) => make.display"
                                   :display="(make) => make.display"
                                   :disabled="disabled || populatingVehicleDetails"
                                   :customHandling="customHandling"
                                   search
                                   @change="makeChanged(true)">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.make.required.$invalid">Make is required</div>
                        <div v-else-if="!util.isNull(validation) && usedValidation.make.isValid.$invalid">{{ vehicleMakeError }}</div>
                    </template>
                </InputRichDropdown>
                <InputRichDropdown v-else-if="!isBusy"
                                   label="Make"
                                   v-model:saturn="data.vehicle.make"
                                   class="glow"
                                   :list="allMakes"
                                   :valueMap="(make) => make.display"
                                   :display="(make) => make.display"
                                   :invalid="util.isNull(validation) ? false : usedValidation.make.$invalid"
                                   :class="{ 'grid-2-column': !showExtra }"
                                   :disabled="(disabled && !releaseVinInfo) || populatingVehicleDetails"
                                   @change="makeChanged(false)">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.make.required.$invalid">Make is required</div>
                        <div v-else-if="!util.isNull(validation) && usedValidation.make.isValid.$invalid">{{ vehicleMakeError }}</div>
                    </template>
                </InputRichDropdown>
                <InputRichDropdown v-if="showTextBox"
                                   v-model:saturn="data.vehicle.model"
                                   label="Model"
                                   :class="{ 'grid-2-column': !showExtra }"
                                   :invalid="util.isNull(validation) ? false : usedValidation.model.$invalid"
                                   :list="models"
                                   :disabled="(disabled && !releaseVinInfo) || populatingVehicleDetails"
                                   :customHandling="customHandling"
                                   search
                                   @change="modelChanged">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.model.required.$invalid">Model is required</div>
                    </template>
                </InputRichDropdown>
                <InputRichDropdown v-else-if="!isBusy"
                                   label="Model"
                                   v-model:saturn="data.vehicle.model"
                                   :list="models"
                                   class="glow"
                                   :invalid="util.isNull(validation) ? false : usedValidation.model.$invalid"
                                   :class="{ 'grid-2-column': !showExtra }"
                                   :disabled="(disabled && !releaseVinInfo) || populatingVehicleDetails"
                                   @change="modelChanged">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.model.required.$invalid">Model is required</div>
                    </template>
                </InputRichDropdown>
                <InputTextbox label="Style"
                              v-model:saturn="data.vehicle.style"
                              :disabled="(disabled || !releaseVinInfo) && !overrideVinDetails"
                              placeholder="Style"
                              class="glow grid-2-column"
                              v-show="showExtra">
                </InputTextbox>
                <InputTextbox label="Exterior Color"
                              v-model:saturn="data.vehicle.exteriorColor"
                              placeholder="Exterior Color"
                              :invalid="util.isNull(validation) ? false : usedValidation.exteriorColor.$invalid"
                              v-if="showExtra">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && usedValidation.exteriorColor.required.$invalid">Exterior Color is required</div>
                    </template>
                </InputTextbox>
            </div>
            <template v-if="showExtra">
                <div :style="$grid('1-1-1-1-1')">
                    <InputRichDropdown label="Vehicle Type"
                                       v-model:saturn="data.vehicle.primaryBodyType"
                                       :list="ENUMS.bodyTypes"
                                       class="glow"
                                       :valueMap="(type) => type.code"
                                       :display="(type) => type.description"
                                       :invalid="util.isNull(validation) ? false : usedValidation.primaryBodyType.$invalid"
                                       nothingSelected="-- Select Value --"
                                       search>
                        <template #validation>
                            <div v-if="!util.isNull(validation) && usedValidation.primaryBodyType.required.$invalid">
                                Vehicle Type is required
                            </div>
                        </template>
                    </InputRichDropdown>
                    <InputRichDropdown label="Fuel Type"
                                       v-model:saturn="data.vehicle.fuelType"
                                       :list="ENUMS.fuelTypes"
                                       class="glow"
                                       :valueMap="(oil) => oil.code"
                                       :display="(oil) => oil.description"
                                       :invalid="util.isNull(validation) ? false : usedValidation.fuelType.$invalid"
                                       nothingSelected="-- Select Value --"
                                       search>
                        <template #validation>
                            <div v-if="!util.isNull(validation) && usedValidation.fuelType.required.$invalid">
                                Fuel Type is required
                            </div>
                        </template>
                    </InputRichDropdown>
                    <InputRichDropdown label="Oil Type"
                                       nothingSelected="-- Select Value --"
                                       v-model:saturn="data.vehicleOil"
                                       :list="oilTypes"
                                       :valueMap="(o) => o.value"
                                       :display="(o) => o.display"
                                       :invalid="util.isNull(usedExtraValidation) ? false : usedExtraValidation.vehicleOil.$invalid"
                                       :disabled="data.vehicle.fuelType == ENUMS.fuelTypes.find(ft=>ft.description == 'Electric').code"
                                       @change="oilTypeChangeHandle"
                                       search>
                        <template #validation>
                            <div v-if="!util.isNull(usedExtraValidation) && usedExtraValidation.vehicleOil.required.$invalid">
                                Oil Type is required
                            </div>
                        </template>
                    </InputRichDropdown>
                    <InputCurrency  v-if="!data.buyersOrderEnabled && msrp"
                                    :label="data.inventoryType == 'New' ? 'MSRP' : 'Retail'"
                                   v-model:saturn="msrp.retail"
                                   class="grid-2-column"
                                   :placeholder="data.inventoryType == 'New' ? 'MSRP' : 'Retail'"
                                   :invalid="util.isNull(validation) ? false : usedExtraValidation.vehicle.bookValues[ManufacturerIndex]?.retail.$invalid"
                                   iconSide="left"
                                   @blur="(e) => checkReprice(e.target.value, setWearPricing)">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && usedExtraValidation.vehicle.bookValues[ManufacturerIndex]?.retail.required.$invalid">
                                {{ data.inventoryType == 'New' ? 'MSRP' : 'Retail' }} is required
                            </div>
                            <div v-else>
                                {{ data.inventoryType == 'New' ? 'MSRP' : 'Retail' }}  Is in valid
                            </div>
                        </template>
                    </InputCurrency>

                    <!-- FIXME -->
                    <!-- Add proper grid and remove placeholder div. -->
                    <div v-else  class="grid-2-column"/>

                    <InputTextbox label="Title Number"
                                  v-model:saturn="data.vehicle.titleNumber"
                                  placeholder="Title Number"
                                  v-if="showExtra">
                    </InputTextbox>
                    <InputRichDropdown label="Title State"
                                       class="grid-2-column"
                                       v-model:saturn="data.vehicle.titleState"
                                       :list="ENUMS.states"
                                       :valueMap="(p) => p.code"
                                       :display="(p) => p.description"
                                       v-if="showExtra"
                                       search>
                    </InputRichDropdown>
                    <div v-if="showExtra"
                         class="radio-button-inline no-padding-grid grid-2-column">
                        <span>Electronic Title?</span>
                        <div class="no-padding-grid gap">
                            <InputRadio label="Yes"
                                        :valueMatch="true"
                                        v-model="data.vehicle.isElectronicTitle"
                                        :isButton="true"></InputRadio>
                            <InputRadio label="No"
                                        :valueMatch="false"
                                        v-model="data.vehicle.isElectronicTitle"
                                        :isButton="true"></InputRadio>
                        </div>
                    </div>

                    <InputRichDropdown label="Mileage Status"
                                       v-model:saturn="data.vehicle.mileageStatus"
                                       :list="ENUMS.VEHICLE_MILEAGE_STATUS.toList()"
                                       class="glow grid-3-column"
                                       :valueMap="(status) => status.value"
                                       :display="(status) => status.display"
                                       :invalid="util.isNull(validation) ? false : usedValidation.mileageStatus.$invalid"
                                       nothingSelected="-- Select Value --">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && usedValidation.mileageStatus.required.$invalid">
                                Mileage Status is required
                            </div>
                        </template>
                    </InputRichDropdown>

                    <div class="radio-button-inline no-padding-grid grid-2-column"
                         :invalid="util.isNull(validation) ? false : usedValidation.hasFiveDigitOdometer.$invalid">
                        <span>Five Digit Odometer?</span>
                        <div class="no-padding-grid gap">
                            <InputRadio label="Yes"
                                        :valueMatch="true"
                                        v-model="data.vehicle.hasFiveDigitOdometer"
                                        :isButton="true"></InputRadio>
                            <InputRadio label="No"
                                        :valueMatch="false"
                                        v-model="data.vehicle.hasFiveDigitOdometer"
                                        :isButton="true"></InputRadio>
                        </div>
                    </div>
                </div>
                <div :style="$grid('4-4-4')">
                    <InputTextbox v-for="info in ENUMS.otherVehicleInfo"
                                  :key="info.key"
                                  :label="info.display"
                                  v-model:saturn="data.vehicle.otherVehicleInfo[info.key]"
                                  :invalid="util.isNull(validation) ? false : !util.isNull(usedExtraValidation.vehicle.otherVehicleInfo[info.key]) ? usedExtraValidation.vehicle.otherVehicleInfo[info.key].$invalid : false"
                                  :placeholder="info.display"
                                  @change="(value) => validateIfIsNull(info.key, value)"
                                  class="glow">
                        <template #validation>
                            <div v-if="!util.isNull(validation) && !util.isNull(usedExtraValidation.vehicle.otherVehicleInfo[info.key]) && !usedExtraValidation.vehicle.otherVehicleInfo[info.key].required">
                                Value Required
                            </div>
                            <div v-else-if="!util.isNull(validation) && !util.isNull(usedExtraValidation.vehicle.otherVehicleInfo[info.key]) && !usedExtraValidation.vehicle.otherVehicleInfo[info.key].numeric">
                                Field must be a number
                            </div>
                        </template>
                    </InputTextbox>
                    <InputTextbox label="Drive Train"
                                  v-model:saturn="DriveTrain[data.vehicle.driveTrain]"
                                  placeholder="Drive Train"
                                  @change="(value) => validateIfIsNull(data.vehicle.driveTrain, value)"
                                  class="glow">
                    </InputTextbox>
                </div>
            </template>
        </div>
    </Panel>
</template>

<script>
    import { BookValue, DriveTrain } from '@core/classes/SharedEnums'
    import DropdownHelper from '@core/helpers/dropdown-helper'
    import ENUMS from "@core/classes/Enums"
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import Panel from '@core/components/Panel.vue'
    import util from '@core/services/util'

    export default {
        name: "PanelVehicleExtra",
        emits:['change'],
        props: [
            'data',
            'panelObject',
            'validation',
            'validationExtra',
            'showExtra',
            "copiedVehicleExtraInfo",
            "oilTypeChangeAfterPricing",
            "oilTypeNotAvailable",
            'setWearPricing',
            "customVin",
            "refreshCache",
            "populatingVehicleDetails",
            "swapDropdown",
            "classOverride",
            "allowVinEdit",
            "disabled",
            "vinFailed",
            "overrideVinDetails",
        ],
        data() {
            return {
                isBusy: false,
                oilTypeActual: null,
                oilTypes: [],
                test: {
                    vt: 'SUV',
                    ft: 'Gas',
                    ln: '',
                    lna: 1000,
                    wr: 11,
                },
                previousMSRP: this.data.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? this.data.getMSRP(),
                allMakes: [],
                allModels: [],
                releaseVinInfo: false,
                DriveTrain
            }
        },
        computed: {
            util() {
                return util
            },
            ENUMS() {
                return ENUMS
            },
            usedValidation() {
                return this.showExtra ? this.validation.vehicle : this.validation
            },
            usedExtraValidation() {
                return this.showExtra ? this.validation : null
            },
            models() {
                return [...new Set(
                    this.allModels
                        .filter(m => m.value.saturnBrandId == this.data.vehicle.makeId)
                        .map(m => m.display)
                )];
            },
            ignoreValidLength() {
                return this.data.vin ? true : false;
            },
            vehicleMakeError() {
                const message = 'Store does not sell this make';
                if (!this.data.inventoryType) {
                    return "You must specify if this vehicle is New/Used/CPO";
                }
                if (this.data.inventoryType == 'Used' && this.data.vehicleWarranty.isCertified) {
                    return `${message} as CPO`;
                }
                else {
                    return `${message} as ${this.data.inventoryType}`;
                }
            },
            showTextBox() {
                if (this.overrideVinDetails) {
                    return true;
                }
                return this.swapDropdown ?? false
            },
            msrp() {
                if(!this.data.buyersOrderEnabled)
                    return this.data.vehicle.getOrCreateManufacturer()
                else
                    return null;
            },
            ManufacturerIndex() {
                return this.data.vehicle.bookValues.findIndex(x => x.source == BookValue.Manufacturer);
            }
        },
        async created() {
            this.isBusy = true

            this.getMakesAndModels();

            this.isBusy = false;

            this.$watch(() => this.data.vehicle.fuelType, () => {
                this.data.vehicleOil = null
                if (this.data.vehicle.fuelType == ENUMS.fuelTypes.find(ft => ft.description == "Electric").code) {
                    this.data.vehicleOil = ENUMS.OIL_TYPES.NA;
                }
                this.setOilTypeList();
            })

            this.$watch(() => this.data.vehicle.modelYear, () => {
                this.yearChanged();
            })

            if (util.isNull(this.data.vehicle.hasFiveDigitOdometer) && this.data.vehicle.modelYear >= 2000) {
                this.data.vehicle.hasFiveDigitOdometer = false
            }

            this.oilTypeActual = this.data.vehicleOil
            this.setOilTypeList()
            if (util.isNull(this.data.vehicle.isElectronicTitle)) {
                this.data.vehicle.isElectronicTitle = true
            }

        },

        watch: {
            refreshCache() {
                if (this.refreshCache === true) {
                    this.getMakesAndModels(true);
                }
            }
        },
        methods: {
            customHandling(value) {
                return {
                    display: value,
                    obj: {
                        brandId: -1,
                        brandName: value,
                        saturnBrandId: null
                    }
                }
            },
            yearChanged() {
                if (this.data.vehicle.modelYear < 2000) {
                    this.data.vehicle.hasFiveDigitOdometer = null
                } else {
                    this.data.vehicle.hasFiveDigitOdometer = false
                }
            },
            makeChanged(clearModel = false) {
                if (clearModel) {
                    this.data.vehicle.model = null
                    this.data.vehicle.modelId = null
                }

                if (this.data.vehicle.make && this.allMakes.length > 0) {
                    this.data.vehicle.makeId = this.allMakes.find(
                        (m) => m.display.toLowerCase() == this.data.vehicle.make.toLowerCase()
                    )?.value.saturnBrandId
                } else {
                    this.data.vehicle.makeId = null
                }
            },
            validateIfIsNull(property, value) {
                if (util.isNullOrEmpty(value, true)) {
                    delete this.data.vehicle.otherVehicleInfo[property]
                }
            },
            modelChanged() {
                if (this.data.vehicle.model) {
                    this.data.vehicle.modelId = this.allModels.find(
                        (m) => m.display == this.data.vehicle.model
                    )?.value.modelId
                } else {
                    this.data.vehicle.modelId = null
                }
            },
            saveYearAsInt(e) {
                if (!e.target.value) this.data.vehicle.modelYear = null
                else this.data.vehicle.modelYear = parseInt(e.target.value)
            },
            oilTypeChangeHandle() {
                if (this.oilTypeActual != this.data.vehicleOil) {
                    this.oilTypeActual = this.data.vehicleOil
                    this.oilTypeChangeAfterPricing()
                }
            },
            setOilTypeList() {
                if (this.data.vehicle.fuelType == this.ENUMS.fuelTypes.find(ft => ft.description == "Diesel").code) {
                    this.oilTypes = ENUMS.oilTypes.map(o => ({ ...o, disabled: o.value !== ENUMS.OIL_TYPES.DIESEL }))
                }
                else {
                    this.oilTypes = ENUMS.oilTypes.map(o => ({ ...o, disabled: o.value === ENUMS.OIL_TYPES.NA }))
                }
            },
            checkReprice(newValue, repriceFunc) {
                if (newValue != null && newValue != '' && this.previousMSRP != parseInt(newValue)) {
                    this.previousMSRP = newValue
                    repriceFunc()
                }
            },
            clearField() {
                this.releaseVinInfo = true;
                this.data.vehicle.make = null;
                this.data.vehicle.model = null;
                this.data.vehicle.modelYear = null;
                this.data.vehicle.style = null;
            },
            getMakesAndModels(forceRefresh = false) {
                DropdownHelper.getBrands(forceRefresh).then(response => {
                    this.allMakes = response
                    if (!this.data.vehicle.makeId) {
                        this.makeChanged();
                    }
                });
                DropdownHelper.getModels(forceRefresh).then(response => { this.allModels = response });
            }
        },
        components: {
            Panel,
            InputTextbox,
            InputRichDropdown,
            InputCurrency,
            InputNumber,
            InputRadio,
        },
    }
</script>

<style scoped>
    .PanelVehicleExtra .radio-button-inline {
        width: 80%;
        justify-content: space-between;
    }

    .vin-lookup-flash {
        animation: blinking 1s infinite;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 2px;
        color: var(--main-color);
        font-size: larger;
    }

    .flash-green {
        background: var(--success-color);
        animation: blinking 1s infinite;
    }
</style>