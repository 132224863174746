<template>
    <div :class="{'fimenu-customer-meetings fade-in': true, 'hidden': !isSideMenuVisible}">
        <button v-if="!meetingConnected" 
            :class="{
                'toggle-customer-meetings': true, 
                'hint-click': !isSideMenuVisible,
                'waiting': customerWaiting
            }" 
            @click="toggleSideMenuView"
        >
            <i class="fas fa-angle-double-right" />
        </button>
        
        <div class="signalr-notes">
            <PanelCustomerVideoChat :fimenu="fimenu"
                                    :customerScreenActions="customerScreenActions"
                                    :paymentSheetDisabled="paymentSheetDisabled"
                                    :peopleInLobby="peopleInLobby"
            />

            <PanelCustomerLive :isSideMenuVisible="isSideMenuVisible" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { broadcastCallbacks, broadcastSubscribeEvents, broadcastUnsubscribeEvents } from '@core/helpers/broadcast-helper';
    import { EVENT_EMITS } from '@core/classes/Enums';
    import FIMenu from '@core/classes/FIMenu';
    import PanelCustomerLive from '@/components/PanelCustomerLive.vue'
    import PanelCustomerVideoChat from '@/components/PanelCustomerVideoChat.vue';
    import { useVuelidate } from '@vuelidate/core';
    import util, { EventBusCore } from '@core/services/util';
    import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';
    import { Emitter } from 'mitt';

    interface FIMenuCustomerMeetingsProps {
        fimenu: FIMenu,
        customerScreenActions: object,   // Expects: {openCustomerPopup: Function, openPrintPaymentSheetModal: Function, openPaperworkErrorsModal: Function}
        paymentSheetDisabled: boolean,
    }

    const { fimenu, customerScreenActions, paymentSheetDisabled} = defineProps<FIMenuCustomerMeetingsProps>();

    const $meetingHub = inject<any>('$meetingHub');
    const meetingHelper = inject<any>("meetingHelper");
    const EventBus = inject<Emitter<any>>("EventBus");
    

    // Variables
    const isSideMenuVisible = ref(false)
    const customerWaiting = ref(false)
    const meetingsWithWaitingCustomers = ref([])
    const broadcastEvents = ref([{
            event: EVENT_EMITS.BROADCAST.CUSTOMER_JOINED_MEETING,
            callback: (data: string) => broadcastCustomerJoinedMeetingHandler(data)
        },
        {
            event: EVENT_EMITS.BROADCAST.CUSTOMER_LEFT_MEETING,
            callback: (data: string) => broadcastCustomerLeftMeetingHandler(data)
        },
        {
            event: EVENT_EMITS.BROADCAST.CUSTOMER_WAITING_MEETING,
            callback: (data: string) => broadcastCustomerWaitingMeetingHandler(data)
        }
    ])
       
    // Computed Properties
    
    const meetingConnected = computed(() => {
        return $meetingHub.code
    })
    const peopleInLobby = computed(() => {
        const myMeeting = meetingsWithWaitingCustomers.value?.find(meeting => meeting.meetingCode === fimenu.dealNumber + '-' + fimenu.storeCode)
        if (myMeeting) {
            return Object.values(myMeeting.users).filter((user: any) => (user.role === "customer" || user.role === "coCustomer") && user.isUserWaiting)
        }
        return [];
    })

    // Events

    onMounted(() => {
        meetingHelper.dealNumber = fimenu.dealNumber;
        meetingHelper.storeCode = fimenu.store.storeCode;
        isSideMenuVisible.value = meetingConnected.value
        subscribeToBroadcastEvents()
        EventBus.on('BroadcastReconnect', broadcastReconnectEmitterHandler);  
    })
    
    onBeforeUnmount(() => {
        unsubscribeToBroadcastEvents()
        EventBus.off('BroadcastReconnect', broadcastReconnectEmitterHandler);
    })

    // Functions
    const broadcastReconnectEmitterHandler = () => {
        subscribeToBroadcastEvents()
    }
    

    const toggleSideMenuView = () => {
        if(!meetingConnected.value) {
            isSideMenuVisible.value = !isSideMenuVisible.value;
        }
    }

    const broadcastCustomerJoinedMeetingHandler =(data: string) => {
        const meetingDetails = JSON.parse(data)
        const dealNumber = meetingDetails.meetingCode.split('-')[0];
        const isInStore = Object.values(meetingDetails.users).some((user: any) => user.isInStoreConnection === true);
        if(!isInStore && fimenu?.dealNumber?.toString() === dealNumber.toString() && !fimenu.isSpectator) {
            const userConnectedData = Object.values(meetingDetails.users)[0]
            broadcastCallbacks.openModalBroadcastMessages(JSON.stringify({
                messageTitle: 'Meeting Notification', 
                messageBody: `The customer ${(userConnectedData as any).fullName} is connected and ready to join the meeting.`
            }))

            meetingsWithWaitingCustomers.value = [meetingDetails]
            customerWaiting.value = true
        }
    }

    const broadcastCustomerLeftMeetingHandler = (data: string) => {
        const parsedData = JSON.parse(data)
        const dealNumber = parsedData.meetingDetails.meetingCode.split('-')[0]
        if(fimenu?.dealNumber?.toString() === dealNumber.toString()  && !fimenu.isSpectator) {
            
            delete meetingsWithWaitingCustomers.value[0].users[parsedData.userDetails.userId]

            const justFIManager = Object.keys(meetingsWithWaitingCustomers.value[0].users).every(userKey => meetingsWithWaitingCustomers.value[0].users[userKey].role == 'fimanager')
            if(!Object.keys(meetingsWithWaitingCustomers.value[0].users).length || justFIManager) {
                meetingsWithWaitingCustomers.value = []
                customerWaiting.value = false
            }
        }
        
    }

    const broadcastCustomerWaitingMeetingHandler = (data: string) => {
        const {currentMeetingDetails, myUserDetails} = JSON.parse(data) 
        if(currentMeetingDetails && myUserDetails) {
            const dealNumber = currentMeetingDetails.meetingCode.split('-')[0]
            if(fimenu && fimenu?.dealNumber?.toString() === dealNumber.toString() && !fimenu.isSpectator) {

                let customerInfo = Object.values(currentMeetingDetails.users).find((u: any) => u.role == myUserDetails.role)
                util.toastr('warn', 'Customer Waiting', 
                    `Customer ${(customerInfo as any).fullName} is connected and ready to join the meeting.`
                );
                meetingsWithWaitingCustomers.value = [currentMeetingDetails]
                customerWaiting.value = true
            }
        }
    }

    const unsubscribeToBroadcastEvents = () => {
        broadcastUnsubscribeEvents($meetingHub, broadcastEvents.value)
    }

    const subscribeToBroadcastEvents = () => {
        $meetingHub.connect()
        broadcastSubscribeEvents($meetingHub, broadcastEvents.value)
    }

</script>

<style scoped>
    @import url("FIMenuCustomerMeetings.css");
</style>