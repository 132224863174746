interface IVideoRecordingInfo {
    id: string;
    fimenuId: string;
    fimenuDealNumber: string;
    fiManagerName: string;
    fiManagerId: string;
    storeCode: string;
    storeName: string;
    startTimestamp: number;
    endTimestamp: number;
    VideoSourcesConfig: VideoSourcesConfig[]
}
export class VideoRecordingsInfo implements IVideoRecordingInfo
{
    id: string = null;
    fimenuId: string = null;
    fimenuDealNumber: string = null;
    fiManagerName: string = null;
    fiManagerId: string = null;
    storeCode: string = null;
    storeName: string = null;
    videoFormat: string = ".webm"
    startTimestamp: number = null;
    endTimestamp: number = null;
    VideoSourcesConfig: VideoSourcesConfig[];

    updateVideoInfo(init: IVideoRecordingInfo) 
    {
        if (init) { Object.assign(this, init); }
    }
}

class VideoSourcesConfig
{
    sourceType: number = null;
    dimensionWidth: number = null;
    dimensionHeight: number = null;
    positionTop: number = null;
    positionLeft: number = null;
}