import { App, reactive } from "vue";
import { broadcastEmitEvent, broadcastUnsubscribeAllEvents } from "@core/helpers/broadcast-helper";
import ENUMS, { CUSTOMER_VIEWS } from "@core/classes/Enums";
import api from "@core/services/api";
import auth from "@core/services/auth";
import FIMenu from "@core/classes/FIMenu";
import FIMenuCustomerViewed from "@core/classes/FIMenuCustomerViewed";
import LogRocket from 'logrocket';
import MeetingInfo from "@core/classes/MeetingInfo";
import moment from "moment";
import util from "@core/services/util";

// Create a Vue instance, so we can access meetingHubP
// access meetingHub with globalProperties.$meetingHub
export default {
    install(app: App) {
        const { globalProperties } = app.config;

        function encodeMeetingCode(dealNumber: string, storeCode: string) {
            const dif = dealNumber.length - storeCode.length;
            let output = "";
            const tempStoreCode = storeCode.split("");
            dealNumber.split("").forEach((c, index) => {
                output +=
                    c +
                    (tempStoreCode[ index ]
                        ? tempStoreCode[ index ]
                        : String.fromCharCode(Math.floor(Math.random() * 26) + 97).toUpperCase());
            });
            return (output + dif).toUpperCase();
        }

        function decodeMeetingCode(code: string) {
            let dealNumber = "";
            let storeCode = "";
            code.split("").forEach((c, index) => {
                if (index % 2) {
                    //this will trigger if odd
                    storeCode += c;
                } else {
                    dealNumber += c;
                }
            });
            const dif = parseInt(dealNumber) % 10;
            const newDealNumber = dealNumber.slice(0, dealNumber.length - 1);
            storeCode = storeCode.substring(0, storeCode.length - dif);
            return newDealNumber + "-" + storeCode;
        }

        function initiateMeeting(dealNumber: string, storeCode: string) {
            meetingHelper.dealNumber = dealNumber;
            meetingHelper.storeCode = storeCode;

            // CREATE ENCODED MEETING ROOM CODE
            meetingHelper.meetingCodeEncoded = encodeMeetingCode(dealNumber, storeCode);

            // GET MEETINGS FROM DATABASE
            //    api.meeting.getDealMeetings(storeCode, dealNumber).then((response) => {
            //        // store previous meetings in helper until we create a meeting
            //        meetingHelper.meetings = response.data.reverse()
            //    }).catch((err) => {
            //        console.log(err)
            //    })

            // GET EMPLOYEE DATA
            const payload = auth.getTokenPayload();
            meetingHelper.employeeId = payload.id;
            meetingHelper.employeeCode = payload.EmployeeCode;
        }

        function createMeeting(dealNumber: string, storeCode: string, fimenu: FIMenu) {
            LogRocket.log("--------------- createMeeting -------------", dealNumber, storeCode, fimenu);
            const start = moment();

            const meetingObject = {
                dealId: fimenu.id,
                employeeId: meetingHelper.employeeId,
                employeeCode: meetingHelper.employeeCode,
                inStore: meetingHelper.inStore,
                language: fimenu.language,
                dealNumber: dealNumber,
                storeCode: storeCode,
                meetingCode: decodeMeetingCode(meetingHelper.meetingCodeEncoded),
                //verificationCode: meetingHelper.createNumberCode(6),
                validAfter: start,
                validUntil: util.toMoment(start).add(20, "seconds"),
            };

            // Create a new meetingInfo object
            meetingHelper.meetingInfo = new MeetingInfo(meetingObject);

            // Assign previous meetings to our current meetingInfo object
            //    meetingHelper.meetingInfo.meetings = meetingHelper.meetings;

            LogRocket.log("meetingInfo", meetingHelper.meetingInfo);

            return api.meeting.createMeeting(meetingHelper.meetingInfo);
        }

        // async function customerGetMeetingByCode() {
        //     console.log('!!! customerGetMeetingByCode', meetingHelper.meetingCodeEncoded);
        //     try {
        //         const response = await api.meeting.getMeetingByCode(decodeMeetingCode(meetingHelper.meetingCodeEncoded))

        //         // Populate meetingHelper
        //         meetingHelper.storeCode = response.data[0].storeCode;
        //         meetingHelper.dealNumber = response.data[0].dealNumber;
        //         meetingHelper.employeeId = response.data[0].employeeId;
        //         meetingHelper.employeeCode = response.data[0].employeeCode;
        //         meetingHelper.inStore = response.data[0].inStore;

        //         // Populate meetingInfo
        //         meetingHelper.meetingInfo = new MeetingInfo({
        //             id: response.data[0].id,
        //             dealId: response.data[0].dealId,
        //             employeeId: response.data[0].employeeId,
        //             employeeCode: response.data[0].employeeCode,
        //             inStore: response.data[0].inStore,
        //             language: response.data[0].language,
        //             dealNumber: response.data[0].dealNumber,
        //             storeCode: response.data[0].storeCode,
        //             meetingCode: response.data[0].meetingCode,
        //             //verificationCode: meetingHelper.createNumberCode(6),
        //             validAfter: response.data[0].validAfter,
        //             validUntil: response.data[0].validUntil,
        //             startedAt: response.data[0].startedAt,
        //             finishedAt: response.data[0].finishedAt,
        //             meetingLogs: response.data[0].meetingLogs

        //         });

        //     } catch (e) {
        //         console.log("error - customerGetMeetingByCode", e);
        //     }

        // }

        // async function checkIfMeetingStillActive() {
        //     let decodedMeetingCode = meetingHelper.decodeMeetingCode(meetingHelper.meetingCodeEncoded)
        //     console.log("checkIfMeetingStillActive", decodedMeetingCode)
        //     globalProperties.$meetingHub.startConnection(decodedMeetingCode);
        //     await globalProperties.$meetingHub.checkIfMeetingStillActive(decodedMeetingCode);
        // }

        // function checkIfMeetingStillActiveResponse(isMeetingStillActive) {
        //     console.log('-- MEETING HELPER >> isMeetingStillActive', isMeetingStillActive)
        //     meetingHelper.isMeetingStillActive = isMeetingStillActive;
        // }

        async function startRemoteMeeting(fimenu: FIMenu) {
            LogRocket.log(`-------------- startRemoteMeeting ----------------`);

            meetingHelper.inStore = false;
            meetingHelper.myRole = "fimanager";

            const payload = auth.getTokenPayload();

            meetingHelper.fullName = util.toProperCase(payload.EmployeeName.split(", ").reverse().join(" "));
            [ meetingHelper.firstName, meetingHelper.lastName ] = meetingHelper.fullName.split(" ");

            meetingHelper.initiateMeeting(meetingHelper.dealNumber, meetingHelper.storeCode);

            //CONNECT
            if (meetingHelper.currentMeetingUsers.length < 1) {
                try {
                    const response = await meetingHelper.createMeeting(meetingHelper.dealNumber, meetingHelper.storeCode, fimenu);
                    meetingHelper.meetingInfo.id = response.data.id;
                    meetingHelper.currentMeeting = response.data;
                    meetingHelper.currentMeeting.startedAt = moment();

                    await globalProperties.$meetingHub.startConnection(meetingHelper.dealNumber + "-" + meetingHelper.storeCode);

                    globalProperties.$meetingHub.connected = true;

                    globalProperties.$meetingHub.joinMeetingRoom(
                        "fimanager",
                        meetingHelper.inStore,
                        fimenu.id,
                        meetingHelper.firstName,
                        meetingHelper.lastName,
                        meetingHelper.fullName
                    );

                    // meetingHelper.setCurrentMeeting(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        /**
         * @returns Boolean to see if you are currently connected to a meeting.
         */
        function meetingCurrentlyOpen() {
            return globalProperties.$meetingHub.connected;
        }

        async function allowUserIntoMeeting(fimenu: FIMenu, userToAllow: any) {
            // If we are not already in the meeting, join the meeting
            if (!globalProperties.$meetingHub.code) {
                await meetingHelper.startRemoteMeeting(fimenu);
            }
            globalProperties.$meetingHub.allowUserIntoMeeting(userToAllow);
        }

        // function setCurrentMeeting(meetingData) {
        //     console.log('setCurrentMeeting', meetingData);
        //     // meetingInfo was initialized in createMeeting, now we set the id that was provided by back-end as well as the meetingLogs
        //     meetingHelper.meetingInfo.id = meetingData.id;
        //     meetingHelper.meetingInfo.meetingLogs = meetingData.meetingLogs;

        //     // Move current meeting to beginning of meetings in meetingInfo
        //     meetingHelper.meetingInfo.meetings.unshift(meetingData);

        //     meetingHelper.currentMeeting = meetingHelper.meetingInfo.meetings[0];

        // }

        async function sendLinkToMeetingRoomParticipant(meetingInvitation: any, fimenu: FIMenu) {
            const failMessage = "Failed to send message";

            meetingHelper.meetingCodeEncoded = meetingHelper.encodeMeetingCode(meetingHelper.dealNumber, meetingHelper.storeCode);

            const sendMethod = meetingInvitation.sendEmailOrText;
            const who: keyof FIMenu = meetingInvitation.who;

            const participantEmail = fimenu[ who ].email;
            const participantCell = fimenu[ who ].cell;

            let participantFirstName = '';
            let participantLastName = '';
            const participantFullName = util.toProperCase(fimenu[ who ].fullName);

            const dealershipName = fimenu.store.storeName;
            const fimanagerName = util.toProperCase(fimenu.fiManagerName.split(", ").reverse().join(" "));

            if (!fimenu[ who ].isEntity) {
                // If customer is not a business, set the first and last name
                participantFirstName = util.toProperCase(fimenu[ who ].firstName);
                participantLastName = util.toProperCase(fimenu[ who ].lastName);
            }

            const meetingLinkInfo = {
                meetingCode: meetingHelper.meetingCodeEncoded,
                participant: meetingInvitation.who,
                firstName: participantFirstName,
                lastName: participantLastName,
                fullName: participantFullName
            };

            console.log("meetingLinkInfo", meetingLinkInfo);
            const encodedLinkInfo = util.encryptJSONText(JSON.stringify(meetingLinkInfo));

            LogRocket.log("encodedLinkInfo ", encodedLinkInfo);

            let sendEmailLinkRequest = null;
            let sendTextMsgLinkRequest = null;

            // Setting up Email To Send
            const emailSubject =
                fimenu.language == "Spanish"
                    ? `Unirse a la reunión`
                    : `Your Meeting Invitation: ${participantFullName} at ${dealershipName}`;

            const emailBody =
                fimenu.language == "Spanish"
                    ? `<p>Siga el enlace para unirse a la reunión: </p><a href=insertLinkHere>Unirse a la reunión</a>`
                    : `<p>Hello ${participantFirstName ? participantFirstName : participantFullName},</p>
            <p>We're thrilled to extend an invitation for an exclusive online meeting with our Finance and Insurance Manager at ${dealershipName}! This personalized session is tailored to address your unique car needs and provide expert guidance on financing options and insurance coverage.</p>
            <p>We're committed to making your car buying journey seamless and enjoyable, and this meeting is another step towards ensuring you have all the information you need to make informed decisions.</p>
            <p>Please follow the link to join meeting: </p>
            <a href=insertLinkHere>Join Meeting</a>
            <br>
            <p>Best regards,<br>
            ${fimanagerName}<br>
            ${dealershipName}</p>`;

            sendEmailLinkRequest = {
                sendToList: [ participantEmail ],
                isBodyHtml: true,
                encodedLinkInfo: encodedLinkInfo,
                emailSubject: emailSubject,
                emailBody: emailBody,
            };

            // Setting up Text To Send
            const textBody =
                fimenu.language == "Spanish"
                    ? "Siga el enlace para unirse a la reunión: insertLinkHere"
                    : `Hello ${participantFirstName ? participantFirstName : participantFullName}, your meeting with ${fimanagerName} is about to begin. Please click the link to start your meeting: \n insertLinkHere`;

            sendTextMsgLinkRequest = {
                toPhoneNumber: "+1" + participantCell,
                body: textBody,
                encodedLinkInfo: encodedLinkInfo,
                storeCode: fimenu.storeCode,
            };
            console.log(`sendEmailLinkRequest ${sendEmailLinkRequest.encodedLinkInfo}`);
            console.log(`sendTextMsgLinkRequest ${sendTextMsgLinkRequest.encodedLinkInfo}`);

            if (sendMethod === "Email" || sendMethod === "Email and Text") {
                //SENDS THE EMAIL INVITATION
                try {
                    const emailResponse = await api.meeting.sendEmailInvitationLink(sendEmailLinkRequest);

                    if (emailResponse && emailResponse.data) {
                        util.toastr("success", "Success", emailResponse.data);
                    } else {
                        util.toastr("error", "Error", failMessage);
                    }
                } catch (error: any) {
                    util.toastr('error', 'Error', error.data ?? failMessage);
                }
            }

            if (sendMethod === "Text" || sendMethod === "Email and Text") {
                //SENDS THE TEXT INVITATION
                try {
                    const textResponse = await api.meeting.sendTextMessageInvitationLink(sendTextMsgLinkRequest);

                    if (textResponse && textResponse.data) {
                        util.toastr("success", "Success", textResponse.data);
                    }
                } catch (error: any) {
                    util.toastr('error', 'Error', error.data ?? failMessage);
                }
            }
        }

        function startInStoreMeeting(inStoreConnectionCode: string) {
            meetingHelper.inStore = true;
            meetingHelper.preConnectionFound = false;
            meetingHelper.initiateMeeting(meetingHelper.dealNumber, meetingHelper.storeCode);

            globalProperties.$meetingHub
                .startConnection(inStoreConnectionCode)
                .then(() => {
                    setTimeout(() => {
                        if (meetingHelper.currentMeetingUsers.length < 2) {
                            globalProperties.$meetingHub.stopConnection("Customer Failed to Join Meeting");
                            meetingHelper.preConnectionFound = null;
                            meetingHelper.connectionFailed = true;
                            LogRocket.log(
                                "startInStoreMeeting - fimanager failed to find preconnection: ",
                                inStoreConnectionCode
                            );
                        } else {
                            meetingHelper.preConnectionFound = true;
                        }
                    }, 8000);
                    globalProperties.$meetingHub.connected = true;
                    globalProperties.$meetingHub.inStorePreConnection("fimanager", meetingHelper.dealNumber + "-" + meetingHelper.storeCode);
                })
                .catch((error: any) => {
                    LogRocket.log("Connection Error:", error);
                });
        }

        async function fimanagerConnectToSecureRoom(fimenu: FIMenu) {
            LogRocket.log("------------ fimanagerConnectToSecureRoom ---------------");
            //globalProperties.$meetingHub.startConnection(meetingHelper.dealNumber + '-' + meetingHelper.storeCode).then((response) => {
            const response = await meetingHelper.createMeeting(meetingHelper.dealNumber, meetingHelper.storeCode, fimenu);
            meetingHelper.currentMeeting = response.data;
            // meetingHelper.setCurrentMeeting(response.data);

            globalProperties.$meetingHub.code = meetingHelper.dealNumber + "-" + meetingHelper.storeCode;
            globalProperties.$meetingHub.joinMeetingRoom("fimanager", true, fimenu.id);
            //})
        }

        function meetingRoomJoinSuccessHandler(role: any, isInStoreConnection: boolean, meetingRoomCode: any, currentMeetingDetails: any) {

            console.log("--------------------- meetingRoomJoinSuccessHandler ----------------------", role);

            meetingHelper.currentMeetingUsers = Object.values(JSON.parse(currentMeetingDetails).users);

            meetingHelper.myUserDetails = meetingHelper.currentMeetingUsers.find(user => user.role === meetingHelper.myRole);

            // LogRocket.log('currentMeetingUsers', meetingHelper.currentMeetingUsers);
            // LogRocket.log("currentMeeting", meetingHelper.currentMeeting);
            // LogRocket.log("myUserDetails", meetingHelper.myUserDetails);

            meetingHelper.currentMeeting.meetingLogs.push(
                new FIMenuCustomerViewed({
                    viewedType: CUSTOMER_VIEWS.CONNECTED,
                    viewedDateTime: moment(),
                    extraInfo: role,
                })
            );

            if (role == "customer" || role == "coCustomer") {
                meetingHelper.currentMeeting.meetingLogs.push(
                    new FIMenuCustomerViewed({
                        viewedType: CUSTOMER_VIEWS.VIDEO_PRESENTATION,
                        viewedDateTime: moment(),
                        extraInfo: "customerEvent",
                    })
                );
            } else if (role == "fimanager") {
                console.log("SETTING MEETING STARTED AT");
                meetingHelper.currentMeeting.startedAt = meetingHelper.currentMeeting.startedAt
                    ? meetingHelper.currentMeeting.startedAt
                    : moment();
                meetingHelper.currentMeeting.meetingLogs.push(
                    new FIMenuCustomerViewed({
                        viewedType: CUSTOMER_VIEWS.WAITING,
                        viewedDateTime: moment(),
                        extraInfo: "customer",
                    })
                );
            }
        }

        async function updateMeetingHubParticipantsHandler(updatedMeetingParticipants: any) {
            console.log("--------------------- updateMeetingHubParticipantsHandler ----------------------", updatedMeetingParticipants);
            meetingHelper.usersToDisconnect = updatedMeetingParticipants.usersToDisconnect;
            meetingHelper.shouldMeetingEnd = updatedMeetingParticipants.shouldMeetingEnd;
            meetingHelper.currentMeetingUsers = Object.values(updatedMeetingParticipants.usersList);
            meetingHelper.myUserDetails = meetingHelper.currentMeetingUsers.find(user => user.role === meetingHelper.myRole);
            meetingHelper.isMuted = meetingHelper.myUserDetails?.isMuted ?? false;
            meetingHelper.isBusyConnecting = false;
            console.log("meetingHelper.myUserDetails", meetingHelper.myUserDetails);
            console.log("meetingHelper.myRole", meetingHelper.myRole);
            console.log("meetingHelper.inStore", meetingHelper.inStore);
            console.log("meetingHelper.shouldMeetingEnd", meetingHelper.shouldMeetingEnd);

            // If the fimanager presses endMeeting Button then shouldMeetingEnd should be true and the customer should disconnect
            if (meetingHelper.myRole === 'customer' && meetingHelper.inStore && meetingHelper.shouldMeetingEnd) {
                globalProperties.$meetingHub.stopConnection("Customer Left Meeting");
                return;
            }

            if (meetingHelper.myUserDetails && meetingHelper.currentMeetingUsers.length < 2 && meetingHelper.inStore && meetingHelper.shouldMeetingEnd) {
                console.log('???????? disconnectParticipant - called from updateMeetingHubParticipantsHandler');
                await meetingHelper.disconnectParticipant(meetingHelper.myUserDetails.userId);
            }

            meetingHelper.hasRequestToPresentBeenSent = false;

            if (meetingHelper.currentMeetingUsers.some(user => user.isPresenting)) {
                meetingHelper.customerScreenConnected = true;
            } else {
                meetingHelper.customerScreenConnected = false;
            }
        }

        async function disconnectParticipant(userId: string) {
            LogRocket.log("--------------------- disconnectParticipant --------------------- ", userId);

            if (!meetingHelper.inStore) {
                // SendParticipantToLobby expects a list
                globalProperties.$meetingHub.sendParticipantToLobby([ userId ]);
            } else if (meetingHelper.inStore && meetingHelper.myRole !== 'fimanager') {
                globalProperties.$meetingHub.stopConnection("Customer/Co-customer has disconnected");
            } else {
                console.log('!!!!!!!!!!!!!!!!!! END MEETING - called from disconnectParticipant');
                await meetingHelper.endMeeting();
            }
        }

        async function customerDisconnectedHandler(updatedMeetingParticipants: any) {
            LogRocket.log(
                "--------------------- customerDisconnectedHandler ---------------------",
                updatedMeetingParticipants
            );
            if (updatedMeetingParticipants) {
                meetingHelper.userToDisconnect = updatedMeetingParticipants.userToDisconnect;
                meetingHelper.currentMeetingUsers = Object.values(updatedMeetingParticipants.usersList);
            }

            const usersLeftInRoom = meetingHelper.currentMeetingUsers ? meetingHelper.currentMeetingUsers : [];

            if (
                usersLeftInRoom.length === 1 &&
                usersLeftInRoom[ 0 ].role === "fimanager" &&
                usersLeftInRoom[ 0 ].isInStoreConnection
            ) {
                console.log('!!!!!!!!!!!!!!!!!! END MEETING - called from customerDisconnectedHandler');
                await meetingHelper.endMeeting();
            }
        }

        async function endMeeting() {
            console.log("-------------- endMeeting ------------");
            meetingHelper.shouldMeetingEnd = true;

            const usersToSendToLobby = meetingHelper.currentMeetingUsers
                ?.filter(user => user.role !== "fimanager")
                ?.map(user => user.userId);

            console.log("usersToSendToLobby", usersToSendToLobby);

            if (usersToSendToLobby) {
                if (!meetingHelper.inStore) {
                    // For remote meetings we might have a list of users to send to the lobby
                    await globalProperties.$meetingHub.sendParticipantToLobby(usersToSendToLobby, meetingHelper.shouldMeetingEnd);
                } else {
                    // In-store meetings will only have one user that will be disconnected from the meeting
                    const userToDisconnect = Object.values(meetingHelper.currentMeetingUsers)?.find(
                        user => user.role !== "fimanager"
                    )?.userId;

                    if (userToDisconnect) {
                        await globalProperties.$meetingHub.disconnectParticipant(userToDisconnect, meetingHelper.shouldMeetingEnd);
                    }
                }
            }
            await meetingHelper.fimanagerDisconnect();

            meetingHelper.currentMeetingUsers = [];

        }

        async function fimanagerDisconnect() {
            console.log("----------- fimanagerDisconnect ----------- ");
            if (globalProperties.$meetingHub.connected && globalProperties.$meetingHub.code) {
                // api.meeting.updateMeeting(meetingHelper.currentMeeting);
                await api.meeting.setMeetingFinishedAt(meetingHelper.currentMeeting.id);

                broadcastUnsubscribeAllEvents(globalProperties.$meetingHub);
                globalProperties.$meetingHub.fimanagerStopConnection("Fi Mgr Left Meeting");

                //Clear any relevant fields so fimanager can have another meeting w/o refreshing
                meetingHelper.fimanagerResetHelper();
            }
        }

        function fimanagerResetHelper() {
            console.log('%c FIMANAGER RESET HELPER', 'background: orange');
            meetingHelper.currentMeetingUsers = [];
            meetingHelper.meetingCodeEncoded = null;
            meetingHelper.myUserDetails = null;
            meetingHelper.usersToDisconnect = null;
            //meetingHelper.employeeId = null;
            //meetingHelper.employeeCode = null;
            //meetingHelper.storeCode = null;
            //meetingHelper.dealNumber = null;
            meetingHelper.inStore = null;
            // meetingHelper.meetings = [];
            meetingHelper.cameraAccessAcknowledged = null;
            meetingHelper.customerScreenConnected = false;
            meetingHelper.cameraAccessAllowed = null;
            meetingHelper.shouldMeetingEnd = null;
            //meetingHelper.noUsersInMeeting = null;
        }

        /***********************************************************
         *
         * Customer Meeting Handlers
         *
         * ********************************************************/

        function customerMoveToSecureRoomHandler(newSecureRoom: string) {
            console.log("*** meetingHub - moveToSecureRoom -> leavePreConnectionRoom", newSecureRoom);
            console.log("--- customerMoveToSecureRoomHandler --- ", newSecureRoom);
            // meetingHelper.meetingCodeEncoded = newSecureRoom;
            meetingHelper.inStore = true;
            globalProperties.$meetingHub.leavePreConnectionRoom(newSecureRoom);
        }

        async function customerConnectToSecureRoom(who = "customer", inStore = true) {
            console.log("meetingHelper.meetingCodeEncoded", meetingHelper.meetingCodeEncoded);

            // If remote meeting, we need to start a meetingHub connection, if we are inStore we are already connected to meetingHub
            if (!inStore) {
                meetingHelper.dealNumber = meetingHelper.decodeMeetingCode(meetingHelper.meetingCodeEncoded);
                await globalProperties.$meetingHub.startConnection(meetingHelper.dealNumber);
            }
            globalProperties.$meetingHub.connected = true;
            globalProperties.$meetingHub.joinMeetingRoom(who, inStore, null, meetingHelper.firstName, meetingHelper.lastName, meetingHelper.fullName);
        }

        function requestToBePresenter() {
            console.log("requestToBePresenter", meetingHelper.myUserDetails);
            if (meetingHelper.myUserDetails) {
                globalProperties.$meetingHub.requestToBePresenter(meetingHelper.myUserDetails.userId);
                meetingHelper.hasRequestToPresentBeenSent = true;
            }
        }

        function userRequestingToPresentHandler(userId: string) {
            console.log("userRequestingToPresentHandler", userId);
            meetingHelper.userRequestingToPresent = userId;

            util.toastr("warn", "Meeting Notification", "User is requesting control of the meeting", 12000);
        }

        function grantRequestToBePresenter(role: string) {
            console.log("grantRequestToBePresenter", role);
            const userToGrantRequest = meetingHelper.currentMeetingUsers.find(user => user.role === role);
            if (userToGrantRequest) {
                globalProperties.$meetingHub.grantRequestToBePresenter(userToGrantRequest.userId);
                meetingHelper.userRequestingToPresent = null;
            }
        }

        function customerMeetingRoomJoinSuccessHandler(
            role: string,
            isInStoreConnection: boolean,
            meetingRoomCode: string,
            currentMeetingDetails: any
        ) {
            meetingHelper.currentMeetingDetails = JSON.parse(currentMeetingDetails);
            meetingHelper.currentMeetingUsers = Object.values(meetingHelper.currentMeetingDetails.users);
            console.log("meetingHelper.currentMeetingUsers", meetingHelper.currentMeetingUsers);
            if(meetingHelper.myRole != "fimanager" && meetingHelper.myRole == role) {
                broadcastEmitEvent(globalProperties.$meetingHub,
                    ENUMS.EVENT_EMITS.BROADCAST.CUSTOMER_JOINED_MEETING,
                    currentMeetingDetails);
            }

            meetingHelper.myUserDetails = meetingHelper.currentMeetingUsers.find(user => user.role === meetingHelper.myRole);
            console.log("meetingHelper.myUserDetails", meetingHelper.myUserDetails);
        }

        async function customerFinishMeetingHandler() {
            const usersLeftInRoom = meetingHelper.currentMeetingUsers
                ? Object.keys(meetingHelper.currentMeetingUsers)
                : [];

            console.log("customerFinishMeetingHandler", meetingHelper.currentMeetingUsers);
            console.log("meetingHelper.inStore", meetingHelper.inStore);
            console.log("usersLeftInRoom", usersLeftInRoom);

            if (usersLeftInRoom.length < 2) {
                //await api.meeting.setMeetingFinishedAt(meetingHelper.meetingInfo.id) //
               await globalProperties.$meetingHub.stopConnection("finishMeetingHandler");
            }
            //else {
            //    await globalProperties.$meetingHub.stopConnection("customerFinishMeetingHandler");
            //}
        }

        function customerResetHelper() {
            meetingHelper.currentMeetingUsers = [];
            meetingHelper.currentMeetingDetails = null;
            meetingHelper.meetingCodeEncoded = null;
            meetingHelper.employeeId = null;
            meetingHelper.employeeCode = null;
            meetingHelper.storeCode = null;
            meetingHelper.dealNumber = null;
            meetingHelper.inStore = null;
            // meetingHelper.meetings = [];
            //meetingHelper.noUsersInMeeting = null;
        }

        function reconnectedMeetingHubHandler(dealId: string = null) {
            console.log('reconnectedMeetingHubHandler', meetingHelper.myRole, meetingHelper.inStore, dealId, meetingHelper.firstName, meetingHelper.lastName);
            globalProperties.$meetingHub.reconnectParticipant(meetingHelper.myRole, meetingHelper.inStore, dealId, meetingHelper.firstName, meetingHelper.lastName);
        }

        const meetingHelper = reactive({
            // Meeting utility functions
            //createNumberCode,
            encodeMeetingCode,
            decodeMeetingCode,
            // setCurrentMeeting,

            // Meeting Actions
            createMeeting,
            initiateMeeting,
            sendLinkToMeetingRoomParticipant,
            startRemoteMeeting,
            allowUserIntoMeeting,
            startInStoreMeeting,
            fimanagerConnectToSecureRoom,
            meetingRoomJoinSuccessHandler,
            disconnectParticipant,
            updateMeetingHubParticipantsHandler,
            meetingCurrentlyOpen,

            customerDisconnectedHandler,
            endMeeting,
            fimanagerDisconnect,
            fimanagerResetHelper,
            customerResetHelper,
            reconnectedMeetingHubHandler,

            // Meeting data - contains data that may be put into the MeetingInfo class and some extra data that may be useful in the MeetingHelper
            meetingCodeEncoded: null,
            encodedUrlParam: null,
            employeeId: null,
            employeeCode: null,
            storeCode: null,
            dealNumber: null,
            inStore: null,
            myRole: null,
            myUserDetails: null,
            firstName: null,
            lastName: null,
            fullName: null,
            preConnectionFound: null,
            connectionFailed: null,
            usersToDisconnect: null,
            // meetings: [],
            //noUsersInMeeting: null,
            customerScreenConnected: false,
            isBusyConnecting: false,
            isMuted: false,

            // Meeting room mgmt
            currentMeeting: null,
            currentMeetingUsers: [],
            currentMeetingDetails: null,
            userToDisconnect: null,
            shouldMeetingEnd: null,

            // Meeting Info Class
            meetingInfo: null,

            /***************************
             * Customer Meeting
             * *************************/
            isMeetingStillActive: false,
            customerMoveToSecureRoomHandler,
            // customerGetMeetingByCode,
            customerConnectToSecureRoom,
            customerMeetingRoomJoinSuccessHandler,
            customerFinishMeetingHandler,
            // checkIfMeetingStillActive,
            // checkIfMeetingStillActiveResponse,
            cameraAccessAllowed: null,
            cameraAccessAcknowledged: null,

            /***************************************************************
             * Customer Presenter Handlers (who is in control in the meeting)
             * *************************************************************/
            requestToBePresenter,
            hasRequestToPresentBeenSent: false,
            userRequestingToPresentHandler,
            userRequestingToPresent: null,
            grantRequestToBePresenter,

            disableVideoChat: null,
        });
        globalProperties.meetingHelper = meetingHelper;
        app.provide("meetingHelper", meetingHelper);
    },
};
