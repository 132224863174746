<template>
    <div class="modal-container ModalAddEditPayment">
        <div class="modal-header">
            Payment Details
            <div>
                <button @click="handleRetrieveCustomer" :disabled="hasPaperworkPacket">Retrieve Customer</button>
            </div>
        </div>
        <div class="modal-body">
            <fieldset :disabled="modal.isModalDisabled">
                <div v-if="customer" :style="$grid('1')" class="no-margin-grid">
                    <Panel title="Basic Info">
                        <div :style="$grid('1-1-1-1')" class="no-margin-grid">
                        </div>
                        <div :style="$grid('1-1-1')" class="no-margin-grid">
                            <InputRichDropdown v-model:saturn="payment.type"
                                               :list="modal.paymentMethods"
                                               :valueMap="(pf) => pf.value"
                                               :display="obj => obj.display"
                                               :invalid="v$.payment.type.$invalid"
                                               :disabled="hasPaperworkPacket"
                                               nothingSelected="-- PAYMENT Type --">
                            </InputRichDropdown>
                            <InputCurrency label="Amount"
                                           placeholder="Amount"
                                           v-model:saturn="payment.amount"
                                           :precision="2"
                                           :invalid="v$.payment.amount.$invalid"
                                           :disabled="hasPaperworkPacket">
                                <template #validation>
                                    <div v-if="v$.payment.amount.required.$invalid">Amount is required</div>
                                    <div v-if="v$.payment.amount.minValue.$invalid">Amount is required</div>
                                </template>vehiclere
                            </InputCurrency>
                            <InputTextbox label="Receipt #"
                                          v-model:saturn="payment.receiptNumber"
                                          placeholder="Receipt number"
                                          :isUpperCase="true"
                                          :invalid="v$.payment.receiptNumber.$invalid"
                                          :disabled="hasPaperworkPacket">
                                <template #validation>
                                    <div v-if="v$.payment.receiptNumber.$invalid">Receipt # is required</div>
                                </template>
                            </InputTextbox>
                        </div>
                    </Panel>
                    <Panel title="Customer Info">
                        <div :style="$grid('1')" class="no-margin-grid">
                            <InputTextbox label="Full name"
                                          v-model:saturn="displayName"
                                          :isUpperCase="true"
                                          :disabled="true">
                            </InputTextbox>

                            <div :style="$grid('1-1-1')" class="no-margin-grid">
                                <InputDate label="Date of Birth"
                                           v-model:saturn="customer.dob"
                                           :disabled="true"
                                           icon="fas fa-calendar">
                                </InputDate>

                                <InputTextbox label="Email"
                                              v-model:saturn="customer.email"
                                              :isUpperCase="true"
                                              :disabled="true">
                                </InputTextbox>

                                <InputPhone label="Cell Phone"
                                            type="tel"
                                            v-model:saturn="customer.cell"
                                            placeholder="Cell Phone"
                                            :disabled="true"
                                            maxlength="10"
                                            minlength="10">
                                </InputPhone>
                            </div>
                            <fieldset :disabled="true">
                                <AddressComponent :data="customer"
                                                  :showCounty="true">
                                </AddressComponent>
                            </fieldset>
                        </div>
                    </Panel>
                </div>
                <div v-else>Please first retrieve a customer</div>
            </fieldset>
        </div>
        <div class="modal-footer modal-footer-split">
            <div>
                <button v-if="!isNewPayment" class="button-unwind" @click="deletePayment()" :disabled="hasPaperworkPacket && (payment.amount)">Delete Payment</button>
            </div>
            <div>
                <button class="button-unwind" @click="cancel()">Cancel</button>
                <button class="button-save" @click="close()" :disabled="modal.isModalDisabled || v$.$invalid || hasPaperworkPacket">Save</button>
            </div>
        </div>
    </div>
</template>
<script>

    import $modal from '@core/services/modal';
    import DropdownHelper from '@core/helpers/dropdown-helper'
    import { openRetrieveCustomersModal } from '@/helpers/customers-helper'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import FIMenuBuyersOrderBucketItem from '@core/classes/FIMenuBuyersOrderBucketItem'
    import FIMenuDownPayment from "@core/classes/FIMenuDownPayment";
    import FIMenuCustomer from "@core/classes/FIMenuCustomer";
    import util from '@core/services/util'
    import api from '@core/services/api'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import AddressComponent from '@/components/AddressComponent.vue'
    import InputDate from '@core/components/InputDate.vue'
    import InputPhone from '@core/components/InputPhone.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import FIMenuBuyersOrderBucket from '../../../vue-core/classes/FIMenuBuyersOrderBucket';
    import { useVuelidate } from '@vuelidate/core'
    import settings from "settings"

    export default {
        name: "modalAddEditDownPayment",
        props: ['modal'],
        setup() {
            return { v$: useVuelidate({ $scope: false }) }
        },
        data() {
            return {
                bo: null,
                downPayments: this.modal.fimenu.downPayments.map(dp => new FIMenuDownPayment(dp)),
                payment: null,
                customer: null,
                bucket: null,
                isNewPayment: false,
                DOWN_PAYMENT_TITLE_ID: '10013',
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            displayName() {
                return this.customer?.getPaperworkName()?.toUpperCase() ?? ""
            },
            customerAddress() {
                return this.customer?.getFullAddress();
            },
            hasPaperworkPacket() {
                return this.modal.fimenu.hasPaperworkPacket()
            }
        },
        async created() {
            this.bo = new FIMenuBuyersOrder(this.modal.bo);

            //IF NEW PAYMENT, CREATE IT AND CREATE A BUCKET FOR IT
            if (!this.modal.itemInfo.item) {
                this.isNewPayment = true;

                //CREATE NEW PAYMENT AND NEW BUCKET
                let bucket = this.bo.buckets.find(b => b.titleId == this.DOWN_PAYMENT_TITLE_ID)
                if (!bucket)
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, this.DOWN_PAYMENT_TITLE_ID);

                this.bucket = bucket;
                this.payment = this.getNewDefaultDownPayment();
            }
            else {
                this.isNewPayment = false;
                this.payment = this.downPayments.find(f => f.id == this.modal.itemInfo.item.metadata?.paymentId);
                this.bucket = this.bo.buckets.find(b => b.titleId == this.modal.itemInfo.item.bucket.titleId);
                this.customer = new FIMenuCustomer(this.modal.fimenu.customersInfo.customers.find(c => c.id == this.payment.customerId))

                if (!this.bucket) {
                    this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, this.DOWN_PAYMENT_TITLE_ID, this.DOWN_PAYMENT_TITLE_ID);
                }
            }
        },
        validations() {
            return {
                payment: {...this.payment.validation(this.modal.fimenu), isDownPaymentTotalValid: true}
            };
        },
        methods: {
            async fetchCustomer(customer) {
                try {
                    const customerResponse = await api.customers.getCustomerById(customer.id)
                    if (!customerResponse?.data) util.toastr('error', "Error", "Coulnd't find customer.")
                    return customerResponse.data;

                } catch (err) {
                    util.toastr('error', "Error", "An error has ocurred while retrieving the customer.")
                    $modal.cancel();
                }
            },
            handleRetrieveCustomer() {
                const actionRetrieveCustomer = async (customer) => {
                    this.payment.customerId = customer.id
                    const customerData = await this.fetchCustomer(customer)
                    this.customer = new FIMenuCustomer()
                    this.customer.setFromCustomer(customerData);
                }

                openRetrieveCustomersModal({
                    actionOnRetrieveCustomer: actionRetrieveCustomer,
                    actionOnRetrieveCompany: actionRetrieveCustomer,
                    canRetrieveCompany: true,
                })
            },
            getNewDefaultDownPayment() {
                let downPayment = new FIMenuDownPayment();
                downPayment.id = util.getGuid();
                return downPayment
            },
            deletePayment() {
                const result = confirm('Are you sure you want to delete this payment?');
                if (result) {
                    this.modal.fimenu.downPayments.splice(this.modal.fimenu.downPayments.findIndex(dp => dp.id === this.payment.id), 1)
                    this.bucket.items = this.bucket.items.filter(i => i.metadata?.paymentId !== this.payment.id)
                    $modal.close(this.bo);
                }
            },
            close() {
                let item = this.bucket.items.find(i => i.metadata?.paymentId === this.payment.id)
                if (item) {
                    this.modal.fimenu.downPayments.splice(this.modal.fimenu.downPayments.findIndex(dp => dp.id === this.payment.id), 1, this.payment)
                }
                else {
                    item = new FIMenuBuyersOrderBucketItem({
                        profitFlag: ENUMS.PROFIT_FLAGS.NONE,
                        name: this.modal.paymentMethods.find(o => o.value == this.payment.type).display,
                        sign: -1,
                        metadata: {
                            paymentId: this.payment.id
                        },
                        bucket: this.bucket
                    });

                    this.modal.fimenu.downPayments.push(this.payment)
                    this.bucket.items.push(item);
                }

                item.amount = this.payment.amount
                item.profit = null

                //update customers list
                this.modal.fimenu.upsertCustomer(this.customer)

                $modal.close(this.bo);
            },
            cancel() {
                $modal.cancel();
            },
        },
        components: {
            InputRichDropdown,
            InputCurrency,
            InputTextbox,
            AddressComponent,
            InputDate,
            InputPhone,
            Panel
        }
    };
</script>
<style>
    .ModalAddEditRebate .description {
        justify-content: flex-start;
    }
</style>