<template>
    <InputRichDropdown
        style="min-height: 50px"
        :height="40"
        :label="dropdownName()"
        :invalid="invalid"
        :saturn="selectedValue"
        :list="filteredListOfEmployees"
        :display="templateDisplay"
        :valueMap="templateValue"
        @change="handleChange"
        search
    >
    </InputRichDropdown>
</template>
<script lang="ts">
    interface EmployeeData {
        code: string;
        firstName: string;
        lastName: string;
        name: string;
        position: string;
        hasAccess: boolean,
        isArchived: boolean,
        employeeAccess: EmployeeAccessClass,
        lastLoginAttempt: Date
    }

    import { PropType, defineComponent } from "vue";
    import util from '@core/services/util';
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import FIMenuDealEmployee from "@core/classes/FIMenuDealEmployee";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import { EmployeeAccessClass } from "@core/classes/AccessEmployeeClass";
    import moment from "moment";

    export default defineComponent({
        name: "DealEmployeeDropdown",
        components: {
            InputRichDropdown,
        },
        props: {
            employees: {
                type: Array as PropType<EmployeeData[]>,
                default: (): EmployeeData[] => [],
            },
            employeeType: {
                type: Number,
                default: null,
            },
            FIMenu: {
                type: Object as PropType<FIMenu>,
                default: null,
            },
            label: {
                type: String,
                default: "",
            },
            invalid: Boolean,
        },
        data() {
            return {};
        },
        computed: {
            selectedValue(): EmployeeData | object {
                const dealEmployees = this.FIMenu.employees as FIMenuDealEmployee[];
                const employeeInDeal = dealEmployees.find(de => de.dealRole === this.employeeType);

                const employeeInStore = this.employees.find(emp => emp.code == employeeInDeal?.code);

                // If we couldn't find the employee in the store list, likely the user's position got changed since it was assigned to this deal.
                if (employeeInDeal && !employeeInStore) {
                    const nameSplit = employeeInDeal.name.split(',');
                    const firstName = (nameSplit.length > 1) ? nameSplit[1] : '';
                    const lastName = (nameSplit.length > 0) ? nameSplit[0] : '';

                    return {
                        code: employeeInDeal.code,
                        name: employeeInDeal.name,
                        firstName: firstName,
                        lastName: lastName,
                        position: util.convertDealRoleToPosition(employeeInDeal.dealRole)
                    };
                }

                return employeeInStore ?? {};
            },
            dealDate() {
                return this.FIMenu.dealDate
            },
            filteredListOfEmployees() : EmployeeData[] {
                const filteredEmployees = this.employees.filter(e => {
                    if(e.employeeAccess.isAdmin || e.position == "HEADQUARTERS") {
                        return false
                    }
                    if(e.hasAccess != true) {
                        return false;
                    }
                    if(e.isArchived) {
                        if(moment(this.dealDate).isAfter(e.lastLoginAttempt)) {
                            return false
                        }
                    }

                    switch (this.employeeType) {
                        case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON1:
                        case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_PERSON2:
                            return e.employeeAccess.isSalesPerson;

                        case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.SALES_MANAGER:
                            return e.employeeAccess.isSalesManager
                            
                        case ENUMS.DEAL_EMPLOYEE_ROLE_TYPES.FI_MANAGER:
                            return e.employeeAccess.isFIManager

                        default:
                            return true;
                    }
                });

                const selectedCode = (this.selectedValue as EmployeeData)?.code;
                if (selectedCode && filteredEmployees.every(e => e.code !== selectedCode))
                    filteredEmployees.push(this.selectedValue as EmployeeData);

                return filteredEmployees;
            },
        },
        methods: {
            templateDisplay(x: FIMenuDealEmployee) {
                return x.name;
            },
            templateValue(x: FIMenuDealEmployee) {
                return x;
            },
            dropdownName() {
                if (this.label) return this.label;
                return ENUMS.dealEmployeeRoleTypes.find(role => role.key == this.employeeType)?.description ?? "";
            },
            handleChange(value: EmployeeData) {
                // Remove if anyone already exists with this role
                const dealEmployees = this.FIMenu.employees as FIMenuDealEmployee[];

                dealEmployees.forEach((element, index) => {
                    if (element.dealRole == this.employeeType) {
                        dealEmployees.splice(index, 1); // Remove even numbers
                    }
                });

                // Add the newly selected employee.
                dealEmployees.push(
                    new FIMenuDealEmployee({
                        dealRole: this.employeeType,
                        code: value.code,
                        name: value.name,
                    })
                );
            },
        },
    });
</script>
