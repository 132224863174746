<template>
    <div class="loading-saturn-anim fade-in IsBusySectionComponent-container">
        <img class="loading-anim" src="@static/img/SaturnLogoAnim.svg" />
        <span :class="textClass">{{ text }}</span>
    </div>
</template>

<script>
    export default {
        name: "IsBusySectionComponent",
        props: ['text', 'delayToShowText'],
        data() {
            return {
                shouldDisplayText: false,
                textClass: "fade-in invisible",
            }
        },
        computed: {

        },
        created() {
            if (this.delayToShowText) {
                setTimeout(() => {
                    this.textClass = "fade-in"
                }, this.delayToShowText)
            } else {
                this.textClass = "fade-in"
            }
        },

        methods: {
        },
        components: {

        }
    };
</script>
<style>
    .IsBusySectionComponent-container {
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        user-select: none;
        flex-grow: 1;
    }
        .IsBusySectionComponent-container .fade-in.invisible{
            opacity: 0;
            animation: unset;
        }
        .IsBusySectionComponent-container span {
            margin-top: 15px;
            font-size: 18px;
            color: var(--load-file-div-color-border);
            animation: blinker 2.5s ease infinite;
        }

    @keyframes blinker {
        50% {
            opacity: 0%;
        }
    }
</style>