<template>
    <!-- Can submit application, but no applications available -->
    <span v-if="showSubmitApplicationMessage">SUBMIT AN APP. TO CONTINUE </span>

    <!-- New message for selecting an application or lender -->
    <!-- Applications available, but none selected -->
    <span v-else-if="showSelectApplicationOrLenderMessage || showSelectApplicationMessage || showSelectLenderMessage">SELECT TO CONTINUE </span>
</template>
<script lang="ts">
    interface ICreditApplication {
        selected: boolean;
    }
    export interface IPanelCreditApplicationErrorMessageProps {
        className?: string
        selectedLender: VersionsLender | null | undefined;
        fimenuLenderCode: string | null | undefined;
        canSubmitCreditApplication: boolean;
        creditApplications: ICreditApplication[];
    }
</script>
<script setup lang="ts">
    import { computed } from 'vue';
    import util from '@core/services/util';
    import VersionsLender from '@core/classes/LenderVersionClass';

    // Props declaration
    const props = defineProps<IPanelCreditApplicationErrorMessageProps>();

    // Computed properties for conditional rendering
    const showSelectLenderMessage = computed(() => {
        return (
            util.isNullOrEmpty(props.fimenuLenderCode) &&
            !props.selectedLender &&
            !props.canSubmitCreditApplication &&
            props.creditApplications.length <= 0 &&
            !props.creditApplications.some(a => a.selected)
        );
    });

    const showSubmitApplicationMessage = computed(() => {
        return props.selectedLender && props.canSubmitCreditApplication && props.creditApplications.length <= 0 && !props.creditApplications.some(a => a.selected);
    });

    const showSelectApplicationMessage = computed(() => {
        return !props.fimenuLenderCode && props.creditApplications.length > 0 && !props.creditApplications.some(a => a.selected);
    });

    // New computed property for selecting an application or lender
    const showSelectApplicationOrLenderMessage = computed(() => {
        return util.isNullOrEmpty(props.fimenuLenderCode) && !props.selectedLender && props.creditApplications.length > 0 && !props.creditApplications.some(a => a.selected);
    });
</script>

<style scoped>
    span {
        color:var(--error-color);
        max-width: 50%;
        font-size: 0.90rem;
        font-weight: bolder;
    }
</style>
