<template>
    <div class="modal-container modal-xxx-large r1container">
        <div class="modal-header">
            Lender information
        </div>
        <div class="modal-body">
            <TabMenu :tabs="tabs" :initialTab="tabs[0]">
                <template v-slot:tab-head-lenderInformation>
                    Lender information
                </template>
                <template v-slot:tab-panel-lenderInformation>
                    <div :style="$grid('2-3')">
                        <Panel :title="props.modal.passedData.lender.lenderName">
                            <LenderInformation 
                                :deal="props.modal.passedData.deal"
                                :selected-lender="props.modal.passedData.lender"
                                :reserve-profit-calcs="props.modal.passedData.reserveProfitCalcs"
                            />
                        </Panel>
                    </div>
                </template>
            </TabMenu>
        </div>
    
        <div class="modal-footer">
            <button class="button" @click="cancel()">Close</button>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref, toRefs } from 'vue';
    import $modal from '@core/services/modal'
    import FIMenu from '@core/classes/FIMenu';
    import IReserveProfitCalc from '@core/classes/IReserveProfitCalc';
    import LenderInformation from '@/components/LenderInformation.vue';
    import Panel from '@core/components/Panel.vue';
    import TabMenu from '@/components/TabMenu.vue';
    import VersionsLender from '@core/classes/LenderVersionClass';

    interface ModalProps<T> {
        modal: PassedData<T>
    }

    interface PassedData<T>{ 
        passedData: T
    }

    export interface IModalLenderInformationProps {
        deal: FIMenu;
        lender: VersionsLender;
        reserveProfitCalcs: IReserveProfitCalc[]
    }

    const props = defineProps<ModalProps<IModalLenderInformationProps>>();
    const tabs = ref<string[]>(["lenderInformation"])

    const cancel = () => {
        $modal.close();
    }

</script>
<style scoped></style>
