<template>
    <div :style="$grid('1')" class="decisionPanel">
        <button v-if="$global.isAdminView" class="button button-edit" :disabled="!creditDecision" @click="viewdecisionJSON" style="max-width:160px;" >
            <i class="fas fa-binoculars"></i> Credit Decision
        </button>

        <Panel v-if="creditDecision.contractTerm" title="Requested Terms" :collapsed="true" class="decisionPanel">
            <div :style="$grid('1-2-1-2-2-4')" class="lines">
                <DisplayData label="Sales Order Number"
                                :displayColumn="true"
                                :saturn="creditDecision.contractTerm.salesOrderNum"
                                :disabled="true">
                </DisplayData>
                <!-- <InputRichDropdown label="Offering Type"
                                :saturn.sync="creditDecision.contractTerm.offeringType"
                                :list="offeringTypes"
                                :valueMap="s => s.code + '-' + s.description"
                                :display="s => s.code"
                                nothingSelected="-- N/A --"
                                :disabled="true">
                </InputRichDropdown> -->

                <DisplayData label="Offering Type"
                                :displayColumn="true"
                                :saturn="offeringTypes[creditDecision.contractTerm.offeringType]?.description"
                                />
                <DisplayData label="Rating Index"
                                :displayColumn="true"
                                :saturn="creditDecision.contractTerm.ratingIndex"
                                :disabled="true">
                </DisplayData>
                <!-- <InputRichDropdown label="Pre-Approved Indicator"
                                :saturn.sync="creditDecision.contractTerm.preApprovedInd"
                                :list="preapprovedIndicators"
                                :valueMap="s => s.code + '-' + s.description"
                                :display="s => s.code"
                                nothingSelected="-- N/A --"
                                :disabled="true">
                </InputRichDropdown> -->
                <DisplayData label="Pre-Approved Indicator"
                                :displayColumn="true"
                                :saturn="preapprovedIndicators[creditDecision.contractTerm.preApprovedInd]?.description"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Pre-Approved Type Description"
                                :saturn="creditDecision.contractTerm.preApprovedTypeDescription"
                                :displayColumn="true"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Comment"
                                :displayColumn="true"
                                :saturn="creditDecision.contractTerm.comment"
                                :disabled="true">
                </DisplayData>
            </div>
            <div :style="$grid('1-1-1-1','0px 10px')" class="lines color-iteration">

                <DisplayData label="Cash Downpayment"
                                :saturn="creditDecision.contractTerm.cashDownPaymentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Total Downpayment"
                                :saturn="creditDecision.contractTerm.totalDownPaymentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Financed"
                                :saturn="creditDecision.contractTerm.financedAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Capitalized Cost"
                                :saturn="creditDecision.contractTerm.capitalizedCostAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Mfr Rebate"
                                :saturn="creditDecision.contractTerm.manufacturerRebateAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Net Trade"
                                :saturn="creditDecision.contractTerm.netTradeAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Trade Allowance"
                                :saturn="creditDecision.contractTerm.tradeAllowanceOnTradeInAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Trade Owed"
                                :saturn="creditDecision.contractTerm.tradeInOwedAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Trade Wholesale"
                                :saturn="creditDecision.contractTerm.tradeInWholesaleValueAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Sales Tax"
                                :saturn="creditDecision.contractTerm.salesTaxAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Doc Fees"
                                :saturn="creditDecision.contractTerm.docFeesAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Additional Fees"
                                :saturn="creditDecision.contractTerm.additionalFeesAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Disability Premium"
                                :saturn="creditDecision.contractTerm.disabilityPremiumAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Life Premium"
                                :saturn="creditDecision.contractTerm.creditLifePremiumAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Service Contract"
                                :saturn="creditDecision.contractTerm.serviceContract"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="GAP"
                                :saturn="creditDecision.contractTerm.gap"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="GST"
                                :saturn="creditDecision.contractTerm.gstAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Ext. Ins. Warranty"
                                :saturn="creditDecision.contractTerm.insuranceTotalExtendedWarrantyAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Other Insurance"
                                :saturn="creditDecision.contractTerm.insuranceServiceOtherAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Base Cost Price"
                                :saturn="creditDecision.contractTerm.baseCostPriceAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Title & License"
                                :saturn="creditDecision.contractTerm.titleLicenseRegistrationAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Approved Equipment"
                                :saturn="creditDecision.contractTerm.approvedEquipmentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Local Fees"
                                :saturn="creditDecision.contractTerm.localFeesAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Monthly Payment"
                                :saturn="creditDecision.contractTerm.totalMonthlyPaymentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Base Monthly Payment"
                                :saturn="creditDecision.contractTerm.baseMonthlyPaymentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Balance"
                                :saturn="creditDecision.contractTerm.balanceAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Financed Term"
                                :saturn="creditDecision.contractTerm.financedTermQuantity"
                                :disabled="true"
                                :precision="0"
                                :isCurrency="false"/>
                <DisplayData label="APR %"
                                :saturn="creditDecision.contractTerm.annualPercentageRate"
                                :disabled="true"
                                :isPercent="true"
                                :precision="2"/>
                <DisplayData label="Amortization Term"
                                :saturn="creditDecision.contractTerm.amortizationTermQuantity"
                                :disabled="true"
                                :precision="0"/>
                <DisplayData label="Lease Tax"
                                :saturn="creditDecision.contractTerm.leaseTaxAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Residual Balloon Payment"
                                :saturn="creditDecision.contractTerm.residualValueBalloonPaymentAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Lease Rate Service Charge Fact.%"
                                :saturn="creditDecision.contractTerm.leaseRateServiceChargeFactorPercent"
                                :disabled="true"
                                :isPercent="true"
                                :precision="2"/>
                <DisplayData label="lease Rate %"
                                :saturn="creditDecision.contractTerm.leaseRatePercent"
                                :disabled="true"
                                :isPercent="true"
                                :precision="2"/>
                <DisplayData label="MSRP Guide"
                                :saturn="creditDecision.contractTerm.msrpGuidePercent"
                                :disabled="true"
                                :isPercent="true"
                                :precision="2"
                                :isCurrency="false"/>
                <DisplayData label="Estimated Annual Mileage"
                                :saturn="creditDecision.contractTerm.estimatedAnnualMileage"
                                :disabled="true"
                                :precision="0"
                                :isCurrency="false"/>
                <DisplayData label="Excess Miles/Year"
                                :saturn="creditDecision.contractTerm.excessMilesPerYear"
                                :disabled="true"
                                :precision="0"
                                :isCurrency="true"/>
                <DisplayData label="Cost/Mile"
                                :saturn="creditDecision.contractTerm.costPerMileAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
                <DisplayData label="Security Deposit"
                                :saturn="creditDecision.contractTerm.securityDepositAmount"
                                :disabled="true"
                                :precision="2"
                                :isCurrency="true"/>
            </div>
        </Panel>
        <Panel v-if="creditDecision.decision" title="Lender's Decision" class="decisionPanel">
            <div :style="$grid('1-1-1-6')" class="lines">
                <DisplayData label="Credit Analyst"
                                :displayColumn="true"
                                :saturn="creditDecision.decision.creditAnalystInitials"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Analyst Comments"
                                :displayColumn="true"
                                :saturn="creditDecision.decision.creditAnalystComments"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Tier"
                                :displayColumn="true"
                                :saturn="creditDecision.decision.tier"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Stipulations"
                :displayColumn="true"
                :saturn="creditDecision.decision.stipulations[0]"
                                :disabled="true">
                </DisplayData>
            </div>
            <div :style="$grid('1-1')" class="lines">
                <DisplayData label="Decision Comments"
                :displayColumn="true"
                :saturn="creditDecision.decision.decisionComments[0]"
                                :multiline="true"
                                :disabled="true">
                </DisplayData>
                <DisplayData label="Condition Rejection"
                    :displayColumn="true"
                    :saturn="creditDecision.decision.conditionRejection?.map((x: any) => x?.conditionRejectionText).filter(Boolean).join(', ')" 
                    :multiline="true"
                    :disabled="true">
                </DisplayData>
            </div>
            <Panel v-if="creditDecision.decision.financing" title="Financing" :isSection="true" :subtext="[{label:'Company', value: financeCompanyName },{label:'Type', value: financeType},{label:'Expiration',value:prettyDate(creditDecision.decision.financing.expirationDate)}, {label:'Maturity',value:prettyDate(creditDecision.decision.financing.maturityDate)}]" class="decisionPanel">
                <div :style="$grid('1-1-1-1', '0px 10px')" class="lines color-iteration">
                    <DisplayData label="Payment"
                                    :saturn="creditDecision.decision.financing.paymentAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Balance"
                                    :saturn="creditDecision.decision.financing.balanceAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Final"
                                    :saturn="creditDecision.decision.financing.finalAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Residual"
                                    :saturn="creditDecision.decision.financing.residualAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Down Payment"
                                    :saturn="creditDecision.decision.financing.downPaymentAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Purchase Price"
                                    :saturn="creditDecision.decision.financing.purchasePrice"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Mfr Rebate"
                                    :saturn="creditDecision.decision.financing.manufacturerRebateAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Net Trade"
                                    :saturn="creditDecision.decision.financing.netTradeAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Ext. Ins. Warranty"
                                    :saturn="creditDecision.decision.financing.insuranceTotalExtendedWarrantyAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Disability Premium"
                                    :saturn="creditDecision.decision.financing.disabilityPremiumAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Life Premium"
                                    :saturn="creditDecision.decision.financing.creditLifePremiumAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Replacement Insurance"
                                    :saturn="creditDecision.decision.financing.replacementInsuranceAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Security Deposit"
                                    :saturn="creditDecision.decision.financing.securityDepositAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Term"
                                    :saturn="creditDecision.decision.financing.term"
                                    :disabled="true"
                                    :precision="0"/>
                    <DisplayData label="Total Contract"
                                    :saturn="creditDecision.decision.financing.totalContractAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Term"
                                    :saturn="creditDecision.decision.financing.maxTerm"
                                    :disabled="true"
                                    :precision="0"/>
                    <DisplayData label="Max Monthly Payment"
                                    :saturn="creditDecision.decision.financing.maxMonthlyPaymentAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Debt To Income"
                                    :saturn="creditDecision.decision.financing.maxDebtToIncomePercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Max Unpaid Balance"
                                    :saturn="creditDecision.decision.financing.maxUnpaidBalanceAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Min Down Payment"
                                    :saturn="creditDecision.decision.financing.minimumDownPaymentAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Min Cash Down"
                                    :saturn="creditDecision.decision.financing.minimumCashDownAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Down Payment %"
                                    :saturn="creditDecision.decision.financing.downPaymentPercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Discount %"
                                    :saturn="creditDecision.decision.financing.discountPercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Max Financed"
                                    :saturn="creditDecision.decision.financing.maxFinancedAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Service Contract"
                                    :saturn="creditDecision.decision.financing.maximumServiceContractAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max GAP"
                                    :saturn="creditDecision.decision.financing.maximumGapAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Cash Down Payment"
                                    :saturn="creditDecision.decision.financing.cashDownPaymentAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Front End Advance"
                                    :saturn="creditDecision.decision.financing.frontEndAdvanceAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Front End Advance %"
                                    :saturn="creditDecision.decision.financing.frontEndAdvancePercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Max Front End Advance"
                                    :saturn="creditDecision.decision.financing.maxFrontEndAdvanceAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Front End Advance %"
                                    :saturn="creditDecision.decision.financing.maxFrontEndAdvancePercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Back End"
                                    :saturn="creditDecision.decision.financing.totalBackEndAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Back End"
                                    :saturn="creditDecision.decision.financing.maxBackEndAmount"
                                    :disabled="true"
                                    :precision="2"
                                    :isCurrency="true"/>
                    <DisplayData label="Max Back End %"
                                    :saturn="creditDecision.decision.financing.maxBackEndPercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="APR %"
                                    :saturn="creditDecision.decision.financing.annualPercentageRate"
                                    :disabled="true"
                                    :isPercent="true"/>
                    <DisplayData label="Contract Rate %"
                                    :saturn="creditDecision.decision.financing.contractRate"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Max Contract Rate"
                                    :saturn="creditDecision.decision.financing.maxContractRate"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Base Buy %"
                                    :saturn="creditDecision.decision.financing.baseBuyPercentageRate"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Buy %"
                                    :saturn="creditDecision.decision.financing.buyPercentageRate"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"/>
                    <DisplayData label="MSRP Guide %"
                                    :saturn="creditDecision.decision.financing.msrpGuidePercentage"
                                    :disabled="true"
                                    :isPercent="true"
                                    :precision="2"
                                    :isCurrency="false"/>
                    <DisplayData label="Money Factor"
                                    :saturn="creditDecision.decision.financing.leaseRateMoneyFactor"
                                    :disabled="true"/>
                    <DisplayData label="Rating Index"
                                    :saturn="creditDecision.decision.financing.ratingIndex"
                                    :disabled="true"/>
                    <DisplayData label="Application Type"
                                    :saturn="creditDecision.decision.financing.applicationType"
                                    :disabled="true"/>
                </div>
                <div :style="$grid('1-1')" class="mt-10">
                    <DisplayData
                                label="Miscellaneous Notes"
                                :saturn="creditDecision.decision.financing.miscellaneousNotes"
                                :displayColumn="true"
                                :disabled="true"/>
                    <DisplayData
                            label="Pre-Approved Program Name"
                            :displayColumn="true"
                            :saturn="creditDecision.decision.financing.preApprovedProgramName"
                            :disabled="true"/>

                </div>
                <div :style="$grid('1-1')">
                    <Panel title="Taxes" :isSection="true">
                        <RichTable
                                    :tableData="creditDecision.decision.financing.tax"
                                    :headers="taxHeaders">
                        </RichTable>
                    </Panel>
                    <Panel title="Fees" :isSection="true">
                        <RichTable
                                    :tableData="creditDecision.decision.financing.fee"
                                    :headers="feeHeaders">
                        </RichTable>
                    </Panel>
                </div>
                <div :style="$grid('1-1')">
                    <Panel title="Buy Rate Adjustments" :isSection="true">
                        <RichTable
                                    :tableData="creditDecision.decision.financing.buyPercentageRateAdjustments"
                                    :headers="buyRateAdjustmentHeaders">
                        </RichTable>
                    </Panel>
                    <Panel title="Stated Incomes" :isSection="true">
                        <RichTable
                                    :tableData="creditDecision.decision.financing.statedIncomes"
                                    :headers="percentageAmountTypeHeaders">
                        </RichTable>
                    </Panel>
                </div>

            </Panel>
        </Panel>
        <div :style="$grid('5-3')">
            <Panel v-if="creditDecision.creditContractData" title="Credit Contract" :subtext="[{label:'Status', value: creditDecision.creditContractData.fundingStatus}]" class="decisionPanel" >
                <Panel v-if="creditDecision.creditContractData.financeCompany" title="Finance Company" class="decisionPanel" :isSection="true">
                        <div :style="$grid('1-1-1-1-1-1-1-1')">
                            <DisplayData label="ID"
                                            :saturn="creditDecision.creditContractData.financeCompany.id"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <DisplayData label="Name"
                                            :saturn="creditDecision.creditContractData.financeCompany.name"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-6-column">
                            </DisplayData>
                            <DisplayData label="Branch Code"
                                            :saturn="creditDecision.creditContractData.financeCompany.branchCode"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <DisplayData label="Company Code"
                                            :saturn="creditDecision.creditContractData.financeCompany.companyCode"
                                            :displayColumn="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <DisplayData label="Division Code"
                                            :saturn="creditDecision.creditContractData.financeCompany.divisionCode"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <DisplayData label="Service Center Code"
                                            :saturn="creditDecision.creditContractData.financeCompany.serviceCenterCode"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <DisplayData label="Holding Company"
                                            :saturn="creditDecision.creditContractData.financeCompany.holdingCompany"
                                            :displayColumn="true"
                                            :disabled="true"
                                            class="grid-2-column">
                            </DisplayData>
                            <Panel title="IDs" :isSection="true" class="grid-8-column">
                                <RichTable :tableData="creditDecision.creditContractData.financeCompany.idInformations"
                                    :headers="idInformationHeaders"
                                    >
                                </RichTable>
                            </Panel>
                            <Panel title="Contacts" :isSection="true" class="grid-8-column">
                                <RichTable
                                            :tableData="creditDecision.creditContractData.financeCompany.contact"
                                            :headers="contactHeaders">
                                </RichTable>
                            </Panel>
                        </div>
                    </Panel>

                    <Panel title="Deductions" :isSection="true" class="grid-8-column">
                        <RichTable
                            :tableData="creditDecision.creditContractData.deduction"
                            :headers="percentageAmountTypeHeaders"/>
                    </Panel>
                    <Panel title="Credits" :isSection="true" class="grid-8-column">
                        <RichTable
                            label="Credits"
                            :tableData="creditDecision.creditContractData.credit"
                            :headers="percentageAmountTypeHeaders"/>
                    </Panel>
            </Panel>
            <Panel v-if="creditDecision.additionalInfo" title="Additional Info" :subtext="[{label:'App Type', value: creditDecision.additionalInfo.appType}]" class="decisionPanel">
                <div :style="$grid('1')">
                    <div>
                        <Panel v-if="creditDecision.additionalInfo.reserve" title="Reserve" class="decisionPanel" :isSection="true">
                            <div :style="$grid('1-1-1')">
                                <DisplayData label="Participation"
                                                :saturn="creditDecision.additionalInfo.reserve.participation"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                                <DisplayData label="Maximum"
                                                :saturn="creditDecision.additionalInfo.reserve.maximum"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                                <DisplayData label="Flat"
                                                :saturn="creditDecision.additionalInfo.reserve.flat"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                            </div>
                        </Panel>
                        <Panel v-if="creditDecision.additionalInfo.partnerSpecificAdditionalInfo" title="Partner Specific Info" class="decisionPanel" :isSection="true">
                            <div :style="$grid('1-1')" class="lines">
                                <DisplayData label="Customer Lease Rate MF"
                                                :saturn="creditDecision.additionalInfo.partnerSpecificAdditionalInfo.customerLeaseRateMoneyFactor"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                                <DisplayData label="Participation Type"
                                                :saturn="creditDecision.additionalInfo.partnerSpecificAdditionalInfo.participationType"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                                <DisplayData label="Base Payment Amount"
                                                :saturn="creditDecision.additionalInfo.partnerSpecificAdditionalInfo.basePaymentAmount"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                                <DisplayData label="Standard Rate"
                                                :saturn="creditDecision.additionalInfo.partnerSpecificAdditionalInfo.standardRate"
                                                :displayColumn="true"
                                                :disabled="true">
                                </DisplayData>
                            </div>
                        </Panel>
                    </div>
                    <Panel v-if="creditDecision.additionalInfo.ltvData" title="LTV" class="decisionPanel" :isSection="true">
                        <div :style="$grid('1-1')">
                            <DisplayData label="Actual"
                                            :saturn="creditDecision.additionalInfo.ltvData.actual"
                                              :displayColumn="true"
                                            :disabled="true">
                            </DisplayData>
                            <DisplayData label="Maximum"
                                            :saturn="creditDecision.additionalInfo.ltvData.maximum"
                                              :displayColumn="true"
                                            :disabled="true">
                            </DisplayData>
                        </div>
                        <div :style="$grid('1')">
                            <DisplayData label="Flat"
                                            :saturn="creditDecision.additionalInfo.ltvData.comment"
                                              :displayColumn="true"
                                            :disabled="true">
                            </DisplayData>
                        </div>
                        <Panel title="LTV Items" :isSection="true" class="grid-8-column">
                            <RichTable
                                        :tableData="creditDecision.additionalInfo.ltvData.ltvItems"
                                        :headers="ltvHeaders"/>
                        </Panel>
                    </Panel>
                </div>
            </Panel>
        </div>
    </div>
</template>
<script lang="ts">
    import {defineComponent, PropType} from 'vue'
    import $modal from '@core/services/modal.js'
    import DisplayData from '@/components/DisplayData.vue'
    import modalPre from '@/modals/modalPre.vue'
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'
    import TableItemListComponent from '@/components/TableItemListComponent.vue'


export default defineComponent({

    name: 'RouteOneCreditDecisionView',
    components: {Panel, RichTable, DisplayData},
    props: {
        creditDecision:  {
            type: Object as PropType<any>,
            required: true, // Set to true if this prop must be provided
            default: () => ({}), // Provide a default value if appropriate
        },
        financeType: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        canViewRawData: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {
                offeringTypes: [
                    { code: 'N', description: 'New Offering' },
                    { code: 'T', description: 'Transfer of Equity' },
                    { code: 'R', description: 'Re-write' }
                ],
                preapprovedIndicators: [
                    { code: '0', description: 'NOT Pre-approved' },
                    { code: '1', description: 'Pre-approved' },
                ],
                taxHeaders: [
                    { name: 'taxType', display: "Type" },
                    { name: 'taxDescription', display: "Description" },
                    { name: 'taxAmount', display: "Amount", cssClass: 'column-number' },
                    { name: 'taxRate', display: "Rate" },
                    { name: 'taxTypeId', display: "Type ID" },
                ],
                feeHeaders: [
                    { name: 'feeType', display: "Type" },
                    { name: 'feeDescription', display: "Description" },
                    { name: 'feePaidTo', display: "Paid To" },
                    { name: 'feeAmount', display: "Amount", cssClass: 'column-number' },
                ],
                buyRateAdjustmentHeaders: [
                    { name: 'buyPercentageRateAdjTypeCode', display: "Type" },
                    { name: 'buyPercentageRateAdjDesc', display: "Description" },
                    { name: 'buyPercentageRateAdjPercent', display: "Percent" },
                ],
                percentageAmountTypeHeaders: [
                    { name: 'type', display: "Type" },
                    { name: 'amount', display: "Amount", cssClass: 'column-number' },
                    { name: 'percentage', display: "Percent" },
                ],
                ltvHeaders: [
                    { name: 'range', display: "Range" },
                    { name: 'rate', display: "Rate" },
                    { name: 'fee', display: "Fee" },
                ],
                idInformationHeaders: [
                    { name: 'id', display: "ID" },
                    { name: 'type', display: "Type" },
                    { name: 'issuingState', display: "Issuing State" },
                    { name: 'expirationDate', display: "Expiration Date" },
                ],
                contactHeaders: [
                    { name: 'personName', display: "Person Name" },
                    { name: 'title', display: "Title" },
                    { name: 'emailAddressDisplay', display: "email Addresses", component:() =>  TableItemListComponent },
                    { name: 'faxDisplay', display: "Fax Numbers", component: () => TableItemListComponent },
                    { name: 'telephoneDisplay', display: "Phone Numbers", component: () =>  TableItemListComponent },
                ],
        }
    },
    computed: {
        financeCompanyName(): string {
            return this.creditDecision.decision.financing.financeCompany?.name || this.creditDecision.creditContractData?.financeCompany?.name;
        }
    },
    methods: {
        prettyDateTime(value: string) {
            return value ? this.$filters.prettyDateTime(value) : '';
        },
        viewdecisionJSON() {
            $modal.open(modalPre, { name: 'modalPre', passedData: { data: { request: this.creditDecision } }, backdrop: true });
        },
        prettyDate(value: string) {
            return value ? this.$filters.prettyDate(value) : 'N/A';
        },
        yesNo(value: string) {
            return value ? value == '1' ? 'Yes' : 'No' : '';
        },

    },
})
</script>
<style scoped>
    .color-iteration .display-data-container:nth-child(8n+1),
    .color-iteration .display-data-container:nth-child(8n+2),
    .color-iteration .display-data-container:nth-child(8n+3),
    .color-iteration .display-data-container:nth-child(8n+4) {
        background: var(--black-10percent);
    }

    .mt-10 {
        margin-top: 10px
    }

</style>