<template>
    <AccordionSection v-if="!applicationSent" :defaultExpanded="true" class="accordion-create-lead">

        <template #accordionHeader>
            <h2 style="margin-left: 20px;">Customer Information</h2>
        </template>

        <template #accordionBody>
           <ApplicantInfoPanel
                :store="store"
                :prefilledData="prefilledData"
                :validation="validation"
                :applicationSent="applicationSent"
                :isLoading="isLoading"
                :createLead="createLead"
           />

        </template>
        <template #footer>
            <button class="hint-click button-save default-btn-footer" @click="handleCreatedLead" :disabled="validation.prefilledData.$invalid">
                Next
            </button>
        </template>
    </AccordionSection>
</template>

<script>
    import AccordionSection from '@core/components/AccordionSection.vue'
    import ApplicantInfoPanel from './ApplicantInfoPanel.vue'
    import ENUMS from "@core/classes/Enums"
    import LOCALIZATION from "@core/classes/Localization"
    import util from '@core/services/util'

    export default {
        name: "AccordionInfoApplication",
        emits:['update:isLoading'],
        props: ['store', 'prefilledData', 'validation', 'applicationSent', 'isLoading', 'createLead'],
        data() {
            return {
                creatingError: false,
                creatingSuccess: false,
                language: "English",
                isEntity: false,
                prefix: [
                    { value: null, text: '' },
                    { value: 'Dr.', text: 'Dr.' },
                    { value: 'Mr.', text: 'Mr.' },
                    { value: 'Mrs.', text: 'Mrs.' },
                    { value: 'Ms.', text: 'Ms.' },
                    { value: 'Mx.', text: 'Mx.' }
                ],
                suffix: [
                    { value: null, text: '' },
                    { value: 'Sr.', text: 'Sr.' },
                    { value: 'Jr.', text: 'Jr.' },
                    { value: 'II', text: 'II' },
                    { value: 'III', text: 'III' },
                    { value: 'IV', text: 'IV' },
                    { value: 'V', text: 'V' }
                ],
                languages: [{ label: "English", value: "English" }, { label: "Spanish", value: "Spanish" }]
            };
        },
        computed: {
            localization() {
                return LOCALIZATION;
            },
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
        },
        methods: {
            handleInput(value, variable) {
                this[variable] = value;
            },
            async handleCreatedLead() {
                try {
                    this.$emit('update:isLoading', true);
                    await this.createLead();

                } catch(err) {
                    console.error(err)
                } finally {
                    this.$emit('update:isLoading', true);
                }

            }
        },
        components: {
            AccordionSection,
            ApplicantInfoPanel
        }

    };
</script>

<style>
    .lead-creation-container .second-line {
        margin-top: 10px;
    }

    .lead-creation-container .panel-body {
        display: flex;
    }

    .lead-creation-container input {
        text-transform: uppercase;
    }

    .lead-creation-container.lead-created {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        background-color: rgba(0,0,0,0.05);
        padding: 40px;
        box-sizing: border-box;
        border-radius: 10px;
        max-width: 800px;
        align-self: center;
    }

    .accordion-create-lead .next-step-wrapper > button {

        max-width: 150px;
        margin-top: 50px;
    }

</style>
