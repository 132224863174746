<template>
    <div class="modal-container ModalAddEditRebate">
        <div class="modal-header">
            Rebate Details <span v-if="$global.isAdminView && rebate">{{ rebate.titleId }}</span>
        </div>
        <div class="modal-body">
            <fieldset :disabled="modal.isModalDisabled">
                <Panel title="Basic Info">
                    <template v-slot:HeaderComponent>
                        <div class="panel-buttons">
                            <InputRichDropdown v-if="isNewRebate"
                                               v-model:saturn="selectedRebate"
                                               :list="storeRebates"
                                               :valueMap="(pf) => pf"
                                               :display="(pf) => (pf.name ? pf.name : 'CUSTOM REBATE')"
                                               @change="onStoreRebateSelected"
                                               nothingSelected="-- REBATE Type --">
                            </InputRichDropdown>
                        </div>
                    </template>

                    <div :style="$grid('1-1')">
                        <InputTextbox label="Name"
                                      v-model:saturn="rebate.desc"
                                      placeholder="Rebate Name"
                                      :isUpperCase="true"
                                      :invalid="v$.rebate.desc.$invalid"
                                      :disabled="!rebate.isEditableName || (isNewRebate && !selectedRebate) || (modal.isDealLocked && rebate.itemized)">
                            <template #validation>
                                <div v-if="v$.rebate.desc.required.$invalid">Name is required</div>
                                <div v-if="v$.rebate.desc.uniqueName.$invalid">Name must be unique</div>
                            </template>
                        </InputTextbox>
                        <InputTextbox label="Code"
                                      v-model:saturn="rebate.code"
                                      placeholder="Rebate Code"
                                      :isUpperCase="true"
                                      :invalid="v$.rebate.code.$invalid"
                                      :disabled="!rebate.isEditableCode || (isNewRebate && !selectedRebate) || (modal.isDealLocked && rebate.itemized)">
                            <template #validation>
                                <div v-if="v$.rebate.code.required.$invalid">Code is required</div>
                            </template>
                        </InputTextbox>

                        <InputCurrency v-if="rebate.matchToFirstPayment"
                                       label="Max First Payment Match amount"
                                       placeholder="Amount"
                                       v-model:saturn="rebate.maxAmount"
                                       :precision="2"
                                       :invalid="v$.rebate.maxAmount.$invalid"
                                       :disabled="(isNewRebate && !selectedRebate) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.rebate.maxAmount.required.$invalid">Max amount is required</div>
                            </template>
                        </InputCurrency>
                        <InputCurrency v-else label="Amount"
                                       placeholder="Amount"
                                       v-model:saturn="rebate.amount"
                                       :precision="2"
                                       :invalid="v$.rebate.amount.$invalid"
                                       :disabled="!rebate.isEditableAmount || (isNewRebate && !selectedRebate) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.rebate.amount.required.$invalid">Amount is required</div>
                            </template>
                        </InputCurrency>

                        <InputCurrency label="Cost"
                                       placeholder="Cost"
                                       v-model:saturn="rebate.cost"
                                       :precision="2"
                                       :invalid="v$.rebate.cost.$invalid"
                                       :disabled="!rebate.isEditableCost || (isNewRebate && !selectedRebate) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.rebate.cost.required.$invalid">Cost is required</div>
                            </template>
                        </InputCurrency>
                    </div>
                    <div :style="$grid('1-1')" class="no-margin-grid">
                        <InputRichDropdown label="Profit Flag"
                                           v-model:saturn="rebate.profitFlag"
                                           :list="profitFlags"
                                           :valueMap="(pf) => pf.value"
                                           :display="(pf) => pf.text"
                                           nothingSelected="-- Select Value --"
                                           :invalid="v$.rebate.profitFlag.$invalid"
                                           :disabled="!rebate.isEditableProfitFlag || (isNewRebate && !selectedRebate) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.rebate.profitFlag.required.$invalid">Profit Flag To is required</div>
                            </template>
                        </InputRichDropdown>

                        <InputRichDropdown v-if="!isNewRebate || selectedRebate"
                                           label="Itemized on Buyer's Order"
                                           class="itemized"
                                           v-model:saturn="rebate.itemized"
                                           :list="itemizedOptions"
                                           :valueMap="(o) => o.value"
                                           :display="(o) => o.display"
                                           :disabled="!rebate.isEditableItemized || (rebate.isEditableItemized && modal.isDealLocked)">
                        </InputRichDropdown>
                    </div>
                </Panel>
                <Panel title="Tax Lines to Apply">
                    <fieldset class="no-margin-grid" :style="$grid('1')" :disabled="modal.isDealLocked">
                        <div class="no-margin-grid" :style="$grid('5-1')" v-for="(taxLine, taxLineIndex) in bo.taxGroups" :key="taxLine.name">
                            <InputCheckbox :modelValue="isInThisTaxLine(taxLineIndex)" @click="toggleTaxLine(taxLineIndex)" class="panel-header-collapse-button description" :label="taxLine.name"></InputCheckbox>

                            <div class="amt">
                                <InputNumber v-model:saturn="taxLine.rate"
                                             placeholder="Tax Rate"
                                             :precision="2"
                                             :isPercent="true"
                                             :disabled="true">
                                </InputNumber>
                            </div>
                        </div>
                    </fieldset>
                </Panel>
            </fieldset>
        </div>
        <div class="modal-footer modal-footer-split">
            <div>
                <button v-if="!isNewRebate" class="button-unwind" @click="deleteRebate()" :disabled="modal.isDealLocked && (rebate.amount || rebate.itemized)">Delete Rebate</button>
            </div>
            <div>
                <button class="button-unwind" @click="cancel()">Cancel</button>
                <button class="button-save" @click="close()" :disabled="modal.isModalDisabled || v$.$invalid || (modal.isDealLocked && rebate.itemized && rebate.isEditableItemized)">Save</button>
            </div>
        </div>
    </div>
</template>
<script>

    import $modal from '@core/services/modal';
    import DropdownHelper from '@core/helpers/dropdown-helper'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import FIMenuBuyersOrderBucketItem from '@core/classes/FIMenuBuyersOrderBucketItem'
    import StoreRebate from '@core/classes/Store/StoreRebate'
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import FIMenuBuyersOrderBucket from '../../../vue-core/classes/FIMenuBuyersOrderBucket';
    import { useVuelidate } from '@vuelidate/core'

    export default {
        name: "modalAddEditRebate",
        props: ['modal'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                bo: null,
                rebate: null,
                rebateIndex: null,
                bucket: null,
                originalName: null,
                isNewRebate: false,
                selectedRebate: null,
                storeRebates: null,
                profitFlags: null,
                itemizedOptions: [
                    {
                        value: true,
                        display: "Yes"
                    },
                    {
                        value: false,
                        display: "No"
                    }
                ],
                REBATES_TITLE_ID: '10012',
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            }
        },
        async created() {
            this.bo = new FIMenuBuyersOrder(this.modal.bo);
            this.profitFlags = DropdownHelper.getProfitFlags();

            this.storeRebates = this.modal.storeRebates.map(r => new StoreRebate(r));

            //IF NEW REBATE, CREATE IT AND CREATE A BUCKET FOR IT
            if (!this.modal.itemInfo.item) {

                this.isNewRebate = true;

                //CREATE NEW REBATE AND NEW BUCKET
                let bucket = this.bo.buckets.find(b => b.titleId == this.REBATES_TITLE_ID)
                if (!bucket)
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, this.REBATES_TITLE_ID);

                this.bucket = bucket;
                this.rebate = this.bo.addNewDefaultRebate(this.bucket.titleId, true, true, true, true);
            }
            else {

                this.isNewRebate = false;

                this.rebate = this.bo.rebates.find(f => f.desc == this.modal.itemInfo.item.name);
                this.originalName = this.rebate.desc
                this.bucket = this.bo.buckets.find(b => b.titleId == this.rebate.titleId);

                if (!this.bucket) {
                    this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, this.rebate.titleId, this.rebate.titleId);
                }
                this.rebateIndex = this.bo.rebates.findIndex(f => f.desc == this.modal.itemInfo.item.name);
            }

            // Only tax lines get reset when Buyer's Order is reset
            this.$watch(() => this.bucket.taxIndex, () => {
                this.bo.isManuallyChanged = true;
            });
        },
        validations() {

            return {
                rebate: this.rebate.validation(this.bo)
            };

        },
        methods: {
            onStoreRebateSelected() {
                if (!this.selectedRebate) return;

                //REBATE INFO
                this.rebate.id = this.selectedRebate.id;
                this.rebate.desc = this.selectedRebate.name;
                this.rebate.code = this.selectedRebate.code;
                this.rebate.amount = this.selectedRebate.amount;
                this.rebate.cost = this.selectedRebate.cost;
                this.rebate.profitFlag = this.selectedRebate.profitFlag;
                this.rebate.isEditableName = this.selectedRebate.isEditableName;
                this.rebate.isEditableCode = this.selectedRebate.isEditableCode;
                this.rebate.isEditableAmount = this.selectedRebate.isEditableAmount;
                this.rebate.isEditableCost = this.selectedRebate.isEditableCost;
                this.rebate.isEditableProfitFlag = this.selectedRebate.isEditableProfitFlag;
                this.rebate.isEditableTitleId = this.selectedRebate.isEditableTitleId;
                this.rebate.isEditableItemized = this.selectedRebate.isEditableItemized;
                this.rebate.matchToFirstPayment = this.selectedRebate.matchToFirstPayment;
                this.rebate.maxAmount = null;
                this.rebate.itemized = this.rebate.isEditableItemized ? false : this.selectedRebate.itemized;
                this.rebate.titleId = this.selectedRebate.titleId;

                //SET UP BUCKET & TITLE ID
                let bucket = null;
                if (this.selectedRebate.titleId) {
                    bucket = this.bo.buckets.find(b => b.titleId == this.selectedRebate.titleId);
                }

                if (!bucket) {
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, this.selectedRebate.titleId);
                }

                this.bucket = bucket
            },

            //GIVEN A taxLineIndex RETURNS TRUE IF THE CURRENT ITEM'S IS TAXED ON THAT TAX LINE
            isInThisTaxLine(taxLineIndex) {
                const taxIndex = this.bo.isLease() ? this.bucket.upfrontTaxIndex : this.bucket.taxIndex;  //Which tax index? (check if the bucket is upfront)
                const taxBinaryArray = util.taxIndexToBinaryArray(taxIndex);
                const isInThisTaxLine = (taxBinaryArray[taxLineIndex] == '1');

                return isInThisTaxLine;

            },
            toggleTaxLine(taxLineIndex) {
               const isSelected = !this.isInThisTaxLine(taxLineIndex);

                const originalBucketData = new FIMenuBuyersOrderBucket(this.bucket);

                //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                const taxBinaryArray = util.taxIndexToBinaryArray(this.bucket.taxIndex);
                taxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                const newTaxIndex = util.binaryArrayToTaxIndex(taxBinaryArray);

                //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                const upfrontTaxBinaryArray = util.taxIndexToBinaryArray(this.bucket.upfrontTaxIndex);
                upfrontTaxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                const newUpfrontTaxIndex = util.binaryArrayToTaxIndex(upfrontTaxBinaryArray);

                //CREATE NEW BUCKET WITH THE NEW TAX INDEX
                this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, newTaxIndex, newUpfrontTaxIndex, originalBucketData, null, this.bucket.originalTitleId);
                this.rebate.titleId = this.bucket.titleId;


            },

            deleteRebate() {
                const result = confirm('Are you sure you want to delete this rebate?');
                if (result) {
                    this.bo.removeRebate(this.rebateIndex);
                    this.bucket.removeItem(this.originalName)
                    $modal.close(this.bo);
                }
            },
            close() {
                let item = this.bucket.items.find(i => i.name == this.originalName)
                if (item) {
                    item.name = this.rebate.desc
                }
                else {
                    item = new FIMenuBuyersOrderBucketItem({
                        amount: this.rebate.amount,
                        cost: this.rebate.cost,
                        profitFlag: this.rebate.profitFlag,
                        name: this.rebate.desc,
                        sign: 1,
                        metadata: {
                            itemized: this.rebate.itemized
                        },
                        bucket: this.bucket
                    });

                    this.bucket.items.push(item);
                }

                item.profit = this.rebate.price - this.rebate.cost;

                $modal.close(this.bo);
            },
            cancel() {
                $modal.cancel();
            },
        },
        components: {
            InputRichDropdown,
            InputCheckbox,
            InputCurrency,
            InputTextbox,
            InputNumber,
            Panel
        }
    };
</script>
<style>
    .ModalAddEditRebate .description {
        justify-content: flex-start;
    }
</style>