<template>
    <div class="PurchaseFiguresPage-container">
        <div
            v-if="!fimenu.buyersOrderEnabled"
            :style="$grid('1-1')"
            class="PurchaseFiguresPage-wrapper"
        >
            <div class="panel-left fullHeight">
                <PanelPurchaseFigures
                    :fimenu
                    :validation="validation"
                    :preloadTaxes="preloadTaxes"
                    :docStamps="docStamps"
                />
            </div>
            <div class="panel-right">
                <Panel title="Submit to R1">
                    <ButtonSubmitToFinanceInstitutions :deal="fimenu" />
                </Panel>
                <PanelLender
                    :fimenu
                    :validation="validation"
                    :panelObject="{ title: 'Lender' }"
                    :panelView="true"
                    class="panel-left-flex-1 fullHeight"
                />
            </div>
        </div>
        <div
            v-else
            :style="$grid('65-35')"
            class="r1-wrapper"
        >
            <PanelBuyersOrder
                :fimenu
                :fimenuValidation="validation"
                ref="bo"
                :submitToFinanceChannel="canSubmitToR1"
            />

            <div class="Content-Container">
                <PanelCreditApplications
                    :fimenu
                    :validation
                    :panelObject="{ title: 'Lender Applications' }"
                />
                <!-- <PanelLender
                    v-else
                    :fimenu
                    :validation="validation"
                    :panelObject="{ title: 'Lender' }"
                    :panelView="true"
                    class="panel-left-flex-1"
                /> -->
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    export interface IPurchaseFiguresPageProps {
        fimenu: FIMenu;
        validation?: any;
    }
</script>
<script setup lang="ts">
    import { computed, inject, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
    import { DEAL_TYPES } from '@core/classes/Enums';
    import { DisplayCreditApplication } from '@/helpers/finance-channels-helper';

    import ButtonSubmitToFinanceInstitutions from '@/components/ButtonSubmitToFinanceInstitutions.vue';
    import { buyersOrderHelper } from '@core/helpers/buyers-order-helper';
    import { Emitter } from 'mitt';
    import FIMenu from '@core/classes/FIMenu';
    import LenderRetail from '@core/classes/LenderRetail';
    import Panel from '@core/components/Panel.vue';
    import PanelBuyersOrder from '@/components/PanelBuyersOrder.vue';
    import PanelCreditApplications from '@/components/PanelCreditApplications.vue';
    import PanelLender from '@/components/PanelLender.vue';
    import PanelPurchaseFigures from '@/components/PanelPurchaseFigures.vue';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';
    import VersionsLender from '@core/classes/LenderVersionClass';

    const { fimenu, validation } = defineProps<IPurchaseFiguresPageProps>();
    const $global = inject<SaturnGlobalPlugin>('$global');
    const EventBus = inject<Emitter<any>>('EventBus');

    // Provided Variables
    const onlyShowBalloonLenders = computed(() => fimenu.isDealLocked() && fimenu.isBalloon() && fimenu.loanTerms.getAcceptedTerm()?.balloonAmount > 0);
    const balloonLenders = computed((): string[] => {
        return (
            $global?.Lenders?.filter((lender: VersionsLender) => {
                const lenderByDealType = lender.versions?.[0].getLenderByDealType('Finance');
                if (lenderByDealType instanceof LenderRetail && lender.lenderCode !== 'UNLISTED') {
                    return (lenderByDealType as LenderRetail).allowBalloonPayment;
                }
                return false;
            }).map(lender => lender.lenderCode) ?? []
        );
    });

    const balloonIconCondition = (lender: VersionsLender) => {
        if (!fimenu.isFinance()) return false;
        return balloonLenders.value?.includes(lender.lenderCode);
    };

    const bo = ref();
    const docStamps = ref<number>(0);
    const preloadTaxes = ref<number>(0);

    const currentBuyersOrder = computed(() => fimenu.getCurrentBuyersOrder());
    const dealTypes = computed(() => DEAL_TYPES.toList().map(dt => dt.display));
    const displayCreditApplication = computed(() => DisplayCreditApplication(fimenu));

    const canSubmitToR1 = computed(() => {
        // If the deal is cash. We cannot submit the deal to the lenders.
        if (fimenu.isCash()) return false;

        // Wether we can send deals or not based on the store settings.
        return !!(fimenu.store?.storeSettings?.submitCustomersToFinanaceInstitutions == true);
    });

    //#region Initializers
    loadData();
    provide('balloonLenders', balloonLenders.value);
    provide('onlyShowBalloonLenders', onlyShowBalloonLenders.value);
    provide('balloonIconCondition', balloonIconCondition);

    watch(
        () => fimenu.preloads,
        () => {
            recalculate();
        },
        { deep: true },
    );

    watch(
        () => currentBuyersOrder.value?.additions,
        () => {
            recalculate();
        },
        { deep: true },
    );

    if (!fimenu.buyersOrderEnabled) {
        watch(
            () => fimenu.otdBase,
            () => {
                recalculate();
            },
            { deep: true },
        );
    }

    provide('buyersOrder', bo);
    onMounted(() => {
        EventBus.on('FIMenu:DealTypeChanged', handleDealTypeChange);
    });

    onBeforeUnmount(() => {
        EventBus.off('FIMenu:DealTypeChanged', handleDealTypeChange);
    });
    //#endregion

    function loadData() {
        if (fimenu.buyersOrderEnabled) {
            //IF WE DONT HAVE A BUYERS ORDER, GET IT
            if (!fimenu.getCurrentBuyersOrder()) {
                buyersOrderHelper.resetBuyersOrderInTheBackground(fimenu);
            }
        } else {
            calculateOTD();
        }
    }

    function recalculate() {
        if (fimenu.buyersOrderEnabled) {
            bo.value.recalculate();
        } else {
            calculateOTD();
        }
    }

    function calculateOTD() {
        const finalNumbers: any = fimenu.finalNumbers($global.selectedStore.storeState, null, null, false);
        preloadTaxes.value = finalNumbers.baseAmountTaxes;
        docStamps.value = finalNumbers.docStamps;
        fimenu.otd = finalNumbers.baseAmount + docStamps.value;
    }

    function handleDealTypeChange() {
        // This method must clear the lender, otherwise validation will allow users to continue if they have previously selected a lender.

        //Clear the lender in the deal. This will reset the customer in the deal.
        fimenu.lender.clear();
        fimenu.isEContracting = null;

        // If we have any credit apps to clear.
        if (fimenu.dealJacket.applications?.length) {
            // Clear selected credit apps.
            fimenu.dealJacket.applications.forEach((c: any) => {
                if (c.selected) c.selected = false;
            });
        }

        // Clear balloon
        if (!fimenu.isFinance()) {
            fimenu.loanTerms.isBalloon = false;
        }
    }
</script>
<style>
    .PurchaseFiguresPage-container .panel-left {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .r1-wrapper {
        display: flex;
        flex-direction: row;
        height: 100% !important;
        overflow: hidden;
    }

    .PurchaseFiguresPage-container .r1-wrapper > * {
        height: 100% !important;
        overflow: hidden;
    }

    .PurchaseFiguresPage-container .panel-left-flex-1 {
        flex: 1;
    }

    .PurchaseFiguresPage-container .buyers-order-row.preloads-list {
        grid-template-columns: 1fr 150px !important;
    }

    .PurchaseFiguresPage-container .buyers-order-row:nth-child(2n) {
        background: var(--richtable-alternating-background-color);
    }

    .fullHeight {
        height: 100%;
    }

    .r1-wrapper .panel-buyers-order {
        margin-top: 8px;
    }

    .PurchaseFiguresPage-container .buyers-order-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .PurchaseFiguresPage-wrapper {
        height: 100%;
    }

    .PurchaseFiguresPage-container .PurchaseFiguresPage-wrapper .panel-right {
        display: flex;
        gap: 15px;
        flex-direction: column;
        height: 100%;
    }

    .PurchaseFiguresPage-container .Content-Container {
        display: flex;
        flex-direction: column;
        padding-top: 8px;
    }
    .PurchaseFiguresPage-container .Content-Container > :first-child {
        height: calc(100% - 240px);
    }
    .PurchaseFiguresPage-container .Content-Container > :nth-child(2) {
        height: 235px;
    }
    .PurchaseFiguresPage-container .Content-Container > :only-child {
        height: 100%;
    }
</style>
