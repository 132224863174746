<template>
    <Panel :title="panelTitle" :action="panelActions" class="panel-car-fax">
        <div v-if="hasResponseError">
            <div>No CarFax Information Available.</div>
            <div v-if="response && response.description">{{ response.description }}</div>
        </div>

        <div v-else>
            <fieldset disabled :style="$grid('1-1-1')">
                <InputTextbox label="Warranty Start Date"
                              v-model:saturn="data.warrantyStartDateTime"
                              placeholder="Warranty Start Date"
                              :showIcon="false"
                              icon="fas fa-calendar" />

                <InputTextbox label="Last Odometer Reading Date"
                              v-model:saturn="data.lastOdometerReadingDateTime"
                              placeholder="Last Odometer Reading Date"
                              :showIcon="false"
                              icon="fas fa-calendar" />

                <InputTextbox label="Last Odometer Reading"
                              v-model:saturn="data.lastOdometerReading"
                              placeholder="Last Odometer Reading"
                              :showIcon="false"
                              icon="fas fa-hashtag" />
            </fieldset>

            <table class="borderless-table">
                <thead>
                    <tr>
                        <td />
                        <th>Manufacturer Months</th>
                        <th>Manufacturer Miles</th>
                        <th>Remaining Months</th>
                        <th>Remaining Miles</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Basic</th>
                        <td>{{data.originalBasicMonths}}</td>
                        <td>{{formatNumber(data.originalBasicMiles)}}</td>
                        <td>{{data.remainingBasicMonths}}</td>
                        <td>{{formatNumber(data.remainingBasicMiles)}}</td>
                    </tr>
                    <tr>
                        <th>Drivetrain</th>
                        <td>{{data.originalDrivetrainMonths}}</td>
                        <td>{{formatNumber(data.originalDrivetrainMiles)}}</td>
                        <td>{{data.remainingDrivetrainMonths}}</td>
                        <td>{{formatNumber(data.remainingDrivetrainMiles)}}</td>
                    </tr>
                    <tr>
                        <th>Roadside</th>
                        <td>{{data.originalRoadsideMonths}}</td>
                        <td>{{formatNumber(data.originalRoadsideMiles)}}</td>
                        <td>{{data.remainingRoadsideMonths}}</td>
                        <td>{{formatNumber(data.remainingRoadsideMiles)}}</td>
                    </tr>
                </tbody>
            </table>

            <fieldset disabled class="extra-carfax-notes">
                <InputTextbox label="Transferrable Note"
                              v-model:saturn="data.transferableNote"
                              placeholder="Transferable Note"
                              :showIcon="false"
                              multiline
                              :rows="(hasTransferrableNote) ? 2 : 1" />

                <InputTextbox label="Warranty Notes"
                              v-model:saturn="data.warrantyNotes"
                              placeholder="Warranty Notes"
                              :showIcon="false"
                              multiline
                              :rows="(hasWarrantyNotes) ? 8 : 1" />
            </fieldset>
        </div>
        <template #header>
            <TooltipComponent v-if="hasReportedAccident" :tooltip="[`Vehicle has Reported Accident/Damage - (${numberOfAccidents})`]" direction="right">
                <template #showFromOutside>
                    <i class="fas fa-car-crash bad"/>
                </template>
            </TooltipComponent>
        </template>
    </Panel>
</template>

<script>
    import FIMenuCarFax from '@core/classes/FIMenuCarFax'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import Panel from '@core/components/Panel.vue'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import util from '@core/services/util'

    export default {
        name: "PanelCarFax",
        components: {
            Panel: Panel,
            InputTextbox: InputTextbox,
            TooltipComponent: TooltipComponent,
        },
        props: {
            panelTitle: {
                type: String,
                default: () => 'CarFax Details'
            },
            data: [FIMenuCarFax, Object],
            response: Object, // Expects: { status: Boolean, description: String }
        },
        data() {
            return {}
        },
        computed: {
            util() {
                return util;
            },
            hasResponseError() {
                if (this.data?.lastOdometerReading) return false;
                return !this.response?.status || (!this.response && !this.data?.lastOdometerReading);
            },
            panelActions() {
                if (this.hasResponseError) return null;

                return [
                    {
                        text: 'View CarFax',
                        icon: 'fas fa-external-link-alt',
                        class: 'view-carfax-button ignore-all-locks',
                        onClick: this.openCarFaxPage
                    }
                ];
            },
            hasTransferrableNote() {
                return this.data.transferrableNote && !this.noCarFaxData(this.data.transferrableNote);
            },
            hasWarrantyNotes() {
                return this.data.warrantyNotes && !this.noCarFaxData(this.data.warrantyNotes);
            },
            hasReportedAccident() {
                return this.data?.reportedAccident?.accidentReported
            },
            numberOfAccidents() {
                return this.data?.reportedAccident?.reportedAccidentInfo?.length
            }
        },
        methods: {
            openCarFaxPage() {
                window.open(this.data.url, '_blank');
            },
            formatNumber(number) {
                if (number == null) return 'N/A';
                return this.$filters.prettyNumber(number);
            },
            noCarFaxData(dataField) {
                return dataField === 'No data reported to CARFAX.';
            }
        },
    };
</script>
<style>
    .panel-car-fax .basic-car-info {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;
    }

    .panel-car-fax .view-carfax-button {
        gap: 5px;
    }

    .panel-car-fax .borderless-table {
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    }

    .panel-car-fax .extra-carfax-notes > * {
        margin: 10px 0;
    }

    .panel-car-fax .input-component-wrapper textarea:disabled,
    .panel-car-fax .input-component-wrapper input:disabled {
        background-color: var(--card-background-color);
        color: var(--text-color);
    }
</style>