<template>
    <div :class="{'app-container': true, 'development' : isUserInDev} " @click="bodyClicked">

        <MultiStreamRecorder ref="multiStream" />
        <notifications group="saturn" dangerouslySetInnerHtml />
        <ModalManager />

        <MainHeader />
        
        <div class="routed-page">
            <router-view :key="$route.path"
                         :meetingsWithWaitingCustomers="meetingsWithWaitingCustomers" />
        </div>

        <button class="support-button"
                @click="clickHelp
        ">
            <i class="fas fa-question-circle" />
            Help
        </button>

        <div v-if="settings.environmentName != 'PRODUCTION'"
             class="staging-overlay" />
    </div>
</template>

<script>
    import util, { EventBusCore } from "@core/services/util";
    import $modal from "@core/services/modal";
    import api from "@core/services/api";
    import auth from "@core/services/auth";
    import LogRocket from "logrocket";
    import settings from "settings";
    
    import ENUMS from "@core/classes/Enums";
    import SaturnDesktopController from '@core/classes/SaturnDesktopController';
    
    import { broadcastSubscribeEvents, broadcastCallbacks } from "@core/helpers/broadcast-helper"
    
    import MainHeader from "@/components/MainHeader/MainHeader.vue";
    import ModalManager from "@core/components/ModalManager.vue";
    import MultiStreamRecorder from '@core/components/MultiStreamRecorder.vue';
    
    import modalHelp from "@core/modals/modalHelp.vue";
    import modalSeleniumVersionUpdate from '@/modals/modalSeleniumVersionUpdate.vue'

    export default {
        name: "app",
        data() {
            return {
                currentQuery: null,
                routeWatch: null,
                meetingsWithWaitingCustomers: [],
                helpOpen: false,
                requestUpdateOpen: false,
                timer: null,
                browserSeleniumVersion: null,
                desktopApp: null
            };
        },
        components: {
            MainHeader,
            ModalManager,
            MultiStreamRecorder,
        },
        computed: {
            payload() {
                return auth.getTokenPayload();
            },
            disableAll() {
                return this.$global.disableAllInput;
            },
            settings() {
                return settings;
            },
            isUserInDev() {
                return this.settings.environmentName === "DEVELOPMENT";
            },
            isMobileBrowser() {
                return navigator?.userAgentData?.mobile;
            },
        },
        beforeUnmount() {
            this.EventBus.off('BroadcastReconnect', this.broadcastReconnectEmitterHandler);
            this.EventBus.off('checkSeleniumVersion', this.checkSeleniumVersionManually)
            EventBusCore.off("toastr", this.toastrEmitterHandler);

            //to not allow multiple help modals open at the same time
            this.EventBus.off("helpOpen", this.helpOpenEmitterHandler);
        },
        mounted() {
            this.initDesktopAppConnection();

            this.subscribeToBroadcastEvents()

            
            if (this.isMobileBrowser || /iPAD|iPHONE|iPOD|OS|MAC/.test(navigator.userAgent.toUpperCase())) {
                // eslint-disable-next-line no-console
                console.log("This is an iOS device.");
                document.getElementById('app').classList.add('ipadMode')
            }

            this.EventBus.on('BroadcastReconnect', this.broadcastReconnectEmitterHandler);
            this.EventBus.on('checkSeleniumVersion', this.checkSeleniumVersionManually)
            EventBusCore.on("toastr", this.toastrEmitterHandler);

            //to not allow multiple help modals open at the same time
            this.EventBus.on("helpOpen", this.helpOpenEmitterHandler);
        },
        async created() {
            if (localStorage.getItem("Saturn-Theme") == "") {
                localStorage.setItem("Saturn-Theme", this.payload.EmployeeTheme);
            }

            document.body.setAttribute("class", localStorage.getItem("Saturn-Theme"));

            const colorScheme = window.getComputedStyle(document.body)?.getPropertyValue("color-scheme");
            document.body.classList.add(colorScheme === "dark" ? "dark-palette" : "light-palette");

            // //Check meeting singleton to see if there are any meetings without an fimanager in them (customer is waiting)
            //  setInterval(async () => {
            //      const response = await api.meeting.meetingsWithWaitingCustomers();
            //      if (response !== null || response !== undefined) {
            //          this.meetingsWithWaitingCustomers = response.data ?? [];
            //      }
            //      //console.log('checking meeting singleton for meetings with waiting customers', this.meetingsWithWaitingCustomers);

            //  }, 5000)
            this.setupLogRocket();
        },
        methods: {
            broadcastReconnectEmitterHandler() {
                this.subscribeToBroadcastEvents()
            },
            helpOpenEmitterHandler() {
                this.helpOpen = false;
            },
            toastrEmitterHandler(notifData) {
                const { title, type, duration, text } = notifData

                this.$notify({
                    group: "saturn",
                    title: title + "<div style='position: absolute; top: 7px; right: 15px;'>x</div>",
                    type: type,
                    duration: duration,
                    text: util.formatToastrText(text),
                    closeOnClick: true,
                });
            },
            initDesktopAppConnection() {
                // Initialize the SaturnDesktopController instance
                this.desktopApp = new SaturnDesktopController(this.onSaturnAppConnected);
            },
            onSaturnAppConnected() {
                if (this.desktopApp.isConnected) {
                    this.timer = setInterval(async () => {
                        if (!this.browserSeleniumVersion) {
                            this.browserSeleniumVersion = window.desktopAppVersion
                            const footerDesktopVersion = document.getElementsByTagName('footer')
                            if (footerDesktopVersion) footerDesktopVersion[0].innerHTML = `${footerDesktopVersion[0].innerHTML} DesktopApp Version: ${this.browserSeleniumVersion}`
                        }
                        await this.checkSeleniumVersion()

                        // eslint-disable-next-line no-console
                        console.log("Checking Selenium Version:", this.browserSeleniumVersion);
                    }, 5000);
                }
            },
            checkSeleniumVersionManually() {
                this.checkSeleniumVersion(true)
            },
            async checkSeleniumVersion(manualCheck = false) {
                if ((!this.requestUpdateOpen || manualCheck) && this.browserSeleniumVersion) {
                    const { data } = await api.settings.getSeleniumVersion()

                    if (this.isNewerVersion(data.currentVersion, this.browserSeleniumVersion)) {
                        if (this.isNewerVersion(data.minimumRequiredVersion, this.browserSeleniumVersion)) {
                            this.seleniumVersionModalOpen(true)
                        } else {
                            this.seleniumVersionModalOpen()
                        }
                    }
                }
            },
            seleniumVersionModalOpen(updateRequired = false) {
                this.requestUpdateOpen = true
                $modal.open(modalSeleniumVersionUpdate, {
                    name: 'modalSeleniumVersionUpdate',
                    passedData: {
                        updateRequired,
                        updateFunction: () => this.desktopApp.update()
                    },
                    postFunction: (data) => {
                        clearInterval(this.timing)
                    },
                })
            },
            isNewerVersion(version1, version2) {
                const v1Array = version1 ? version1.split('.') : [];
                const v2Array = version2 ? version2.split('.') : [];
                for (var i = 0; i < (Math.max(v1Array.length, v2Array.length)); i++) {

                    if (v1Array[i] == null) { v1Array[i] = 0; }
                    if (v2Array[i] == null) { v2Array[i] = 0; }

                    if (parseInt(v1Array[i]) != parseInt(v2Array[i])) {
                        return parseInt(v1Array[i]) > parseInt(v2Array[i])
                    }
                }
                return false
            },
            subscribeToBroadcastEvents() {
                this.$meetingHub.connect()
                broadcastSubscribeEvents(this.$meetingHub, [
                    {
                        event: ENUMS.EVENT_EMITS.BROADCAST.SEND_MESSAGE,
                        callback: broadcastCallbacks.openModalBroadcastMessages
                    }
                ])
            },
            clickHelp() {
                if (!this.helpOpen) {
                    this.helpOpen = true;
                    $modal.open(modalHelp, {
                        name: "modalHelp",
                        passedData: { login: true, isOpen: this.helpOpen },
                        postFunction: () => {
                            return;
                        },
                        backdrop: false,
                    });
                }
            },
            bodyClicked(e) {
                this.EventBus.emit("bodyClicked", e);
            },
            setupLogRocket() {
                //login - name - date - User_Session

                //Explicit Start new session if we refresh after 24hr period
                if (util.getCookie("_lr_saturn_init") === null) {
                    LogRocket.startNewSession();
                }

                LogRocket.identify(
                    `${this.payload.EmployeeLogin} - ${this.payload.EmployeeName} - ${util
                        .toMoment(Date.now())
                        .format("MM/DD/YYYY")} - User_Session`,
                    {
                        user: this.payload.EmployeeLogin,
                        name: this.payload.EmployeeName,
                        employeeCode: this.payload.EmployeeCode,
                        email: this.payload.EmployeeEmail,
                        storeCode: this.$global.selectedStore.storeCode,
                        environment: settings.environmentName,
                    }
                );
            },
        },
    };
</script>

<style>
html, body {
    height: 100vh; /* Ensure the full viewport height is used */
    margin: 0;
    overflow: hidden;
}

div#app {
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--footer-height));
}

.app-container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%; /* Fill the parent height */
}


.app-container .routed-page {
    flex-grow: 1; /* Take up all remaining space */
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden; 
    height: calc(100% - var(--header-height));
}

footer {
    width: 100%;
    bottom: 0;
    z-index: 11111;
    font-weight: 800;
    font-size: 0.8rem;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: var(--footer-height);
    color: var(--disabled-color);
    background-color: var(--secondary-color);
}





    .support-button {
        width: 70px;
        position: fixed;
        bottom: 10px;
        right: 10px;
        /*big number good*/
        z-index: 9999999999999999999;
        color: var(--background-color);
        background: var(--main-color);
        text-decoration: none;
        font-size: 15px;
        width: 4%;
        height: 40px;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        gap: 5px;
    }

    body:has(.modals-container:not(:empty)) footer {
        z-index: 1;
    }

    

    .app-container .staging-overlay {
        pointer-events: none;
        box-shadow: inset 0 0 5px 5px orangered;
        animation: 1s ease-in-out 1 show-staging-overlay;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999999999999999;
    }

    @keyframes show-staging-overlay {
        from {
            box-shadow: inset 0 0 5px -2px orangered;
        }

        to {
        }
    }

    body:has(.app-container.development) iframe#launcher-frame {
        display: none;
    }

    

    .routed-page h1 {
        margin-top: 0;
        color: var(--third-color);
    }

    
</style>
