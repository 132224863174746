<template>
	<div class="SelectVehicleContainer">
		<InputRichDropdown
			:saturn="selectedVehicle"
			:valueMap="predicateVin"
			:display="predicateDisplays"
			:list="vehiclesList"
			:sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
			@change="handleVehicleSelection"
			:label="label"
			:disabled="disabled"
			nothingSelected="">
		</InputRichDropdown>
	</div>
</template>

<script lang="ts">
	import ENUMS from "@core/classes/Enums"
	import util from '@core/services/util'
	import InputRichDropdown from '@core/components/InputRichDropdown.vue'
	import api from '@core/services/api'
	import { defineComponent, PropType } from 'vue'
	import FIMenu from '@core/classes/FIMenu'
	import FIMenuTradeIn from '@core/classes/FIMenuTradeIn'
	import DDIResponseGetDealsPayload, { DDIVehicleInquiryResponse } from '@core/classes/DDI/DDIResponseGetDealsPayload'

	export default defineComponent({
		name: 'SelectVehicle',
		props: {
			fimenu: {
				type: FIMenu,
				required: false,
			},
			label: {
				type: String,
				required: false,
				default: 'Select Vehicle',
			},
			disabled: {
                type: [Boolean, Function],
                default: false
            },
		},
		data() {
			return {
				newVehicle: null,
				registrationInfo: this.fimenu.vehicle.registrationInfo,
				vehicleInquiries: [] as DDIVehicleInquiryResponse[],
			}
		},
		computed: {
			ENUMS(): typeof ENUMS {
				return ENUMS
			},
			tradeIns(): FIMenuTradeIn[] {
				return this.fimenu.tradeIns as FIMenuTradeIn[]
			},
			vehiclesList(): FIMenuTradeIn[] {
				return this.tradeIns.filter((veh) => veh.plateNumber && veh.plateState)
			},
			selectedVehicle(): string {
				return this.tradeIns.find((x) => x.plateNumber == this.fimenu.vehicle.registrationInfo.plateNumber)?.vin
			},
		},
		methods: {
			predicateVin(vehicle: FIMenuTradeIn): string {
				return vehicle.vin ?? null
			},
			predicateDisplays(vehicle: FIMenuTradeIn): string {
				return `${vehicle.plateNumber} - ${vehicle.make} ${vehicle.model}`
			},
			handleVehicleSelection(vin: string) {
				// NO VIN = NEW TEMPORARY PLATE
				const tradeInVehicle = this.tradeIns.find((veh) => veh.vin === vin)

				if (!tradeInVehicle) return
				//this.selectedVehicle = tradeInVehicle.plateNumber;
				this.registrationInfo.controlNumber = null
				this.registrationInfo.plateNumber = tradeInVehicle.plateNumber
				this.registrationInfo.plateState = tradeInVehicle.plateState
			},
			async getVehiclesLastInquiry(): Promise<DDIVehicleInquiryResponse[]> {
				const promises = this.vehiclesList.map((veh) => {
					return Promise.resolve(api.plateTransactions.getLastVehicleInquiryVin(veh.vin, this.fimenu.storeCode))
				})

				const responses = await Promise.all(promises)
				const inquiries = [] as DDIVehicleInquiryResponse[]

				responses.forEach((response: any) => {
					const payloadRes = new DDIResponseGetDealsPayload(response.data?.response?.payload)
					if (payloadRes.vehicleInquiryResult.length) {
						inquiries.push(...payloadRes.vehicleInquiryResult)
					}
				})

				this.vehicleInquiries = inquiries
				return inquiries
			},
		},
		components: {
			InputRichDropdown,
		},
	})
</script>
<style>
	.SelectVehicleContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.SelectVehicleContainer .custom-tooltip {
		position: relative;
	}

	.SelectVehicleContainer .custom-tooltip:hover::before {
		content: attr(ttmesage);
		background: white;
		padding: 5px 10px;
		position: absolute;
		bottom: 110%;
		right: 50%;
		transform: translateX(50%);
		color: var(--text-color);
		border: 1px solid var(--border-color);
		border-radius: 3px;
		min-width: 250px;
		box-shadow: 1px 2px 5px 2px var(--black-20percent);
		z-index: 100;
	}

	.SelectVehicleContainer .custom-tooltip:hover::after {
		/* the arrow */
		border: 10px solid #000;
		border-color: transparent black transparent transparent;
	}

	.VehicleContainer {
		transform: scale(1); /* Initial scale factor set to 1 (normal size) */
		transition: transform 0.1s; /* Add a 1-second transition for the transform property */
		min-height: 55px;
		height: auto;
		border-bottom: 1px solid var(--main-color);
		padding: 10px;
		margin-bottom: 0px !important;
		box-sizing: border-box;
		align-items: center;
	}

	.VehicleContainer:not(:disabled):hover {
		transform: scale(1.005);
		background-color: var(--black-10percent);
	}

	.VehicleContainer:disabled {
		background-color: var(--black-20percent);
		color: var(--disabled-color);
		cursor: default;
	}

	.SubContentText {
		font-size: 12px;
		font-weight: bold;
		margin: 0px;
	}

	.SelectVehicleContainer .accordion-label {
		color: var(--main-color);
		font-weight: bold;
	}

	.SelectChild {
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		align-items: center;
		height: 100%;
	}

	.SelectChild:disabled {
		opacity: 0.7;
	}

	.SelectInfoContainer {
		flex-direction: column;
		align-items: flex-end;
	}

	.SelectVehicleContainer .label {
		margin-bottom: 1%;
	}
</style>
